/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { useForm } from "../../hooks/useForm";
import { FileFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import UploadInput from "../../../../components/Input/UploadInput";
import { composeFieldPath } from "./utils";

const FormUploadInput: FC<FileFieldType & { groupPath: string }> = ({
  name,
  label,
  groupPath,
  accept,
}) => {
  const { handleFileUpload, handleFileDelete } = useForm();

  const [value] = useFieldValue(name, groupPath);
  const fieldPath = composeFieldPath(groupPath, name);

  return (
    <UploadInput
      label={label}
      file={null}
      onDelete={() => handleFileDelete(name, groupPath)}
      fileName={value?.fileName}
      fileType={accept}
      handleChange={(e) => handleFileUpload(e, groupPath)}
      name={name}
      id={fieldPath}
    />
  );
};

export default FormUploadInput;

/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from "react";
import { useForm } from "../../hooks/useForm";
import { TextFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import PriceInput from "../../../../components/Input/PriceInput";
import useDebounce from "../../../../hooks/useDebounce";

const FormInputMonetary: FC<TextFieldType & { groupPath: string }> = ({
  type,
  name,
  label,
  disabled = false,
  groupPath,
}) => {
  const { handleChange, startEditing, finishEditing } = useForm();

  const [value, inputError] = useFieldValue(name, groupPath);
  const [localValue, setLocalValue] = React.useState<string>(value);
  const { debouncedValue } = useDebounce(localValue, 1000);

  const handleInputChange = (newValue: any) => {
    setLocalValue(newValue);
  };
  useEffect(() => {
    if (debouncedValue !== value) {
      handleChange(
        {
          target: { name, value: debouncedValue },
        } as React.ChangeEvent<HTMLInputElement>,
        groupPath
      );
    }
  }, [debouncedValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <PriceInput
      placeholder={label.toLowerCase()}
      disabled={disabled}
      type={type}
      name={name}
      value={localValue}
      handleChange={handleInputChange}
      onFocus={() => startEditing(groupPath, name)}
      onBlur={() => finishEditing(groupPath, name)}
      errorMessage={inputError}
    />
  );
};

export default FormInputMonetary;

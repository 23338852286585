/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames, theme } from "../../../../../../../../theme";
import Icon from "../../../../../../../../components/Icons";
import { IconSizes } from "../../../../../../../../components/Icons/styles/iconSizes";
import { IconNames } from "../../../../../../../../components/Icons/styles/iconNames";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../../../../hooks/useCSSRulesWithTheme";
import Typography from "../../../../../../../../components/Typography";
import CustomLink from "../../../../../../../../components/CustomLink";
import Tooltip from "../../../../../../../../components/Tooltip";

interface ChartBarProps {
  label: string;
  count: number;
  iconName: IconNames;
  redirectTo: string;
  maxCount: number;
  color: string;
}

const getCSSRules: CSSRulesResolver<{
  color: string;
  height: number;
}> = ({ height, color }) => ({
  barContainer: {
    height: "100%",
    textAlign: "center",
    position: "relative",
    cursor: height > 0 ? "pointer" : "not-allowed",
    "& > span": {
      cursor: height > 0 ? "pointer" : "not-allowed",
    },
    "&:hover": {
      "& > div > div > div": {
        display: "block",
      },
      "& path": height > 0 && {
        fill: theme.colors.Blue[100],
      },
    },
  },
  bar: {
    height: `calc(100% - ${IconSizes.Medium + 6}px)`,
    marginBottom: 6,
    position: "relative",
  },
  blankBar: {
    height: `${100 - height}%`,
  },
  filledBar: {
    height: `${height}%`,
    width: "100%",
    boxSizing: "border-box" as const,
    backgroundColor: color,
    position: "relative",
  },
  value: {
    position: "absolute",
    top: 0,
    width: "100%",
    display: "none",
  },
});

const ChartBar: FC<ChartBarProps> = (props) => {
  const { label, count, maxCount, iconName, color, redirectTo } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, {
    color,
    height: (count / maxCount) * 100,
  });

  return (
    <CustomLink to={count ? redirectTo : ""}>
      <div css={styles.barContainer}>
        <div css={styles.bar}>
          <div css={styles.blankBar} />
          <div css={styles.filledBar}>
            {count > 0 && (
              <Typography
                variant="textSmall"
                component="div"
                weight="bold"
                css={styles.value}
              >
                {count}
              </Typography>
            )}
          </div>
        </div>
        <Tooltip id={`chart-icon-${label}`} text={label}>
          <Icon
            name={iconName!}
            size={IconSizes.Medium}
            color={ColorNames.BLACK}
            disabled={!count}
          />
        </Tooltip>
      </div>
    </CustomLink>
  );
};

export default ChartBar;

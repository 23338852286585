/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react";
import { CSSProperties, FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { useUIStore } from "../../hooks/useUIStore";
import { ColorNames, getThemeColorFromAlias, theme } from "../../theme";
import HR from "../HorizontalLine";
import Typography from "../Typography";

const getCSSRules: CSSRulesResolver = ({ ...props }) => ({
  mainLarge: {
    position: "fixed" as CSSProperties["position"],
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `${props.colors.White[60]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  extraLarge: {
    fontSize: 80,
  },
  large: {
    fontSize: 50,
  },
  small: {
    fontSize: 16,
    padding: "0px 10px",
  },
});

const rotate = keyframes`
from {
  rotate: 0deg;
}
to {
  rotate: 180deg;
}
`;
const rotateBar = { animation: `${rotate} 1s infinite` };

interface LoadingProps {
  size: "small" | "large" | "extraLarge";
}

const Loading: FC<LoadingProps> = (props) => {
  const { isLoading, loadingMessage, tabColor } = useUIStore();
  const color = getThemeColorFromAlias(tabColor, theme.colors);
  const loadingColor = {
    color: color,
  };
  const styles = useCSSRulesWithTheme(getCSSRules, props);
  const { size } = props;
  const loadingContainer =
    size === "extraLarge" ? styles.mainLarge : styles.main;
  if (isLoading) {
    return (
      <div css={loadingContainer}>
        {loadingMessage && size === "large" ? (
          <>
            <Typography
              uppercase
              variant="textLarge"
              align="center"
              component="div"
              color={tabColor}
            >
              {loadingMessage}
            </Typography>
            <HR color={ColorNames.GREEN} width="80%" />
          </>
        ) : null}
        <span css={css(styles[size], rotateBar, loadingColor)}>/</span>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Loading;

/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { CustomTheme, theme } from "../../../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { BaseFontSize } from "../../../../components/Typography";
import VariableFieldColumn from "../utils/VariableFieldColumn";
import { InputVariant, Maybe } from "../../../../graphql/generated/types";
import FormLabel from "../FormLabel";

type DefaultFieldSummaryProps = {
  label: string;
  value: string;
  variant?: Maybe<InputVariant>;
};

const getStyles: CSSRulesResolver = ({ colors }: CustomTheme) => ({
  formField: {
    color: colors.Black[100],
    width: "100%",
    height: 45,
    borderRadius: 5,
    border: "0.2px solid rgba(0, 0, 0, 0.2)",
    paddingLeft: 12,
    lineHeight: "45px",
    textAlign: "start",
    backgroundColor: colors.White[100],
    boxSizing: "border-box",
    boxShadow: theme.boxShadow.default,
    overflow: "hidden",
    fontSize: BaseFontSize,
  },
});

const DefaultFieldSummary: FC<DefaultFieldSummaryProps> = ({
  label,
  value,
  variant,
}) => {
  const styles = useCSSRulesWithTheme(getStyles);
  return (
    <VariableFieldColumn variant={variant}>
      <FormLabel>{label}</FormLabel>
      <div css={styles.formField}>{value}</div>
    </VariableFieldColumn>
  );
};

export default DefaultFieldSummary;

import { useMemo } from "react";
import { CategoryDashboardCounters } from "../../../graphql/generated/types";
import { StageChartsProps } from "../components/StageCharts";
import { StageChartProps } from "../components/StageCharts/components";
import { TFilter } from "../../../filters/types";
import { ColorNames } from "../../../theme";

export type TChartCard<
  GroupFilter extends TFilter = TFilter,
  ButtonFilter extends TFilter = TFilter
> = {
  color: ColorNames;
  category: string;
  label?: string;
  groupFilter: [GroupFilter, GroupFilter["options"][number]["name"]];
  buttonFilters: [ButtonFilter, ButtonFilter["options"][number]["name"][]];
  mergeFilters?: boolean;
};

const useDashboardCharts = (
  dashboardCharts: TChartCard[],
  data: CategoryDashboardCounters[],
  loading: boolean,
  redirectLocation: string
) => {
  const getGroupCount = (category: string) => {
    const stageData = data?.find((counter) => counter.value === category);
    return stageData?.count || 0;
  };

  const getStatusCount = (category: string, status: string) => {
    const stageData = data?.find((counter) => counter.value === category);
    const statusData = stageData?.statuses?.find(
      (counter) => counter.value === status
    );
    return statusData?.count || 0;
  };

  const chartData: StageChartsProps = useMemo(() => {
    const data: StageChartProps[] = dashboardCharts?.map((chart) => {
      const groupFilter = chart.groupFilter[0];
      const groupOption = groupFilter.options.find(
        ({ name }) => chart.groupFilter[1] === name
      )!;
      chart.groupFilter[1];
      const statusFilter = chart.buttonFilters[0];
      const statusOptions = statusFilter.options.filter(({ name }) =>
        chart.buttonFilters[1].includes(name)
      );
      return {
        label: chart.label || groupOption.label,
        count: getGroupCount(chart.category),
        urlParam: [groupFilter.urlParam, groupOption.value],
        color: chart.color,
        redirectLocation: redirectLocation,
        statuses: statusOptions.map((option) => {
          return {
            label: option?.label!,
            count: getStatusCount(chart.category, option.name!),
            iconName: option?.iconName!,
            urlParam: [statusFilter.urlParam, option.value],
            mergeFilters: chart.mergeFilters,
          };
        }),
      };
    });
    return { data: data, loading: loading };
  }, [data, loading]);

  return chartData;
};

export default useDashboardCharts;

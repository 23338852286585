/** @jsxImportSource @emotion/react */

import { InputHTMLAttributes, useMemo } from "react";
import { FC } from "react";
import { BaseFontSize } from "../Typography";
import { ColorNames, theme } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";

export const messageInputHeight = 45;

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: messageInputHeight,
    borderRadius: 5,
    border: `0.2px solid ${theme.colors.Grey[100]}`,
    backgroundColor: theme.colors.White[100],
    padding: 12,
    boxSizing: "border-box" as "border-box",
  },
  textarea: {
    fontSize: BaseFontSize,
    fontFamily: "inherit",
    border: "none",
    width: "100%",
    height: "100%",
    resize: "none" as "none",
    "&:focus-visible": {
      outline: "none",
      border: "none",
    },
    "::placeholder": {
      color: theme.colors.Grey[100],
    },
  },
};

interface MessageInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  onSend: () => void;
  value: string;
  color?: ColorNames;
}

const MessageInput: FC<MessageInputProps> = ({
  value,
  color,
  onSend,
  ...props
}) => {
  const { onChange } = props;

  const disabled = useMemo(() => {
    return value.length === 0;
  }, [value]);

  const handleSend = () => {
    if (disabled) return;
    onSend();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const iconColor = useMemo(() => {
    return disabled ? ColorNames.GREY : color;
  }, [disabled]);

  return (
    <div css={styles.container}>
      <textarea
        css={styles.textarea}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        value={value}
        {...props}
      />
      <Icon
        name={IconNames.Send}
        color={iconColor}
        onClick={handleSend}
        disabled={disabled}
      />
    </div>
  );
};

export default MessageInput;

import { IconNames } from "../components/Icons/styles/iconNames";
import {
  DocumentStatus,
  InsuranceTypeGroupEnum,
} from "../graphql/generated/types";

export const insuranceTypeGroupsIcons = {
  [InsuranceTypeGroupEnum.Agricultural]: IconNames.Agriculture,
  [InsuranceTypeGroupEnum.Machinery]: IconNames.Machine,
  [InsuranceTypeGroupEnum.Forest]: IconNames.Forest,
  [InsuranceTypeGroupEnum.PropertyImprovement]: IconNames.Benefectory,
  [InsuranceTypeGroupEnum.Livestock]: IconNames.Pecuary,
};

export const documentStatusIcons = {
  [DocumentStatus.Pending]: IconNames.Pending,
  [DocumentStatus.Incomplete]: IconNames.Incomplete,
  [DocumentStatus.Done]: IconNames.Done,
  [DocumentStatus.Sent]: IconNames.Sent,
  [DocumentStatus.Rejected]: IconNames.Rejected,
  [DocumentStatus.Effective]: IconNames.Valid,
  [DocumentStatus.UnderAnalysis]: IconNames.Proposal,
  [DocumentStatus.Cancelled]: IconNames.Close,
  [DocumentStatus.NonEffective]: IconNames.Close,
};

import {
  Payment,
  PaymentStatus,
  SubventionType,
} from "../../../graphql/generated/types";

const isThisYearSubventionType = (
  payment: Payment,
  subventionType: SubventionType
) => {
  if (
    !payment.dateDue ||
    payment.subventionType != subventionType ||
    payment.status === PaymentStatus.Cancelled
  )
    return false;
  const thisYear = new Date().getFullYear();
  return new Date(payment.dateDue).getFullYear() === thisYear;
};

export const getSubventionTotalsThisYear = (payments: Payment[]) => {
  return payments.reduce(
    (totals, payment) => {
      if (isThisYearSubventionType(payment, SubventionType.Federal)) {
        totals[SubventionType.Federal] += payment.amount;
      } else if (isThisYearSubventionType(payment, SubventionType.State)) {
        totals[SubventionType.State] += payment.amount;
      }
      return totals;
    },
    { [SubventionType.Federal]: 0, [SubventionType.State]: 0 }
  );
};

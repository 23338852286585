import Typography from "../../../components/Typography";
import { UserObject } from "../../../models/User";
import { orgDepartmentRoleLabels } from "../../../shared/org/departmentRoles";
import { formatRg, formatCpfCnpj } from "../../../utils/formatNumber";
import TermLine from "./TermLine";
import { FC } from "react";
import { getAddressString, getCommissionValueString } from "./termsUtils";

const DefaultOrgContractText: FC<{ user: UserObject }> = ({ user }) => {
  const org = user.org!;
  const address = org.address!;
  const { contact } = user;

  const cnpj = formatCpfCnpj(contact.cpfCnpj!);
  const rg = formatRg(contact.rg!);
  const role = orgDepartmentRoleLabels[contact.departmentRole!];
  const addressString = getAddressString(address);
  const commissionValueString = getCommissionValueString(org.commission!);
  return (
    <>
      {/* Title */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          ACORDO DE PARCERIA COMERCIAL
        </Typography>
      </TermLine>

      {/* Introduction */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Pelo presente instrumento particular:
        </Typography>
      </TermLine>

      {/* Party I */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>I.</strong> Prisma Corretora de Seguros Ltda., sociedade
          empresária limitada, com sede na cidade de São Paulo, Estado de São
          Paulo, na Alameda Ministro Rocha Azevedo, nº 912, Letra E37, CEP
          01410-002, inscrita no CNPJ sob o nº 57.061.327/0001-90, representada
          neste ato na forma de seu contrato social (“
          <strong>Contratante</strong>”); e
        </Typography>
      </TermLine>

      {/* Party II */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>II.</strong> {org.name}, com sede na cidade de {address.city},
          Estado de {address.state}, na {addressString}, CEP {address.cep},
          inscrita no CNPJ sob o nº {cnpj}, representada neste ato por{" "}
          {contact.name}, {role}, portador do documento nº {rg}, na forma de seu
          documento constitutivo (“<strong>Contratado</strong>”) e, em conjunto
          com a Contratante, as (“<strong>Partes</strong>”).
        </Typography>
      </TermLine>

      {/* Considerations */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>CONSIDERANDO QUE:</strong>
        </Typography>
      </TermLine>

      {/* Considerations Introduction */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Ambas as partes desejam estabelecer uma parceria comercial para
          benefício mútuo, concordam com o seguinte:
        </Typography>
      </TermLine>

      {/* Consideration I */}
      <TermLine subSection="I.">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Prisma é uma corretora de seguros devidamente registrada na
          Superintendência de Seguros Privados – SUSEP e opera no mercado de
          seguros há mais de 35 anos, tendo se especializado no segmento rural;
        </Typography>
      </TermLine>

      {/* Consideration II */}
      <TermLine subSection="II.">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          {org.name} é uma empresa especializada em [descrever o ramo de atuação
          da empresa].
        </Typography>
      </TermLine>

      {/* Clause 1 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 1ª - OBJETIVO DA PARCERIA
        </Typography>
      </TermLine>

      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Buscando estimular a difusão do Seguro Rural como insumo essencial no
          êxito econômico da exploração agrícola, as partes concordam em
          estabelecer uma parceria comercial com o objetivo de facilitar a
          obtenção de seguros rurais para os clientes da {org.name}.
        </Typography>
      </TermLine>

      {/* Clause 2 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 2ª – RESPONSABILIDADE DA PRISMA CORRETORA DE SEGUROS
        </Typography>
      </TermLine>

      {/* Responsibilities of Prisma */}
      <TermLine section="2.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Entender as necessidades individuais ou empresariais dos clientes de{" "}
          {org.name} e ajudá-los a determinar que tipo de seguros são
          apropriados.
        </Typography>
      </TermLine>

      <TermLine section="2.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Identificar as melhores opções de seguros rurais para atender às
          necessidades dos clientes da {org.name}.
        </Typography>
      </TermLine>

      <TermLine section="2.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Apresentar as opções de seguros disponíveis aos clientes e explicar os
          detalhes das políticas.
        </Typography>
      </TermLine>

      <TermLine section="2.4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Coordenar a emissão de apólices de seguro para os clientes após a
          seleção das coberturas adequadas, disponibilizando informações claras
          sobre os termos, condições e custos dos seguros oferecidos.
        </Typography>
      </TermLine>

      <TermLine section="2.5">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Prestar suporte contínuo aos clientes, auxiliando-os com sinistros,
          renovações e quaisquer alterações nas apólices.
        </Typography>
      </TermLine>

      <TermLine section="2.6">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Auxiliar os clientes no processo de apresentação de reclamações de
          sinistro e facilitar a comunicação com a seguradora.
        </Typography>
      </TermLine>

      {/* Clause 3 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 3ª – RESPONSABILIDADES DA {org.name!.toUpperCase()}
        </Typography>
      </TermLine>

      {/* Responsibilities of the Contracted Company */}
      <TermLine section="3.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Promover a conscientização entre seus clientes sobre a importância dos
          Seguro Rural e Agroindustrial.
        </Typography>
      </TermLine>

      <TermLine section="3.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Facilitar a comunicação entre a Prisma e os clientes interessados.
        </Typography>
      </TermLine>

      <TermLine section="3.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Coletar informações necessárias dos clientes para fins de contratação
          e renovação dos seguros.
        </Typography>
      </TermLine>

      <TermLine section="3.4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Colaborar com a Prisma na divulgação desta parceria entre os clientes
          da {org.name}.
        </Typography>
      </TermLine>

      {/* Clause 4 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 4ª – REMUNERAÇÃO E CONDIÇÕES DE PAGAMENTO
        </Typography>
      </TermLine>

      <TermLine section="4.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Em contrapartida à prestação dos Serviços, a Prisma deverá à{" "}
          {org.name}, {commissionValueString} do comissionamento registrado em
          cada apólice contratada pelos clientes.
        </Typography>
      </TermLine>

      <TermLine section="4.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O pagamento da remuneração será realizado, pela Prisma à {org.name},
          após recebimento do respectivo comissionamento pela Prisma e mediante
          recebimento da nota fiscal de serviço apresentada pela {org.name}.
          Fica entendido e acordado que a remuneração correspondente às
          participações devidas à {org.name}, nos termos da Cláusula 4.1, será
          calculada sobre o montante líquido recebido pela Prisma (deduzido
          todos os impostos devidos) e o pagamento poderá ser diferido de acordo
          com a vigência da apólice e nos mesmos termos do pagamento do
          comissionamento à Prisma.
        </Typography>
      </TermLine>

      <TermLine section="4.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Prisma poderá reter o pagamento, e os pagamentos serão efetuados,
          sem nenhum custo ou multa contratual, após a regularização das
          pendências, nas seguintes hipóteses: (i) se a {org.name} deixar de
          apresentar os documentos exigidos neste Contrato, ou, nestes, sejam
          verificadas quaisquer irregularidades ou (ii) se houver erro de
          faturamento ou divergência de valor.
        </Typography>
      </TermLine>

      <TermLine section="4.4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A remuneração especificada nesta Cláusula 4ª equivale à remuneração
          total da {org.name}, por todas as despesas pelos trabalhos prestados
          associadas com os Serviços.
        </Typography>
      </TermLine>

      {/* Clause 5 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 5ª – CONFIDENCIALIDADE E PROPRIEDADE INTELECTUAL
        </Typography>
      </TermLine>

      <TermLine section="5.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes reconhecem que durante a vigência deste Contrato todas as
          informações e documentos, inclusive dados, informações comerciais,
          estudos, projetos, especificações técnicas, dentre outras informações,
          aos quais tenham acesso em decorrência da parceria objeto deste
          Contrato deverão ser tratadas como confidenciais.
        </Typography>
      </TermLine>

      {/* Clause 6 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 6ª - PRAZO DE VIGÊNCIA CONTRATUAL
        </Typography>
      </TermLine>

      <TermLine section="6.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O prazo de vigência deste Contrato é de 12 (doze) meses, a contar da
          data de assinatura. Caso as Partes concordem em renovar o prazo deste
          Contrato, as Partes deverão, antes do fim do prazo de vigência de 12
          (doze) meses, celebrar um aditivo a esse contrato refletindo o novo
          prazo acordado.
        </Typography>
      </TermLine>

      {/* Clause 7 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 7ª – RESCISÃO
        </Typography>
      </TermLine>

      <TermLine section="7.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Qualquer uma das Partes poderá rescindir este Contrato mediante aviso
          prévio por escrito de 30 (trinta) dias. Em caso de rescisão imotivada
          pela Prisma, esta compromete-se a pagar pelos serviços efetivamente
          prestados até a data da rescisão, observado, entretanto, que o
          pagamento será realizado nos termos da Cláusula 4.2.
        </Typography>
      </TermLine>

      <TermLine section="7.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato poderá ser rescindido por uma Parte em caso de um
          descumprimento pela outra Parte que não tenha sido sanado dentro de 10
          (dez) dias contados da respectiva notificação.
        </Typography>
      </TermLine>

      {/* Clause 8 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 8ª – DISPOSIÇÕES GERAIS
        </Typography>
      </TermLine>

      <TermLine section="8.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O Acordo de Parceria só poderá ser alterado por meio de instrumento
          escrito assinado por todas as Partes.
        </Typography>
      </TermLine>

      <TermLine section="8.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Nenhum atraso ou falha por uma Parte em tomar medidas com relação a
          qualquer violação pela outra Parte constituirá renúncia do direito
          dessa Parte de exigir o cumprimento de qualquer disposição deste
          Acordo ou tomar qualquer ação com relação a tal quebra ou qualquer
          violação posterior. A renúncia de qualquer disposição do presente
          Acordo só será eficaz se for feita por escrito e assinada pela Parte
          renunciante.
        </Typography>
      </TermLine>

      <TermLine section="8.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Se qualquer disposição deste Acordo for considerada inválida ou
          inexequível, tal disposição deve ser interpretada na máxima medida
          para ser aplicável e refletir a intenção das Partes, e a validade e
          exequibilidade das demais disposições do presente Acordo não serão
          afetadas.
        </Typography>
      </TermLine>

      <TermLine section="8.4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O presente Acordo não configura qualquer forma de sociedade, vínculo,
          representação comercial, associação, consórcio, joint venture ou
          parceria, de fato ou de direito, entre as Partes. As Partes reconhecem
          e concordam que o presente Acordo é mera forma de prestação de
          serviços, sendo cada uma das Partes inteiramente responsável por seus
          atos e obrigações, não podendo nenhuma delas declarar que possui
          qualquer autoridade para assumir ou criar qualquer obrigação, expressa
          ou implícita, em nome da outra Parte.
        </Typography>
      </TermLine>

      <TermLine section="8.4.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes devem ser consideradas a todo tempo independentes, sendo que
          não há qualquer subordinação ou ingerência por parte da Prisma ou seus
          prepostos sobre a {org.name} e seu negócio, cabendo unicamente à{" "}
          {org.name} a definição das condições comerciais sob as quais a Prisma
          realizará a prestação dos Serviços.
        </Typography>
      </TermLine>

      <TermLine section="8.4.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Nenhuma disposição deste Acordo deverá ser interpretada no sentido de
          criar qualquer vínculo societário, trabalhista, previdenciário ou
          tributário entre as Partes ou entre uma Parte e os administradores,
          empregados ou prepostos da outra, permanecendo cada uma responsável
          pelo recolhimento dos encargos trabalhistas e previdenciários de seus
          respectivos administradores e empregados, bem como pelo pagamento dos
          tributos e contribuições, inclusive sociais, incidentes sobre suas
          respectivas atividades.
        </Typography>
      </TermLine>

      <TermLine section="8.5">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Documento constitui o integral acordo e entendimento entre as
          Partes com relação à questão ora tratada e substitui todas as
          discussões, negociações, entendimentos e acordos anteriores, sejam por
          escrito ou verbais.
        </Typography>
      </TermLine>
    </>
  );
};

export default DefaultOrgContractText;

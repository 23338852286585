import { useMemo, useState } from "react";

type GroupData<T> = {
  label: string;
  filter: (obj: T) => boolean;
};

type GroupChunk<T> = {
  label: string;
  list: T[];
};

const useGroupedChunk = <T>(
  data: T[],
  groups: GroupData<T>[],
  chunkSize?: number
) => {
  const [chuncks, setChuncks] = useState(1);

  const groupedData = useMemo<GroupChunk<T>[]>(() => {
    return groups.map((group) => ({
      label: group.label,
      list: data.filter(group.filter),
    }));
  }, [data, groups]);

  const groupedChuncks = useMemo<GroupChunk<T>[]>(() => {
    const max = chunkSize ? chuncks * chunkSize : Infinity;
    return groupedData.reduce(
      ([groups, newMax], group) => {
        const groupCount = Math.min(group.list.length, newMax);
        if (groupCount === 0)
          return [groups, newMax] as [GroupChunk<T>[], number];
        else if (groupCount === group.list.length)
          return [groups.concat(group), newMax - groupCount] as [
            GroupChunk<T>[],
            number
          ];
        else {
          const list = group.list.slice(0, groupCount);
          return [groups.concat({ ...group, list }), newMax - groupCount] as [
            GroupChunk<T>[],
            number
          ];
        }
      },
      [[], max] as [GroupChunk<T>[], number]
    )[0];
  }, [chuncks, chunkSize, groupedData]);

  const loadNext = () => {
    setChuncks((prev) => prev + 1);
  };

  const totalCount = useMemo(() => {
    return data.length;
  }, [data.length]);

  return {
    groupedChuncks,
    loadNext,
    totalCount,
  };
};

export default useGroupedChunk;

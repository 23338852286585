/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from "react";
import { useForm } from "./hooks/useForm";
import { TDefaultValues } from ".";
import { useUIStore } from "../../hooks/useUIStore";
import FormContent from "./components/FormContent";
import { composeFieldPath } from "./components/inputs/utils";

interface FormProps {
  formId: string;
  docId: string;
  onSubmit: (res: any) => void;
  defaultValues?: TDefaultValues;
  large?: boolean;
  loadingMessage?: string;
  onCancel?: () => void;
  displayAsTableRow?: boolean;
}

const applyDefaultValues = (
  defaultValues: TDefaultValues,
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    groupPath: string
  ) => void,
  groupPath: string = ""
) => {
  for (let key of Object.keys(defaultValues)) {
    if (defaultValues[key] instanceof Object) {
      applyDefaultValues(
        defaultValues[key],
        handleChange,
        composeFieldPath(groupPath, key)
      );
    } else {
      handleChange(
        {
          target: { name: key, value: defaultValues[key] },
        } as React.ChangeEvent<HTMLInputElement>,
        groupPath
      );
    }
  }
};

const Form: FC<FormProps> = ({
  formId,
  docId,
  onSubmit,
  defaultValues,
  large = false,
  loadingMessage,
  onCancel,
  displayAsTableRow,
}) => {
  const { handleSubmit, formData, error, setError, handleChange } = useForm();

  const [appliedDefaultValues, setAppliedDefaultValues] = useState(false);

  const { setIsLoading } = useUIStore();

  const submit = async () => {
    try {
      setIsLoading(true, loadingMessage);
      const res = await handleSubmit(docId);
      onSubmit(res);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (formData && !appliedDefaultValues) {
      setAppliedDefaultValues(true);
      applyDefaultValues(defaultValues || {}, handleChange);
    }
  }, [defaultValues, formData, appliedDefaultValues]);

  if (error) return <div> Error...</div>;

  return (
    <>
      {formData && (
        <FormContent
          handleFormSubmit={submit}
          formData={formData}
          large={large}
          displayAsTableRow={displayAsTableRow}
          handleCancel={onCancel}
        />
      )}
    </>
  );
};

export default Form;

import { useCallback, useEffect, useState } from "react";

function useDebounce<T>(
  value: T,
  delay?: number
): {
  debouncedValue: T;
  cancelDebounce: () => void;
  forceDebounce: () => void;
} {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const newTimer = setTimeout(() => setDebouncedValue(value), delay || 300);
    setTimer(newTimer);
    return () => {
      clearTimeout(newTimer);
    };
  }, [value, delay]);

  const cancelDebounce = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  const forceDebounce = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setDebouncedValue(value);
  }, [value]);

  return { debouncedValue, cancelDebounce, forceDebounce };
}

export default useDebounce;

/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { MultiValueProps, components } from "react-select";
import Icon from "../../../Icons";
import { ColorNames } from "../../../../theme";
import {
  SelectInputOptionInterface,
  SelectOptionDisplay,
} from "../SelectInput";
import Avatar from "../../../Avatar";
import { MultiSelectValueDisplay } from "../MultiSelectInput";
import Typography, { FontSizes } from "../../../Typography";

const styles = {
  iconsValue: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: FontSizes.Medium,
  },
};

const MultiValue: FC<MultiValueProps<SelectInputOptionInterface, true>> = (
  props
) => {
  const {
    getValue,
    data,
    selectProps: { options },
  } = props;
  const values = getValue() as SelectInputOptionInterface[];

  const multiValueDisplay = (props.selectProps as any)
    .multiValueDisplay as MultiSelectValueDisplay;

  const optionDisplay = (props.selectProps as any)
    .optionDisplay as SelectOptionDisplay;

  const color = (props.selectProps as any).color as ColorNames;

  const valueText = useMemo(() => {
    const isFirst =
      values[0].value === (data as SelectInputOptionInterface).value;
    if (multiValueDisplay === "all") {
      if (values.length && isFirst) {
        return (
          <div css={styles.iconsValue}>
            {values.map((v, i) => (
              <div key={`${v}-${i}`} css={styles.iconsValue}>
                {i !== 0 && <span css={{ margin: "0 5px" }}>{"/"}</span>}
                {optionDisplay === "icon" && (
                  <span>
                    <Icon name={v.iconName!} color={color} hoverColor={color} />
                  </span>
                )}
                {optionDisplay === "avatar" && (
                  <span>
                    <Avatar name={v.label} filled color={color} />
                  </span>
                )}
                {(optionDisplay === "default" || optionDisplay === "old") &&
                  v.label}
              </div>
            ))}
          </div>
        );
      }
    } else {
      if (values.length === 1) {
        return options.find(
          (op) => "value" in op && op.value === values[0].value
        )?.label;
      }
      if (values.length > 1 && isFirst) {
        return `${values.length} selecionados`;
      }
    }
    return null;
  }, [values.length, options, multiValueDisplay, optionDisplay]);

  return valueText ? (
    <components.MultiValue {...props}>
      <Typography variant="textMedium" color={color}>
        {valueText}
      </Typography>
    </components.MultiValue>
  ) : null;
};

export default MultiValue;

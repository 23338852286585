import { FC } from "react";
import GeoLocationInput from "../../../../components/Input/GeoLocationInput";
import { LatLng } from "../../../../types";
import { useForm } from "../../hooks/useForm";
import { GeoLocationFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import { geolocationToEvent } from "./utils";
import Typography from "../../../../components/Typography";

const FormInputGeoLocation: FC<
  GeoLocationFieldType & { groupPath: string }
> = ({ name, groupPath, label }) => {
  const [value, inputError] = useFieldValue(name, groupPath);
  const { handleChange } = useForm();
  const handleMapClick = (latLng: LatLng) => {
    handleChange(geolocationToEvent(latLng, name), groupPath);
  };
  return (
    <>
      <GeoLocationInput
        value={value}
        handleChange={handleMapClick}
        label={label}
      />
      {inputError && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="right"
          component="div"
        >
          {inputError}
        </Typography>
      )}
    </>
  );
};

export default FormInputGeoLocation;

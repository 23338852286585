import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  FieldUpdateResponseV2,
  FormFieldV2,
  FormV2,
  InputTypes,
  useAddObjectListItemMutation,
  useCopyObjectListItemMutation,
  useDeleteObjectListItemMutation,
  useGetFormResponseV2LazyQuery,
  useSubmitFormResponseV2Mutation,
  useUpdateFormFieldV2Mutation,
} from "../../../graphql/generated/types";
import { useAuth } from "../../../hooks/useAuth";
import { uploadFileV2 } from "../services/form";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

interface IFormContext {
  form: FormV2 | null;
  handleFieldUpdate: (field: FormFieldV2, value: any) => Promise<void>;
  handleAddObjectListItem: (fieldPath: string) => Promise<void>;
  handleCopyObjectListItem: (
    fieldPath: string,
    itemIndex?: number
  ) => Promise<void>;
  handleDeleteObjectListItem: (fieldPath: string) => Promise<void>;
  showFormErrors: boolean;
  setShowFormErrors: (show: boolean) => void;
  handleSubmitFormResponse: () => Promise<void>;
  handleFileUpload: (field: FormFieldV2, file: File) => Promise<void>;
  onCancel?: () => void;
}

export const FormContext = createContext<IFormContext>({
  form: null,
  handleFieldUpdate: async () => {},
  handleAddObjectListItem: async () => {},
  handleCopyObjectListItem: async () => {},
  handleDeleteObjectListItem: async () => {},
  showFormErrors: false,
  setShowFormErrors: () => {},
  handleSubmitFormResponse: async () => {},
  handleFileUpload: async () => {},
});

interface FormContextProviderProps extends PropsWithChildren {
  formResponseId: string;
  onSubmit?: (formSubmitResponse: any) => void | Promise<void>;
  onCancel?: () => void;
}

export const FormContextProvider: FC<FormContextProviderProps> = ({
  formResponseId,
  onSubmit,
  onCancel,
  children,
}) => {
  const [form, setForm] = useState<FormV2 | null>(null);
  const authState = useAuth();
  const { loaded } = authState;

  const { errorHandler } = useErrorHandler();

  const [getFormResponseQuery] = useGetFormResponseV2LazyQuery({
    variables: {
      formResponseId,
    },
    fetchPolicy: "network-only",
  });

  const loadForm = useCallback(async () => {
    const { data } = await getFormResponseQuery();
    setForm(data?.getFormResponseV2 || null);
  }, [getFormResponseQuery]);

  useEffect(() => {
    if (loaded) {
      loadForm();
    }
  }, [loaded]);

  const [updateFieldMutation] = useUpdateFormFieldV2Mutation();

  const handleFieldUpdate = useCallback(
    async (field: FormFieldV2, value: any) => {
      const res = await updateFieldMutation({
        variables: {
          formResponseId,
          fieldPath: field.fieldPath,
          value,
        },
        fetchPolicy: "network-only",
      });
      const updateResponses = res.data?.updateFormFieldV2;
      if (updateResponses) {
        handleUpdateResponses(updateResponses);
      }
    },
    [formResponseId]
  );

  const handleUpdateResponses = (updateResponses: FieldUpdateResponseV2[]) => {
    const updatedValues = Object.fromEntries(
      updateResponses.map((update) => [update.fieldPath, update])
    );
    const applyFieldUpdates = (f: FormFieldV2): FormFieldV2 => {
      const field = { ...f };
      const updatedProperties = updatedValues[field.fieldPath];
      if (updatedProperties) {
        // Ignore null ones
        const actuallyUpdatedProperties = Object.fromEntries(
          Object.entries(updatedProperties).filter(
            ([, value]) => value !== "__UNCHANGED__"
          )
        );
        Object.assign(field, actuallyUpdatedProperties);
        if (field.type === InputTypes.List) {
          loadForm();
        }
      }
      return {
        ...field,
        fields: field.fields?.map(applyFieldUpdates),
      };
    };

    setForm((prevForm) => ({
      ...prevForm!,
      sections: prevForm!.sections.map((section) => ({
        ...section,
        fields: section.fields.map(applyFieldUpdates),
      })),
    }));
  };

  const [addObjectListItemMutation] = useAddObjectListItemMutation();

  const addNewItemToForm = useCallback(
    async (fieldPath: string, itemField: FormFieldV2) => {
      const mapItemToField = (field: FormFieldV2): FormFieldV2 => {
        if (field.fieldPath === fieldPath) {
          return {
            ...field,
            fields: [...field.fields!, itemField],
          };
        }
        return {
          ...field,
          fields: field.fields?.map(mapItemToField),
        };
      };
      setForm((prevForm) => ({
        ...prevForm!,
        sections: prevForm!.sections.map((section) => ({
          ...section,
          fields: section.fields.map(mapItemToField),
        })),
      }));
    },
    []
  );

  const handleAddObjectListItem = useCallback(
    async (fieldPath: string) => {
      const res = await addObjectListItemMutation({
        variables: {
          formResponseId,
          fieldPath,
        },
      });
      if (res.data?.addObjectListItem) {
        const { updates, newItem } = res.data?.addObjectListItem || {};
        addNewItemToForm(fieldPath, newItem);
        handleUpdateResponses(updates);
      }
    },
    [formResponseId]
  );

  const [copyListItemMutation] = useCopyObjectListItemMutation();

  const handleCopyObjectListItem = useCallback(
    async (fieldPath: string, itemIndex?: number) => {
      const res = await copyListItemMutation({
        variables: {
          formResponseId,
          fieldPath,
          itemIndex,
        },
      });
      if (res.data?.copyObjectListItem) {
        const { updates, newItem } = res.data?.copyObjectListItem || {};
        addNewItemToForm(fieldPath, newItem);
        handleUpdateResponses(updates);
      }
    },
    [formResponseId]
  );

  const [deleteObjectListItemMutation] = useDeleteObjectListItemMutation();

  const removeItemFromObjectList = useCallback((fieldPath: string) => {
    const removeItemFromField = (
      fields: FormFieldV2[],
      group: string
    ): FormFieldV2[] => {
      const newFieldPath = (name: string) =>
        group ? [group, name].join(".") : name;
      const newFields = fields.filter((field) => field.fieldPath !== fieldPath);
      const itemRemoved = newFields.length !== fields.length;
      return fields
        .filter((field) => field.fieldPath !== fieldPath)
        .map((field, i) => ({
          ...field,
          name: itemRemoved ? i.toString() : field.name,
          fieldPath: newFieldPath(itemRemoved ? i.toString() : field.name),
          fields: field.fields
            ? removeItemFromField(
                field.fields,
                newFieldPath(itemRemoved ? i.toString() : field.name)
              )
            : undefined,
        }));
    };
    setForm((prevForm) => ({
      ...prevForm!,
      sections: prevForm!.sections.map((section) => ({
        ...section,
        fields: removeItemFromField(section.fields, ""),
      })),
    }));
  }, []);

  const handleDeleteObjectListItem = useCallback(
    async (fieldPath: string) => {
      const res = await deleteObjectListItemMutation({
        variables: {
          formResponseId,
          fieldPath,
        },
      });
      if (res.data?.deleteObjectListItem) {
        const updates = res.data?.deleteObjectListItem || [];
        removeItemFromObjectList(fieldPath);
        handleUpdateResponses(updates);
      }
    },
    [formResponseId]
  );

  const [showFormErrors, setShowFormErrors] = useState(false);

  const [submitFormMutation] = useSubmitFormResponseV2Mutation();

  const handleSubmitFormResponse = useCallback(async () => {
    try {
      const res = await submitFormMutation({
        variables: {
          formResponseId,
        },
      });
      onSubmit && (await onSubmit(res.data?.submitFormResponseV2));
    } catch (err: any) {
      errorHandler(new Error(err.message), err);
    }
  }, [formResponseId]);

  const handleFileUpload = useCallback(
    async (field: FormFieldV2, file: File) => {
      const res = await uploadFileV2(
        authState,
        formResponseId,
        field.fieldPath,
        file
      );
      handleUpdateResponses(res.data!);
    },
    [formResponseId]
  );

  return (
    <FormContext.Provider
      value={{
        form,
        handleFieldUpdate,
        handleAddObjectListItem,
        handleCopyObjectListItem,
        handleDeleteObjectListItem,
        showFormErrors,
        setShowFormErrors,
        handleSubmitFormResponse,
        handleFileUpload,
        onCancel,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { FC } from "react";

import SkeletonTypography from "../../../../../components/Skeletons/components/SkeletonTypography";
import { theme } from "../../../../../theme";
import SkeletonChart from "./Chart/SkeletonChart";

const styles: CSSObject = {
  cardContainer: {
    boxSizing: "border-box" as const,
    border: "1px solid",
    borderColor: theme.colors.Grey[20],
    backgroundColor: theme.colors.White[100],
    width: "100%",
    height: "25vh",
    borderRadius: 10,
    padding: "1rem 0.5rem",
    boxShadow: theme.boxShadow.default,
  },
};

const SkeletonStageChart: FC = () => {
  return (
    <div css={styles.cardContainer}>
      <SkeletonTypography variant="textLarge" />
      <br />
      <SkeletonChart />
    </div>
  );
};

export default SkeletonStageChart;

/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { ColorNames, theme } from "../../../theme";
import Typography from "../../Typography";
import { formatPhone } from "../../../utils/formatNumber";
import { ToContactDetailsLocation } from "../../../Locations";
import { Contact } from "../../../graphql/generated/types";
import { IconNames } from "../../Icons/styles/iconNames";
import CustomLink from "../../CustomLink";
import Button from "../../Button";
import { FC } from "react";
import Icon from "../../Icons";
import { IconSizes } from "../../Icons/styles/iconSizes";
import Column from "../../Grid/Column";
import Row from "../../Grid/Row";

const styles: CSSObject = {
  contactHeaderBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.colors.Green[40],
    padding: "10px",
  },
  buttonBox: {
    width: "100%",
  },
  contactLabel: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    color: theme.colors.White[100],
    height: "100%",
  },
  contactIcon: {
    paddingLeft: 5,
  },
};

interface ContactHeaderInterface {
  contact: Contact;
  isSubscribed: boolean;
  toggleSubscription: () => Promise<void>;
}

export const ContactHeader: FC<ContactHeaderInterface> = ({
  contact,
  isSubscribed,
  toggleSubscription,
}) => {
  const ContactContent = (
    <>
      <Typography variant="textLarge" color="White" weight="bold">
        {contact.name || formatPhone(contact.mobilePhone!)}
      </Typography>
      {contact.name && (
        <div css={styles.contactIcon}>
          <Icon
            name={IconNames.User}
            color={ColorNames.WHITE}
            size={IconSizes.Small}
            onClick={() => null}
          />
        </div>
      )}
    </>
  );

  return (
    <Row css={styles.contactHeaderBox} noMargins>
      <Column xs={12} lg={3}>
        {contact.name ? (
          <CustomLink
            to={ToContactDetailsLocation(contact._id!)}
            css={styles.contactLabel}
          >
            {ContactContent}
          </CustomLink>
        ) : (
          <div css={styles.contactLabel}>{ContactContent}</div>
        )}
      </Column>
      <Column xs={0} lg={6} />
      <Column xs={12} lg={3}>
        <div css={styles.buttonBox}>
          <Button
            text={isSubscribed ? "Não seguir" : "Seguir "}
            iconName={isSubscribed ? IconNames.Close : IconNames.Valid}
            onClick={toggleSubscription}
          />
        </div>
      </Column>
    </Row>
  );
};

/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import { MenuListProps, components } from "react-select";
import { MultiSelectMenuListDisplay } from "../MultiSelectInput";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { ColorNames, getThemeColorFromAlias, theme } from "../../../../theme";
import {
  SelectInputOptionInterface,
  SelectOptionDisplay,
} from "../SelectInput";
import Typography, { FontWeights } from "../../../Typography";
import Avatar from "../../../Avatar";
import Icon from "../../../Icons";

type MultiSelectMenuListProps = MenuListProps<SelectInputOptionInterface, true>;

const getCSSRules: CSSRulesResolver<{
  color: string;
  props: MultiSelectMenuListProps;
}> = ({ color }) => ({
  menuList: {
    width: "400px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px",
  },
  optionItem: {
    cursor: "pointer",
    border: `1px solid ${theme.colors.Green[100]}`,
    boxShadow: theme.boxShadow.default,
    borderRadius: 15,
    width: "calc(50% - 20px)",
    padding: 12,
    boxSizing: "border-box",
    height: 45,
    margin: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: FontWeights.Bold,
    color: theme.colors.Grey[100],
    ":hover": {
      backgroundColor: color,
      color: theme.colors.White[100],
    },
  },
  selectedOption: {
    borderColor: color,
    color: theme.colors.White[100],
    backgroundColor: color,
  },
  optionLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  },
});

const MenuList: FC<MultiSelectMenuListProps> = (props) => {
  const color = getThemeColorFromAlias(ColorNames.GREEN, theme.colors)!;
  const styles = useCSSRulesWithTheme(getCSSRules, { props, color });

  const menuListDisplay = (props.selectProps as any)
    .menuListDisplay as MultiSelectMenuListDisplay;
  const optionDisplay = (props.selectProps as any)
    .optionDisplay as SelectOptionDisplay;

  const values = props.getValue() as SelectInputOptionInterface[];

  const getFirstAndLastName = (name: string) => {
    const names = name.split(" ");
    const firstName = names[0];
    const lastName = names.length > 1 ? ` ${names[names.length - 1]}` : "";
    const firstAndLastNames = [firstName, lastName];
    return firstAndLastNames.join("");
  };

  const [hoveredOption, setHoveredOption] = useState("");

  if (menuListDisplay === "buttons") {
    const { options } = props;
    return (
      <div css={styles.menuList}>
        {options.map((option) => {
          const { iconName, label, value } =
            option as SelectInputOptionInterface;
          const isSelected = values.some((op) => op.value === value);
          return (
            <div
              key={value}
              css={{
                ...(styles.optionItem as any),
                ...(isSelected ? (styles.selectedOption as any) : {}),
              }}
              onClick={() =>
                props.selectOption(option as SelectInputOptionInterface)
              }
              onMouseEnter={() => setHoveredOption(value)}
              onMouseLeave={() => setHoveredOption(value)}
            >
              <Typography
                variant="textMedium"
                align="left"
                uppercase
                css={styles.optionLabel}
              >
                {optionDisplay === "avatar"
                  ? getFirstAndLastName(label)
                  : label}
              </Typography>
              {optionDisplay === "avatar" && (
                <Avatar
                  name={label}
                  color={
                    isSelected || hoveredOption === value
                      ? ColorNames.GREEN
                      : ColorNames.GREY
                  }
                />
              )}
              {optionDisplay === "icon" && (
                <Icon
                  name={iconName!}
                  color={
                    isSelected || hoveredOption === value
                      ? ColorNames.WHITE
                      : ColorNames.GREY
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return <components.MenuList {...props} />;
};

export default MenuList;

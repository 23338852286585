import { FC } from "react";
import FormField from "./form.field";
import Row from "../../components/Grid/Row";
import { FormSectionV2 } from "../../graphql/generated/types";
import SectionTitle from "./components/SectionTitle";

interface FormSectionProps {
  section: FormSectionV2;
}

const FormSection: FC<FormSectionProps> = ({ section }) => {
  return (
    <>
      {section.subtitle && (
        <SectionTitle
          sectionData={{
            ...section,
            title: section.subtitle,
            subtitle: section.description,
          }}
        />
      )}
      <Row>
        {section.fields.map((field) => {
          return <FormField key={field.fieldPath} field={field} />;
        })}
      </Row>
    </>
  );
};

export default FormSection;

/** @jsxImportSource @emotion/react */

import { FC, ReactNode } from "react";
import Typography from "../../Typography";
import { ColorNames } from "../../../theme";
import CardContainer from "./CardContainer";
import Column from "../../Grid/Column";
import Row from "../../Grid/Row";

interface CardProps {
  detailTop?: ReactNode;
  detailBottom?: ReactNode;
  cardData: {
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    responsibleIcon?: any;
  };
  color: ColorNames;
  active: boolean;
  onClick?: () => void;
}

export type CardFC<T> = FC<{
  data: T;
  active: boolean;
  onClick?: () => void;
}>;

const Card: FC<CardProps> = ({
  detailBottom,
  detailTop,
  active,
  cardData,
  color,
  onClick,
}) => {
  return (
    <CardContainer onClick={onClick} borderColor={color} active={active}>
      <Row justify="between">
        <Column xs={10}>
          <Typography
            variant="textLarge"
            align="left"
            uppercase
            component="div"
            weight="bold"
            noWrap
          >
            {cardData.line1 || ""}
          </Typography>
        </Column>
        <Column xs={2}>{detailTop}</Column>
      </Row>
      <Row noMargins align="end">
        <Column xs={9}>
          <Typography variant="textLarge" align="left" component="div" noWrap>
            {cardData.line2 || "-"}
          </Typography>
          <Typography variant="textLarge" align="left" component="div" noWrap>
            {cardData.line3 || "-"}
          </Typography>
          <Typography variant="textLarge" align="left" component="div" noWrap>
            {cardData.line4 || "-"}
          </Typography>
        </Column>
        <Column xs={3}>{detailBottom}</Column>
      </Row>
    </CardContainer>
  );
};

export default Card;

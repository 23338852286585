/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Container from "../../../../components/Grid/Container";
import Row from "../../../../components/Grid/Row";
import { ObjectListFieldType } from "../../models/formInterfaces";

import FormSectionRows from "../FormSectionRows";
import FormInputLabel from "./FormInputLabel";
import Column from "../../../../components/Grid/Column";
import { composeFieldPath } from "./utils";

const FormInputObject: FC<ObjectListFieldType & { groupPath: string }> = ({
  fields,
  name,
  label,
  groupPath,
  labelDisplay,
  invisible,
}) => {
  const newGroupPath = composeFieldPath(groupPath, name);
  if (invisible) {
    return null;
  }
  return (
    <Container css={{ padding: "0 !important" }}>
      <Row noGutters css={{ margin: 0, marginBottom: 5 }}>
        <Column>
          <FormInputLabel label={label} labelDisplay={labelDisplay} />
        </Column>
      </Row>
      <FormSectionRows fields={fields} groupPath={newGroupPath} subFields />
    </Container>
  );
};

export default FormInputObject;

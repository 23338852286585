/** @jsxImportSource @emotion/react */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from "@emotion/react";
import { CSSProperties, FC, useMemo } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { ColorNames, theme } from "../../theme";
import Row from "../Grid/Row";
import Container from "../Grid/Container";
import Column from "../Grid/Column";
import { SelectInputOptionInterface } from "./SelectInputs/SelectInput";
import Typography from "../Typography";
import { FormInputOptions } from "../../graphql/generated/types";

const styles: CSSRulesResolver = ({ ...props }) => ({
  div: {
    textTransform: "uppercase",
    fontWeight: 300,
    fontSize: 20,
    height: 22,
  },
  inputWrapper: {
    padding: 2,
    width: "100%",
    height: 20,
    cursor: "pointer",
    marginBottom: 4,
    textAlign: "initial " as CSSProperties["textAlign"],
    fontSize: 20,
    "& input:checked + span": {
      color: `${props.colors.Green[100]}`,
    },
    "& input:checked": {
      backgroundColor: `${props.colors.Green[100]}`,
    },
  },
  radio: {
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    display: "inline-block",
    font: "inherit",
    width: 15,
    height: 15,
    padding: 3,
    margin: 0,
    backgroundClip: "content-box",
    border: "0.2px solid #bbbbbb",
    borderRadius: "50%",
    boxShadow: theme.boxShadow.default,
  },
  typograph: {
    marginLeft: "0.5rem",
  },
});

interface RadioOptionInputProps {
  error: string;
  options: FormInputOptions[];
  name: string;
  onSelect: (option: SelectInputOptionInterface, inputName: string) => void;
  value: string;
}

const RadioOptionInput: FC<RadioOptionInputProps> = ({
  error,
  options,
  name,
  onSelect,
  value,
}) => {
  const { inputWrapper, radio, typograph, warning } =
    useCSSRulesWithTheme(styles);

  const selectedOptionIndex = options?.findIndex(
    (option) => option.value === value
  );

  const warningText = useMemo(() => {
    return selectedOptionIndex >= 0
      ? options[selectedOptionIndex]?.warning
      : "";
  }, [options, selectedOptionIndex]);

  return (
    <Container>
      <Row
        align="start"
        style={{
          marginBottom: 0,
        }}
      >
        {options?.map((option, i) => {
          return (
            <Column xs={12} key={option.value}>
              <div css={inputWrapper} onClick={() => onSelect(option, name)}>
                <input
                  css={radio}
                  id={option.value}
                  type={"radio"}
                  checked={selectedOptionIndex === i}
                  onChange={() => {}}
                />
                <Typography
                  css={typograph}
                  variant="textMedium"
                  component={"span"}
                >
                  {option.label}
                </Typography>
              </div>
            </Column>
          );
        })}
      </Row>
      {warningText && (
        <Row>
          <Column>
            <Typography
              css={warning}
              variant="textMedium"
              component={"div"}
              align="left"
              color={ColorNames.GREEN}
            >
              {warningText}
            </Typography>
          </Column>
        </Row>
      )}
      {error && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="left"
          component="div"
          css={{ width: "100%" }}
        >
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default RadioOptionInput;

import Table from "../../../../components/Table";
import {
  ClaimEvent,
  useListClaimsQuery,
} from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToDocumentDetailsLocation } from "../../../../Locations";
import {
  ClaimEventLabels,
  ClaimStatusLabels,
} from "../../../Documents/DocumentDetails/sections/Claim/sections/components/Claim";
import { concatArrayOfStrings } from "../../../../utils/stringUtils";
import ScrollContainer from "../../../../components/ScrollContainer";

type TableClaimsData = {
  _id: string;
  documentId: string;
  externalId: string;
  noticeDate: string;
  eventsLabel: string | string[];
  status: string;
};

const ContactClaims = () => {
  const { contactData } = useContactDetails();

  const { data } = useListClaimsQuery({
    variables: {
      filter: { contactId: contactData._id! },
    },
  });

  const tableClaimsData: TableClaimsData[] = (data?.claims || []).map(
    (claim) => ({
      ...claim,
      status: ClaimStatusLabels[claim.status],
      eventsLabel: concatArrayOfStrings(
        claim.events.map((ev: ClaimEvent) => `${ClaimEventLabels[ev]}`)
      ),
    })
  );

  return (
    <ScrollContainer>
      <Table<TableClaimsData>
        data={tableClaimsData}
        withBorder
        color={ColorNames.ORANGE}
        rowLink={(claim: TableClaimsData) =>
          ToDocumentDetailsLocation(claim.documentId!)
        }
        columns={[
          {
            label: "ID na Seguradora",
            key: "externalId",
          },
          {
            label: "Data de aviso na Seguradora",
            key: "noticeDate",
            special: "date",
          },
          {
            label: "Eventos",
            key: "eventsLabel",
          },
          {
            label: "Status",
            key: "status",
          },
        ]}
      />
    </ScrollContainer>
  );
};

export default ContactClaims;

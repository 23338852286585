/** @jsxImportSource @emotion/react */

import { FC, ReactNode, useMemo, useState } from "react";
import { ColorNames, theme } from "../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Button from "../Button";
import ModalStoreProvider from "../Modal/context/ModalStore";
import { IconNames } from "../Icons/styles/iconNames";
import useIsMobile from "../../hooks/useIsMobile";
import { DetailsColorProvider } from "../../contexts/detailsColor";
import Tabs from "../Tabs";
import { useUIStore } from "../../hooks/useUIStore";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../Icons";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { DetailsRowProps } from "../Details/components/Main/Grid/DetailsRow";
import SidebarHeader, { headerHeight, mobileHeaderHeight } from "./Header";
import { useFlags } from "../../hooks/useFlags";

export interface MenuItem {
  title: string;
  Component?: FC;
  disabled?: boolean;
  pending?: boolean;
  color?: ColorNames;
  tabs?: {
    title: string;
    Component: FC;
  }[];
  location?: string;
  flags?: string[];
}

interface SidebarProps {
  TopComponent?: FC;
  menuItems: MenuItem[];
  header?: DetailsRowProps[];
  children?: ReactNode;
}

const sidebarFullWidth = 250;
const sidebarWidthCollapsed = 30;

const getCSSRules: CSSRulesResolver<{
  sidebarWidth: number;
  contentMargin: number;
  currentHeaderHeight: number;
}> = ({ sidebarWidth, contentMargin, currentHeaderHeight }) => {
  return {
    sidebar: {
      height: "100%",
      position: "relative" as const,
      backgroundColor: theme.colors.LightGrey[40],
    },
    topComponent: {
      marginBottom: 24,
    },
    menu: {
      position: "absolute" as const,
      top: 0,
      left: 0,
      zIndex: 100,
      minWidth: sidebarWidth,
      height: "100%",
      backgroundColor: theme.colors.LightGrey[100],
      padding: `12px ${0.05 * sidebarWidth}px`,
      boxSizing: "border-box" as const,
      "& button": {
        marginBottom: 12,
      },
    },
    content: {
      transition: "margin-left 0.3s",
      marginLeft: contentMargin,
      width: `calc(100% - ${contentMargin}px)`,
      height: "100%",
      backgroundColor: theme.colors.White[40],
      position: "relative" as const,
      boxSizing: "border-box" as const,
      padding: "0 12px",
    },
    main: {
      height: `calc(100% - ${currentHeaderHeight}px)`,
    },
  };
};

const Sidebar: FC<SidebarProps> = ({
  TopComponent,
  menuItems,
  children,
  header,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const location = useLocation();
  const initialSelectedItemIndex = useMemo(() => {
    const initialIndex = menuItems.findIndex(
      (item) => item.location === location.pathname
    );
    return initialIndex === -1 ? 0 : initialIndex;
  }, [menuItems, location.pathname]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(
    initialSelectedItemIndex
  );
  const isMobile = useIsMobile();
  const {
    isConfirmationModalOpen,
    renderConfirmationModal,
    isSubmittedModalOpen,
    renderSubmittedModal,
  } = useUIStore();

  const sidebarWidth = useMemo(() => {
    return sidebarOpen ? sidebarFullWidth : sidebarWidthCollapsed;
  }, [sidebarOpen]);

  const contentMargin = useMemo(() => {
    return isMobile ? sidebarWidthCollapsed : sidebarWidth;
  }, [sidebarWidth, isMobile]);

  const currentHeaderHeight = useMemo(() => {
    return header ? (isMobile ? mobileHeaderHeight : headerHeight) : 0;
  }, [isMobile, header]);

  const styles = useCSSRulesWithTheme(getCSSRules, {
    sidebarWidth,
    contentMargin,
    currentHeaderHeight,
  });

  const toggleSidebarOpen = () => setSidebarOpen(!sidebarOpen);

  const navigate = useNavigate();

  const handleMenuItemClick = (index: number) => {
    const item = menuItems[index];
    if (item.disabled) return;
    setSelectedItemIndex(index);
    isMobile && toggleSidebarOpen();
    const location = item.location;
    location && navigate(location);
  };

  const sidebarIconName = useMemo(() => {
    return sidebarOpen ? IconNames.ChevronLeft : IconNames.ChevronRight;
  }, [sidebarOpen]);

  const selectedColor = useMemo(() => {
    return menuItems[selectedItemIndex].color;
  }, [selectedItemIndex, menuItems]);

  const Content = useMemo(() => {
    return menuItems[selectedItemIndex].Component;
  }, [selectedItemIndex, menuItems]);

  const tabs = useMemo(() => {
    return menuItems[selectedItemIndex].tabs;
  }, [selectedItemIndex, menuItems]);

  const { isFlagEnabled } = useFlags();

  return (
    <DetailsColorProvider color={selectedColor}>
      <Row noGutters noMargins css={styles.sidebar}>
        <Row css={styles.menu} noGutters justify="center">
          {sidebarOpen && (
            <Column>
              <>
                {TopComponent && (
                  <div css={styles.topComponent}>
                    <TopComponent />
                  </div>
                )}
                {menuItems.map(
                  (item, index) =>
                    (!item.flags ||
                      item.flags.every((flag) => isFlagEnabled(flag))) && (
                      <Button
                        key={`menuItem-${index}`}
                        text={item.title}
                        onClick={() => handleMenuItemClick(index)}
                        align={"left"}
                        selected={index === selectedItemIndex}
                        color={selectedColor}
                        style={"link"}
                        disabled={item.disabled}
                      />
                    )
                )}
              </>
            </Column>
          )}
          <Column xs={sidebarOpen ? 1 : 12}>
            <Icon name={sidebarIconName} onClick={toggleSidebarOpen} />
          </Column>
        </Row>
        <div css={styles.content}>
          <ModalStoreProvider>
            {header && <SidebarHeader header={header} />}
            <div css={styles.main}>
              {isConfirmationModalOpen && renderConfirmationModal()}
              {isSubmittedModalOpen && renderSubmittedModal()}
              {tabs && <Tabs tabs={tabs} color={selectedColor} />}
              {Content && <Content />}
              {children && children}
            </div>
          </ModalStoreProvider>
        </div>
      </Row>
    </DetailsColorProvider>
  );
};

export default Sidebar;

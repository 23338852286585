/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { theme } from "../../theme";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.LightGrey[80],
    opacity: 0.9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
};

// this component uses position absolute to position itself, so don't forget to use position relative in ur desired parent
const LoadingOverlay = ({ children }: { children: ReactNode }) => {
  return <div css={styles.loadingOverlay}>{children}</div>;
};

export default LoadingOverlay;

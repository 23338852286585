/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames } from "../../../../theme";
import Button from "../../../../components/Button";
import HR from "../../../../components/HorizontalLine";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { useFlags } from "../../../../hooks/useFlags";
import { TypedDocumentNode } from "@apollo/client";
import { FormRequestResponse } from "../../../../graphql/generated/types";
import CustomLink from "../../../../components/CustomLink";

export interface SectionFooterButton {
  text: string;
  icon?: JSX.Element;
  iconName?: IconNames;
  disabled?: boolean;
  onClick?: () => void;
  flag?: string;
  formQuery?: {
    query: TypedDocumentNode<{ [key: string]: FormRequestResponse }, any>;
    variables: any;
  };
  useV2?: boolean;
  link?: string;
}

interface SectionFooterProps {
  buttons: SectionFooterButton[];
  subsection?: boolean;
}

const sectionFooterHeight = 95;

const styles = {
  container: {
    height: sectionFooterHeight,
  },
  footer: {
    display: "flex",
    justifyContent: "end",
    gap: "1rem",
  },
};

const SectionFooter: FC<SectionFooterProps> = ({ buttons, subsection }) => {
  const { isFlagEnabled } = useFlags();
  return (
    <div css={styles.container}>
      {!subsection && <HR dashedLine color={ColorNames.GREY} />}
      <div css={styles.footer}>
        {buttons &&
          buttons
            .filter((button) => !button.flag || isFlagEnabled(button.flag))
            .map((button) => {
              const buttonProps = {
                style: "outlinePrimary",
                text: button.text,
                icon: button.icon,
                disabled: !!button.disabled,
                onClick: button.onClick,
                width: "auto",
                key: button.text,
                iconName: button.iconName,
              };
              if (button.link)
                return (
                  <CustomLink to={button.link} key={button.text}>
                    <Button {...buttonProps} />;
                  </CustomLink>
                );
              return <Button {...buttonProps} />;
            })}
      </div>
    </div>
  );
};

export default SectionFooter;

/** @jsxImportSource @emotion/react */
import React, { FC } from "react";
import { formatInputMask, parseMask } from "../../../../utils/formatNumber";
import { useForm } from "../../hooks/useForm";
import { FloatFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import FloatInput from "../../../../components/Input/FloatInput";
import FormLabel from "../FormLabel";

const FormInputFloatField: FC<FloatFieldType & { groupPath: string }> = ({
  type,
  name,
  label,
  disabled = false,
  inputMask,
  groupPath,
}) => {
  const { handleChange, startEditing, finishEditing } = useForm();

  const [value, inputError] = useFieldValue(name, groupPath);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputMask) {
      e.target.value = parseMask(e.target.value);
    }
    handleChange(e, groupPath);
  };
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <FloatInput
        placeholder={label.toLowerCase()}
        disabled={disabled}
        type={type}
        name={name}
        value={formatInputMask(value, inputMask)}
        onChange={handleInputChange}
        onFocus={() => startEditing(groupPath, name)}
        onBlur={() => finishEditing(groupPath, name)}
        errorMessage={inputError}
      />
    </>
  );
};

export default FormInputFloatField;

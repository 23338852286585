/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useCreateFormResponseV2Mutation } from "../../graphql/generated/types";
import { useAuth } from "../../hooks/useAuth";
import FormV2 from "../../modules/Forms/form.v2";
import Layout from "../../components/Layout";
import Row from "../../components/Grid/Row";
import Column from "../../components/Grid/Column";
import { CSSObject } from "@emotion/react";
import { theme } from "../../theme";
import { Visible } from "react-grid-system";
import { farmFieldsUrl } from "../../s3/bgImages";
import { useNavigate } from "react-router-dom";
import { HomeLocation, TermsAndConditionsLocation } from "../../Locations";
import { orgIsCompleted } from "../Contact/utils/tags";

const styles: CSSObject = {
  bgImage: {
    border: `2px solid ${theme.colors.Green[100]}`,
    borderRadius: "10px",
    backgroundImage: `url(${farmFieldsUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  },
  imgWrapper: {
    padding: "15px",
    height: "100%",
    boxSizing: "border-box",
  },
};

const formId = "orgCompleteRegister";

export const OrgCompleteRegister = () => {
  const { errorHandler } = useErrorHandler();

  const [resId, setResId] = useState<string>("");
  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();
  const { user, loadCurrentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!resId && user.org) {
      if (orgIsCompleted(user)) {
        navigate(HomeLocation);
      }
      createFormResponseMutation({
        variables: {
          formId,
          metadata: { org: user.org, orgAdmin: user.contact },
        },
      })
        .then(({ data }) => setResId(data!.createFormResponseV2!))
        .catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [user]);

  const handleSubmit = async () => {
    await loadCurrentUser();
    navigate(TermsAndConditionsLocation);
  };

  return (
    <Layout padding={20}>
      <Row noMargins noGutters noWrap css={{ height: "100%" }}>
        <Column xs={12} lg={7}>
          {resId && (
            <FormV2
              formId={formId}
              onSubmit={handleSubmit}
              formResponseId={resId}
            />
          )}
        </Column>
        <Visible lg md xl xxl xxxl>
          <Column lg={5} xs={0}>
            <div css={styles.imgWrapper}>
              <div css={styles.bgImage} />
            </div>
          </Column>
        </Visible>
      </Row>
    </Layout>
  );
};

import { FC } from "react";

const Archive: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_324_19)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8284 8H28C28.5304 8 29.0391 8.21071 29.4142 8.58579C29.7893 8.96086 30 9.46957 30 10V26C30 26.5304 29.7893 27.0391 29.4142 27.4142C29.0391 27.7893 28.5304 28 28 28H4C3.46957 28 2.96086 27.7893 2.58579 27.4142C2.21071 27.0391 2 26.5304 2 26V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H16C16.2627 4 16.5228 4.05175 16.7654 4.15228C17.0081 4.25281 17.2286 4.40015 17.4143 4.5859L20.8284 8ZM8 14V26H28V14H22V12H28V10H20L16 6H4V26H6V14C6.00066 13.4698 6.21159 12.9614 6.58652 12.5865C6.96145 12.2116 7.46977 12.0007 8 12H16C16.5302 12.0007 17.0386 12.2116 17.4135 12.5865C17.7884 12.9614 17.9993 13.4698 18 14V20.17L20.59 17.59L22 19L17 24L12 19L13.41 17.59L16 20.17V14H8Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_324_19">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Archive;

import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { setUser as setSentryUser } from "@sentry/react";
import { ApiTokenPayload, AuthCredentials, AuthObject } from "../models/Auth";
import {
  authenticateUser,
  getSessionToken,
  renewToken,
  requestLoginCode,
  validateQueryToken,
} from "../services/auth";
import cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import { useErrorHandler } from "../hooks/useErrorHandler";
import { getCurrentUser } from "../services/user";
import { UserObject } from "../models/User";
import { useFlags } from "../hooks/useFlags";
import { useLocation } from "react-router-dom";
import { useRefreshLastAccessAtMutation } from "../graphql/generated/types";

export const TOKEN_COOKIE_NAME = "loginToken";
const TOKEN_REFRESH_TIME = 10 * 24 * 3600e3; // 10 days
const TOKEN_COOKIE_DOMAIN =
  process.env.NODE_ENV === "development" ? "" : ".prismaagro.com.br";
const LAST_ACCESS_AT_MIN_DELAY = 5 * 1 * 1000; // 5 mins
const LAST_ACCESS_AT_SYNC_KEY = "lastAccessAtSyncAt";

export interface AuthContextInterface {
  bearerToken: string | null;
  tokenPayload: any;
  loaded: boolean;
  signIn: (credentials: AuthCredentials) => Promise<AuthObject>;
  signOut: () => void;
  requestCode: (credentials: AuthCredentials) => Promise<void>;
  invalidate: () => void;
  isLoggedIn: boolean;
  user: UserObject;
  loadCurrentUser: () => Promise<void>;
  shareProviderLink: () => void;
  updateToken: (token: string) => Promise<void>;
}

const authContextDefaults: AuthContextInterface = {
  bearerToken: null,
  tokenPayload: null,
  loaded: false,
  signIn: () => new Promise(() => {}),
  signOut: () => new Promise(() => {}),
  requestCode: () => new Promise(() => {}),
  invalidate: () => null,
  isLoggedIn: false,
  loadCurrentUser: () => new Promise(() => {}),
  user: {
    email: "",
    _id: "",
    role: "",
    name: "",
    cpf: "",
    rg: "",
    civilState: undefined,
    profession: "",
    cep: "",
    state: "",
    city: "",
    signedDate: undefined,
    flags: [],
    contactId: "",
    orgId: "",
    contact: {
      _id: "",
    },
  },
  shareProviderLink: () => {},
  updateToken: async () => {},
};

const AuthContext = createContext<AuthContextInterface>(authContextDefaults);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState(authContextDefaults);
  const { errorHandler } = useErrorHandler();
  const [user, setUser] = useState<UserObject>(authContextDefaults.user);
  const isLoggedIn = useMemo(() => {
    return !!user._id && !!authState.bearerToken;
  }, [user, authState.bearerToken]);
  const { loadFlags } = useFlags();
  const { search } = useLocation();
  const tokenLoadedStartedRef = useRef<boolean>(false);

  const sessionTokenRequestedRef = useRef<boolean>(false);

  const loadSessionToken = async () => {
    if (!authState.bearerToken) {
      sessionTokenRequestedRef.current = true;
      const res = await getSessionToken(authState);
      setToken(res.token);
      return res.token;
    }
    return authState.bearerToken;
  };

  const handleQueryToken = async (queryToken: string, savedToken?: string) => {
    try {
      const data = await validateQueryToken({
        queryToken,
        currentToken: savedToken,
      });
      setToken(data.token);
    } catch (e) {
      errorHandler(
        new Error("Não foi possível carregar o token de compartilhamento"),
        e
      );
    }
  };

  const [refreshLastAccessAtMutation] = useRefreshLastAccessAtMutation();

  const refreshLastAccessAtIfNeeded = async (userId: string) => {
    const now = Date.now();

    const lastMutationTime = localStorage.getItem(LAST_ACCESS_AT_SYNC_KEY);
    const lastMutationTimestamp = lastMutationTime
      ? parseInt(lastMutationTime, 10)
      : null;

    if (
      lastMutationTimestamp &&
      now - lastMutationTimestamp < LAST_ACCESS_AT_MIN_DELAY
    ) {
      return;
    }

    localStorage.setItem(LAST_ACCESS_AT_SYNC_KEY, now.toString());
    await refreshLastAccessAtMutation({ variables: { userId } });
  };

  const loadCurrentUser = async () => {
    try {
      const { data } = await getCurrentUser(authState);
      setUser(data);
      loadFlags(data.flags);
      await refreshLastAccessAtIfNeeded(data._id);
    } catch (e) {
      errorHandler(new Error("Erro ao carregar usuário"), e);
    }
  };

  useEffect(() => {
    if (!user?._id) return;

    const handleFocus = async () => {
      await refreshLastAccessAtIfNeeded(user._id);
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [user]);

  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (tokenLoadedStartedRef.current) {
      return;
    }
    tokenLoadedStartedRef.current = true;
    const queryToken = query.get("token");
    try {
      const savedToken = cookie.get(TOKEN_COOKIE_NAME) || undefined;
      if (queryToken) {
        handleQueryToken(queryToken, savedToken);
        return;
      } else if (savedToken) {
        setToken(savedToken);
      } else {
        loadSessionToken();
      }
    } catch (e) {
      errorHandler(new Error("Erro ao carregar token"), e);
      invalidate();
    }
  }, []);

  const setToken = (token?: string) => {
    if (!token) {
      invalidate();
      return;
    }
    const tokenPayload = jwtDecode(token) as ApiTokenPayload & {
      exp: number;
    };

    if (
      !(tokenPayload && (tokenPayload.sessionId || tokenPayload.user)) ||
      // !tokenPayload.uid ||
      // ! ||
      // !tokenPayload.email ||
      !tokenPayload.exp
    ) {
      throw new TypeError(
        `Invalid token payload: ${JSON.stringify(jwtDecode(token))}`
      );
    }

    setAuthState({
      ...authState,
      bearerToken: token,
      tokenPayload,
      loaded: true,
    });

    const expires = new Date(tokenPayload.exp * 1000);
    cookie.set(TOKEN_COOKIE_NAME, token, {
      expires,
      domain: TOKEN_COOKIE_DOMAIN,
    });

    if (
      typeof window === "object" &&
      expires.getTime() < Date.now() - TOKEN_REFRESH_TIME
    ) {
      setTimeout((): void => {
        if (authState.bearerToken !== token) return;

        renewToken(authState)
          .then((nextToken: string): void => {
            setToken(nextToken);
          })
          .catch((err: any): void => {
            console.warn(`Error while auto-renew token:`, err);
          });
      }, 2e3);
    }
  };

  const invalidate = () => {
    // create date in the past so cookie is expired
    // https://stackoverflow.com/questions/62246308/how-to-delete-web-application-cookies-in-react-js
    const expires = new Date(new Date().setDate(new Date().getDate() - 1));
    cookie.set(TOKEN_COOKIE_NAME, authState.bearerToken || "", {
      expires,
      domain: TOKEN_COOKIE_DOMAIN,
    });
    setUser({ ...authContextDefaults.user });
    setAuthState({
      ...authState,
      user: authContextDefaults.user,
      bearerToken: null,
      loaded: true,
    });
  };

  const signOut = async (): Promise<void> => {
    // try {
    //   await logout(authState);
    // } catch (e) {
    //   //todo: capture error
    //   errorHandler(e as Error, e);
    // }
    invalidate();
    await loadSessionToken();
    //TODO : reset all stores and contexts on logout
  };

  const signIn = async (credentials: AuthCredentials): Promise<AuthObject> => {
    try {
      const bearerToken = await loadSessionToken();
      const data = await authenticateUser(
        { ...authState, bearerToken },
        credentials
      );
      setToken(data.token);
      return Promise.resolve(data);
    } catch (errorRes: any) {
      return Promise.reject(errorRes);
    }
  };

  const requestCode = async (credentials: AuthCredentials): Promise<void> => {
    try {
      const res = await requestLoginCode(credentials);
      return Promise.resolve(res);
    } catch (errorRes: any) {
      return Promise.reject(errorRes);
    }
  };

  const shareProviderLink = () => {
    const slug = user.org?.slug || user.contact.slug;
    const shareLink = `${
      process.env.REACT_APP_PUBLIC_SITE_BASE_URL
    }?partner=${encodeURIComponent(String(slug))}`;
    navigator.clipboard.writeText(shareLink);
    alert(`Link copiado: ${shareLink}`);
  };

  const updateToken = async (token: string) => {
    await handleQueryToken(token);
  };

  useEffect(() => {
    setSentryUser(
      isLoggedIn
        ? {
            id: user._id,
            username: user.name,
            email: user.email,
          }
        : null
    );
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        invalidate,
        signOut,
        signIn,
        requestCode,
        user,
        loadCurrentUser,
        isLoggedIn,
        shareProviderLink,
        updateToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import { FC } from "react";

const Proposal: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2567_68)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 17H11C9.9 17 9 16.1 9 15V4C9 2.9 9.9 2 11 2H20C21.1 2 22 2.9 22 4V15C22 16.1 21.1 17 20 17ZM11 4V15H20V4H11ZM13 6H18V8H13V6ZM13 10H18V12H13V10ZM28.1532 16.2289C28.5155 16.3797 28.8444 16.6006 29.1211 16.8789C29.3997 17.1574 29.6208 17.4881 29.7716 17.852C29.9224 18.216 30 18.606 30 19C30 19.394 29.9224 19.784 29.7716 20.148C29.6208 20.5119 29.3997 20.8426 29.1211 21.1211L21.707 28.5352C21.2438 29.001 20.6929 29.3704 20.086 29.6218C19.4791 29.8733 18.8283 30.0018 18.1714 30H11V28H18.1714C18.5656 28.0012 18.9561 27.9241 19.3203 27.7732C19.6845 27.6223 20.0151 27.4007 20.293 27.1211L27.707 19.707C27.8466 19.5674 27.9417 19.3895 27.9804 19.1959C28.0191 19.0022 27.9996 18.8015 27.9243 18.6189C27.8491 18.4363 27.7215 18.2801 27.5576 18.17C27.3937 18.0599 27.2009 18.0007 27.0034 18H27C26.8686 17.9996 26.7385 18.0253 26.6171 18.0756C26.4958 18.1259 26.3856 18.1998 26.293 18.293L20.7573 23.8281C20.3868 24.2009 19.946 24.4964 19.4605 24.6976C18.9749 24.8987 18.4543 25.0015 17.9287 25H13V23H17C17.2652 23 17.5196 22.8946 17.7071 22.7071C17.8946 22.5196 18 22.2652 18 22C18 21.7348 17.8946 21.4804 17.7071 21.2929C17.5196 21.1054 17.2652 21 17 21H10C9.20462 21.0009 8.44206 21.3172 7.87964 21.8796C7.31722 22.4421 7.00087 23.2046 7 24V25.333L3.6 29.8631L2 28.6631L5 24.667V24C5.00156 22.6744 5.52885 21.4035 6.46619 20.4662C7.40353 19.5288 8.6744 19.0016 10 19H17C17.7555 19.0022 18.4823 19.29 19.0345 19.8055C19.5868 20.3211 19.9237 21.0264 19.9778 21.78L24.8789 16.8789C25.1567 16.5993 25.4872 16.3776 25.8513 16.2268C26.2154 16.0759 26.6059 15.9988 27 16H27.01C27.4024 16.0003 27.7909 16.0781 28.1532 16.2289Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2567_68">
          <rect width="32" height="32" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Proposal;

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const SavableQueries = ["originador"];

const useQuerySave = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  for (let key of SavableQueries) {
    const value = query.get(key);
    if (value) {
      localStorage.setItem(key, value);
    }
  }
};

export default useQuerySave;

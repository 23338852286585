/** @jsxImportSource @emotion/react */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from "@emotion/react";
import { CSSProperties, useEffect, useMemo } from "react";
import { FC } from "react";
import Column from "../../../../components/Grid/Column";
import Container from "../../../../components/Grid/Container";
import Row from "../../../../components/Grid/Row";
import Typography from "../../../../components/Typography";
import { useCSSRulesWithTheme } from "../../../../hooks/useCSSRulesWithTheme";
import { CustomTheme, theme } from "../../../../theme";
import { useForm } from "../../hooks/useForm";
import {
  FormField,
  Option,
  SelectionInputTypes,
} from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import { findOptionIndex, selectionToEvent } from "./utils";

const getStyles = ({ colors }: CustomTheme) => ({
  col: {
    paddingBottom: 16,
  },
  option: {
    backgroundColor: "white",
    textOverflow: "ellipsis",
    height: 45,
    borderRadius: 5,
    //@ts-ignore
    boxSizing: "border-box" as CSSProperties["box-sizing"],
    border: "0.2px solid rgba(0, 0, 0, 0.2)",
    boxShadow: theme.boxShadow.default,
    cursor: "pointer",
    width: "100%",
    overflow: "hidden",
    //@ts-ignore
    whiteSpace: "nowrap" as CSSProperties["white-space"],
    "&:hover": {
      border: `1px solid ${colors.Green[100]}`,
    },
  },
  content: {
    lineHeight: "45px",
  },
  active: {
    border: `1px solid ${colors.Green[100]}`,
    color: colors.Green[100],
  },
});

const FormInputSelect: FC<FormField & { groupPath: string }> = ({
  label,
  name,
  group,
  options,
  disabled = false,
  type,
  groupPath,
}) => {
  const styles = useCSSRulesWithTheme(getStyles, {});
  const { handleChange } = useForm();
  const [value, inputError] = useFieldValue(name, groupPath);

  const handleSelect = (option: Option) => {
    handleChange(
      selectionToEvent(name, type as SelectionInputTypes, option),
      group
    );
  };

  const selectedOptionIndex = findOptionIndex(
    type as SelectionInputTypes,
    options!,
    value
  );
  return (
    <Container>
      <Row>
        {options?.map((option, i) => (
          <Column
            xs={12}
            sm={6}
            md={4}
            key={`${option.id}/${option.name}`}
            css={styles.col}
          >
            <div
              css={css(
                styles.option,
                selectedOptionIndex === i && styles.active
              )}
              onClick={(e) => handleSelect(option)}
            >
              <Typography
                variant="textMedium"
                component={"div"}
                css={styles.content}
                lowercase
              >
                {option.name}
              </Typography>
            </div>
          </Column>
        ))}
        {inputError && (
          <Typography
            variant="textSmall"
            color="ErrorRed"
            align="left"
            component="div"
            css={{ width: "100%" }}
          >
            {inputError}
          </Typography>
        )}
      </Row>
    </Container>
  );
};

export default FormInputSelect;

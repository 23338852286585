/** @jsxImportSource @emotion/react */

import { FC, useCallback } from "react";
import Reject from ".";
import { ConsultantsLocation } from "../../Locations";
import useContactDetails from "../../hooks/useContactDetails";
import { useHandleSignupRejectionMutation } from "../../graphql/generated/types";
import { rejectReasons } from "../../shared/contact/rejectReason";

const PartnerRejection: FC = () => {
  const { contactData } = useContactDetails();
  const _id = contactData._id!;
  const [handleSignupRejectionMutation] = useHandleSignupRejectionMutation();

  const rejectPartnerById = useCallback(
    async (_: any, __: string, rejectReason: string) => {
      await handleSignupRejectionMutation({
        variables: { contactId: _id, rejectReason },
      });
    },
    [_id]
  );

  return (
    <Reject
      data={contactData}
      callbackLocation={ConsultantsLocation}
      confirmationMessage={"Cadastro recusado!"}
      errorMessage={"Não foi possível recusar o parceiro."}
      rejectReasons={rejectReasons}
      deletePartnerOrQuotation={rejectPartnerById}
    />
  );
};

export default PartnerRejection;

import { Services, UserRoles } from "../graphql/generated/types";
import { TFilter } from "./types";

export const genericToFilter = (key: string) => (value: any) => ({
  [key]: value,
});

export const responsibleFilter: TFilter = {
  label: "Responsável",
  value: [],
  urlParam: "responsavel",
  name: "responsible",
  selectOptionDisplay: "avatar",
  selectMenuListDisplay: "buttons",
  selectMultiValueDisplay: "all",
  many2Many: {
    serviceName: Services.Users,
    domain: { role: UserRoles.Admin },
  },
  toFilter: genericToFilter("responsibleId"),
  options: [
    {
      value: "nenhum",
      label: "Sem Responsável",
      filter: {
        responsibleId: null,
      },
    },
  ],
};

import { FC } from "react";

const ChevronLeft: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_818)">
        <path
          d="M10 16L20 6L21.4 7.4L12.8 16L21.4 24.6L20 26L10 16Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_818">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronLeft;

import React, { FC, useMemo } from "react";
import { FormField, FormInputTypes } from "../models/formInterfaces";
import FormInputBoolean from "./inputs/FormInputBoolean";
import FormInputDate from "./inputs/FormInputDate";
import FormInputDropdown from "./inputs/FormInputDropdown";
import FormInputGeoLocation from "./inputs/FormInputGeoLocation";
import FormInputObjectList from "./inputs/FormInputObjectList";
import FormInputRadio from "./inputs/FormInputRadio";
import FormInputSelect from "./inputs/FormInputSelect";
import FormInputTextArea from "./inputs/FormInputTextAreaField";
import FormInputTextField from "./inputs/FormInputTextField";
import FormInputObject from "./inputs/FormInputObject";
import FormInputFloatField from "./inputs/FormInputFloatField";
import FormUploadInput from "./inputs/FormInputFile";
import FormInputPolygon from "./inputs/FormInputPolygon";
import FormInputMonetary from "./inputs/FormInputMonetary";
import DetailsSectionTitle from "../../../components/Details/components/Main/Section/DetailsSectionTitle";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";
import FormInputMultiSelect from "./inputs/FormInputMultiSelect";
import FormInputMany2One from "./inputs/FormInputMany2One";
import FormInputMany2Many from "./inputs/FormInputMany2Many";
import { composeFieldPath } from "./inputs/utils";

interface FormInputProps {
  fieldProps: FormField;
  groupPath: string;
}

const FormInput = ({ fieldProps, groupPath }: FormInputProps) => {
  const { inputType } = fieldProps;
  groupPath = groupPath === "main" || !groupPath ? fieldProps.group : groupPath;
  const inputComponent = useMemo(() => {
    switch (inputType) {
      case FormInputTypes.TEXT_FIELD:
        return FormInputTextField;
      case FormInputTypes.NUMBER:
        return FormInputFloatField;
      case FormInputTypes.SELECT:
        return FormInputSelect;
      case FormInputTypes.MULTI_SELECT:
        return FormInputMultiSelect;
      case FormInputTypes.RADIO:
        return FormInputRadio;
      case FormInputTypes.DATE:
        return FormInputDate;
      case FormInputTypes.DROPDOWN:
        return FormInputDropdown;
      case FormInputTypes.MANY2ONE:
        return FormInputMany2One;
      case FormInputTypes.MANY2MANY:
        return FormInputMany2Many;
      case FormInputTypes.GEO_LOCATION:
        return FormInputGeoLocation;
      case FormInputTypes.TEXT_AREA:
        return FormInputTextArea;
      case FormInputTypes.BOOLEAN:
        return FormInputBoolean;
      case FormInputTypes.OBJECT_LIST:
        return FormInputObjectList;
      case FormInputTypes.OBJECT:
        return FormInputObject;
      case FormInputTypes.FILE:
        return FormUploadInput;
      case FormInputTypes.POLYGON:
        return FormInputPolygon;
      case FormInputTypes.MONETARY:
        return FormInputMonetary;
      default:
        return FormInputTextField;
    }
  }, [inputType]);

  if (!inputComponent) return <></>;

  const element = React.createElement<FormField & { groupPath: string }>(
    inputComponent as FC,
    {
      ...fieldProps,
      groupPath,
      key: composeFieldPath(groupPath, fieldProps.name),
    }
  );
  if (fieldProps.divider)
    return (
      <>
        <Row>
          <Column>
            <DetailsSectionTitle title={fieldProps.divider} subtitle />
          </Column>
        </Row>
        {element}
      </>
    );

  return element;
};

export default FormInput;

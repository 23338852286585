import { FC } from "react";

const Upload: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_822)">
        <path
          d="M6 4V8H4V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H26C26.5304 2 27.0391 2.21071 27.4142 2.58579C27.7893 2.96086 28 3.46957 28 4V8H26V4H6Z"
          fill="black"
        />
        <path
          d="M7.41 19.41L6 18L16 8L26 18L24.59 19.41L17 11.83V30H15V11.83L7.41 19.41Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_822">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Upload;

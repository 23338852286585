/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useMemo, useState } from "react";
import { TableProps, styles } from "../Table";
import TableHeader from "../Table/TableHeader";
import TableRow from "../Table/TableRow";
import FormApp from "../../modules/Forms";
import useObjectFormResponse, {
  ObjectFormResponseProps,
} from "../../hooks/useObjectFormResponse";
import { OperationVariables } from "@apollo/client";
import { IconNames } from "../Icons/styles/iconNames";
import { ColorNames } from "../../theme";
import Container from "../Grid/Container";
import Button from "../Button";
import Row from "../Grid/Row";
import Typography from "../Typography";
import { useModalStore } from "../Modal/context/ModalStore";
import DetailsSection from "../Details/components/Main/Section/DetailsSection";
import { useFlags } from "../../hooks/useFlags";
import FormV2 from "../../modules/Forms/form.v2";

interface EditableTableProps<
  T,
  C extends OperationVariables,
  E extends OperationVariables
> extends TableProps<T>,
    ObjectFormResponseProps<C, E> {
  onSubmit?: (res: any) => void;
  addText?: string;
  formDisplay?: "modal" | "row";
  formTitle?: string;
  // Está editando um item na lista de um objeto?
  parentObjectId?: string;
  addFlag?: string;
  editFlag?: string;
  useV2?: boolean;
}

const EditableTable = <
  T,
  C extends OperationVariables,
  E extends OperationVariables
>({
  data,
  columns,
  createQuery,
  createPayload,
  editQuery,
  objectId,
  editPayload,
  actions = [],
  color,
  withBorder,
  onSubmit,
  addText = "Adicionar",
  formDisplay = "row",
  formTitle = "",
  DetailsComponent,
  parentObjectId,
  addFlag,
  editFlag,
  useV2,
}: EditableTableProps<T, C, E>) => {
  const [editingRowIndexes, setEditingRowIndexes] = useState(
    Array(data.length).fill("")
  );
  const [formId, setFormId] = useState("");
  useEffect(() => {
    setEditingRowIndexes(data.map((_, i) => editingRowIndexes[i] || ""));
  }, [data.length]);

  const { handleEditObject, handleNewObject } = useObjectFormResponse<C, E>({
    createQuery,
    createPayload,
    editQuery,
    objectId,
    editPayload,
  });
  const handleEdit = (index: number) => {
    handleEditObject(
      parentObjectId || (data[index] as any)._id,
      parentObjectId ? index : undefined
    ).then(({ formResponseId, formId }) => {
      setFormId(formId);
      setEditingRowIndexes((prev) => {
        const newEditingRowIndexes = [...prev];
        newEditingRowIndexes[index] = formResponseId;
        return newEditingRowIndexes;
      });
    });
  };
  const handleStopEdit = (index: number) => {
    setFormId("");
    setEditingRowIndexes((prev) => {
      const newEditingRowIndexes = [...prev];
      newEditingRowIndexes[index] = "";
      return newEditingRowIndexes;
    });
  };

  const [creatingResId, setCreatingResId] = useState("");
  const handleCreate = useCallback(() => {
    handleNewObject().then(({ formResponseId, formId }) => {
      setCreatingResId(formResponseId);
      setFormId(formId);
    });
  }, []);

  const renderForm = useCallback(
    (resId: string, index?: number) => {
      const closeForm = () => {
        if (index === undefined) {
          setFormId("");
          setCreatingResId("");
        } else {
          handleStopEdit(index);
        }
      };
      if (useV2) {
        return (
          <FormV2
            formResponseId={resId}
            onSubmit={(res) => {
              closeForm();
              onSubmit && onSubmit(res);
            }}
            onCancel={closeForm}
          />
        );
      }

      return (
        <FormApp
          formId={formId}
          docId={resId}
          onSubmit={(res) => {
            closeForm();
            onSubmit && onSubmit(res);
          }}
          onCancel={closeForm}
          displayAsTableRow={formDisplay === "row"}
        />
      );
    },
    [formId]
  );

  const { openModal, closeModal } = useModalStore();

  useEffect(() => {
    if (formDisplay !== "modal") {
      return;
    }
    if (creatingResId) {
      openModal(
        <DetailsSection main title={formTitle}>
          {renderForm(creatingResId)}
        </DetailsSection>,
        () => setCreatingResId("")
      );
      return;
    }
    const editingIndex = editingRowIndexes.findIndex((resId) => !!resId);
    if (editingIndex !== -1) {
      openModal(
        <DetailsSection main title={formTitle}>
          {renderForm(editingRowIndexes[editingIndex], editingIndex)}
        </DetailsSection>
      );
      return;
    }
    closeModal();
  }, [creatingResId, editingRowIndexes]);

  const { isFlagEnabled } = useFlags();
  const creationIsAllowed = useMemo(() => {
    return !addFlag || isFlagEnabled(addFlag);
  }, [isFlagEnabled, addFlag]);

  return (
    <Container css={styles.table}>
      <TableHeader
        columns={columns}
        hasActions={!!editQuery || !!actions.length || !!creatingResId}
        color={color}
        withBorder={withBorder}
      />
      {data.length ? (
        data.map((row, index) => (
          <TableRow
            key={`row-${index}`}
            columns={columns}
            data={row}
            borderColor={withBorder ? color || ColorNames.BLACK : undefined}
            DetailsComponent={DetailsComponent}
            forceActionsColumn={!!creatingResId}
            actions={
              editQuery
                ? [
                    {
                      label: "Editar",
                      onClick: () => handleEdit(index),
                      iconName: IconNames.Edit,
                      flag: editFlag,
                    },
                    ...actions,
                  ]
                : actions
            }
          >
            {editingRowIndexes[index] && formDisplay === "row"
              ? renderForm(editingRowIndexes[index], index)
              : undefined}
          </TableRow>
        ))
      ) : (
        <Typography variant="textMedium" uppercase color={color}>
          Não há registros
        </Typography>
      )}
      {creationIsAllowed && createQuery && !creatingResId && (
        <Row style={{ marginTop: "1em", maxWidth: 200 }}>
          <Button
            style="outlinePrimary"
            onClick={handleCreate}
            text={addText}
            iconName={IconNames.Add}
          />
        </Row>
      )}
      {createQuery && creatingResId && formDisplay === "row" && (
        <TableRow
          columns={columns}
          data={{} as any}
          borderColor={withBorder ? color || ColorNames.BLACK : undefined}
        >
          {renderForm(creatingResId)}
        </TableRow>
      )}
    </Container>
  );
};

export default EditableTable;

/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DetailsTab } from "../../../../../Routes";
import { ColorNames } from "../../../../../theme";
import { IconNames } from "../../../../Icons/styles/iconNames";
import Icon from "../../../../Icons";
import { IconSizes } from "../../../../Icons/styles/iconSizes";
import Tooltip from "../../../../Tooltip";
import useDetailsColor from "../../../../../hooks/useDetailsColor";
import { useFlags } from "../../../../../hooks/useFlags";

const styles = {
  contentSelector: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
};

type DetailsMenuTab = {
  title: string;
  iconName: IconNames;
  navigate?: string;
  flag?: string;
};

const getDefaultTabs = () => [
  {
    title: "Detalhes",
    iconName: IconNames.Details,
  },
  {
    title: "Notas",
    iconName: IconNames.Notes,
    navigate: DetailsTab.Notes,
    flag: "admin-only",
  },
  {
    title: "Chat",
    iconName: IconNames.Chat,
    navigate: DetailsTab.Chat,
    flag: "admin-only",
  },
];

const DetailsMenu: FC<{ tabs?: DetailsMenuTab[] }> = ({
  tabs = getDefaultTabs(),
}) => {
  const { color } = useDetailsColor();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFlagEnabled } = useFlags();

  const activeTab = useMemo(() => {
    const tabId = location.pathname.split("/")[4];
    return tabId as DetailsTab;
  }, [location]);

  const tabsWithAccess = useMemo(() => {
    return tabs.filter((t) => !t.flag || isFlagEnabled(t.flag));
  }, [tabs, isFlagEnabled]);

  return (
    <div css={styles.contentSelector}>
      {tabsWithAccess.map((tab, index) => (
        <Tooltip id={tab.title} key={index} text={tab.title}>
          <Icon
            name={tab.iconName}
            size={IconSizes.Medium}
            color={activeTab === tab.navigate ? color : ColorNames.GREY}
            onClick={() => navigate(tab.navigate || ".")}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default DetailsMenu;

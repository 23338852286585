/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import Typography from "../Typography";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames, theme } from "../../theme";

interface StylesParams {
  open: boolean;
}

interface ActionsListProps {
  label: string;
  actions: {
    label: string;
    onClick: () => void;
  }[];
}

const getStyles = ({ open }: StylesParams) => ({
  container: css`
    position: relative;
  `,
  button: css`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    user-select: none;
    cursor: pointer;
    align-items: center;
    text-align: left;
    border: 1px solid black;
    height: 45px;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 10px;

    svg {
      cursor: pointer;
    }
  `,
  dropdown: css`
    z-index: 1000;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    text-align: left;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    visibility: ${open ? "visible" : "hidden"};

    > *:hover {
      background-color: ${theme.colors.Grey[20]};
    }
  `,
  item: css`
    padding: 8px 16px;
  `,
});

const ActionList: React.FC<ActionsListProps> = ({ label, actions }) => {
  const [open, setOpen] = useState(false);
  const styles = getStyles({ open });

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div css={styles.container}>
      <div onClick={toggleOpen} css={styles.button}>
        <Typography size={20}>{label}</Typography>
        <Icon name={IconNames.ChevronDown} color={ColorNames.GREY} />
      </div>
      <div css={styles.dropdown}>
        {actions.map(({ label, onClick }) => (
          <div
            css={styles.item}
            onClick={() => {
              onClick();
              toggleOpen();
            }}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActionList;

import { FC } from "react";

const Chat: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_261_52)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.74 30L16 29V24H6C4.93913 24 3.92172 23.5786 3.17157 22.8284C2.42143 22.0783 2 21.0609 2 20V8C2 6.93913 2.42143 5.92172 3.17157 5.17157C3.92172 4.42143 4.93913 4 6 4H26C27.0609 4 28.0783 4.42143 28.8284 5.17157C29.5786 5.92172 30 6.93913 30 8V20C30 21.0609 29.5786 22.0783 28.8284 22.8284C28.0783 23.5786 27.0609 24 26 24H21.16L17.74 30ZM18 22V25.5L20 22H26C26.5304 22 27.0391 21.7893 27.4142 21.4142C27.7893 21.0391 28 20.5304 28 20V8C28 7.46957 27.7893 6.96086 27.4142 6.58579C27.0391 6.21071 26.5304 6 26 6H6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_261_52">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Chat;

/** @jsxImportSource @emotion/react */

import { useNotes } from "../../../hooks/useNotes";
import ScrollContainer from "../../ScrollContainer";
import Note from "./Note";
import { notesFooterHeight } from "./NotesFooter";

const NotesContainer = () => {
  const notes = useNotes();
  const { documentNotes } = notes;

  return (
    <ScrollContainer height={`calc(100% - ${notesFooterHeight}px)`} useAnchor>
      {!documentNotes?.length && <div>Não há notas</div>}
      {documentNotes?.map((note, i) => (
        <Note {...note} key={`note-${i}`} />
      ))}
    </ScrollContainer>
  );
};

export default NotesContainer;

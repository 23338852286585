export const concatArrayOfStrings = (
  array: string[],
  placeholder: string = ""
) => {
  return array.length === 0
    ? placeholder
    : array.length > 1
    ? array.map((item, i) => (i !== array.length - 1 ? `${item}, ` : item))
    : array;
};

export const breakTextLines = (text: string) => {
  return text.split("\n");
};

export const booleanToYesNo = (value?: boolean | null) => {
  if (value === false) return "Não";
  if (value === true) return "Sim";
  return "-";
};

import { useMemo } from "react";
import { useForm } from "../../../hooks/useForm";
import { composeFieldPath, getFieldValue } from "../utils";

const useFieldValue = (fieldName: string, groupPath: string) => {
  const { formState, errors, fieldsToValidate, clickedSubmit } = useForm();
  const fieldPath = useMemo(() => {
    return composeFieldPath(groupPath, fieldName);
  }, [fieldName, groupPath]);

  const value = getFieldValue(formState, fieldPath);
  const error =
    ((fieldsToValidate && fieldsToValidate[fieldPath]) || clickedSubmit) &&
    errors &&
    errors[fieldPath];
  return [value, error];
};

export default useFieldValue;

/** @jsxImportSource @emotion/react */

import Column from "../../Grid/Column";
import Row from "../../Grid/Row";
import SkeletonAnimationContainer from "../../Skeletons/components/SkeletonAnimationContainer";
import SkeletonCard from "./SkeletonCard";

const SkeletonCardList = () => {
  const cards = Array(24).fill(<SkeletonCard />);
  return (
    <>
      <SkeletonAnimationContainer />
      <Row>
        {cards.map((_, i) => (
          <Column
            xs={12}
            sm={6}
            lg={4}
            key={`skeleton-card-${i}`}
            css={{ marginBottom: 24 }}
          >
            <SkeletonCard />
          </Column>
        ))}
      </Row>
    </>
  );
};

export default SkeletonCardList;

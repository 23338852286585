/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { css } from "@emotion/react";
import { ColorNames, theme } from "../../theme";

interface StyleParams {
  color: ColorNames;
}

interface ChipProps extends StyleParams {
  label: string | ReactNode;
}

const getStyles = ({ color }: StyleParams) => ({
  chip: css`
    color: ${theme.colors[color][100]};
    background-color: ${theme.colors[color][40]};
    font-weight: bold;
    padding: 5px;
    height: 20px;
    border-radius: 15px;
    text-align: center;
  `,
});

const Chip: React.FC<ChipProps> = ({ label, color }) => {
  const styles = getStyles({ color });
  return <div css={styles.chip}>{label}</div>;
};

export default Chip;

import { createContext, FC, ReactNode, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { isError } from "lodash";
import { captureException, captureMessage } from "@sentry/react";
import { CaptureContext, Extras } from "@sentry/types";
import { ApolloError } from "@apollo/client";
import { Errors } from "../graphql/generated/types";

enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
}

interface ErrorStore {
  notify: (message: string, type: NotificationType) => void;
  errorHandler: (
    error: Error | Response,
    originalError?: unknown,
    errorLocation?: string,
    extra?: Extras
  ) => void;
  localErrors: LocalErrorsInterface;
  dismissError: (errorLocation: string) => void;
  withErrorHandler: (
    fn: any,
    errorMsg: string
  ) => (...args: any[]) => Promise<void>;
}

export const ErrorContext = createContext<ErrorStore>({
  notify: () => null,
  errorHandler: () => null,
  localErrors: {},
  dismissError: () => null,
  withErrorHandler: () => async () => {},
});

interface ErrorContextProviderProps {
  children: ReactNode;
}

interface LocalErrorsInterface {
  [k: string]: string | undefined;
}

export const ErrorContextProvider: FC<ErrorContextProviderProps> = ({
  children,
}) => {
  const [, setLocalError] = useState();

  const [localErrors, setLocalErrors] = useState<LocalErrorsInterface>({});

  const notify = (message: string, type: NotificationType) => {
    if (type === NotificationType.ERROR) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const addError = (error: Error, errorLocation: string) => {
    if (errorLocation === "toast") {
      notify(error.message, NotificationType.ERROR);
    } else {
      setLocalErrors({ ...localErrors, [errorLocation]: error.message });
    }
  };
  const formatError = (e: any) => {
    if (isError(e)) {
      return e;
    } else {
      return `Non-Error exception: ${String(e)}`;
    }
  };

  const sendErrorToSentry = (error: unknown, extra?: Extras) => {
    const formatedError = formatError(error);
    const context: CaptureContext = { extra };

    if (isError(formatedError)) captureException(formatedError, context);
    else captureMessage(formatedError, context);
  };

  const errorHandler: ErrorStore["errorHandler"] = function (
    error,
    originalError,
    errorLocation = "toast",
    extra = {}
  ) {
    sendErrorToSentry(originalError || error, extra);

    if (isError(error)) {
      return addError(error as Error, errorLocation);
    }

    setLocalError(() => {
      // https://github.com/facebook/react/issues/14981#issuecomment-468460187
      throw new Error(error.statusText || "Unknown error");
    });
  };

  const dismissError = useCallback(
    (errorLocation: string) => {
      setLocalErrors({ ...localErrors, [errorLocation]: undefined });
    },
    [localErrors]
  );

  const withErrorHandler = useCallback(
    (fn: any, defaultErrorMsg: string) => {
      return async function (...args: any[]) {
        try {
          await fn(...args);
        } catch (e: any) {
          let error = undefined;
          let errorMsg = defaultErrorMsg;
          if (e instanceof ApolloError) {
            const exception: any = e.graphQLErrors[0]?.extensions?.exception;
            if (exception?.name === Errors.PrismaValidationError) {
              errorMsg = e.message;
            }
          } else {
            error = e;
          }
          errorHandler(new Error(errorMsg), error);
        }
      };
    },
    [errorHandler]
  );

  return (
    <ErrorContext.Provider
      value={{
        notify,
        errorHandler,
        localErrors,
        dismissError,
        withErrorHandler,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

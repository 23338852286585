import { FC } from "react";
import { Many2OneFieldType } from "../../models/formInterfaces";
import { useGetMany2XOptionsQuery } from "../../../../graphql/generated/types";

interface SummaryInputMany2oneProps {
  field: Many2OneFieldType;
  value: string;
}

const SummaryInputMany2one: FC<SummaryInputMany2oneProps> = ({
  field,
  value,
}) => {
  const { data } = useGetMany2XOptionsQuery({
    variables: {
      serviceName: field.serviceName,
      currentValue: value,
      domain: field.domain,
      searchTerm: "",
    },
  });
  return (
    <>
      {data?.getMany2XOptions.find(
        ({ value: optionValue }) => optionValue === value
      )?.label || ""}
    </>
  );
};

export default SummaryInputMany2one;

import { useMemo } from "react";
import { useScreenClass } from "react-grid-system";

const useIsMobile = () => {
  const screenClass = useScreenClass();
  const isMobile = useMemo(() => {
    return (
      screenClass === "lg" ||
      screenClass === "md" ||
      screenClass === "sm" ||
      screenClass === "xs"
    );
  }, [screenClass]);
  return isMobile;
};

export default useIsMobile;

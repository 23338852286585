import { FC, PropsWithChildren } from "react";
import Typography from "../../../../Typography";
import useDetailsColor from "../../../../../hooks/useDetailsColor";

const DetailsRowLabel: FC<PropsWithChildren> = ({ children }) => {
  const { color } = useDetailsColor();
  return (
    <Typography
      variant="textMedium"
      uppercase
      component="div"
      color={color}
      align="left"
      noWrap
    >
      {children}
    </Typography>
  );
};

export default DetailsRowLabel;

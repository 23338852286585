import { FC } from "react";

const Location: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_306_214)">
        <path
          d="M16 2C13.0837 2.00355 10.2879 3.16361 8.22575 5.22574C6.16362 7.28787 5.00356 10.0837 5.00001 13C4.99657 15.3833 5.77515 17.7018 7.21631 19.6C7.21631 19.6 7.51631 19.9945 7.56451 20.0517L16 30L24.439 20.0474C24.4834 19.9941 24.7837 19.5996 24.7837 19.5996L24.7852 19.5972C26.2255 17.6997 27.0036 15.3822 27 13C26.9965 10.0837 25.8364 7.28787 23.7743 5.22574C21.7121 3.16361 18.9163 2.00355 16 2ZM16 17C15.2089 17 14.4355 16.7654 13.7777 16.3259C13.1199 15.8864 12.6072 15.2616 12.3045 14.5307C12.0017 13.7998 11.9225 12.9956 12.0769 12.2196C12.2312 11.4437 12.6122 10.731 13.1716 10.1716C13.731 9.61216 14.4437 9.2312 15.2197 9.07686C15.9956 8.92252 16.7998 9.00173 17.5307 9.30448C18.2616 9.60723 18.8864 10.1199 19.3259 10.7777C19.7654 11.4355 20 12.2089 20 13C19.9988 14.0605 19.577 15.0772 18.8271 15.8271C18.0772 16.577 17.0605 16.9988 16 17Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_306_214">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Location;

/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import DetailsWrapper from "../../../components/Details/components/Main/DetailsWrapper";
import { useGetContactQuery } from "../../../graphql/generated/types";
import SkeletonDetails from "../../../components/Details/components/SkeletonDetails";
import ContactDetailsProvider from "../../../contexts/contacts";
import Sidebar from "../../../components/Sidebar";
import DetailsMenu from "../../../components/Details/components/Header/DetailsTabs";
import { ColorNames } from "../../../theme";
import ContactFlow from "../ContactFlow";
import { contactDetailsTabRoutes } from "../Routes";

const ContactDetails: FC = () => {
  const params = useParams();
  const location = useLocation();
  const contactId = params.contactId!;
  const { data, loading, refetch } = useGetContactQuery({
    variables: { contactId },
  });
  const tab = useMemo(
    () => location.pathname.split("/").pop(),
    [location.pathname]
  );

  if (loading) return <SkeletonDetails />;

  const detailsTabContent = (
    <Sidebar
      TopComponent={() => <DetailsMenu />}
      menuItems={[
        {
          title: tab!,
          Component: Outlet,
          color: ColorNames.GREEN,
        },
      ]}
    ></Sidebar>
  );

  return (
    <ContactDetailsProvider contactData={data!.contact} refetch={refetch}>
      <DetailsWrapper
        detailsTabContent={detailsTabContent}
        detailsTabRoutes={contactDetailsTabRoutes}
      >
        <ContactFlow />
      </DetailsWrapper>
    </ContactDetailsProvider>
  );
};

export default ContactDetails;

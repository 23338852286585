import documentFilters from "../filters/documents";
import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import { CountDocumentsDocument } from "../graphql/generated/types";

const useDocumentsFilter = () => {
  const filters = useModelFilter(documentFilters, [], CountDocumentsDocument);

  useUrlFilter(filters);

  return filters;
};

export default useDocumentsFilter;

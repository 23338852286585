/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorAlias, getThemeColorFromAlias, theme } from "../../theme";

interface ArrowUpIconProps {
  onClick?: () => void;
  color?: ColorAlias;
}

const ArrowUpIcon: FC<ArrowUpIconProps> = ({ onClick, color }) => {
  const iconColor = color
    ? getThemeColorFromAlias(color, theme.colors)
    : getThemeColorFromAlias("Green", theme.colors);
  return (
    <span onClick={onClick}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 27 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26 14.5L13.633 2L1 14.5"
          stroke={iconColor}
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};

export default ArrowUpIcon;

import { components } from "react-select";
import { SelectOptionDisplay } from "../SelectInput";
import OptionTemplate from "./OptionTemplate";

const Placeholder = (props: any) => {
  const display = (props.selectProps as any)
    .optionDisplay as SelectOptionDisplay;
  if (display === "avatar") {
    return (
      <components.Placeholder {...props}>
        <OptionTemplate label="" optionDisplay="avatar" />
      </components.Placeholder>
    );
  }
  return <components.Placeholder {...props} />;
};

export default Placeholder;

/** @jsxImportSource @emotion/react */

import { useState } from "react";
import useContactDetails from "../../../../hooks/useContactDetails";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { IconSizes } from "../../../../components/Icons/styles/iconSizes";
import { CSSObject } from "@emotion/react";
import Typography from "../../../../components/Typography";
import { PercentageInput } from "../../../../components/Input/PercentageInput";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    justifyContent: "start",
    width: "100%",
    alignItems: "center",
  },
  iconWrapper: {
    marginLeft: 5,
    paddingTop: 2,
  },
};

export const EditableCommission = () => {
  const {
    contactData: { commission: currentValue },
    handleSetCommission,
  } = useContactDetails();
  const [isEditing, setIsEditing] = useState(false);
  const initialValue =
    currentValue !== undefined && currentValue !== null
      ? (currentValue * 100).toString()
      : "";
  const [value, setValue] = useState(initialValue);

  const abortChanges = () => {
    setValue(initialValue);
    setIsEditing(false);
  };

  const getDecimalValue = () => {
    const numericString = value.trim();
    const parsedValue = parseFloat(numericString);

    if (isNaN(parsedValue)) {
      return null;
    }

    return parsedValue / 100;
  };

  const handleToggleEdit = async () => {
    if (isEditing) {
      const decimalValue = getDecimalValue();
      if (decimalValue !== null) {
        await handleSetCommission(decimalValue);
      }
    }
    setIsEditing(!isEditing);
  };

  return (
    <div css={styles.wrapper}>
      {!isEditing && (
        <>
          <Typography>
            {currentValue !== undefined && currentValue !== null
              ? `${(currentValue * 100).toString()}%`
              : "Não informado"}
          </Typography>
          <Icon
            css={styles.iconWrapper}
            name={IconNames.Edit}
            size={IconSizes.Small}
            onClick={handleToggleEdit}
            tooltipText="Editar"
          />
        </>
      )}
      {isEditing && (
        <>
          <PercentageInput
            value={value}
            onChange={setValue}
            name="commission"
          />
          <Icon
            css={styles.iconWrapper}
            name={IconNames.Done}
            size={IconSizes.Medium}
            onClick={handleToggleEdit}
            tooltipText="Salvar"
          />
          <Icon
            css={styles.iconWrapper}
            name={IconNames.Close}
            size={IconSizes.Medium}
            onClick={abortChanges}
            tooltipText="Cancelar"
          />
        </>
      )}
    </div>
  );
};

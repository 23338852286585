/** @jsxImportSource @emotion/react */

import { FC, PropsWithChildren } from "react";
import { theme } from "../../../theme";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  label: {
    display: "inline-block",
    width: "100%",
    color: theme.colors.Black[100],
    textAlign: "left",
    fontWeight: "bold",
    paddingLeft: 5,
    marginBottom: 5,
    fontSize: 14,
    textTransform: "uppercase",
  },
};

const FormLabel: FC<PropsWithChildren> = ({ children }) => {
  return <label css={styles.label}>{children}</label>;
};

export default FormLabel;

/** @jsxImportSource @emotion/react */
import { Outlet, useNavigate } from "react-router-dom";
import MainFrame from "../../components/MainFrame";
import { useUIStore } from "../../hooks/useUIStore";
import Layout from "../../components/Layout";
import { DocumentsLocation } from "../../Locations";
import {
  ListDocumentsDocument,
  useListDocumentsLazyQuery,
} from "../../graphql/generated/types";
import useDocumentsFilter from "../../hooks/useDocumentsFilter";
import documentsTableColumns, {
  mapTableData,
} from "./DocumentsTable/tableColumns";
import TableProvider from "../../contexts/table";
import DocumentsTable from "./DocumentsTable";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";

const Documents = () => {
  const { toggleDrawerSize } = useUIStore();
  const navigate = useNavigate();
  const documentFilters = useDocumentsFilter();
  const [listDocumentsQuery] = useListDocumentsLazyQuery();

  const fetchCSVData = async () => {
    const result = await listDocumentsQuery({
      query: ListDocumentsDocument,
      variables: {
        filter: documentFilters.composeQuery(),
      },
    });

    if (result.data?.documents) {
      return mapTableData(result.data.documents);
    }

    return [];
  };

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(DocumentsLocation)}
        onToggle={toggleDrawerSize}
        filter={documentFilters}
      >
        <ModalStoreProvider>
          <TableProvider
            allColumns={documentsTableColumns}
            initialSort={{ id: "date", sortKey: "createdAt", direction: -1 }}
            fetchCSVData={fetchCSVData}
            localStorageOptionsKey="documentsTableSelectedColumns"
          >
            <DocumentsTable filter={documentFilters} />
          </TableProvider>
        </ModalStoreProvider>
      </MainFrame>
    </Layout>
  );
};

export default Documents;

/** @jsxImportSource @emotion/react */

import { FC, ReactNode, useMemo, useState } from "react";
import ButtonGroup from "../../../../components/ButtonGroup";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { theme } from "../../../../theme";
import ScrollContainer from "../../../../components/ScrollContainer";

const buttonGroupHeight = 42;
const buttonGroupMargin = 24;

const styles = {
  container: {
    height: "100%",
    overflow: "hidden",
  },
  buttonGroup: {
    height: buttonGroupHeight,
    backgroundColor: theme.colors.Grey[20],
    marginBottom: buttonGroupMargin,
  },
};

export interface SwitchOption {
  iconName: IconNames;
  element: ReactNode;
}

interface SwitchContainerProps {
  options: SwitchOption[];
}

const SwitchContainer: FC<SwitchContainerProps> = ({ options }) => {
  const [activeOption, setActiveOption] = useState(0);

  const buttons = useMemo(() => {
    return options.map((option, index) => ({
      iconName: option.iconName,
      onClick: () => setActiveOption(index),
    }));
  }, [options]);

  return (
    <div css={styles.container}>
      <div css={styles.buttonGroup}>
        <ButtonGroup
          buttons={buttons}
          noBackground
          noBorder
          defaultActiveIndex={0}
        />
      </div>
      <ScrollContainer
        height={`calc(100% - ${buttonGroupHeight + buttonGroupMargin}px)`}
      >
        {options[activeOption].element}
      </ScrollContainer>
    </div>
  );
};

export default SwitchContainer;

import { AuthContextInterface } from "../contexts/auth";
import serviceFetch, { FileData, serviceFetchFile } from "./fetch";

export const uploadFile = async (
  auth: AuthContextInterface,
  modelName: string,
  id: string,
  fileField: string,
  file: File,
  payload: any = {}
): Promise<{ success: boolean }> =>
  serviceFetch(
    auth,
    `${modelName}/${id}/upload-file/${fileField}`,
    null,
    "post",
    payload,
    file
  );

export const downloadFile = async (
  auth: AuthContextInterface,
  modelName: string,
  id: string,
  fileField: string
): Promise<FileData> =>
  serviceFetchFile(
    auth,
    `${modelName}/${id}/download-file/${fileField}`,
    null,
    "get"
  );

export const fetchTotals = async (
  auth: AuthContextInterface,
  modelName: string,
  filter: any
): Promise<{ data: { totalArea: number; totalValue: number } }> =>
  serviceFetch(auth, `${modelName}/totals`, null, "post", { filter });

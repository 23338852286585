/** @jsxImportSource @emotion/react */
import { CSSProperties } from "@emotion/serialize";
import React, { FC, useCallback, useMemo } from "react";
import Column from "../../../components/Grid/Column";
import Typography, { BaseFontSize } from "../../../components/Typography";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { ColorNames, CustomTheme, theme } from "../../../theme";
import { formatInputMask } from "../../../utils/formatNumber";
import { useForm } from "../hooks/useForm";
import {
  FormField,
  Many2OneFieldValue,
  LabelAndCodeFieldValue,
  TextFieldType,
  FormInputTypes,
  ObjectListFieldType,
  Many2OneFieldType,
} from "../models/formInterfaces";
import FormInputBoolean from "./inputs/FormInputBoolean";
import FormInputRadio from "./inputs/FormInputRadio";
import { composeFieldPath, findGroupObj } from "./inputs/utils";
import SectionRows from "./SectionRows";
import { formatISODate } from "../../../utils/dateUtils";
import SummaryInputMany2one from "./inputs/SummaryInputMany2one";

const getStyles: CSSRulesResolver = ({ colors }: CustomTheme) => ({
  formField: {
    color: colors.Black[100],
    width: "100%",
    height: 45,
    borderRadius: 5,
    border: "0.2px solid rgba(0, 0, 0, 0.2)",
    paddingLeft: 12,
    lineHeight: "45px",
    textAlign: "start" as CSSProperties["textAlign"],
    backgroundColor: colors.White[100],
    boxSizing: "border-box" as CSSProperties["boxSizing"],
    boxShadow: theme.boxShadow.default,
    overflow: "hidden",
    fontSize: BaseFontSize,
  },
  col: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginBottom: 24,
    },
  },
});

interface FormSumarrySectionRowsProps {
  fields: FormField[];
  groupPath: string;
}

const getFieldValue = (
  field: FormField,
  stateValue: (typeof field)["value"]
): string => {
  if (field.type === "Many2one") {
    return (
      (stateValue as Many2OneFieldValue)?.name ||
      field.options?.find((op) => op.value === stateValue)?.label ||
      stateValue
    );
  } else if (field.type == "labelAndCode") {
    return stateValue ? stateValue.label : "";
  } else if (field.inputType == FormInputTypes.DATE) {
    return stateValue ? formatISODate(stateValue) : "";
  } else if (field.inputType == FormInputTypes.DROPDOWN) {
    return field.options?.find((op) => op.value === stateValue)?.label || "";
  } else if (typeof stateValue === "string") {
    return stateValue || "";
  }
  return (stateValue as LabelAndCodeFieldValue)?.label;
};

const FormSumarrySectionRows: FC<FormSumarrySectionRowsProps> = ({
  fields,
  groupPath,
}) => {
  const { formState, visibility } = useForm();
  const styles = useCSSRulesWithTheme(getStyles);
  const fieldToColumn = useCallback((field: FormField) => {
    if (field.inputType === "radio") {
      return (
        <Column key={field.name} xs={12} sm={8}>
          {React.createElement<FormField & { groupPath: string }>(
            FormInputRadio as FC,
            {
              ...field,
              disabled: true,
              groupPath,
            }
          )}
        </Column>
      );
    }
    if (field.inputType === FormInputTypes.BOOLEAN) {
      return (
        <Column key={field.name} xs={12} sm={12}>
          {React.createElement<FormField & { groupPath: string }>(
            FormInputBoolean as FC,
            {
              ...field,
              disabled: true,
              groupPath,
            }
          )}
        </Column>
      );
    } else if (field.inputType === "geoLocation") {
      const value = findGroupObj(formState, groupPath)[field.name];
      return (
        <>
          <Column
            key={field.name}
            xs={12}
            sm={4}
            css={field.variant === "xs" && styles.col}
          >
            <div css={styles.formField}>{value && value.lat}</div>
          </Column>
          <Column
            key={field.name}
            xs={12}
            sm={4}
            css={field.variant === "xs" && styles.col}
          >
            <div css={styles.formField}>{value && value.lng}</div>
          </Column>
        </>
      );
    } else if (field.inputType === "objectList") {
      const value = findGroupObj(formState, groupPath)[field.name];
      return (
        <>
          <Column key={field.name} xs={12}>
            {value &&
              value.map((item: any, index: number) => {
                return (
                  <React.Fragment key={`${item.name}-${index}`}>
                    <Typography
                      css={{ padding: "0.5em" }}
                      variant="textLarge"
                      component="div"
                      align="left"
                      color={ColorNames.GREEN}
                    >
                      {`${field.label} ${index + 1}${
                        item.name && ` - ${item.name}`
                      }`}
                    </Typography>
                    <FormSumarrySectionRows
                      groupPath={composeFieldPath(
                        groupPath,
                        `${field.name}.${index}`
                      )}
                      fields={(field as ObjectListFieldType).fields}
                    />
                  </React.Fragment>
                );
              })}
          </Column>
        </>
      );
    } else if (field.inputType === "object") {
      return (
        <>
          <Column key={field.name} xs={12}>
            <FormSumarrySectionRows
              groupPath={composeFieldPath(groupPath, field.name)}
              fields={(field as ObjectListFieldType).fields}
            />
          </Column>
        </>
      );
    } else if (field.inputType === FormInputTypes.MANY2ONE) {
      const value = getFieldValue(
        field,
        findGroupObj(formState, groupPath)[field.name]
      );

      return (
        <Column
          key={field.name}
          xs={12}
          sm={field.variant === "xs" ? 4 : 8}
          css={field.variant === "xs" ? styles.col : styles.col}
        >
          <div css={styles.formField}>
            <SummaryInputMany2one
              field={field as Many2OneFieldType}
              value={value}
            />
          </div>
        </Column>
      );
    } else {
      const value = getFieldValue(
        field,
        findGroupObj(formState, groupPath)[field.name]
      );
      return (
        <Column
          key={field.name}
          xs={12}
          sm={field.variant === "xs" ? 4 : 8}
          css={field.variant === "xs" ? styles.col : styles.col}
        >
          <div css={styles.formField}>
            {formatInputMask(value, (field as TextFieldType).inputMask)}
          </div>
        </Column>
      );
    }
  }, []);

  const visibleFields = useMemo(() => {
    return fields.filter((field) => {
      const fieldPath = composeFieldPath(groupPath || field.group, field.name);
      return !(fieldPath in visibility) || visibility[fieldPath];
    });
  }, [fields, visibility]);

  return (
    <SectionRows
      fields={visibleFields}
      fieldToColumn={fieldToColumn}
      subSectionDivider={<Column xs={12} css={styles.verticalSpace} />}
    />
  );
};

export default FormSumarrySectionRows;

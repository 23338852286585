import { FC, useMemo } from "react";

import SelectionFieldSummary from "./SelectionFieldSummary";
import {
  FormFieldV2,
  useGetMany2XOptionsQuery,
} from "../../../../graphql/generated/types";

type Many2XFieldSummaryProps = {
  field: FormFieldV2;
};

const Many2XFieldSummary: FC<Many2XFieldSummaryProps> = ({
  field: { value, serviceName, ...field },
}) => {
  const valueAsArray = value instanceof Array ? value : [value];
  const { data } = useGetMany2XOptionsQuery({
    variables: {
      serviceName: serviceName!,
      currentValue: valueAsArray,
      domain: { _id: { $in: valueAsArray } },
      searchTerm: "",
    },
  });

  const options = useMemo(() => {
    return (
      data?.getMany2XOptions?.map(({ value, label }) => ({
        label: label!,
        value: value!,
      })) || []
    );
  }, [data?.getMany2XOptions]);

  return <SelectionFieldSummary field={{ ...field, value, options }} />;
};

export default Many2XFieldSummary;

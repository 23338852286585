import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";
import useFormField from "../../hooks/useFormField";
import SelectInput, {
  SelectInputOptionInterface,
} from "../../../../components/Input/SelectInputs/SelectInput";
import FormLabel from "../FormLabel";

interface DropdownFieldProps {
  field: FormFieldV2;
}

const DropdownField: FC<DropdownFieldProps> = ({ field }) => {
  const { options, value, name, label, disabled, saveAs } = field;
  const { handleChange, startEditing, finishEditing, error } =
    useFormField(field);
  const handleSelect = ({ value, label }: SelectInputOptionInterface) => {
    handleChange(saveAs === "option" ? { value, label } : value);
  };

  const inputValue = saveAs === "option" ? value?.value : value;

  return (
    <Column useYPaddings xs={12}>
      <FormLabel>{label}</FormLabel>
      <SelectInput
        options={options!}
        value={inputValue}
        inputName={name}
        placeholder={label}
        onSelect={handleSelect}
        onFocus={() => startEditing()}
        onBlur={() => finishEditing()}
        errorMessage={error}
        disabled={!!disabled}
        optionDisplay="old"
      />
    </Column>
  );
};

export default DropdownField;

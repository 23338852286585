import { FC } from "react";

const Deal: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_409_38)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2223 9.32588C9.56448 9.76541 8.79113 10 8 10C6.93913 10 5.92172 9.57858 5.17157 8.82843C4.42143 8.07828 4 7.06087 4 6C4 5.20888 4.2346 4.43552 4.67412 3.77772C5.11365 3.11992 5.73836 2.60723 6.46927 2.30448C7.20017 2.00173 8.00444 1.92252 8.78036 2.07686C9.55628 2.2312 10.269 2.61216 10.8284 3.17157C11.3878 3.73098 11.7688 4.44372 11.9231 5.21964C12.0775 5.99556 11.9983 6.79983 11.6955 7.53074C11.3928 8.26164 10.8801 8.88635 10.2223 9.32588ZM9.11114 4.33706C8.78224 4.1173 8.39556 4 8 4C7.46957 4 6.96086 4.21072 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6C6 6.39556 6.1173 6.78224 6.33706 7.11114C6.55682 7.44004 6.86918 7.69639 7.23463 7.84776C7.60009 7.99914 8.00222 8.03874 8.39018 7.96157C8.77814 7.8844 9.13451 7.69392 9.41421 7.41422C9.69392 7.13451 9.8844 6.77814 9.96157 6.39018C10.0387 6.00222 9.99913 5.60009 9.84776 5.23463C9.69638 4.86918 9.44004 4.55683 9.11114 4.33706Z"
          fill="black"
        />
        <path
          d="M24 12V11C24 10.3434 23.8707 9.69321 23.6194 9.08658C23.3681 8.47996 22.9998 7.92876 22.5355 7.46447C22.0712 7.00017 21.52 6.63188 20.9134 6.3806C20.3068 6.12933 19.6566 6 19 6H16V4H19C20.8565 4 22.637 4.7375 23.9497 6.05025C25.2625 7.36301 26 9.14348 26 11V12H24Z"
          fill="black"
        />
        <path
          d="M6 20V21C6 22.3261 6.52678 23.5979 7.46447 24.5355C8.40215 25.4732 9.67392 26 11 26H14V28H11C9.14348 28 7.36301 27.2625 6.05025 25.9497C4.7375 24.637 4 22.8565 4 21V20H6Z"
          fill="black"
        />
        <path
          d="M11 11H5C4.20435 11 3.44129 11.3161 2.87868 11.8787C2.31607 12.4413 2 13.2044 2 14V16H4V14C4 13.7348 4.10536 13.4804 4.29289 13.2929C4.48043 13.1054 4.73478 13 5 13H11C11.2652 13 11.5196 13.1054 11.7071 13.2929C11.8946 13.4804 12 13.7348 12 14V16H14V14C14 13.2044 13.6839 12.4413 13.1213 11.8787C12.5587 11.3161 11.7956 11 11 11Z"
          fill="black"
        />
        <path
          d="M21 25C20.2044 25 19.4413 25.3161 18.8787 25.8787C18.3161 26.4413 18 27.2044 18 28V30H20V28C20 27.7348 20.1054 27.4804 20.2929 27.2929C20.4804 27.1054 20.7348 27 21 27H27C27.2652 27 27.5196 27.1054 27.7071 27.2929C27.8946 27.4804 28 27.7348 28 28V30H30V28C30 27.2044 29.6839 26.4413 29.1213 25.8787C28.5587 25.3161 27.7956 25 27 25H21Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6741 22.2223C20.2346 21.5645 20 20.7911 20 20C20 18.9391 20.4214 17.9217 21.1716 17.1716C21.9217 16.4214 22.9391 16 24 16C24.7911 16 25.5645 16.2346 26.2223 16.6741C26.8801 17.1136 27.3928 17.7384 27.6955 18.4693C27.9983 19.2002 28.0775 20.0044 27.9231 20.7804C27.7688 21.5563 27.3878 22.269 26.8284 22.8284C26.269 23.3878 25.5563 23.7688 24.7804 23.9231C24.0044 24.0775 23.2002 23.9983 22.4693 23.6955C21.7384 23.3928 21.1136 22.8801 20.6741 22.2223ZM25.6629 21.1111C25.8827 20.7822 26 20.3956 26 20C26 19.4696 25.7893 18.9609 25.4142 18.5858C25.0391 18.2107 24.5304 18 24 18C23.6044 18 23.2178 18.1173 22.8889 18.3371C22.56 18.5568 22.3036 18.8692 22.1522 19.2346C22.0009 19.6001 21.9613 20.0022 22.0384 20.3902C22.1156 20.7781 22.3061 21.1345 22.5858 21.4142C22.8655 21.6939 23.2219 21.8844 23.6098 21.9616C23.9978 22.0387 24.3999 21.9991 24.7654 21.8478C25.1308 21.6964 25.4432 21.44 25.6629 21.1111Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_409_38">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Deal;

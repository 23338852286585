import { FC } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import EditableSection from "../../../../../components/EditableSection";
import useDetailsColor from "../../../../../hooks/useDetailsColor";
import Typography from "../../../../../components/Typography";
import Table from "../../../../../components/Table";
import EndorsementComponent from "./components/Endorsement";
import { GetCreateEndorsementOnDocumentFormResponseDocument } from "../../../../../graphql/generated/types";
import { EndorsementStatusLabels } from "../../../../../shared/endorsements/endorsementStatus";
import { EndorsementReasonLabels } from "../../../../../shared/endorsements/endorsementReasons";

const Endorsements: FC = () => {
  const {
    documentData: { _id, endorsements },
    refetch,
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  return (
    <EditableSection
      title="Endossos"
      noTitle
      main
      useV2
      createPayload={{ documentId: _id! }}
      createQuery={GetCreateEndorsementOnDocumentFormResponseDocument}
      addFlag="admin-only"
      addText={"Adicionar Endosso"}
      onSubmit={refetch}
    >
      {!endorsements?.length ? (
        <Typography variant="textMedium" uppercase color={color}>
          Não há endossos
        </Typography>
      ) : (
        <Table
          data={endorsements}
          color={color}
          DetailsComponent={EndorsementComponent}
          withBorder
          columns={[
            { key: "externalId", label: "Número do endosso" },
            {
              key: "status",
              label: "Status",
              options: EndorsementStatusLabels,
            },
            {
              key: "reason",
              label: "Motivo do endosso",
              options: EndorsementReasonLabels,
            },
          ]}
        />
      )}
    </EditableSection>
  );
};

export default Endorsements;

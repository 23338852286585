import { FC } from "react";

const StarFilled: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_417_20)">
        <path
          d="M11.6726 11.22L16 2L20.3274 11.22L30 12.7L23 19.87L24.6549 30L16 25.22L7.34509 30L9 19.87L2 12.69L11.6726 11.22Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_417_20">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarFilled;

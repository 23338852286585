/** @jsxImportSource @emotion/react */

import React, { CSSProperties, FC } from "react";
import Typography from "../../../../components/Typography";
import { useCSSRulesWithTheme } from "../../../../hooks/useCSSRulesWithTheme";
import { theme } from "../../../../theme";

type StepProps = {
  index: number;
  isSelected: boolean;
  label: string;
  isLast: boolean;
  handleClick: () => void;
  isSelectable: boolean;
};

const CSSSteps = ({
  isSelected,
  isSelectable,
}: {
  isSelected: boolean;
  isSelectable: boolean;
}) => ({
  step: {
    color:
      isSelectable && !isSelected
        ? theme.colors.Black[100]
        : isSelected
        ? theme.colors.Green[100]
        : theme.colors.Grey[100],
    whiteSpace: "nowrap" as CSSProperties["whiteSpace"],
    cursor: isSelectable ? "pointer" : "auto",
  },
  content: {
    marginRight: 12,
  },
});

const Step: FC<StepProps> = ({
  isSelectable,
  isSelected,
  label,
  isLast,
  handleClick,
}) => {
  const styles = useCSSRulesWithTheme(CSSSteps, { isSelected, isSelectable });
  return (
    <div css={styles.step} onClick={handleClick}>
      <Typography
        variant="textSmall"
        component="span"
        uppercase
        css={styles.content}
      >
        {label}
      </Typography>
      <Typography variant="textSmall" component="span" css={styles.content}>
        {!isLast && ">"}
      </Typography>
    </div>
  );
};

export default Step;

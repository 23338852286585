import { FC } from "react";

const Sompo: FC = () => {
  return (
    <svg
      width="256"
      height="128"
      viewBox="0 0 256 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.3953 49.1667C37.2326 49.1667 36.0927 49.2641 34.9832 49.4512C32.1985 52.4295 30.4936 56.4322 30.4936 60.8334C30.4936 70.0345 37.9447 77.4934 47.1361 77.4934C51.5328 77.4934 55.5312 75.7867 58.5064 72.9991C58.6933 71.8884 58.7906 70.7473 58.7906 69.5834C58.7906 58.3076 49.6593 49.1667 38.3953 49.1667Z"
        fill="black"
      />
      <path
        d="M47.1361 80.6668C50.708 80.6668 54.059 79.7206 56.9529 78.0649C53.7359 85.1071 46.6364 90.0001 38.3953 90.0001C27.1313 90.0001 18 80.8592 18 69.5834C18 61.3337 22.8878 54.2267 29.9227 51.0064C28.2687 53.9032 27.3236 57.2578 27.3236 60.8334C27.3236 71.7871 36.1939 80.6668 47.1361 80.6668Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.909 52.6863C212.912 52.6863 207.819 59.7674 207.819 68.0759C207.819 76.3845 213.006 83.3712 222.909 83.4656C232.813 83.4656 238 76.7621 238 68.0759C238 59.3897 233.096 52.6863 222.909 52.6863ZM232.813 67.9815C232.813 73.552 229.983 80.161 222.909 80.161C215.741 80.161 212.912 73.4576 213.006 67.9815C213.006 62.5054 215.93 55.8019 222.909 55.8019C230.077 55.8019 232.813 62.411 232.813 67.9815Z"
        fill="black"
      />
      <path
        d="M91.5267 80.4456C88.1313 80.4456 84.9245 79.5959 82.2837 78.3685L82.0007 81.8618C84.6416 82.8059 88.8858 83.4669 91.8096 83.4669C98.6947 83.4669 104.259 80.54 104.259 74.7806C104.259 70.2487 100.392 67.9828 96.0538 66.3777L93.413 65.3391C90.0176 64.0173 87.5654 62.6955 87.5654 60.2407C87.5654 57.2195 90.9608 55.7088 94.6391 55.7088C97.4686 55.7088 100.204 56.2753 102.562 57.1251L102.939 53.9149C100.109 53.1596 97.5629 52.7819 94.5448 52.7819C88.7915 52.7819 82.378 55.1423 82.378 60.8072C82.378 64.7727 85.962 66.9442 89.546 68.3604L92.2812 69.399C95.9595 70.8153 98.9776 72.4203 98.9776 75.4416C98.9776 79.0293 95.205 80.4456 91.5267 80.4456Z"
        fill="black"
      />
      <path
        d="M169.432 53.1593L159.717 78.1793L149.909 53.1593H142.929V82.9001H147.362V56.9359L157.548 82.9001H161.887L171.979 56.8414V82.9001H176.411V53.1593H169.432Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.919 82.9001V53.1593H192.162C198.67 53.1593 204.612 55.0476 204.612 61.751C204.612 68.6434 198.764 71.0982 191.785 71.0982H187.729V82.9945L182.919 82.9001ZM187.729 67.9825H192.445C196.784 67.9825 199.425 65.7165 199.425 61.751C199.425 57.88 196.501 56.1806 192.351 56.1806H187.635L187.729 67.9825Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.745 52.6863C112.748 52.6863 107.655 59.7674 107.655 68.0759C107.655 76.3845 112.842 83.3712 122.745 83.4656C132.649 83.4656 137.836 76.7621 137.836 68.0759C137.836 59.3897 132.932 52.6863 122.745 52.6863ZM132.649 67.9815C132.649 73.552 129.819 80.161 122.745 80.161C115.672 80.161 112.842 73.4576 112.842 67.9815C112.842 62.5054 115.766 55.8019 122.745 55.8019C129.914 55.8019 132.649 62.411 132.649 67.9815Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1363 80.6667C58.0785 80.6667 66.9488 71.787 66.9488 60.8333C66.9488 49.8797 58.0785 41 47.1363 41C36.1941 41 27.3237 49.8797 27.3237 60.8333C27.3237 71.787 36.1941 80.6667 47.1363 80.6667ZM47.1363 77.4933C56.3277 77.4933 63.7788 70.0344 63.7788 60.8333C63.7788 51.6323 56.3277 44.1733 47.1363 44.1733C37.9449 44.1733 30.4937 51.6323 30.4937 60.8333C30.4937 70.0344 37.9449 77.4933 47.1363 77.4933Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default Sompo;

/** @jsxImportSource @emotion/react */

import * as React from "react";
import Tooltip from "../../../../components/Tooltip";
import Typography from "../../../../components/Typography";
import Icon from "../../../../components/Icons";
import { IconSizes } from "../../../../components/Icons/styles/iconSizes";
import CustomLink from "../../../../components/CustomLink";
import { ColorNames, theme } from "../../../../theme";
import {
  GetPartnersDashboardDataQuery,
  UserStatus,
} from "../../../../graphql/generated/types";
import {
  contactStatusFilter,
  getFilterLink,
} from "../../../../filters/contacts";

const styles = {
  iconsList: {
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    flexWrap: "wrap" as "wrap",
  },
  icon: {
    paddingRight: 30,
  },
  counterContainer: {
    position: "relative" as "relative",
    top: 10,
    left: 30,
    zIndex: 10,
  },
  counter: {
    borderRadius: 50,
    borderColor: theme.colors.ErrorRed[100],
    backgroundColor: theme.colors.White[100],
    border: "1px solid",
    padding: "0px 4px",
  },
};

const UserStatusIconsList: React.FC<{
  tagGroup: GetPartnersDashboardDataQuery["getPartnersDashboardData"][number];
}> = ({ tagGroup: { tag, statusGroups } }) => {
  return (
    <div css={styles.iconsList}>
      {contactStatusFilter.options.map((option, i) => {
        const { status } = option.filter;
        const redirectLocation = getFilterLink(tag, status as UserStatus);
        const count =
          statusGroups.find((sg) => sg.status === status)?.count || 0;
        return (
          <CustomLink to={redirectLocation} key={`status-icon-${status}`}>
            <div css={styles.icon} key={`statusIcon-${i}`}>
              <div css={styles.counterContainer}>
                <Typography
                  variant="textSmall"
                  weight="bold"
                  color={count ? ColorNames.RED : ColorNames.GREY}
                  css={styles.counter}
                >
                  {count}
                </Typography>
              </div>
              <Tooltip id={`tooltip-id-${tag}-${status}`} text={option.label}>
                <Icon
                  name={option.iconName!}
                  size={IconSizes.Large}
                  color={ColorNames.BLACK}
                  hoverColor={ColorNames.GREEN}
                  disabled={!count}
                />
              </Tooltip>
            </div>
          </CustomLink>
        );
      })}
    </div>
  );
};

export default UserStatusIconsList;

import { FC } from "react";

const Share: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_177_275)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 10V28H16V30H10C9.46975 29.9994 8.9614 29.7885 8.58646 29.4135C8.21152 29.0386 8.00061 28.5302 8 28V6C8.00053 5.46973 8.21141 4.96133 8.58637 4.58637C8.96133 4.21141 9.46973 4.00053 10 4H22C22.5302 4.00061 23.0386 4.21152 23.4135 4.58646C23.7885 4.9614 23.9994 5.46975 24 6V17H22V10H10ZM22 6H10V8H22V6Z"
          fill="black"
        />
        <path
          d="M27.6795 19.0096C27.7571 19.0266 27.8282 19.0654 27.8845 19.1213C27.9392 19.1785 27.9764 19.2499 27.9919 19.3274C28.0074 19.4048 28.0006 19.4851 27.9721 19.5588L24.6349 28.725C24.6063 28.8042 24.5543 28.8729 24.4858 28.922C24.4172 28.9711 24.3354 28.9983 24.2512 29C24.1676 29.0001 24.086 28.9751 24.0168 28.9283C23.9476 28.8815 23.8941 28.815 23.8632 28.7375L22.4282 25.1585L25.0855 22.5045L24.4973 21.917L21.8525 24.5669L18.2692 23.1378C18.1901 23.1078 18.1219 23.0545 18.0738 22.9849C18.0258 22.9153 18 22.8328 18 22.7482C18 22.6637 18.0258 22.5811 18.0738 22.5116C18.1219 22.442 18.1901 22.3887 18.2692 22.3587L27.4465 19.0255C27.5211 18.9982 27.6019 18.9927 27.6795 19.0096Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_177_275">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Share;

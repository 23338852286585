import { FC } from "react";

const Minimize: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_228)">
        <path
          d="M4 18V20H10.586L2 28.582L3.414 30L12 21.414V28H14V18H4Z"
          fill="black"
        />
        <path
          d="M30 3.416L28.592 2L20 10.586V4H18V14H28V12H21.414L30 3.416Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_228">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Minimize;

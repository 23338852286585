/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import UserSelect from "../../../../../components/Details/components/Main/UserSelect";
import { UserRoles } from "../../../../../graphql/generated/types";

interface ResponsibleModalSelectProps {
  value: string;
  onChange: (id: string) => void;
}

const getStyles = () => ({
  selectItem: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    margin-bottom: 16px;
  `,
  selectWrapper: css`
    flex-grow: 1;
  `,
  labelWrapper: css`
    width: 100px;
    text-align: left;
  `,
});

const ResponsibleModalSelect: React.FC<ResponsibleModalSelectProps> = ({
  value,
  onChange,
}) => {
  const styles = getStyles();
  return (
    <div css={styles.selectItem}>
      <div css={styles.selectWrapper}>
        <UserSelect
          domain={{ role: UserRoles.Admin }}
          value={value}
          placeholder="SELECIONAR RESPONSÁVEL"
          inputName="name"
          onSelect={(option) => onChange(option?.value || "")}
        />
      </div>
    </div>
  );
};

export default ResponsibleModalSelect;

/** @jsxImportSource @emotion/react */

import { CSSProperties, FC, useEffect, useState } from "react";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { useAuth } from "../../hooks/useAuth";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames } from "../../theme";
import FormTitle from "../../modules/Forms/components/FormTitle";
import FormV2 from "../../modules/Forms/form.v2";
import { useCreateFormResponseV2Mutation } from "../../graphql/generated/types";
import { toast } from "react-toastify";
import ScrollContainer from "../ScrollContainer";
import LoadingSpinner from "../Loading/LoadingSpinner";

const getCSSRules = ({ ...props }) => ({
  modal: {
    position: "fixed" as CSSProperties["position"],
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    paddingTop: 64,
    backgroundColor: `${props.colors.White[60]}`,
    display: "flex",
    alignItems: "start",
    justifyContent: "end",
    zIndex: 1000,
  },
  modalContent: {
    border: `2px solid ${props.colors.Green[100]}`,
    borderRadius: 10,
    backgroundColor: `${props.colors.White[100]}`,
    width: "25%",
    height: "calc(100% - 48px)",
    minWidth: 250,
    padding: "12px 12px 12px 20px",
    marginRight: 20,
    overflow: "hidden",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
});

interface UserProfileModalProps {
  show: boolean;
  onClose: () => void;
  onRef: any;
}

const formId = "editUser";

const UserProfileModal: FC<UserProfileModalProps> = ({
  show,
  onClose,
  onRef,
}) => {
  const [resId, setResId] = useState<string>("");
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { user, loadCurrentUser } = useAuth();
  const { errorHandler } = useErrorHandler();

  const [createFormResponseMutation, { loading }] =
    useCreateFormResponseV2Mutation();

  useEffect(() => {
    if (user._id) {
      createFormResponseMutation({
        variables: {
          formId,
          metadata: {
            _id: user._id,
            initialState: user,
          },
        },
      })
        .then(({ data }) => setResId(data!.createFormResponseV2!))
        .catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [user]);

  if (!show) {
    return null;
  }

  const handleSubmit = () => {
    toast.success("Dados editados com sucesso!");
    loadCurrentUser();
  };

  return (
    <div css={styles.modal}>
      <div ref={onRef} css={styles.modalContent}>
        <div css={styles.modalHeader}>
          <FormTitle title="Perfil do usuario" />
          <Icon
            name={IconNames.Close}
            color={ColorNames.GREEN}
            onClick={onClose}
          />
        </div>
        {loading && <LoadingSpinner />}
        {!loading && (
          <ScrollContainer>
            <FormV2
              formId={formId}
              onSubmit={handleSubmit}
              formResponseId={resId}
            />
          </ScrollContainer>
        )}
      </div>
    </div>
  );
};

export default UserProfileModal;

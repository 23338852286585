import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import initSentry from "./integrations/sentry";

const GTM_ID = process.env.REACT_APP_GTM_ID || "GTM-XXXXXXX";

const gtmArgs = {
  gtmId: GTM_ID,
};

TagManager.initialize(gtmArgs);

initSentry();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  claimStatusFilter,
  documentStageFilter,
  documentStatusFilter,
} from "../../../filters/documents";
import {
  ClaimStatus,
  DocumentStages,
  DocumentStatus,
} from "../../../graphql/generated/types";
import { ColorNames } from "../../../theme";
import { TChartCard } from "../hooks/useDashboardCharts";

export const documentsCharts: [
  TChartCard<typeof documentStageFilter, typeof documentStatusFilter>,
  TChartCard<typeof documentStageFilter, typeof documentStatusFilter>,
  TChartCard<typeof documentStageFilter, typeof documentStatusFilter>,
  TChartCard<typeof claimStatusFilter, typeof claimStatusFilter>
] = [
  {
    category: DocumentStages.Simulation,
    color: ColorNames.GREEN,
    groupFilter: [documentStageFilter, DocumentStages.Simulation],
    buttonFilters: [
      documentStatusFilter,
      [DocumentStatus.Pending, DocumentStatus.Done, DocumentStatus.Sent],
    ],
  },
  {
    color: ColorNames.PINK,
    category: DocumentStages.Proposal,
    groupFilter: [documentStageFilter, DocumentStages.Proposal],
    buttonFilters: [
      documentStatusFilter,
      [
        DocumentStatus.Incomplete,
        DocumentStatus.Pending,
        DocumentStatus.Done,
        DocumentStatus.Sent,
      ],
    ],
  },
  {
    color: ColorNames.BLUE,
    category: DocumentStages.Policy,
    groupFilter: [documentStageFilter, DocumentStages.Policy],
    buttonFilters: [
      documentStatusFilter,
      [DocumentStatus.Pending, DocumentStatus.Done, DocumentStatus.Effective],
    ],
  },
  {
    label: "Sinistros",
    color: ColorNames.ORANGE,
    category: "claim",
    groupFilter: [claimStatusFilter, "withClaim"],
    mergeFilters: false,
    buttonFilters: [
      claimStatusFilter,
      [
        ClaimStatus.Pending,
        ClaimStatus.Open,
        ClaimStatus.Approved,
        ClaimStatus.Contested,
      ],
    ],
  },
];

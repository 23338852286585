/** @jsxImportSource @emotion/react */
import React, { FC, useCallback } from "react";
import FloatInput from "./FloatInput";
import { TextInputProps } from "./TextInput";

const IntegerInput: FC<TextInputProps> = ({ value, ...props }) => {
  const handleIntegerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.value = e.target.value.replace(/[.,]/g, "");
      props.onChange?.(e);
    },
    [props.onChange]
  );
  return (
    <FloatInput {...props} value={value || ""} onChange={handleIntegerChange} />
  );
};

export default IntegerInput;

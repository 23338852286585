import { useCallback, useState } from "react";
import { useAuth } from "./useAuth";
import { Services } from "../graphql/generated/types";
import { serviceFetchFile } from "../services/fetch";
import { openFile } from "../helpers/openFile";
import { useErrorHandler } from "./useErrorHandler";

interface DownloadFileProps {
  serviceName: Services;
  objectId: string;
  fieldPath: string;
}

const useDownloadFile = ({
  serviceName,
  objectId,
  fieldPath,
}: DownloadFileProps) => {
  const { errorHandler } = useErrorHandler();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const handleDownloadFile = useCallback(
    async (shouldOpen = true) => {
      setLoading(true);
      try {
        const file = await serviceFetchFile(
          auth,
          `download-file/${serviceName}/${objectId}/${fieldPath}`,
          null,
          "get"
        );
        setLoading(false);
        if (shouldOpen) return openFile(file);
        else return file;
      } catch (e) {
        setLoading(false);
        errorHandler(new Error("Não foi baixar o arquivo."), e);
      }
    },
    [auth, serviceName, objectId, fieldPath]
  );
  return { handleDownloadFile, loading };
};

export default useDownloadFile;

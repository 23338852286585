/** @jsxImportSource @emotion/react */
import { GetPartnersDashboardDataQuery } from "../../../../graphql/generated/types";
import CardContainer from "../../../../components/CardList/components/CardContainer";
import { ColorNames } from "../../../../theme";
import CustomLink from "../../../../components/CustomLink";
import Typography from "../../../../components/Typography";
import UserStatusIconsList from "./UserStatusIconsList";
import { getFilterLink } from "../../../../filters/contacts";
import { FC } from "react";
import { contactTagLabels } from "../../../../shared/contacts/contactTags";

const cardHeaderHeight = 30;

const styles = {
  cardHeader: {
    display: "flex" as "flex",
    justifyContent: "space-between" as "space-between",
    alignItems: "center" as "center",
    height: cardHeaderHeight,
  },
  cardIcons: {
    display: "flex" as "flex",
    alignItems: "center" as "center",
    height: `calc(100% - ${cardHeaderHeight}px)`,
  },
};

const ContactDashboardCard: FC<{
  tagGroup: GetPartnersDashboardDataQuery["getPartnersDashboardData"][number];
}> = ({ tagGroup }) => {
  const totalTagContacts = tagGroup.statusGroups?.reduce(
    (sum, item) => sum + item.count,
    0
  );

  return (
    <CardContainer
      borderColor={ColorNames.GREEN}
      disabled={!tagGroup.statusGroups?.length}
    >
      <CustomLink to={getFilterLink(tagGroup.tag)}>
        <div css={styles.cardHeader}>
          <Typography
            variant="textLarge"
            align="left"
            uppercase
            component="p"
            weight="bold"
            css={{ cursor: "pointer" }}
          >
            {contactTagLabels[tagGroup.tag]}{" "}
            {totalTagContacts >= 0 && `[${totalTagContacts}]`}
          </Typography>
        </div>
        <div css={styles.cardIcons}>
          <UserStatusIconsList tagGroup={tagGroup} />
        </div>
      </CustomLink>
    </CardContainer>
  );
};

export default ContactDashboardCard;

/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { FC, useCallback, useMemo } from "react";
import Column from "../../../components/Grid/Column";
import { theme } from "../../../theme";
import { FormField, FormInputTypes } from "../models/formInterfaces";
import FormInput from "./FormInput";
import SectionRows from "./SectionRows";
import { useForm } from "../hooks/useForm";
import { composeFieldPath } from "./inputs/utils";

const styles: CSSObject = {
  alignBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  verticalSpace: {
    height: 34,
  },
  colMobileMb: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginBottom: theme.gutters.xs,
    },
  },
  objectListCol: {
    // padding: "0px !important",
  },
};

interface FormSectionRowsProps {
  fields: FormField[];
  groupPath: string;
  subFields?: boolean;
  large?: boolean;
}

const FormSectionRows: FC<FormSectionRowsProps> = ({
  fields,
  groupPath,
  subFields,
  large = false,
}) => {
  const fieldToColumn = useCallback(
    (field: FormField) => {
      const size = large
        ? field.variant === "xs"
          ? 6
          : 12
        : field.variant === "xs"
        ? subFields
          ? 6
          : 4
        : field.inputType === "select"
        ? 10
        : (field.inputType === "object" && groupPath.split(".").length > 1) ||
          ["boolean", "objectList", "geoLocation", "polygon", "radio"].includes(
            field.inputType
          )
        ? 12
        : subFields
        ? 12
        : 8;

      const shouldAlignBottom = [
        FormInputTypes.TEXT_FIELD,
        FormInputTypes.NUMBER,
      ].includes(field.inputType);

      return (
        <Column
          xs={12}
          sm={size}
          css={css(
            shouldAlignBottom ? styles.alignBottom : {},
            field.inputType === "objectList" ? styles.objectListCol : {},
            field.variant === "xs" ? styles.colMobileMb : {}
          )}
        >
          <FormInput fieldProps={field} groupPath={groupPath} />
        </Column>
      );
    },
    [groupPath]
  );
  const { visibility } = useForm();
  const visibleFields = useMemo(() => {
    return fields.filter((field) => {
      const fieldPath = composeFieldPath(groupPath || field.group, field.name);
      return !(fieldPath in visibility) || visibility[fieldPath];
    });
  }, [fields, visibility]);
  return (
    <div id={groupPath}>
      <SectionRows
        fields={visibleFields}
        fieldToColumn={fieldToColumn}
        subSectionDivider={<Column xs={12} css={styles.verticalSpace} />}
      />
    </div>
  );
};

export default FormSectionRows;

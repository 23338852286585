/** @jsxImportSource @emotion/react */

import { useContext, useState } from "react";

import AuthContext from "../../contexts/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HomeLocation, RegisterLocation } from "../../Locations";
import AuthLayout from "../../components/AuthLayout";
import Button from "../../components/Button";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import TextInput from "../../components/Input/TextInput";
import Typography from "../../components/Typography";
import Column from "../../components/Grid/Column";
import Row from "../../components/Grid/Row";
import { soyBeansUrl } from "../../s3/bgImages";

enum LoginAlertMessages {
  INVALID_EMAIL = "Usuário não encontrado!",
  CODE_EMAIL_SENT = "Enviamos um código de acesso no seu e-mail. Insira-o para fazer login.",
  INVALID_CODE = "Código inválido",
}

enum AlertTypes {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

const getCSSRules: CSSRulesResolver = ({ ...props }) => ({
  input: {
    borderRadius: 5,
    height: 35,
    width: "100%",
    padding: 5,
    paddingLeft: 15,
    boxShadow: props.boxShadow.default,
    border: "1px solid",
    borderColor: `${props.colors.Black[100]}`,
  },
  bgImage: {
    backgroundImage: `url(${soyBeansUrl})`,
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
    [`@media (max-width: ${props.breakpoints.sm}px)`]: {
      minHeight: 250,
    },
  },
  authContainer: {
    padding: "30px 50px",
    [`@media (max-width: ${props.breakpoints.sm}px)`]: {
      padding: "30px 15px",
    },
    maxWidth: "100%",
    margin: "0px !important",
  },
});

const Login = () => {
  const location = useLocation();
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [step, setStep] = useState(0);
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAlertType] = useState<any>(undefined);

  const { requestCode, signIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleFirstStepSubmit = async () => {
    try {
      await requestCode({ email });
      setStep(step + 1);
      setAlertMessage(LoginAlertMessages.CODE_EMAIL_SENT);
      setAlertType(AlertTypes.INFO);
      setShowAlert(true);
    } catch (e) {
      setAlertMessage(LoginAlertMessages.INVALID_EMAIL);
      setAlertType(AlertTypes.ERROR);
      setShowAlert(true);
    }
  };

  const handleSecondStepSubmit = async () => {
    try {
      code && (await signIn({ email, code }));

      // check if redirect path and navigate
      const urlParams = new URLSearchParams(location.search);
      const redirectPath = urlParams.get("redirect");
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      } else {
        navigate(HomeLocation);
      }
    } catch (e) {
      setAlertMessage(LoginAlertMessages.INVALID_CODE);
      setAlertType(AlertTypes.ERROR);
      setShowAlert(true);
    }
  };

  const handleSubmit = async () => {
    if (step === 0) {
      await handleFirstStepSubmit();
    }
    if (step === 1) {
      await handleSecondStepSubmit();
    }
  };

  return (
    <AuthLayout rightSideContent={<div css={styles.bgImage} />}>
      <div css={styles.authContainer}>
        <Row>
          <Column>
            <TextInput
              value={email}
              name="nome"
              placeholder="email..."
              onChange={handleEmailChange}
            />
          </Column>
        </Row>
        {step === 1 && (
          <Row>
            <Column>
              <TextInput
                value={code}
                name="codigo"
                placeholder="código"
                onChange={handleCodeChange}
              />
            </Column>
          </Row>
        )}
        <Row>
          <Column>
            <Button
              text="Entrar"
              style="outlinePrimary"
              onClick={handleSubmit}
            />
            {showAlert && (
              <Typography variant="textMedium" margin="sm" component="div">
                {alertMessage}
              </Typography>
            )}
            <Typography variant="textMedium" margin="sm" component="div">
              Não tem uma conta? <Link to={RegisterLocation}>Cadastre-se</Link>
            </Typography>
          </Column>
        </Row>
      </div>
    </AuthLayout>
  );
};

export default Login;

import React, { FC, useEffect } from "react";
import TextAreaInput from "../../../../components/Input/TextAreaInput";
import { useForm } from "../../hooks/useForm";
import { TextFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import useDebounce from "../../../../hooks/useDebounce";
import FormLabel from "../FormLabel";

const FormInputTextArea: FC<TextFieldType & { groupPath: string }> = ({
  groupPath,
  name,
  label,
}) => {
  const [value, error] = useFieldValue(name, groupPath);
  const { handleChange, startEditing, finishEditing } = useForm();

  const [localValue, setLocalValue] = React.useState<string>(value);
  const { debouncedValue } = useDebounce(localValue, 1000);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(e.target.value);
  };

  useEffect(() => {
    if (debouncedValue !== value) {
      handleChange(
        {
          target: { name, value: debouncedValue },
        } as React.ChangeEvent<HTMLInputElement>,
        groupPath
      );
    }
  }, [debouncedValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <TextAreaInput
        name={name}
        value={localValue}
        onChange={handleInputChange}
        onFocus={() => startEditing(groupPath, name)}
        onBlur={() => finishEditing(groupPath, name)}
        errorMessage={error}
        placeholder={label}
      />
    </>
  );
};

export default FormInputTextArea;

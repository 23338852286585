import {
  ConsultantsLocation,
  ContactDetailsChatLocation,
  ContactDetailsLocation,
  ContactDetailsNotesLocation,
  ContactsLocation,
  CultureDetailLocation,
  CultureListLocation,
  DocumentDetailsChatTabLocation,
  DocumentDetailsLocation,
  DocumentDetailsNotesTabLocation,
  DocumentsDashboardLocation,
  DocumentsLocation,
  HomeLocation,
  PartnersDashboardLocation,
  SimulationLocation,
} from "../../../Locations";
import { BreadcrumbItem } from "../../Breadcrumbs/components/Breadcrumb";
import { locationLabels } from "../../../utils/pageLabels";

const toLabelAndValueJson = (value: string) => ({
  value,
  label: locationLabels[value] || "Desconhecido",
});

const commonBreadCrumbs = [
  HomeLocation,
  DocumentsLocation,
  ConsultantsLocation,
  DocumentDetailsLocation,
  SimulationLocation,
  ContactsLocation,
  ContactDetailsLocation,
  ContactDetailsChatLocation,
  CultureListLocation,
  CultureDetailLocation,
];

export const mainFrameBreadCrumbs: BreadcrumbItem[] = [
  ...commonBreadCrumbs.map((bc) => toLabelAndValueJson(bc)),
  {
    label: "Dashboards",
    value: "",
    selectOptions: [
      toLabelAndValueJson(DocumentsDashboardLocation),
      { ...toLabelAndValueJson(PartnersDashboardLocation), flag: "admin-only" },
    ],
  },
  {
    label: "Tabs do Documento",
    value: "",
    selectOptions: [
      toLabelAndValueJson(DocumentDetailsNotesTabLocation),
      toLabelAndValueJson(DocumentDetailsChatTabLocation),
    ],
  },
  {
    label: "Tabs do Contato",
    value: "",
    selectOptions: [
      toLabelAndValueJson(ContactDetailsNotesLocation),
      toLabelAndValueJson(ContactDetailsChatLocation),
    ],
  },
  {
    label: "Tabs da Cultura",
    value: "",
    selectOptions: [toLabelAndValueJson(CultureDetailLocation)],
  },
];

import { FC } from "react";

const Pitch: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_409_27)">
        <path d="M15 10H17V18H15V10Z" fill="black" />
        <path d="M20 14H22V18H20V14Z" fill="black" />
        <path d="M12 12H10V18H12V12Z" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 4H25C25.5304 4 26.0391 4.21071 26.4142 4.58579C26.7893 4.96086 27 5.46957 27 6V20C27 20.5304 26.7893 21.0391 26.4142 21.4142C26.0391 21.7893 25.5304 22 25 22H17V28H21V30H11V28H15V22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6C5 5.46957 5.21071 4.96086 5.58579 4.58579C5.96086 4.21071 6.46957 4 7 4H15V2H17V4ZM7 20H25V6H7V20Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_409_27">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pitch;

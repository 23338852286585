import { useCallback } from "react";
import { useUIStore } from "./useUIStore";
import { ConfirmedActions } from "../components/Modal/ConfirmedAction";

const useConfirmedAction = (
  confirmationMessage: string = "Tem certeza?",
  actions: ConfirmedActions
) => {
  const { openConfirmationModal } = useUIStore();
  const action = useCallback(async () => {
    openConfirmationModal(confirmationMessage, actions);
  }, [confirmationMessage, openConfirmationModal]);
  return action;
};

export default useConfirmedAction;

/** @jsxImportSource @emotion/react */

import { FC, useMemo, useState } from "react";
import { TModelFilter } from "../../hooks/useModelFilter";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames, theme } from "../../theme";
import ScrollContainer from "../ScrollContainer";
import { headerHeight } from "../Layout/components/Header";
import { totalBreadcrumbsHeight } from "../Breadcrumbs";
import useOutsideClick from "../../hooks/useOutsideClick";
import Filters from "./Filters";
import BasicSearch from "./BasicSearch";

const styles = {
  filters: {
    position: "absolute" as const,
    top: totalBreadcrumbsHeight,
    right: 0,
    backgroundColor: theme.colors.White[100],
    zIndex: 100,
    width: "100%",
    height: `calc(100vh - ${headerHeight + totalBreadcrumbsHeight + 40}px)`,
    padding: 24,
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box" as const,
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.colors.Black[100],
  },
  filterCount: {
    position: "absolute" as const,
    top: 0,
    right: 0,
    backgroundColor: theme.colors.Green[100],
    boxShadow: theme.boxShadow.default,
    color: theme.colors.White[100],
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
};

interface SearchBarProps {
  filter: TModelFilter;
}

const SearchBar: FC<SearchBarProps> = ({ filter }) => {
  const [showFilters, setShowFilters] = useState(false);

  const ref = useOutsideClick(() => {
    setShowFilters(false);
  });
  const filterCount = useMemo(() => {
    return filter.filters.filter((filter) => filter.value.length > 0).length;
  }, [filter.filters]);

  return (
    <div ref={ref} css={{ height: "100%", position: "relative" }}>
      {filterCount > 0 && <div css={styles.filterCount}>{filterCount}</div>}
      <BasicSearch
        searchTerm={filter.searchTerm}
        setSearchTerm={filter.setSearchTerm}
      >
        <Icon
          name={IconNames.Filter}
          color={ColorNames.BLACK}
          onClick={() => setShowFilters(!showFilters)}
        />
      </BasicSearch>
      {showFilters && (
        <div css={styles.filters}>
          <ScrollContainer height="100%">
            <Filters filter={filter} />
          </ScrollContainer>
        </div>
      )}
    </div>
  );
};

export default SearchBar;

/** @jsxImportSource @emotion/react */

import { theme } from "../../../theme";
import SkeletonTypography from "../../Skeletons/components/SkeletonTypography";

const styles = {
  skeleton: {
    backgroundColor: theme.colors.White[100],
    boxSizing: "border-box" as "border-box",
    padding: 20,
    height: "100%",
    width: "100%",
    minWidth: 250,
    border: "2px solid",
    borderColor: theme.colors.LightGrey[100],
    borderRadius: 10,
    boxShadow: theme.boxShadow.default,
    textAlign: "initial" as "initial",
  },
};

const SkeletonCard = () => {
  return (
    <div css={styles.skeleton}>
      <div css={{ height: "100%", width: "100%" }}>
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
      </div>
    </div>
  );
};

export default SkeletonCard;

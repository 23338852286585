/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ClearIndicatorProps } from "react-select";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";
import { IconSizes } from "../../../Icons/styles/iconSizes";
import { SelectInputOptionInterface } from "../SelectInput";
import { ColorNames } from "../../../../theme";

const ClearIndicator: FC<
  ClearIndicatorProps<SelectInputOptionInterface, true>
> = (props) => {
  const styles = {
    width: 24,
    padding: 6,
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    height: "100%",
    boxSizing: "border-box" as "border-box",
  };

  return (
    <div css={styles}>
      <Icon
        name={IconNames.Close}
        size={IconSizes.Small}
        color={ColorNames.GREY}
        onClick={props.clearValue}
      />
    </div>
  );
};

export default ClearIndicator;

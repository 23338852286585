import { FC } from "react";
import Typography from "../../../components/Typography";
import { ColorNames } from "../../../theme";

interface FormTitleProps {
  title: string;
}
const FormTitle: FC<FormTitleProps> = ({ title }) => {
  return (
    <Typography
      variant="textExtraLarge"
      component="div"
      weight="bold"
      align="left"
      uppercase
      color={ColorNames.GREEN}
    >
      {title}
    </Typography>
  );
};

export default FormTitle;

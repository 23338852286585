import { TModelOptionLabels } from '../types';
import { EndorsementReason } from '../../graphql/generated/types';

export const EndorsementReasonLabels: TModelOptionLabels<EndorsementReason> = {
  [EndorsementReason.ChangeOfBankingAddress]: 'Alteração de endereço bancário',
  [EndorsementReason.ChangeOfBeneficiary]: 'Alteração de beneficiário',
  [EndorsementReason.ChangeOfCarData]: 'Adição de número do CAR',
  [EndorsementReason.ChangeOfCulture]: 'Alteração de cultura',
  [EndorsementReason.ChangeOfName]: 'Alteração de nome',
  [EndorsementReason.ChangeOfPhone]: 'Alteração de telefone',
  [EndorsementReason.ChangeOfSoilType]: 'Alteração de tipo de solo',
  [EndorsementReason.PolicyCancellation]: 'Cancelamento de apólice',
  [EndorsementReason.Other]: 'Outro',
};

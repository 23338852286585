/** @jsxImportSource @emotion/react */

import { GetDirectDocumentDetailsDocument } from "../../../graphql/generated/types";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import DocumentDetailsProvider from "../../../contexts/documents";
import Layout from "../../../components/Layout";
import Quotations from "./sections/Quotations";
import { useAuth } from "../../../hooks/useAuth";
import SkeletonDetails from "../../../components/Details/components/SkeletonDetails";
import Footer from "../../../components/Layout/components/Footer";
import ApprovedQuotation from "./sections/ApprovedQuotation";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";

const getCSSStyles: CSSRulesResolver<{ isMobile: boolean }> = ({
  isMobile,
}) => ({
  main: {
    padding: isMobile ? 10 : 40,
  },
});

const DirectDocumentDetails = () => {
  const { docId: paramsId } = useParams();
  const { loaded: authIsLoaded } = useAuth();
  const { data, loading, refetch } = useQuery(
    GetDirectDocumentDetailsDocument,
    {
      variables: {
        docId: paramsId,
      },
      skip: !authIsLoaded,
    }
  );
  const isMobile = useIsMobile();
  const styles = useCSSRulesWithTheme(getCSSStyles, { isMobile });

  if (!authIsLoaded || loading) return <SkeletonDetails />;

  return (
    <DocumentDetailsProvider documentData={data?.document} refetch={refetch}>
      <Layout useFullHeight>
        <div css={styles.main}>
          <Quotations />
          <ApprovedQuotation />
        </div>
      </Layout>
      <Footer />
    </DocumentDetailsProvider>
  );
};

export default DirectDocumentDetails;

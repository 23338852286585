const Task = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1724_3769)">
      <path
        d="M7.93455 12.4214L10.5455 14.9857L16.0655 9.55711L17.0909 10.5714L10.5455 17L6.90909 13.4285L7.93455 12.4214Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3636 4.14286H18.5455C18.9312 4.14286 19.3012 4.29337 19.574 4.56128C19.8468 4.82919 20 5.19255 20 5.57143V20.5714C20 20.9503 19.8468 21.3137 19.574 21.5816C19.3012 21.8495 18.9312 22 18.5455 22H5.45455C5.06878 22 4.69881 21.8495 4.42603 21.5816C4.15325 21.3137 4 20.9503 4 20.5714V5.57143C4 5.19255 4.15325 4.82919 4.42603 4.56128C4.69881 4.29337 5.06878 4.14286 5.45455 4.14286H7.63636V3.42857C7.63636 3.04969 7.78961 2.68633 8.06239 2.41842C8.33517 2.15051 8.70514 2 9.09091 2H14.9091C15.2949 2 15.6648 2.15051 15.9376 2.41842C16.2104 2.68633 16.3636 3.04969 16.3636 3.42857V4.14286ZM14.9091 3.42857H9.09091V6.28571H14.9091V3.42857ZM5.45455 20.5714H18.5455V5.57143H16.3636V7.71429H7.63636V5.57143H5.45455V20.5714Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1724_3769">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Task;

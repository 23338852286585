import { FC } from "react";
import { useCulturesQuery } from "../../../graphql/generated/types";
import Loading from "../../../components/Loading";
import CardList from "../../../components/CardList";
import CultureCard from "./Card";
import {
  CreateCultureLocation,
  toCultureDetailsLocation,
} from "../../../Locations";
import MainFrame from "../../../components/MainFrame";
import { Outlet } from "react-router-dom";
import Layout from "../../../components/Layout";

const CulturesList: FC = () => {
  const { data, loading } = useCulturesQuery();

  if (loading) {
    return <Loading size="extraLarge" />;
  }

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        drawerParamKey="cultureId"
        createLink={CreateCultureLocation}
      >
        <CardList
          data={data?.cultures || []}
          redirectLocation={toCultureDetailsLocation}
          CardComponent={CultureCard}
          loading={loading}
        />
      </MainFrame>
    </Layout>
  );
};

export default CulturesList;

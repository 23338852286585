import { FC } from "react";
import { CultureProvider } from "../context/culture";
import { useCultureQuery } from "../../../graphql/generated/types";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import CultureFlow from "./CultureFlows";

const Culture: FC = () => {
  const { cultureId } = useParams();
  const { data, loading, refetch } = useCultureQuery({
    variables: {
      cultureId: cultureId!,
    },
  });
  if (loading || !data) return <Loading size="extraLarge" />;
  return (
    <CultureProvider culture={data!.culture!} refetch={refetch}>
      <CultureFlow />
    </CultureProvider>
  );
};

export default Culture;

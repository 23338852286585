import { FC, useMemo } from "react";
import {
  GetCreateSignedProposalOnFormResponseDocument,
  GetEditSignedProposalOnFormResponseDocument,
  Services,
} from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import EditableSection from "../../../../../components/EditableSection";
import Typography from "../../../../../components/Typography";
import DetailsSectionColumn from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import useDetailsColor from "../../../../../hooks/useDetailsColor";

const SignedProposal: FC = () => {
  const {
    documentData: { proposalResult },
    refetch,
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  const signedProposalInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Proposta Assinada",
        variant: DetailsRowValueVariants.File,
        value: {
          file: {
            fileName: proposalResult?.signedProposalFile?.fileName,
          },
          objectId: proposalResult?._id!,
          fieldPath: "signedProposalFile",
          serviceName: Services.Results,
        },
      },
    ];
  }, [proposalResult]);

  return (
    <EditableSection
      title="Proposta Assinada"
      noTitle
      main
      createPayload={{ proposalResultId: proposalResult?._id! }}
      createQuery={GetCreateSignedProposalOnFormResponseDocument}
      editQuery={GetEditSignedProposalOnFormResponseDocument}
      addFlag="admin-only"
      editFlag="admin-only"
      objectId={
        proposalResult?.signedProposalFile ? proposalResult._id! : undefined
      }
      footerButtons={[]}
      addText={"Adicionar Proposta Assinada"}
      onSubmit={() => refetch()}
    >
      {!proposalResult?.signedProposalFile?.fileKey ? (
        <Typography variant="textMedium" uppercase color={color}>
          Proposta não assinada
        </Typography>
      ) : (
        <DetailsSectionColumn rows={signedProposalInfo} />
      )}
    </EditableSection>
  );
};

export default SignedProposal;

import { FC } from "react";

const Sancor: FC = () => {
  return (
    <svg
      width="256"
      height="128"
      viewBox="0 0 256 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.565 55.688C186.448 59.7191 190.225 61.2205 194.682 60.7794C198.06 60.5134 200.504 58.8771 201.964 56.009C203.425 53.1374 203.44 50.0503 202.716 46.9512C202.038 44.0548 200.335 41.9603 197.576 40.801C195.672 40.0011 193.669 39.8392 191.593 40.1435C188.111 40.654 185.607 42.4079 184.352 45.6575C183.606 47.5877 183.198 49.6339 183.632 51.7316C183.708 52.1019 183.771 52.4795 183.834 52.8576C183.996 53.8367 184.159 54.8191 184.565 55.688ZM195.154 56.3421C194.914 56.4898 194.674 56.6374 194.457 56.7989C192.465 57.2781 190.708 56.5519 189.825 54.7101C188.482 51.9081 188.551 49.0241 189.805 46.198C190.451 44.7429 191.676 43.9336 193.272 43.9251C194.857 43.9167 196.19 44.6593 196.825 46.0976C198.181 49.1666 198.305 52.2921 196.482 55.2378C196.196 55.7005 195.675 56.0213 195.154 56.3421Z"
        fill="black"
      />
      <path
        d="M58.9946 60.0389C58.9312 59.9954 58.8667 59.9538 58.8032 59.9129C58.5591 59.7554 58.3307 59.608 58.2388 59.4013C56.943 56.4862 55.6605 53.5654 54.3779 50.6445C53.7519 49.219 53.1256 47.7927 52.4981 46.3678C52.4058 46.1581 52.2893 45.9587 52.1308 45.6873C52.0423 45.5358 51.9407 45.3618 51.8228 45.1511C50.1711 48.7147 48.6574 52.2362 47.1458 55.7529C46.2136 57.9217 45.2818 60.0892 44.3193 62.2631H75.3057C76.5219 73.8688 68.0782 85.1275 55.9794 87.1939C41.334 89.6953 30.1596 79.2239 28.4314 68.1952H32.0392C33.003 68.1952 33.9669 68.196 34.9307 68.1968C37.9462 68.1992 40.9617 68.2016 43.977 68.1806C44.5432 68.1766 44.8176 68.3484 45.0656 68.8485C45.92 70.5711 46.7891 72.2866 47.6583 74.0021C48.3973 75.4609 49.1364 76.9196 49.8664 78.3828C50.2121 79.0757 50.5265 79.7839 50.8409 80.4921C51.0255 80.908 51.2102 81.3241 51.4012 81.7369C51.4601 81.8642 51.5408 81.9818 51.6464 82.1358C51.7037 82.2194 51.7687 82.3141 51.8413 82.4265L59.2657 66.6662H28.1188C27.432 58.8749 29.7023 52.0939 35.4619 46.9024C43.4847 39.6709 52.7933 38.3629 62.5453 43.0824C69.5185 46.457 73.6305 52.2878 75.1713 60.0398C69.6941 60.0398 64.3823 60.0398 58.9946 60.0389Z"
        fill="black"
      />
      <path
        d="M149.284 50.801L149.284 50.7996C148.357 49.1571 147.43 47.5146 146.468 45.8194C146.277 45.4847 146.079 45.1592 145.883 44.8372C145.434 44.0997 144.996 43.3805 144.686 42.6109C144.016 40.9492 143.03 40.1709 141.125 40.4644C140.226 40.6028 139.3 40.5683 138.37 40.5338H138.369C137.953 40.5183 137.535 40.5027 137.119 40.5027V60.3145H141.932V47.3461C142.757 48.4719 143.433 49.6192 144.08 50.7819C144.78 52.041 145.478 53.3017 146.176 54.5624L146.184 54.577L146.187 54.5832C147.153 56.3275 148.118 58.0717 149.091 59.8117C149.216 60.0353 149.47 60.341 149.671 60.3468C150.864 60.3807 152.057 60.3771 153.246 60.3735H153.248C153.614 60.3724 153.981 60.3713 154.346 60.3713V40.5662H149.52V50.7735L149.289 50.8094L149.284 50.801Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.436 87.5097H174.525V80.0594C174.786 80.1325 175.051 80.179 175.301 80.2227C175.801 80.3103 176.239 80.387 176.463 80.6455C177.834 82.2344 179.144 83.8921 180.31 85.6333C181.242 87.0254 182.239 87.8662 184.07 87.5997C184.957 87.4705 185.869 87.5007 186.836 87.5328H186.838C187.286 87.5477 187.746 87.563 188.221 87.563L187.8 87.0299L187.798 87.0275L187.793 87.0217C187.544 86.7059 187.331 86.4361 187.116 86.1682C186.773 85.7416 186.429 85.3152 186.086 84.8887L186.058 84.8538C184.616 83.0628 183.174 81.2719 181.75 79.4663C181.664 79.3562 181.758 78.9302 181.888 78.8553C184.323 77.4533 185.379 75.3648 185.18 72.6077C185.049 70.7907 184.191 69.4614 182.608 68.5268C181.18 67.6839 179.591 67.5919 178.024 67.5395C176.162 67.4771 174.297 67.4913 172.432 67.5055C171.691 67.5111 170.95 67.5167 170.209 67.5176C170.04 67.5178 169.871 67.5294 169.695 67.5416L169.627 67.5463L169.557 67.5509C169.518 67.5535 169.477 67.556 169.436 67.5582V87.5097ZM179.689 73.3175C179.721 74.3452 179.754 75.361 178.71 76.0268C177.311 76.309 176.008 76.5001 174.6 76.7066V71.3544C174.982 71.3792 175.365 71.3967 175.747 71.4142C176.659 71.4559 177.57 71.4976 178.465 71.6374C179.195 71.7513 179.66 72.3318 179.684 73.1578L179.689 73.3175Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M221.285 51.9794C225.725 49.7677 225.808 44.4852 223.619 42.2449C222.245 41.0434 220.69 40.4972 218.923 40.5012C217.077 40.5053 215.23 40.5044 213.383 40.5035H213.379C212.593 40.5031 211.807 40.5027 211.02 40.5027H209.295V60.3276H214.411V52.9998C214.682 53.0933 214.958 53.1592 215.219 53.2215C215.742 53.3466 216.205 53.457 216.447 53.7472C217.464 54.9664 218.416 56.238 219.369 57.51L219.37 57.5118L219.371 57.5128L219.373 57.5151L219.374 57.5166L219.374 57.5175L219.375 57.5185L219.376 57.5197L219.377 57.5212C219.899 58.2183 220.422 58.9154 220.954 59.604C221.199 59.9195 221.585 60.3298 221.919 60.3417C223.374 60.3935 224.832 60.3872 226.372 60.3806C226.903 60.3783 227.445 60.3759 228 60.3759L221.285 51.9794ZM219.459 47.3123L219.459 47.3149C219.444 47.4931 219.428 47.6712 219.416 47.8494C218.718 48.9493 217.819 49.3835 216.63 49.3533C216.154 49.3412 215.675 49.4035 215.172 49.4691C214.938 49.4995 214.699 49.5306 214.452 49.5553V44.1698C214.824 44.1958 215.195 44.2179 215.563 44.2398L215.564 44.2399C216.444 44.2923 217.314 44.3441 218.178 44.4472C218.851 44.5275 219.53 45.4186 219.529 46.098C219.529 46.5028 219.494 46.9075 219.459 47.3123Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.975 68.8283C206.924 70.1908 208.017 72.0545 208.521 74.241C209.076 76.6479 209.054 79.0791 208.363 81.4656C207.311 85.0972 204.222 87.7622 200.201 87.9198C198.12 88.0014 196.128 87.8883 194.214 86.9426C191.47 85.5872 190.038 83.2925 189.51 80.4588C189.083 78.1662 189.146 75.8428 189.793 73.5416C190.631 70.5656 193.557 67.7283 196.95 67.3184C199.648 66.9924 202.194 67.201 204.588 68.538C204.684 68.5911 204.769 68.6609 204.877 68.7491L204.878 68.75L204.885 68.7558L204.888 68.7581L204.924 68.7873L204.975 68.8283ZM200.161 71.2484C198.559 70.719 196.725 71.26 195.862 72.757C194.153 75.7209 194.113 78.838 195.564 81.8982C196.581 84.0447 198.708 84.5213 200.797 83.6413C202.218 83.0427 202.9 81.7287 203.164 80.3526C203.427 78.9812 203.445 77.5353 203.361 76.1344C203.234 74.0111 202.516 72.1521 200.161 71.2484Z"
        fill="black"
      />
      <path
        d="M143.372 76.52V86.4759C141.184 87.2037 138.969 87.8404 136.59 87.934C134.44 88.0186 132.375 87.8312 130.437 86.8912C127.737 85.581 126.124 83.3869 125.59 80.4982C125.218 78.4792 125.182 76.4193 125.697 74.3957C126.614 70.7917 128.91 68.4644 132.534 67.5774C136.021 66.7239 139.437 67.0835 142.692 68.6762C143.101 68.8761 143.28 69.0823 143.268 69.5539C143.243 70.5317 143.247 71.5102 143.252 72.6051V72.6087C143.254 73.0626 143.256 73.5367 143.256 74.0389C143.017 73.8748 142.799 73.7254 142.596 73.5856L142.594 73.5843C142.191 73.308 141.843 73.0692 141.497 72.8275C139.061 71.1263 136.483 70.3771 133.636 71.7718C132.169 72.4904 131.323 73.7789 130.945 75.2818C130.479 77.1315 130.538 79.0253 131.269 80.816C132.195 83.082 134.145 83.9112 136.432 84.1546C136.852 84.1994 137.279 84.188 137.726 84.1761C137.938 84.1704 138.155 84.1646 138.378 84.1646V80.4423H134.555V76.52H143.372Z"
        fill="black"
      />
      <path
        d="M156.099 83.998C156.499 84.0062 156.874 84.014 157.234 83.9461C158.515 83.705 159.104 82.7502 159.374 81.6083C159.533 80.9337 159.566 80.2184 159.569 79.521C159.582 76.8147 159.581 74.1083 159.579 71.4019V71.3937C159.578 70.4525 159.577 69.5112 159.577 68.57V67.5813H164.69C164.693 67.638 164.697 67.6934 164.701 67.7479L164.706 67.8097C164.718 67.9644 164.73 68.1124 164.73 68.2601C164.73 69.435 164.735 70.6099 164.74 71.7849L164.74 71.7983V71.7997C164.752 74.8364 164.764 77.8731 164.707 80.9086C164.671 82.8405 164.118 84.6597 162.583 86.0366C161.368 87.1266 159.895 87.7003 158.315 87.8665C156.298 88.0786 154.268 88.1125 152.303 87.4008C149.729 86.4689 148.083 84.7239 147.925 81.9956C147.783 79.5539 147.767 77.1049 147.751 74.6562L147.751 74.6494C147.745 73.7415 147.738 72.8336 147.726 71.9262C147.712 70.9422 147.716 69.9579 147.719 68.9575C147.721 68.4981 147.723 68.0352 147.723 67.5674H152.877V68.4694C152.877 69.4186 152.876 70.3678 152.875 71.3171C152.874 73.9899 152.872 76.6627 152.885 79.3354C152.889 80.0898 152.914 80.8629 153.08 81.5934C153.365 82.8413 154.038 83.8005 155.533 83.9936C155.727 83.9903 155.916 83.9942 156.099 83.998Z"
        fill="black"
      />
      <path
        d="M92.5806 83.3175C94.3492 84.0191 96.0871 84.3863 97.9353 83.9991C98.7595 83.8265 99.7306 83.033 99.7869 82.3895C99.8662 81.4825 99.3755 80.7837 98.2482 80.4536C97.5146 80.2387 96.7712 80.0546 96.028 79.8705L96.0269 79.8702C95.0242 79.6218 94.0213 79.3734 93.0441 79.05C91.1243 78.4148 89.7587 77.1864 89.2714 75.111C88.6344 72.3981 89.7367 69.7847 92.192 68.4771C94.3819 67.3108 96.7066 66.8952 99.1626 67.3393C100.159 67.5196 101.152 67.7226 102.145 67.9257L102.146 67.9259C102.583 68.0155 103.021 68.105 103.459 68.1926C104.213 68.3433 104.506 68.757 104.446 69.55C104.397 70.204 104.407 70.8621 104.416 71.5205C104.422 71.9108 104.427 72.3012 104.421 72.691C104.411 73.2912 104.141 73.4766 103.616 73.0859C101.977 71.8675 100.132 71.1071 98.0977 70.9629C97.0108 70.8859 95.8805 71.0818 94.9987 71.8412C94.0125 72.6906 94.3415 74.0516 95.6708 74.4542C96.3571 74.6621 97.0575 74.8246 97.7578 74.9871C98.454 75.1486 99.1511 75.3104 99.8335 75.5165C101.734 76.0904 103.637 76.7197 104.587 78.6965C105.887 81.4022 104.938 85.0578 102.056 86.6178C99.49 88.0068 96.7603 88.1651 93.97 87.7311C92.9994 87.5802 92.0456 87.3244 91.0917 87.0687L91.0905 87.0684C90.6323 86.9455 90.1737 86.8225 89.7135 86.7113C89.1663 86.579 88.9325 86.2868 88.9509 85.6932C88.9811 84.7172 88.9755 83.7402 88.9695 82.7127V82.7077C88.9671 82.2806 88.9645 81.8447 88.9645 81.3967C89.0776 81.4332 89.1825 81.4619 89.2801 81.4886L89.2811 81.4888C89.4735 81.5414 89.6377 81.5862 89.7798 81.6682C90.2341 81.9303 90.6849 82.1985 91.1424 82.4706L91.1435 82.4713L91.1449 82.4721L91.1464 82.473L91.1475 82.4736C91.6146 82.7515 92.0886 83.0335 92.5806 83.3175Z"
        fill="black"
      />
      <path
        d="M104.334 41.537C104.383 43.0577 104.383 44.5254 104.383 46.1573C104.271 46.1272 104.158 46.1065 104.049 46.0864C103.823 46.0452 103.613 46.0067 103.458 45.8941C101.52 44.4874 99.3977 43.6625 96.9635 43.9216C96.3411 43.9878 95.6846 44.2105 95.1532 44.536C94.2938 45.0625 93.9338 46.5594 95.4245 47.1287C96.3253 47.4727 97.2673 47.7104 98.2097 47.9482C98.6834 48.0678 99.1572 48.1873 99.6259 48.3204C101.258 48.7838 102.855 49.3147 103.992 50.679C105.614 52.6244 105.493 56.1895 103.781 58.0547C102.113 59.8732 99.9931 60.603 97.5842 60.7227C94.6061 60.8706 91.7132 60.5679 88.9899 59.2221V54.3503C89.1267 54.4013 89.2588 54.4421 89.3844 54.4808C89.6368 54.5586 89.8629 54.6282 90.046 54.7572C92.0537 56.1716 94.2194 57.1235 96.7407 56.966C97.4303 56.9229 98.1276 56.74 98.7841 56.5151C99.4155 56.2988 99.7691 55.8022 99.7936 55.0973C99.818 54.3927 99.5419 53.8547 98.8713 53.6485C98.0775 53.4043 97.2758 53.185 96.4742 52.9657C95.7889 52.7783 95.1034 52.5908 94.4229 52.3878C92.738 51.8851 91.0775 51.3025 90.0207 49.7762C88.6197 47.7529 88.6552 43.8387 91.1686 42.0331C92.8394 40.8328 94.6612 40.2132 96.7432 40.1338C99.3754 40.0335 101.9 40.3227 104.334 41.537Z"
        fill="black"
      />
      <path
        d="M219.09 80.0363L219.076 80.033C217.228 79.6056 215.458 79.1966 213.889 78.1777C212.744 77.4345 212.13 76.3567 211.839 75.1211C211.404 73.2715 211.67 71.5245 212.953 69.9999C214.024 68.7276 215.407 67.9981 216.99 67.5863C220.27 66.7327 223.435 67.3018 226.546 68.3924C226.718 68.4526 226.903 68.7593 226.908 68.955C226.931 70.0219 226.928 71.0892 226.926 72.2182V72.2335C226.925 72.5944 226.924 72.9616 226.924 73.3372C226.812 73.3017 226.704 73.2766 226.604 73.2531C226.407 73.2073 226.239 73.168 226.118 73.0708C224.487 71.7569 222.585 71.1571 220.532 70.9262C219.323 70.7901 217.611 71.5018 217.188 72.322C216.815 73.0476 217.032 73.9164 217.815 74.197C218.819 74.5563 219.854 74.834 220.887 75.1115L220.888 75.1118L220.889 75.1121C221.155 75.1836 221.422 75.2551 221.687 75.3279L221.89 75.3833C223.599 75.8505 225.308 76.3176 226.523 77.7401C227.569 78.9639 228.011 80.4246 227.673 81.9575C226.88 85.5574 224.96 87.1936 221.206 87.7414C217.9 88.2239 214.733 87.7768 211.665 86.4432V81.3143C211.816 81.4059 211.956 81.4869 212.088 81.5631C212.35 81.714 212.578 81.8455 212.788 82.0007C214.706 83.4195 216.863 84.1818 219.252 84.141C220.219 84.1245 221.238 84.0101 221.973 83.2015C222.518 82.6012 222.459 81.3863 221.739 81.0194C221.191 80.7407 220.6 80.5456 219.988 80.3435C219.693 80.2461 219.393 80.147 219.09 80.0363Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.815 50.8063C127.924 53.995 129 57.1238 130.119 60.3775C129.701 60.3775 129.291 60.3786 128.887 60.3797C127.583 60.3833 126.344 60.3868 125.106 60.3513C124.908 60.3456 124.631 60.0144 124.544 59.7788C124.219 58.8904 123.93 57.9863 123.682 57.0735C123.555 56.6048 123.321 56.4564 122.843 56.4635C121.213 56.488 119.582 56.4838 117.951 56.4664C117.451 56.461 117.213 56.5717 117.1 57.1363C116.915 58.0589 116.584 58.9576 116.252 59.8435C116.169 60.0665 115.852 60.3492 115.636 60.3552C114.449 60.3882 113.26 60.3847 112.017 60.3811L112.015 60.3811C111.615 60.3799 111.209 60.3787 110.796 60.3787C110.993 59.82 111.188 59.2703 111.382 58.7265C111.799 57.5531 112.207 56.407 112.6 55.2559C114.184 50.6134 115.763 45.9691 117.32 41.3176C117.516 40.7309 117.775 40.4591 118.449 40.486C119.837 40.5413 121.228 40.5179 122.617 40.4946L122.654 40.494C123.076 40.487 123.345 40.5803 123.488 41.0043C124.579 44.2538 125.682 47.4995 126.815 50.8063ZM119.181 50.4152L118.526 52.521H122.268C122.176 52.2105 122.086 51.9045 121.997 51.6019C121.798 50.9226 121.604 50.2603 121.397 49.6017C121.194 48.951 120.977 48.3042 120.761 47.6574L120.759 47.6527C120.665 47.3702 120.57 47.0877 120.477 46.8048L120.31 46.8123C119.94 47.9678 119.571 49.1232 119.181 50.4152Z"
        fill="black"
      />
      <path
        d="M121.608 78.7089H113.769V83.6167H122.237V87.4932H108.604V67.5819H122.245V71.5529H113.779V74.7922H121.608V78.7089Z"
        fill="black"
      />
      <path
        d="M172.027 43.943C168.808 43.6879 166.689 45.9029 166.259 48.7934C165.984 50.6415 166.079 52.3933 166.879 54.1137C167.975 56.4697 170.997 57.6228 173.46 56.5461C174.149 56.245 174.778 55.8121 175.408 55.3787C175.726 55.1595 176.045 54.9399 176.372 54.7375C176.596 54.599 176.831 54.479 177.118 54.3325C177.266 54.2569 177.428 54.1743 177.609 54.0785C177.609 54.4822 177.61 54.8734 177.611 55.2548C177.614 56.4947 177.616 57.6325 177.587 58.7695C177.582 58.9415 177.3 59.1566 177.098 59.2656C174.996 60.4043 172.719 60.9547 170.344 60.8658C167.686 60.7664 165.219 60.0425 163.308 58.0401C162.047 56.7181 161.277 55.1149 160.994 53.3679C160.545 50.6042 160.525 47.8247 161.812 45.218C163.087 42.6367 165.089 40.9212 167.993 40.3284C171.116 39.6908 174.101 40.0562 176.969 41.4459C177.436 41.6718 177.648 41.9063 177.631 42.4448C177.601 43.4115 177.607 44.3793 177.613 45.3445V45.3458C177.615 45.7324 177.617 46.1186 177.617 46.5041C176.921 46.8239 176.54 46.4318 176.188 46.0709C176.078 45.9571 175.97 45.8463 175.856 45.762C174.71 44.912 173.532 44.1978 172.027 43.943Z"
        fill="black"
      />
    </svg>
  );
};

export default Sancor;

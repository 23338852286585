import { FC } from "react";
import FormFieldSummary from "./FormFieldSummary";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";
type ObjectFieldSummaryProps = {
  field: FormFieldV2;
};

const ObjectListItemFieldSummary: FC<ObjectFieldSummaryProps> = ({
  field: { fields },
}) => {
  return (
    <Column xs={12} noPadding>
      {fields!.map((field) => (
        <FormFieldSummary field={field} key={field.fieldPath} />
      ))}
    </Column>
  );
};

export default ObjectListItemFieldSummary;

import { useTheme } from "@emotion/react";
import { useMemo } from "react";
import { ColorNames, CustomTheme, getThemeColorFromAlias } from "../theme";

const useColor = (colorName?: ColorNames) => {
  const theme = useTheme() as CustomTheme;
  const color = useMemo(() => {
    if (!colorName) return "inherit";
    return getThemeColorFromAlias(colorName, theme.colors);
  }, [colorName, theme.colors]);
  return color;
};

export default useColor;

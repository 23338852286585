/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from "react";
import TextInput from "../../../../components/Input/TextInput";
import { formatInputMask, parseMask } from "../../../../utils/formatNumber";
import { useForm } from "../../hooks/useForm";
import { TextFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import useDebounce from "../../../../hooks/useDebounce";
import FormLabel from "../FormLabel";

const FormInputTextField: FC<TextFieldType & { groupPath: string }> = ({
  type,
  name,
  label,
  disabled = false,
  inputMask,
  groupPath,
}) => {
  const { handleChange, startEditing, finishEditing } = useForm();

  const [value, inputError] = useFieldValue(name, groupPath);
  const [localValue, setLocalValue] = React.useState<string>(value);
  const { debouncedValue } = useDebounce(localValue, 1000);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(inputMask ? parseMask(e.target.value) : e.target.value);
  };
  useEffect(() => {
    if (debouncedValue !== value) {
      handleChange(
        {
          target: { name, value: debouncedValue },
        } as React.ChangeEvent<HTMLInputElement>,
        groupPath
      );
    }
  }, [debouncedValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <TextInput
        placeholder={label.toLowerCase()}
        disabled={disabled}
        type={type}
        name={name}
        value={formatInputMask(localValue, inputMask)}
        onChange={handleInputChange}
        onFocus={() => startEditing(groupPath, name)}
        onBlur={() => finishEditing(groupPath, name)}
        errorMessage={inputError}
      />
    </>
  );
};

export default FormInputTextField;

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import QuotationCardRow, { IQuotationCardRow } from "./QuotationCardRow";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import Row from "../../../../../components/Grid/Row";

interface QuotationCardSectionProps {
  rows: IQuotationCardRow[];
  backgroundColor?: string;
}

const getCSSRules: CSSRulesResolver<{ backgroundColor: string }> = ({
  backgroundColor,
}) => ({
  container: {
    backgroundColor: backgroundColor,
    padding: 20,
    borderRadius: 15,
  },
  row: {
    marginBottom: 5,
    ":last-of-type": {
      marginBottom: 0,
    },
  },
});

const QuotationCardSection: FC<QuotationCardSectionProps> = ({
  rows,
  backgroundColor = "transparent",
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { backgroundColor });
  return (
    <div css={styles.container}>
      {rows.map((row, index) => (
        <Row css={styles.row} key={`quotationCardRow-${index}`}>
          <QuotationCardRow row={row} key={index} />
        </Row>
      ))}
    </div>
  );
};

export default QuotationCardSection;

import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import UploadInput from "../../../../components/Input/UploadInput";

interface FileFieldProps {
  field: FormFieldV2;
}

const FileField: FC<FileFieldProps> = ({ field }) => {
  const { name, label, value, fieldPath, accept } = field;

  const { handleChange, handleFileChange } = useFormField(field);

  return (
    <UploadInput
      label={label}
      file={null}
      onDelete={() => handleChange(null)}
      fileName={value?.fileName || undefined}
      fileType={accept!}
      handleChange={(e) => handleFileChange(e.target.files![0])}
      name={name}
      id={fieldPath}
    />
  );
};

export default FileField;

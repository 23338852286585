import { FC } from "react";

const Done: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_842)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.76777 19.7678L7.18198 18.182L8.59619 16.7678L10.1821 18.3535L8.76777 19.7678ZM10.182 21.182L13 24L15.7929 21.2071L14.3784 19.7927L13 21.171L11.5964 19.7676L10.182 21.182ZM18.7929 18.2071L17.2071 19.7929L15.7927 18.3785L17.3786 16.7928L18.7929 18.2071ZM20.2071 16.7929L21.7929 15.2071L20.3787 13.7929L18.7928 15.3786L20.2071 16.7929ZM7.18188 15.3536L5.76777 16.7678L4 15L5.414 13.586L7.18188 15.3536ZM23.2071 13.7929L24.7929 12.2071L23.3788 10.793L21.7929 12.3787L23.2071 13.7929ZM26.2071 10.7929L24.793 9.37882L26.586 7.586L28 9L26.2071 10.7929Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_842">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Done;

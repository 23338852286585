/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import { useNotes } from "../../../hooks/useNotes";
import HR from "../../HorizontalLine";
import MessageInput from "../../Input/MessageInput";

export const notesFooterHeight = 90;

const NotesFooter: FC = () => {
  const [value, setNoteValue] = useState("");
  const { sendNote } = useNotes();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(e.target.value);
  };

  const handleSend = () => {
    sendNote(value);
    setNoteValue("");
  };

  return (
    <>
      <HR />
      <MessageInput
        value={value}
        placeholder="Adicionar nota neste documento"
        onChange={handleChange}
        onSend={handleSend}
      />
    </>
  );
};

export default NotesFooter;

import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import TextInput from "../../../../components/Input/TextInput";
import { TInputMasks } from "../../models/formInterfaces";
import useFormField from "../../hooks/useFormField";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import FormLabel from "../FormLabel";
import VariableFieldColumn from "../utils/VariableFieldColumn";

interface TextFieldProps {
  field: FormFieldV2;
}

const TextField: FC<TextFieldProps> = ({ field }) => {
  const { name, label, value, disabled, inputMask, type, variant } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { maskedValue, handleInputChange, forceDebounce } =
    useDebouncedMaskedValue(
      value,
      editing,
      handleChange,
      inputMask as TInputMasks
    );

  return (
    <VariableFieldColumn variant={variant}>
      <FormLabel>{label}</FormLabel>
      <TextInput
        placeholder={label.toLowerCase()}
        disabled={disabled!}
        type={type}
        name={name}
        value={maskedValue}
        onChange={handleInputChange}
        onFocus={startEditing}
        onBlur={() => {
          finishEditing();
          forceDebounce();
        }}
        errorMessage={error}
      />
    </VariableFieldColumn>
  );
};

export default TextField;

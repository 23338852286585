import InsuranceCell from "./cells/InsuranceCell";
import StageCell from "./cells/StageCell";
import StatusCell from "./cells/StatusCell";
import {
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  ListDocumentsQuery,
} from "../../../graphql/generated/types";
import { TableColumn } from "../../../hooks/useResizeableTable";
import { NullPartial } from "../../../shared/types";
import AvatarCell from "../../../components/ResizableTable/components/cells/AvatarCell";

type DocumentsTableColumns =
  | "id"
  | "farmer"
  | "stage"
  | "status"
  | "insuranceTypeGroup"
  | "detail"
  | "area"
  | "premium"
  | "city"
  | "uf"
  | "insuranceCompany"
  | "date"
  | "partner"
  | "ope"
  | "com"
  | "sin";

export interface DocumentsTableData
  extends NullPartial<Record<DocumentsTableColumns, string>> {
  id: string;
}

const documentsTableColumns: TableColumn<DocumentsTableData>[] = [
  { id: "farmer", label: "PRODUTOR", width: 200, sortKey: "farmer.name" },
  {
    id: "stage",
    label: "ETAPA",
    cell: (value) => <StageCell stage={value as DocumentStages} />,
    width: 120,
  },
  {
    id: "status",
    label: "STATUS",
    cell: (value) => <StatusCell status={value as DocumentStatus} />,
    width: 120,
  },
  {
    id: "insuranceTypeGroup",
    label: "SEGURO",
    cell: (value) => (
      <InsuranceCell insuranceType={value as InsuranceTypeGroupEnum} />
    ),
    width: 120,
  },
  { id: "detail", label: "DETALHE", sortKey: "culture.name" },
  { id: "area", label: "ÁREA (HA)", sortKey: "crop.area" },
  {
    id: "premium",
    label: "PRÊMIO (R$)",
    emptyValue: "-",
  },
  { id: "city", label: "CIDADE", sortKey: "property.address.city" },
  { id: "uf", label: "UF", width: 55, sortKey: "property.address.state" },
  { id: "insuranceCompany", label: "SEGURADORA", sortKey: "provider" },
  { id: "date", label: "DATA", sortKey: "createdAt" },
  { id: "partner", label: "PARCEIRO", sortKey: "originator.name" },
  {
    id: "ope",
    label: "OPE",
    cell: (value) => <AvatarCell name={value} />,
    width: 70,
    disableSort: true,
  },
  {
    id: "com",
    label: "COM",
    cell: (value) => <AvatarCell name={value} />,
    width: 70,
    disableSort: true,
  },
  {
    id: "sin",
    label: "SIN",
    cell: (value) => <AvatarCell name={value} />,
    width: 70,
    disableSort: true,
  },
];

export const mapTableData = (
  documents?: ListDocumentsQuery["documents"]
): DocumentsTableData[] => {
  return (
    documents?.map((doc) => ({
      id: doc._id!,
      farmer: doc.farmer?.name,
      stage: doc.stage,
      status: doc.status,
      insuranceTypeGroup: doc.insuranceTypeGroup,
      detail: doc.crop?.culture?.name,
      area: doc.crop?.area
        ? doc.crop?.area?.toLocaleString("pr-br")
        : undefined,
      premium: doc.premium?.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      city: doc.properties?.[0]?.address?.city,
      uf: doc.properties?.[0]?.address?.state,
      insuranceCompany: doc.provider?.toUpperCase(),
      date: new Date(doc.createdAt).toLocaleDateString("pt-br"),
      partner: doc.originator?.name?.toUpperCase(),
      ope: doc.operationalResponsible?.name,
      com: doc.commercialResponsible?.name,
      sin: doc.claimResponsible?.name,
    })) || []
  );
};

export default documentsTableColumns;

import { FC } from "react";

const Star: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_417_19)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6726 11.22L16 2L20.3275 11.22L30 12.7L23 19.87L24.6549 30L16 25.22L7.34511 30L9 19.87L2 12.69L11.6726 11.22ZM18.625 12.1L16 6.52L13.337 12.05L12.8994 13.05L11.9484 13.2L6.08973 14.09L10.2935 18.44L10.9973 19.21L10.8261 20.21L9.87504 26.34L15.1156 23.49L16 23L16.9225 23.41L22.163 26.3L21.1645 20.17L20.9932 19.17L21.7065 18.44L25.8723 14.14L20.0136 13.25L19.0625 13.1L18.625 12.1Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_417_19">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Star;

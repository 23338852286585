/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC, useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ScrollContainer from "../ScrollContainer";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs";

interface PdfPreviewProps {
  file: Blob;
}

const styles: CSSObject = {
  pdfWrapper: {
    height: "100%",
  },
  pdfPage: {
    "& canvas": {
      width: "100% !important",
      height: "auto !important",
    },
  },
};

export const PdfPreview: FC<PdfPreviewProps> = ({ file }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState(1);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const adjustScale = () => {
      const containerWidth = wrapperRef.current?.clientWidth || 0;
      const scaleFactor = 1.2;
      setScale((containerWidth / 800) * scaleFactor);
    };

    adjustScale();
    window.addEventListener("resize", adjustScale);

    return () => {
      window.removeEventListener("resize", adjustScale);
    };
  }, []);

  return (
    <div ref={wrapperRef} css={styles.pdfWrapper}>
      <ScrollContainer>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              css={styles.pdfPage}
              scale={scale}
            />
          ))}
        </Document>
      </ScrollContainer>
    </div>
  );
};

import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { RouteObject } from "../types";

export function useMatchedRoute(routes: RouteObject[]) {
  const { pathname } = useLocation();
  for (const route of routes) {
    if (matchPath({ path: route.path }, pathname)) {
      return route;
    }
  }
  return undefined;
}

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ResultPoints } from "../../../../../graphql/generated/types";
import Tooltip from "../../../../../components/Tooltip";
import Typography from "../../../../../components/Typography";
import { theme } from "../../../../../theme";

interface QuotationPointsProps {
  points: ResultPoints;
}

const styles = {
  points: {
    backgroundColor: theme.colors.Green[40],
    borderRadius: "50%",
    width: 45,
    aspectRatio: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const QuotationPoints: FC<QuotationPointsProps> = ({ points }) => {
  return (
    <Tooltip
      id={"result-points"}
      text={`Prêmio: ${points.totalPremium}, Produtiv: ${points.guaranteedProductivity}, Saca: ${points.sackValueDifference}`}
    >
      <Typography
        variant="textLarge"
        component="div"
        weight="bold"
        css={styles.points}
      >
        {points.totalPoints}
      </Typography>
    </Tooltip>
  );
};

export default QuotationPoints;

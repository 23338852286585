import {
  GetCreateMeetingOnObjectFormResponseDocument,
  Services,
} from "../../../../graphql/generated/types";
import Meetings from "../../../../components/Meetings";
import useDetailsColor from "../../../../hooks/useDetailsColor";
import useContactDetails from "../../../../hooks/useContactDetails";

const ContactMeetings = () => {
  const { contactData, refetch } = useContactDetails();
  const { meetings, _id } = contactData!;

  const { color } = useDetailsColor();

  return (
    <Meetings
      meetings={meetings!}
      createQuery={GetCreateMeetingOnObjectFormResponseDocument}
      parentId={_id!}
      serviceName={Services.Contacts}
      color={color}
      refetch={refetch}
    />
  );
};

export default ContactMeetings;

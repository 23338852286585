import { FC } from "react";

const Add: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_846)">
        <path d="M17 15V8H15V15H8V17H15V24H17V17H24V15H17Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_137_846">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Add;

import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import FormField from "../../form.field";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";

interface ObjectFieldProps {
  field: FormFieldV2;
}

const ObjectField: FC<ObjectFieldProps> = ({
  field: { fields, fieldPath },
}) => {
  return (
    <Column xs={12} style={{ padding: 0 }} id={fieldPath}>
      <Row noMargins>
        {fields!.map((field) => (
          <FormField key={field.fieldPath} field={field} />
        ))}
      </Row>
    </Column>
  );
};

export default ObjectField;

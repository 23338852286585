import { useEffect, useState } from "react";
import { TableColumn } from "./useResizeableTable";
import { SortOption } from "../components/ResizableTable";
import { json2csv } from "json-2-csv";
import { SelectInputOptionInterface } from "../components/Input/SelectInputs/SelectInput";
import {
  getLocalStorageOptions,
  LocalStorageOptions,
  setLocalStorageOptions,
} from "../utils/localStorageOptions";
import { useAuth } from "./useAuth";

export interface TableActionsContext<C> {
  activeView: string;
  selectedColumns: TableColumn<C>[];
  tableColumnsOptions: SelectInputOptionInterface[];
  selectedRows: Record<string, boolean>;
  sort: SortOption;
  onTableSwitchViewClick: (active: string) => void;
  onTableColumnsSelect: (selected: string[]) => void;
  onResetColumnsClick: () => void;
  onSortClick: (id: string) => void;
  onDownloadCSVClick: () => Promise<void>;
  onCheckboxClick: (id: string) => void;
}

const useTableActions = <C, D extends { _id: string }>(
  allColumns: TableColumn<C>[],
  initialSort: SortOption,
  fetchCSVData: () => Promise<C[]>,
  data: D[],
  localStorageOptionsKey: keyof LocalStorageOptions
): TableActionsContext<C> & {
  selectedRows: Record<string, boolean>;
  onCheckboxClick: (id: string) => void;
} => {
  const [activeView, setActiveView] = useState("table");
  const { user } = useAuth();
  const [selectedColumns, setSelectedColumns] = useState<TableColumn<C>[]>([]);

  const [sort, setSort] = useState<SortOption>(initialSort);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const tableColumnsOptions = allColumns
    .filter(({ id }) => id !== "checkbox")
    .map(({ id, label }) => ({
      value: id as string,
      label,
    }));

  useEffect(() => {
    if (user._id) {
      const options = getLocalStorageOptions(user._id);
      const localStorageColumns = options[localStorageOptionsKey];
      setSelectedColumns(
        localStorageColumns
          ? allColumns.filter(({ id }) =>
              localStorageColumns.includes(id as string)
            )
          : allColumns
      );
    }
  }, [user]);

  const onTableSwitchViewClick = (id: string) => setActiveView(id);

  const onTableColumnsSelect = (selected: string[]) => {
    const columns = allColumns.filter(
      ({ id }) => selected.includes(id as string) || id === "checkbox"
    );
    setSelectedColumns(columns);
    setLocalStorageOptions(user._id, {
      [localStorageOptionsKey]: columns.map(({ id }) => id) as string[],
    });
  };
  const onResetColumnsClick = () => {
    setSelectedColumns(allColumns);
    setLocalStorageOptions(user._id, {
      [localStorageOptionsKey]: allColumns.map(({ id }) => id) as string[],
    });
  };

  const onSortClick = (id: string) => {
    const col = allColumns.find((c) => c.id === id);
    if (col) {
      const direction = id !== sort.id ? 1 : ((sort.direction * -1) as 1 | -1);

      setSort({
        id: col.id as string,
        sortKey: col.sortKey || (col.id as string),
        direction,
      });
    }
  };

  const onCheckboxClick = (id: string) => {
    const checked = { ...selectedRows };
    if (id !== "all") {
      checked[id] = !selectedRows[id];
    } else {
      data?.forEach(({ _id }) => (checked[_id] = !selectedRows.all));
    }

    const numberOfChecks = Object.keys(checked).reduce((prev, curr) => {
      if (curr !== "all" && checked[curr]) prev += 1;
      return prev;
    }, 0);

    checked.all = numberOfChecks === data?.length;

    setSelectedRows(checked);
  };

  const downloadCSV = (data: Record<string, string>[]) => {
    const csv = json2csv(data);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDownloadCSVClick = async () => {
    const data = (await fetchCSVData()) as Record<string, string>[];
    const parsedData = data.map((entry) => {
      Object.keys(entry).forEach((key) => {
        const d = entry[key];
        if (d === undefined) {
          entry[key] = "";
        } else if (!isNaN(Number(d))) {
          entry[key] = Number(d).toLocaleString("pt-br");
        }
      });
      return entry;
    });
    downloadCSV(parsedData);
  };

  return {
    activeView,
    selectedColumns,
    selectedRows,
    sort,
    tableColumnsOptions,
    onTableSwitchViewClick,
    onTableColumnsSelect,
    onResetColumnsClick,
    onSortClick,
    onDownloadCSVClick,
    onCheckboxClick,
  };
};

export default useTableActions;

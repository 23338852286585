/** @jsxImportSource @emotion/react */
import AuthLayout from "../../../components/AuthLayout";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import Typography from "../../../components/Typography";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useUIStore } from "../../../hooks/useUIStore";

const getCSSRules: CSSRulesResolver = () => ({
  image: {
    paddingTop: 10,
    textAlign: "left",
  },
  logo: {
    width: 120,
  },
  videoContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
  },
});

const DirectQuotations: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    isConfirmationModalOpen,
    isSubmittedModalOpen,
    renderSubmittedModal,
    renderConfirmationModal,
    directQuotationCulture,
  } = useUIStore();

  return (
    <AuthLayout
      rightSideContent={
        isConfirmationModalOpen ? (
          renderConfirmationModal()
        ) : isSubmittedModalOpen ? (
          renderSubmittedModal()
        ) : (
          <Outlet />
        )
      }
    >
      <>
        <Typography
          variant="textLarge"
          margin="lg"
          align="left"
          uppercase
          component="p"
        >
          Faça o seguro da sua{" "}
          <Typography
            variant="textLarge"
            align="left"
            uppercase
            component="span"
            color="Green"
          >
            lavoura{" "}
            {directQuotationCulture !== "" && `de ${directQuotationCulture}`}
          </Typography>{" "}
          e proteja sua safra!
        </Typography>
        <Typography
          variant="textLarge"
          margin="xxl"
          align="left"
          uppercase
          component="p"
        >
          Descontos na contratação do seguro rural com proteção{" "}
          <Typography
            variant="textLarge"
            align="left"
            uppercase
            component="span"
            color="Green"
          >
            contra riscos climáticos como chuvas excessivas, seca, granizo e
            outros.
          </Typography>{" "}
        </Typography>
        <div css={styles.videoContainer}>
          <iframe
            css={styles.video}
            src="https://www.youtube.com/embed/2AUhvx_VybE"
            title="Seguro Rural Prisma Agro"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </>
    </AuthLayout>
  );
};

export default DirectQuotations;

import { FC } from "react";

const Delete: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_823)">
        <path d="M14 12H12V24H14V12Z" fill="black" />
        <path d="M20 12H18V24H20V12Z" fill="black" />
        <path
          d="M4 6V8H6V28C6 28.5304 6.21071 29.0391 6.58579 29.4142C6.96086 29.7893 7.46957 30 8 30H24C24.5304 30 25.0391 29.7893 25.4142 29.4142C25.7893 29.0391 26 28.5304 26 28V8H28V6H4ZM8 28V8H24V28H8Z"
          fill="black"
        />
        <path d="M20 2H12V4H20V2Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_137_823">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Delete;

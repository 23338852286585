import { FC } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import Table from "../../../../../components/Table";
import Typography from "../../../../../components/Typography";
import useDetailsColor from "../../../../../hooks/useDetailsColor";

const ArchivedPolicies: FC = () => {
  const {
    documentData: { archivedPolicies },
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  return (
    <DetailsSection title={"Propostas arquivadas"} noTitle>
      {archivedPolicies?.length ? (
        <Table
          data={archivedPolicies}
          color={color}
          withBorder
          columns={[
            {
              key: "externalId",
              label: "ID",
              textAlign: "center",
            },
            {
              key: "startDate",
              label: "Início da vigência",
              textAlign: "center",
              special: "date",
            },
            {
              key: "endDate",
              label: "Final da vigência",
              textAlign: "center",
              special: "date",
            },
          ]}
        />
      ) : (
        <Typography variant="textMedium" uppercase color={color}>
          Não há apólices arquivadas
        </Typography>
      )}
    </DetailsSection>
  );
};

export default ArchivedPolicies;

import { FC } from "react";

const Policy: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2586_39)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 30H12V28H6V4H22V14H24V4C23.9994 3.46974 23.7886 2.96135 23.4136 2.5864C23.0386 2.21145 22.5303 2.00056 22 2H6C5.46974 2.00056 4.96135 2.21145 4.5864 2.5864C4.21145 2.96135 4.00056 3.46974 4 4V28C4.00056 28.5303 4.21145 29.0386 4.5864 29.4136C4.96135 29.7886 5.46974 29.9994 6 30ZM8 6H20V8H8V6ZM8 10H20V12H8V10ZM14 14H8V16H14V14ZM8 24H12V26H8V24ZM29.707 19.293L26.707 16.293C26.6142 16.2001 26.504 16.1264 26.3827 16.0761C26.2614 16.0258 26.1313 16 26 16C25.8687 16 25.7386 16.0258 25.6173 16.0761C25.496 16.1264 25.3858 16.2001 25.293 16.293L16 25.5859V30H20.4141L29.707 20.707C29.7999 20.6142 29.8736 20.504 29.9239 20.3827C29.9742 20.2614 30 20.1313 30 20C30 19.8687 29.9742 19.7387 29.9239 19.6173C29.8736 19.496 29.7999 19.3858 29.707 19.293ZM19.5859 28H18V26.4141L23 21.4141L24.5859 23L19.5859 28ZM26 21.5859L24.4141 20L26 18.4141L27.5859 20L26 21.5859Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2586_39">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Policy;

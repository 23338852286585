import { FC } from "react";

const Pending: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_808)">
        <path
          d="M16 23C15.4067 23 14.8266 23.1759 14.3333 23.5056C13.8399 23.8352 13.4554 24.3038 13.2284 24.852C13.0013 25.4001 12.9419 26.0033 13.0576 26.5853C13.1734 27.1672 13.4591 27.7018 13.8787 28.1213C14.2982 28.5409 14.8328 28.8266 15.4147 28.9424C15.9967 29.0581 16.5999 28.9987 17.148 28.7716C17.6962 28.5446 18.1648 28.1601 18.4944 27.6667C18.8241 27.1734 19 26.5933 19 26C19 25.2044 18.6839 24.4413 18.1213 23.8787C17.5587 23.3161 16.7957 23 16 23Z"
          fill="black"
        />
        <path d="M18 3H14V20H18V3Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_137_808">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pending;

import { ListContactsQuery } from "../../../../graphql/generated/types";
import { TableColumn } from "../../../../hooks/useResizeableTable";
import { contactTagLabels } from "../../../../shared/contacts/contactTags";
import { NullPartial } from "../../../../shared/types";
import { userStatusLabels } from "../../../../shared/user/userStatus";

type ContactsTableColumns =
  | "id"
  | "name"
  | "type"
  | "uf"
  | "status"
  | "responsible"
  | "cnpj"
  | "email"
  | "date";

export interface ContactsTableData
  extends NullPartial<Record<ContactsTableColumns, string>> {
  id: string;
}

const contactsTableColumns: TableColumn<ContactsTableData>[] = [
  { id: "name", label: "PRODUTOR" },
  { id: "type", label: "TIPO" },
  { id: "uf", label: "UF" },
  { id: "status", label: "STATUS" },
  { id: "responsible", label: "RESPONSÁVEL" },
  { id: "cnpj", label: "CPF/CNPJ" },
  { id: "email", label: "EMAIL" },
  { id: "date", label: "DATA" },
];

export const mapTableData = (
  contacts?: ListContactsQuery["contacts"]
): ContactsTableData[] => {
  return (
    contacts?.map((doc) => ({
      id: doc._id,
      name: doc.name,
      type: doc.tags?.[0] && contactTagLabels[doc.tags?.[0]],
      uf: doc.address?.state,
      status: doc.status && userStatusLabels[doc.status],
      responsible: doc.responsible?.name,
      cnpj: doc.cpfCnpj,
      email: doc.email,
      date: new Date(doc.createdAt).toLocaleDateString("pt-br"),
    })) || []
  );
};

export default contactsTableColumns;

/** @jsxImportSource @emotion/react */

import { FC, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  GetCreateOrgMembersFormResponseDocument,
  useGetOrgWithMembersQuery,
  UserRoles,
} from "../../graphql/generated/types";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import EditableTable from "../../components/EditableTable";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import { orgDepartmentRoleLabels } from "../../shared/org/departmentRoles";
import { formatPhone } from "../../utils/formatNumber";
import Row from "../../components/Grid/Row";
import DetailsSection from "../../components/Details/components/Main/Section/DetailsSection";
import DetailsSectionColumns from "../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { industryLabels } from "../../shared/org/industries";
import { operationalAreaLabels } from "../../shared/org/operationalArea";
import { orgMembershipCountLabels } from "../../shared/org/membershipCount";
import Column from "../../components/Grid/Column";

const MyOrg: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  //   turn this into a feature flag later
  useEffect(() => {
    if (user._id && !(user.orgId && user.role === UserRoles.OrgAdmin)) {
      navigate("/");
    }
  }, [user]);

  const { data, refetch } = useGetOrgWithMembersQuery({
    variables: { contactId: user.orgId! },
    skip: !user.orgId,
  });

  if (!data) return null;

  const members = (data?.contact.members || []).map((m) => ({
    name: m.name,
    email: m.email!,
    phone: formatPhone(m.mobilePhone!),
    departmentRole: orgDepartmentRoleLabels[m.departmentRole!],
  }));

  if (!user.orgId) return null;

  const leftColumn = [
    {
      label: "Nome",
      value: data.contact.name,
    },
    {
      label: "Setor",
      value: industryLabels[data.contact.industry!],
    },
    {
      label: "Cidades",
      value: data.contact.cities?.join(", "),
    },
    {
      label: "Número de filiais",
      value: data.contact.subsidiariesCount,
    },
  ];
  const rightColumn = [
    {
      label: "Área de atuação",
      value: data.contact.operationalArea
        ? operationalAreaLabels[data.contact.operationalArea]
        : "-",
    },
    {
      label: "Nº de produtores",
      value: data.contact.membershipCount
        ? orgMembershipCountLabels[data.contact.membershipCount]
        : "-",
    },
    {
      label: "Link",
      value: data.contact.slug,
    },
  ];

  return (
    <ModalStoreProvider>
      <Layout>
        <Row>
          {/* <Column xs={1} /> */}

          <Column xs={12}>
            <DetailsSection title="Minha organização" main>
              <DetailsSectionColumns columns={[leftColumn, rightColumn]} />
              <EditableTable
                withBorder
                formTitle="Adicionar membro"
                data={members}
                createQuery={GetCreateOrgMembersFormResponseDocument}
                createPayload={{ orgId: user.orgId! }}
                formDisplay="modal"
                onSubmit={refetch}
                columns={[
                  {
                    label: "Nome",
                    key: "name",
                  },
                  {
                    label: "E-mail",
                    key: "email",
                  },
                  {
                    label: "Telefone",
                    key: "phone",
                  },
                  {
                    label: "Setor",
                    key: "departmentRole",
                  },
                ]}
                useV2
              />
            </DetailsSection>
          </Column>
        </Row>
      </Layout>
    </ModalStoreProvider>
  );
};

export default MyOrg;

import { components } from "react-select";
import { SelectOptionDisplay } from "../SelectInput";
import OptionTemplate from "./OptionTemplate";

const SingleValue = (props: any) => {
  const display = (props.selectProps as any)
    .optionDisplay as SelectOptionDisplay;
  if (display === "avatar") {
    return (
      <components.SingleValue {...props}>
        <OptionTemplate
          label={props.data.label}
          optionDisplay="avatar"
          color={props.selectProps.color}
        />
      </components.SingleValue>
    );
  }
  if (display === "icon") {
    return (
      <components.SingleValue {...props}>
        <OptionTemplate
          label={props.data.label}
          optionDisplay="icon"
          color={props.selectProps.color}
          iconName={props.data.iconName}
        />
      </components.SingleValue>
    );
  }
  return <components.SingleValue {...props} />;
};

export default SingleValue;

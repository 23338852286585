import { FC, PropsWithChildren, useMemo } from "react";
import { InputVariant, Maybe } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";

interface VariableFieldColumnProps extends PropsWithChildren {
  variant?: Maybe<InputVariant>;
  useYPaddings?: boolean;
}

const VariableFieldColumn: FC<VariableFieldColumnProps> = ({
  variant,
  children,
  useYPaddings = true,
}) => {
  const colSpan = useMemo(() => {
    if (variant === InputVariant.Xs) {
      return 6;
    } else {
      return 12;
    }
  }, [variant]);

  return (
    <Column xs={12} sm={colSpan} useYPaddings={useYPaddings}>
      {children}
    </Column>
  );
};

export default VariableFieldColumn;

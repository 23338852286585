/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { useCreateFormResponseV2Mutation } from "../../../graphql/generated/types";
import { useAuth } from "../../../hooks/useAuth";
import FormV2 from "../../../modules/Forms/form.v2";
import Layout from "../../../components/Layout";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toCultureDetailsLocation } from "../../../Locations";
import FormTitle from "../../../modules/Forms/components/FormTitle";

const formId = "createCulture";

export const CreateCulture = () => {
  const { errorHandler } = useErrorHandler();

  const [resId, setResId] = useState<string>("");
  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();
  const { loaded: authIsLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authIsLoaded && !resId) {
      createFormResponseMutation({
        variables: {
          formId,
          metadata: {},
        },
      })
        .then(({ data }) => setResId(data!.createFormResponseV2!))
        .catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [authIsLoaded]);

  const handleSubmit = (res: { _id: string }) => {
    toast.success("Cultura criada com sucesso!");
    navigate(toCultureDetailsLocation(res._id));
  };

  return (
    <Layout padding={20}>
      <Row noMargins noGutters noWrap css={{ height: "100%" }}>
        <Column xs={2} />
        <Column xs={8}>
          {resId && (
            <>
              <FormTitle title="Criar cultura" />
              <FormV2
                formId={formId}
                onSubmit={handleSubmit}
                formResponseId={resId}
              />
            </>
          )}
        </Column>
      </Row>
    </Layout>
  );
};

/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode } from "react";
import Row from "../../../components/Grid/Row";
import { FormField } from "../models/formInterfaces";

interface SectionRowProps {
  fields: FormField[];
  fieldToColumn: (f: FormField) => ReactNode;
  subSectionDivider?: ReactNode;
}

const SectionRows: FC<SectionRowProps> = ({
  fields,
  fieldToColumn,
  subSectionDivider,
}) => {
  const sectionRows: ReactNode[] = [];
  let currentRow: ReactNode[] = [];
  fields.forEach((field) => {
    const fieldElement = fieldToColumn(field);
    const divider = field.subSection && subSectionDivider;
    if (divider) {
      currentRow.push(
        <React.Fragment key={`${field.group}-${field.name}-divider`}>
          {divider}
        </React.Fragment>
      );
    }
    currentRow.push(
      <React.Fragment key={`${field.group}-${field.name}`}>
        {fieldElement}
      </React.Fragment>
    );
    if (field.variant !== "xs" || (currentRow.length >= 2 && !divider)) {
      sectionRows.push(currentRow);
      currentRow = [];
    }
  });
  return (
    <>
      {sectionRows.map((row, i) => (
        <Row key={i}>{row}</Row>
      ))}
    </>
  );
};

export default SectionRows;

/** @jsxImportSource @emotion/react */

import { components } from "react-select";
import { IconNames } from "../../../Icons/styles/iconNames";
import Icon from "../../../Icons";
import { ColorNames } from "../../../../theme";
import { IconSizes } from "../../../Icons/styles/iconSizes";

const styles = {
  width: 24,
  padding: 6,
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  height: "100%",
  boxSizing: "border-box" as "border-box",
};

const SingleValueClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <div css={styles}>
        <Icon
          name={IconNames.Close}
          size={IconSizes.Small}
          color={ColorNames.GREY}
          onClick={props.clearValue}
        />
      </div>
    </components.ClearIndicator>
  );
};

export default SingleValueClearIndicator;

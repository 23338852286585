/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import { theme } from "../../../../theme";
import SkeletonDashboardCard from "./components/SkeletonStageChart";
import SkeletonAnimationContainer from "../../../../components/Skeletons/components/SkeletonAnimationContainer";

const styles = {
  content: {
    backgroundColor: theme.colors.Grey[20],
    padding: "1rem",
    boxSizing: "border-box" as const,
    display: "flex",
    alignItems: "center",
    margin: "0.5rem 0",
    position: "relative" as const,
  },
  row: {
    width: "100%",
    height: "100%",
    margin: 0,
    paddingLeft: 12,
  },
  col: {
    height: "100%",
    boxSizing: "border-box" as const,
    padding: "0.5rem",
  },
};

const SkeletonStageCharts: FC = () => {
  const skeletonData = Array(4).fill(<SkeletonDashboardCard />);
  return (
    <div css={styles.content}>
      <SkeletonAnimationContainer />
      <Row justify="around" align="center" css={styles.row}>
        {skeletonData.map((skeleton, index) => (
          <Column
            xs={12}
            md={6}
            xl={3}
            key={`skeleton-chart-${index}`}
            css={styles.col}
          >
            {skeleton}
          </Column>
        ))}
      </Row>
    </div>
  );
};

export default SkeletonStageCharts;

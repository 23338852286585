/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";

const getCSSRules = ({ position }: { position: string }) => {
  const baseTooltipText = {
    visibility: "hidden" as "hidden",
    backgroundColor: "rgb(230, 230, 230)",
    textAlign: "center" as "center",
    borderRadius: "6px",
    padding: "5px",
    position: "absolute" as "absolute",
    zIndex: Number.MAX_VALUE,
    opacity: 0,
    transition: "opacity 0.3s",
    minWidth: "100px",
  };

  let positionRules = {};

  switch (position) {
    case "top":
      positionRules = {
        bottom: "100%",
        left: "50%",
        transform: "translateX(-50%)",
      };
      break;
    case "right":
      positionRules = {
        top: "50%",
        left: "100%",
        transform: "translateY(-50%)",
      };
      break;
    case "bottom":
      positionRules = {
        top: "100%",
        left: "50%",
        transform: "translateX(-50%)",
      };
      break;
    case "left":
      positionRules = {
        top: "50%",
        right: "100%",
        transform: "translateY(-50%)",
      };
      break;
    default:
      positionRules = {
        top: "100%",
        left: "50%",
        transform: "translateX(-50%)",
      };
      break;
  }

  return {
    tooltip: {
      position: "relative" as "relative",
      display: "inline-block",
      cursor: "pointer",
      width: "fit-content",
      zIndex: Number.MAX_VALUE,
    },
    tooltipText: {
      ...baseTooltipText,
      ...positionRules,
    },
  };
};

interface TooltipProps {
  id: string;
  text: string;
  children: React.ReactNode;
  position?: "default" | "top" | "right" | "left" | "bottom";
}

const Tooltip = ({
  id,
  text,
  children,
  position = "default",
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleIsVisible = () => setIsVisible(!isVisible);
  const styles = useCSSRulesWithTheme(getCSSRules, { position });

  return (
    <div
      css={styles.tooltip}
      onMouseEnter={toggleIsVisible}
      onMouseLeave={toggleIsVisible}
    >
      {children}
      <span
        id={id}
        css={{
          ...styles.tooltipText,
          visibility: isVisible ? "visible" : "hidden",
          opacity: isVisible ? 1 : 0,
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default Tooltip;

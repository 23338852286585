import { FC } from "react";

const Claim: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00084 5.45877C6.00028 5.47245 6 5.4862 6 5.50002V17.6331C5.99973 17.6465 5.99973 17.6599 6 17.6733V27H5C3.89543 27 3 27.8954 3 29V30H11V29C11 27.8954 10.1046 27 9 27H8V17.9086C8.43199 17.1455 8.87898 16.685 9.31062 16.41C9.77205 16.116 10.2849 15.9896 10.8863 16.0007C12.1703 16.0242 13.7034 16.6564 15.6021 17.5109C15.7381 17.5721 15.8755 17.6342 16.0145 17.697C17.7308 18.4727 19.6684 19.3484 21.6628 19.7581C23.8623 20.2099 26.2042 20.1139 28.5146 18.7271C28.7866 18.5638 28.953 18.2918 28.9915 18H29V17.8861C29.0002 17.8745 29.0002 17.8629 29 17.8514V6.00002C29 5.97668 28.9992 5.95354 28.9976 5.9306C29.0104 5.75332 28.9761 5.57041 28.8885 5.40092C28.6351 4.9102 28.0319 4.71781 27.5412 4.9712C23.3676 7.12629 20.0576 5.48139 16.7087 3.81722L16.5886 3.75754C14.9637 2.95034 13.2127 2.08907 11.4603 2.00659C9.56871 1.91757 7.82638 2.724 6.21035 4.80384C6.05933 4.9982 5.99128 5.23071 6.00084 5.45877ZM27 7.38635V17.2796C25.3841 18.091 23.7444 18.1439 22.0653 17.799C20.2994 17.4363 18.558 16.6505 16.7972 15.8558L16.4229 15.6871C14.6174 14.8746 12.6943 14.0335 10.923 14.001C9.9969 13.984 9.08238 14.1839 8.23586 14.7233C8.156 14.7742 8.07738 14.8277 8 14.8838V5.76972C9.21593 4.31444 10.3106 3.9547 11.3663 4.00438C12.6216 4.06346 13.9842 4.69693 15.6989 5.54871L15.9735 5.68536C18.9066 7.14626 22.576 8.97381 27 7.38635Z"
        fill="black"
      />
    </svg>
  );
};

export default Claim;

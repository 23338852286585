import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export interface PaginationContext {
  limit: number;
  skip: number;
  currentPage: number;
  onPaginatorChange: (values: { currentPage: number; limit: number }) => void;
}

const usePagination = (initialLimit: number): PaginationContext => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) || 0
  );
  const [limit, setLimit] = useState(
    Number(searchParams.get("limit") || initialLimit)
  );

  const skip = currentPage * limit;

  const onPaginatorChange = (values: {
    currentPage: number;
    limit: number;
  }) => {
    searchParams.set("page", String(values.currentPage));
    searchParams.set("limit", String(values.limit));
    setSearchParams(searchParams);
    setCurrentPage(values.currentPage);
    setLimit(values.limit);
  };

  return {
    currentPage,
    skip,
    limit,
    onPaginatorChange,
  };
};

export default usePagination;

import moment from "moment";
import "moment/locale/pt-br";

export const isToday = (date: Date) => {
  const today = moment();
  return moment(date).isSame(today, "day");
};

export const formatISODate = (date: string) => {
  if (!date) return "";
  return moment.utc(date).format("DD/MM/YYYY");
  // const [year, month, day] = date.split("T")[0].split("-");
  // return `${day}/${month}/${year}`;
};

export const formatDateWithTime = (date?: Date) => {
  if (!date) return "-";
  return moment(date).format("DD/MM/YYYY HH:mm");
};

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorAlias, getThemeColorFromAlias, theme } from "../../theme";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { css } from "@emotion/react";

interface ArrowRightIconProps {
  color: ColorAlias;
  onClick?: () => void;
  style?: any;
}

const getStyles = ({ cursor }: { cursor: string }) => ({
  cursor: cursor,
});

const ArrowRightIcon: FC<ArrowRightIconProps> = ({ color, onClick, style }) => {
  const iconColor = getThemeColorFromAlias(color, theme.colors);
  const cursor = onClick ? "pointer" : "arrow";
  const styles = useCSSRulesWithTheme(getStyles, { cursor });

  return (
    <span css={css(styles, style)} onClick={onClick}>
      <svg
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.899414 1.47559L7.44742 7.55755L0.899414 13.6395"
          stroke={iconColor}
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};

export default ArrowRightIcon;

import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import DefaultFieldSummary from "./DefaultFieldSummary";
import { decimalCoordToGMSValue } from "../../../../utils/formatNumber";

export const GeoLocationFieldSummary: FC<{ field: FormFieldV2 }> = ({
  field,
}) => {
  const { value, variant, label } = field;
  const lat = decimalCoordToGMSValue(value.lat);
  const lng = decimalCoordToGMSValue(value.lng);
  const valueStr = `Latitude: ${lat.coordG}º ${lat.coordM}' ${lat.coordS}" Longitude: ${lng.coordG}º ${lng.coordM}' ${lng.coordS}"`;
  return (
    <DefaultFieldSummary label={label} value={valueStr} variant={variant} />
  );
};

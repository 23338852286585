import { OrgIndustries } from '../../graphql/generated/types';
import { TModelOptionLabels } from '../types';

export const industryLabels: TModelOptionLabels<OrgIndustries> = {
  [OrgIndustries.Dealership]: 'Revenda',
  [OrgIndustries.Union]: 'Sindicato',
  [OrgIndustries.Cooperative]: 'Cooperativa',
  [OrgIndustries.Association]: 'Associação',
  [OrgIndustries.Agrochemical]: 'Agroquímica',
  [OrgIndustries.InputDistributor]: 'Distribuidor de Insumos',
  [OrgIndustries.InsuranceBroker]: 'Corretora de Seguros',
  [OrgIndustries.Other]: 'Outros',
};

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Row from "../../../../Grid/Row";
import Column from "../../../../Grid/Column";
import DetailsRowLabel from "./DetailsRowLabel";
import DetailsRowValue, { DetailsRowValueProps } from "./DetailsRowValue";

export type DetailsRowProps = DetailsRowValueProps & {
  label?: string;
  colSpan?: [number, number];
};

const DetailsRow: FC<DetailsRowProps> = ({ label, colSpan, ...valueProps }) => {
  return (
    <Row style={{ marginBottom: "8px" }} align="center">
      <Column xs={colSpan ? colSpan[0] : 4}>
        <DetailsRowLabel>{label}</DetailsRowLabel>
      </Column>
      <Column xs={colSpan ? colSpan[1] : 8}>
        <DetailsRowValue {...valueProps} />
      </Column>
    </Row>
  );
};

export default DetailsRow;

import { Culture } from "../../../graphql/generated/types";
import { createContext, FC, PropsWithChildren } from "react";

interface CultureContextValue {
  culture: Culture;
  refetch: () => Promise<void>;
}

const CultureContext = createContext<CultureContextValue>({
  culture: {},
  refetch: async () => {},
});

interface CultureProviderProps extends PropsWithChildren {
  culture: Culture;
  refetch: () => Promise<any>;
}

export const CultureProvider: FC<CultureProviderProps> = ({
  culture,
  children,
  refetch,
}) => {
  return (
    <CultureContext.Provider
      value={{
        culture,
        refetch,
      }}
    >
      {children}
    </CultureContext.Provider>
  );
};

export default CultureContext;

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorAlias, getThemeColorFromAlias, theme } from "../../theme";

interface CloseIconProps {
  onClick?: () => void;
  color?: ColorAlias;
}

const CloseIcon: FC<CloseIconProps> = ({ onClick, color }) => {
  const handleClick = () => {
    onClick && onClick();
  };
  const iconColor = color
    ? getThemeColorFromAlias(color, theme.colors)
    : getThemeColorFromAlias("Green", theme.colors);

  return (
    <span onClick={handleClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 17L16.2381 1" stroke={iconColor} />
        <path opacity="0.9" d="M17 17L1 1" stroke={iconColor} />
      </svg>
    </span>
  );
};

export default CloseIcon;

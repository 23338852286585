/** @jsxImportSource @emotion/react */

import { FC } from "react";
import {
  ContactTags,
  GetPartnersDashboardDataQuery,
  useGetPartnersDashboardDataQuery,
} from "../../graphql/generated/types";
import Layout from "../../components/Layout";
import MainFrame from "../../components/MainFrame";
import Row from "../../components/Grid/Row";
import Column from "../../components/Grid/Column";
import ContactDashboardCard from "./components/ContactDashboardCard";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  cardWrapper: {
    height: "150px",
    padding: 10,
  },
};
const getTagGroup = (tag: ContactTags, data?: GetPartnersDashboardDataQuery) =>
  data?.getPartnersDashboardData.find((d) => d.tag === tag) || {
    tag,
    statusGroups: [],
  };

const PartnersDashboard: FC = () => {
  const { data } = useGetPartnersDashboardDataQuery({});

  return (
    <Layout>
      <MainFrame>
        <Row noMargins>
          <Column xs={0} lg={4} />
          <Column xs={12} lg={4}>
            {[ContactTags.Consultant, ContactTags.Organization].map((tag) => (
              <div css={styles.cardWrapper}>
                <ContactDashboardCard tagGroup={getTagGroup(tag, data)} />
              </div>
            ))}
          </Column>
        </Row>
      </MainFrame>
    </Layout>
  );
};

export default PartnersDashboard;

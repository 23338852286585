import { FC } from "react";
import { formatPrice } from "../../utils/formatNumber";
import TextInput, { TextInputProps } from "./TextInput";

interface PriceInputProps extends TextInputProps {}

const PriceInput: FC<PriceInputProps> = ({ handleChange, value, ...rest }) => {
  const toInput = (val: string) => (val ? formatPrice(Number(val || 0)) : val);
  const toState = (val: string) =>
    (parseFloat(val.replace(/\D/g, "")) / 100).toFixed(2);
  return (
    <TextInput
      {...rest}
      handleChange={(val) =>
        handleChange ? handleChange(toState(val)) : undefined
      }
      value={toInput(value)}
      type="text"
    />
  );
};

export default PriceInput;

import { FC, useMemo } from "react";
import DefaultFieldSummary from "./DefaultFieldSummary";
import { FormFieldV2 } from "../../../../graphql/generated/types";

type BooleanFieldSummaryProps = {
  field: FormFieldV2;
};

const BooleanFieldSummary: FC<BooleanFieldSummaryProps> = ({
  field: { value, options, variant, label },
}) => {
  const valueStr = useMemo(() => {
    return value ? "Sim" : "Não";
  }, [options, value]);

  return (
    <DefaultFieldSummary
      label={label}
      value={valueStr || ""}
      variant={variant}
    />
  );
};

export default BooleanFieldSummary;

import { FC } from "react";

const User: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_810)">
        <path
          d="M16 4C16.9889 4 17.9556 4.29324 18.7779 4.84265C19.6001 5.39206 20.241 6.17295 20.6194 7.08658C20.9978 8.00021 21.0969 9.00555 20.9039 9.97545C20.711 10.9454 20.2348 11.8363 19.5355 12.5355C18.8363 13.2348 17.9454 13.711 16.9755 13.9039C16.0056 14.0969 15.0002 13.9978 14.0866 13.6194C13.173 13.241 12.3921 12.6001 11.8427 11.7779C11.2932 10.9556 11 9.98891 11 9C11 7.67392 11.5268 6.40215 12.4645 5.46447C13.4022 4.52678 14.6739 4 16 4ZM16 2C14.6155 2 13.2622 2.41054 12.111 3.17971C10.9599 3.94888 10.0627 5.04213 9.53285 6.32122C9.00303 7.6003 8.86441 9.00776 9.13451 10.3656C9.4046 11.7235 10.0713 12.9708 11.0503 13.9497C12.0292 14.9287 13.2765 15.5954 14.6344 15.8655C15.9922 16.1356 17.3997 15.997 18.6788 15.4672C19.9579 14.9373 21.0511 14.0401 21.8203 12.889C22.5895 11.7378 23 10.3845 23 9C23 7.14348 22.2625 5.36301 20.9497 4.05025C19.637 2.7375 17.8565 2 16 2Z"
          fill="black"
        />
        <path
          d="M26 30H24V25C24 24.3434 23.8707 23.6932 23.6194 23.0866C23.3681 22.48 22.9998 21.9288 22.5355 21.4645C22.0712 21.0002 21.52 20.6319 20.9134 20.3806C20.3068 20.1293 19.6566 20 19 20H13C11.6739 20 10.4021 20.5268 9.46447 21.4645C8.52678 22.4021 8 23.6739 8 25V30H6V25C6 23.1435 6.7375 21.363 8.05025 20.0503C9.36301 18.7375 11.1435 18 13 18H19C20.8565 18 22.637 18.7375 23.9497 20.0503C25.2625 21.363 26 23.1435 26 25V30Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_810">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default User;

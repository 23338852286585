/** @jsxImportSource @emotion/react */

import { FC } from "react";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import EditableSection from "../../../../components/EditableSection";
import {
  GetEditContactOnFormResponseDocument,
  GetEditContactOnFormResponseQueryVariables,
} from "../../../../graphql/generated/types";
import ContactData from "../../../../components/Details/ContactData";
import { ToContactDetailsLocation } from "../../../../Locations";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { useFlags } from "../../../../hooks/useFlags";

const FarmerData: FC = () => {
  const {
    documentData: { farmer: farmerData },
    refetch,
  } = useDocumentDetails();

  const { _id: farmerId } = farmerData!;

  const { isFlagEnabled } = useFlags();

  return (
    <EditableSection<{}, GetEditContactOnFormResponseQueryVariables>
      title="Dados do Produtor"
      footerButtons={
        isFlagEnabled("admin-only")
          ? [
              {
                text: "Página do produtor",
                link: ToContactDetailsLocation(farmerId!),
                iconName: IconNames.Share,
              },
            ]
          : []
      }
      createPayload={{}}
      editQuery={GetEditContactOnFormResponseDocument}
      editPayload={{ objectId: farmerId! }}
      objectId={farmerId}
      onSubmit={refetch}
      main
      useV2
    >
      <ContactData contact={farmerData!} />
    </EditableSection>
  );
};

export default FarmerData;

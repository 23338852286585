/** @jsxImportSource @emotion/react */
import Column from "../../../components/Grid/Column";
import Row from "../../../components/Grid/Row";
import HR from "../../../components/HorizontalLine";
import Typography from "../../../components/Typography";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { ColorNames } from "../../../theme";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  messageContainer: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: "calc(100vh - 246px)",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});

export const FormSuccess = ({ successMessage }: { successMessage: string }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.messageContainer}>
      <Row justify="center" css={styles.successMessage}>
        <Column>
          <Typography
            uppercase
            variant="textMedium"
            color="Grey"
            align="center"
            component="p"
          >
            {successMessage}
          </Typography>
          <HR spacingSize="small" color={ColorNames.GREEN} />
        </Column>
      </Row>
    </div>
  );
};

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import Typography from "../Typography";
import ExclamationIcon from "../../components/assets/redExclamation.svg";
import CloseIcon from "../../components/assets/redClose.svg";

interface ErrorMessageProps {
  message: string;
  handleClose: () => void;
}

const getStyles: CSSRulesResolver = ({ ...props }) => ({
  errorContainer: {
    border: `1px solid ${props.colors.ErrorRed[100]}`,
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: ".5em",
    paddingLeft: "1.5em",
    height: "45px",
  },
  exclamationIcon: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  closeIcon: {
    cursor: "pointer",
  },
});

const ErrorMessage: FC<ErrorMessageProps> = ({ message, handleClose }) => {
  const styles = useCSSRulesWithTheme(getStyles);
  return (
    <Row css={styles.errorContainer} justify="between" align="start">
      <Column width="auto" css={styles.exclamationIcon}>
        <img src={ExclamationIcon} />
      </Column>
      <Column>
        <Typography variant="textMedium" color="ErrorRed">
          {message}
        </Typography>
      </Column>
      <Column width="auto">
        <img src={CloseIcon} css={styles.closeIcon} onClick={handleClose} />
      </Column>
    </Row>
  );
};

export default ErrorMessage;

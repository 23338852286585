/** @jsxImportSource @emotion/react */
import { FC } from "react";
import DetailsSectionTitle from "../../../../components/Details/components/Main/Section/DetailsSectionTitle";
import Typography from "../../../../components/Typography";
import { CSSObject } from "@emotion/react";

interface FormInputLabelProps {
  label: string;
  labelDisplay?: "title" | "subtitle" | "none";
}

const styles: CSSObject = {
  default: {
    textTransform: "uppercase",
    fontWeight: 300,
    fontSize: 20,
    height: 22,
  },
};

const FormInputLabel: FC<FormInputLabelProps> = ({ label, labelDisplay }) => {
  switch (labelDisplay) {
    case "title":
      return <DetailsSectionTitle title={label} />;
    case "subtitle":
      return <DetailsSectionTitle title={label} subtitle />;
    case "none":
      return null;
    default:
      return (
        <Typography
          css={styles.default}
          variant="textMedium"
          align="left"
          width={"100%"}
          component={"div"}
        >
          {label}
        </Typography>
      );
  }
};

export default FormInputLabel;

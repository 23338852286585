import { FC } from "react";

const Instagram: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_306_217)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 11C4 7.13401 7.13401 4 11 4H21C24.866 4 28 7.13401 28 11V21C28 24.866 24.866 28 21 28H11C7.13401 28 4 24.866 4 21V11ZM22.4056 11.0344C23.2009 11.0344 23.8456 10.3897 23.8456 9.59439C23.8456 8.7991 23.2009 8.15439 22.4056 8.15439C21.6103 8.15439 20.9656 8.7991 20.9656 9.59439C20.9656 10.3897 21.6103 11.0344 22.4056 11.0344ZM12.5764 10.8763C13.5898 10.1992 14.7812 9.8378 16 9.8378C17.6343 9.8378 19.2017 10.487 20.3573 11.6427C21.5129 12.7983 22.1622 14.3657 22.1622 16C22.1622 17.2188 21.8008 18.4102 21.1237 19.4235C20.4465 20.4369 19.4841 21.2267 18.3581 21.6931C17.2321 22.1595 15.9931 22.2816 14.7978 22.0438C13.6024 21.806 12.5044 21.2191 11.6426 20.3573C10.7808 19.4955 10.1939 18.3975 9.95618 17.2022C9.71841 16.0068 9.84044 14.7678 10.3068 13.6418C10.7732 12.5158 11.5631 11.5534 12.5764 10.8763ZM13.7777 19.3259C14.4355 19.7654 15.2088 20 16 20C17.0608 20 18.0783 19.5786 18.8284 18.8284C19.5785 18.0783 20 17.0609 20 16C20 15.2089 19.7654 14.4355 19.3258 13.7777C18.8863 13.1199 18.2616 12.6072 17.5307 12.3045C16.7998 12.0017 15.9955 11.9225 15.2196 12.0769C14.4437 12.2312 13.731 12.6122 13.1715 13.1716C12.6121 13.731 12.2312 14.4437 12.0768 15.2196C11.9225 15.9956 12.0017 16.7998 12.3045 17.5307C12.6072 18.2616 13.1199 18.8864 13.7777 19.3259Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_306_217">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;

/** @jsxImportSource @emotion/react */
import Layout from "../../components/Layout/index";
import Typography from "../../components/Typography";
import {
  useCSSRulesWithTheme,
  CSSRulesResolver,
} from "../../hooks/useCSSRulesWithTheme";
import { ColorNames, theme } from "../../theme";
import HR from "../../components/HorizontalLine";

const getCSSRules: CSSRulesResolver = () => ({
  main: {
    border: `2px solid ${theme.colors.ErrorRed[100]}`,
    borderRadius: 10,
    height: "95%",
    boxSizing: "border-box",
    backgroundColor: `${theme.colors.White[100]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
  },
  centered: {
    width: "50vw",
  },
  typo: {
    color: "#C81F1C",
  },
});

const ErrorPage = () => {
  const styles = useCSSRulesWithTheme(getCSSRules, {});

  return (
    <Layout>
      <div css={styles.main}>
        <div css={styles.centered}>
          <Typography uppercase variant="textLarge" color="Grey">
            Ops! Algo deu errado. Página não encontrada!
          </Typography>
          <HR color={ColorNames.ERROR_RED} spacingSize="small" />
          <Typography
            css={styles.typo}
            uppercase
            size={32}
            variant="textLarge"
            color={ColorNames.ERROR_RED}
          >
            !
          </Typography>
        </div>
      </div>
    </Layout>
  );
};

export default ErrorPage;

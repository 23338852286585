/** @jsxImportSource @emotion/react */

import { FC, ReactNode } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";

interface BoxContainerProps {
  children: ReactNode;
  backgroundColor: string;
  useFullHeight?: boolean;
}

const getStyles: CSSRulesResolver<{
  backgroundColor: string;
  useFullHeight?: boolean;
}> = ({ backgroundColor, useFullHeight }) => ({
  box: {
    backgroundColor,
    padding: 20,
    boxSizing: "border-box" as const,
    display: "flex",
    alignItems: "center",
    height: useFullHeight ? "100%" : "auto",
  },
  content: {
    width: "100%",
  },
});

const BoxContainer: FC<BoxContainerProps> = ({
  backgroundColor,
  children,
  useFullHeight,
}) => {
  const styles = useCSSRulesWithTheme(getStyles, {
    backgroundColor,
    useFullHeight,
  });
  return (
    <div css={styles.box}>
      <div css={styles.content}>{children}</div>
    </div>
  );
};

export default BoxContainer;

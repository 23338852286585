import React from "react";
import { InsuranceTypeGroupEnum } from "../../../../graphql/generated/types";
import { insuranceTypeGroupLabels } from "../../../../shared/insurance/insuranceTypeGroup";
import { insuranceTypeGroupsIcons } from "../../../../utils/icons";
import IconLabel from "../../../../components/IconLabel";
import TableCell from "../../../../components/ResizableTable/components/cells/TableCell";

interface InsuranceCellProps {
  insuranceType?: InsuranceTypeGroupEnum;
}

const InsuranceCell: React.FC<InsuranceCellProps> = ({ insuranceType }) => {
  const label = insuranceType
    ? insuranceTypeGroupLabels[insuranceType].toUpperCase()
    : undefined;
  return (
    <TableCell tooltip={label}>
      {insuranceType ? (
        <IconLabel
          label={label!}
          icon={insuranceTypeGroupsIcons[insuranceType]}
        />
      ) : null}
    </TableCell>
  );
};
export default InsuranceCell;

import { SortOption } from "../components/ResizableTable";
import { LocalStorageOptions } from "../utils/localStorageOptions";
import usePagination from "./usePagination";
import useResizeableTable, { TableColumn } from "./useResizeableTable";
import useTableActions from "./useTableActions";

const useTable = <C, D extends { _id: string }>(
  allColumns: TableColumn<C>[],
  initialSort: SortOption,
  fetchCSVData: () => Promise<C[]>,
  localStorageOptionsKey: keyof LocalStorageOptions,
  data?: D[]
) => {
  const paginator = usePagination(9);
  const actions = useTableActions(
    [{ id: "checkbox", disableSort: true, width: 30 } as TableColumn<C>].concat(
      allColumns
    ),
    initialSort,
    fetchCSVData,
    data || [],
    localStorageOptionsKey
  );
  const resize = useResizeableTable(actions.selectedColumns);

  return {
    paginator,
    actions,
    resize,
  };
};

export default useTable;

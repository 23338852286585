import Table from "../../../../components/Table";
import {
  InsuranceDocument,
  Property,
  useListContactDocumentsQuery,
} from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToDocumentDetailsLocation } from "../../../../Locations";
import { formatDateWithTime } from "../../../../utils/dateUtils";
import { getDocumentContactRoles } from "../../utils/documentRoles";
import ScrollContainer from "../../../../components/ScrollContainer";
import { getArea } from "../../../../utils/formatNumber";

type TableDocumentData = {
  description: string;
  _id: string;
  updatedAt: string;
  area: string | null;
  propertyDescription: string | null;
  roleOnDocument: string;
};

const ContactDocuments = () => {
  const { contactData } = useContactDetails();

  const { data } = useListContactDocumentsQuery({
    variables: {
      filter: [[{ anyContactId: contactData._id }]],
    },
  });

  const getPropertyDescription = (properties: Property[]) => {
    if (properties?.length === 1) {
      return properties[0].displayName!;
    }
    return `${properties?.length} propriedades`;
  };

  const tableDocumentData: TableDocumentData[] = (data?.documents || []).map(
    (doc) => {
      return {
        _id: doc!._id!,
        roleOnDocument: getDocumentContactRoles(doc!, contactData._id!),
        description: doc.description!,
        area: getArea(doc as InsuranceDocument),
        propertyDescription: getPropertyDescription(doc!.properties || []),
        updatedAt: formatDateWithTime(doc!.updatedAt!),
      };
    }
  );

  return (
    <ScrollContainer>
      <Table<TableDocumentData>
        data={tableDocumentData}
        withBorder
        color={ColorNames.BLACK}
        rowLink={(doc: TableDocumentData) =>
          ToDocumentDetailsLocation(doc._id!)
        }
        columns={[
          {
            label: "Papel no documento",
            key: "roleOnDocument",
          },
          {
            label: "Descrição",
            key: "description",
          },
          {
            label: "Localização",
            key: "propertyDescription",
          },
          {
            label: "Area",
            key: "area",
          },
          {
            label: "Atualizado em",
            key: "updatedAt",
          },
        ]}
      />
    </ScrollContainer>
  );
};

export default ContactDocuments;

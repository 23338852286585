/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { ColorNames, theme } from "../../../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import Column from "../../../../components/Grid/Column";
import Row from "../../../../components/Grid/Row";
import BoxContainer from "../BoxContainer";
import { IconSizes } from "../../../../components/Icons/styles/iconSizes";
import { useScreenClass } from "react-grid-system";
import Tooltip from "../../../../components/Tooltip";
import { TModelFilter } from "../../../../hooks/useModelFilter";

interface SelectInsuranceTypeProps {
  filter: TModelFilter;
}

const activeColor = theme.colors.Blue[100];
const defaultColor = theme.colors.Black[100];

const getItemStyles: CSSRulesResolver<{
  active?: boolean;
  disabled?: boolean;
}> = ({ active, disabled }) => ({
  button: {
    position: "relative",
    height: 80,
    padding: "0 12px",
    width: "100%",
    fontFamily: "inherit",
    fontSize: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.White[100],
    border: "solid",
    borderWidth: 2,
    borderColor: active
      ? activeColor
      : disabled
      ? theme.colors.Grey[20]
      : defaultColor,
    borderRadius: 10,
    cursor: disabled ? "not-allowed" : "pointer",
    boxShadow: theme.boxShadow.default,
    transition: "border-color border-width 0.3s",
    "&:hover + !disabled": {
      borderColor: activeColor,
      borderWidth: 2,
    },
    "& span": {
      cursor: disabled ? "not-allowed" : "pointer",
    },
  },
  buttonContainer: {
    position: "relative",
  },
  clearFilter: {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: theme.colors.Blue[100],
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 10,
  },
});

const SelectInsuranceType: FC<SelectInsuranceTypeProps> = ({ filter }) => {
  const { filters, setFilterValue } = filter;

  const itemStyles = (active?: boolean, disabled?: boolean) => {
    return useCSSRulesWithTheme(getItemStyles, { active, disabled });
  };

  const screenClass = useScreenClass();
  const avoidYPaddings = useMemo(() => {
    return (
      screenClass === "xl" || screenClass === "xxl" || screenClass === "xxxl"
    );
  }, [screenClass]);

  return (
    <BoxContainer backgroundColor={theme.colors.LightGrey[100]}>
      <Row align="center" justify="between" noMargins>
        {filters[0].options.map((option, index) => (
          <Column
            key={`insurance-type-${index}`}
            useYPaddings={!avoidYPaddings}
          >
            <div css={() => itemStyles().buttonContainer}>
              {filters[0].value[0] === option.value && (
                <div
                  css={() => itemStyles().clearFilter}
                  onClick={() => setFilterValue(filter.filters[0].name, [])}
                >
                  <Icon
                    name={IconNames.Close}
                    size={IconSizes.Small}
                    color={ColorNames.WHITE}
                  />
                </div>
              )}
              <button
                css={() =>
                  itemStyles(
                    filters[0].value[0] === option.value,
                    option.disabled
                  ).button
                }
                onClick={() =>
                  !option.disabled &&
                  setFilterValue(filter.filters[0].name, [option.value])
                }
              >
                <Tooltip
                  id={`tooltip-insurance-${option.iconName}`}
                  text={option.label}
                >
                  <Icon
                    name={option.iconName!}
                    size={IconSizes.Large}
                    disabled={option.disabled}
                  />
                </Tooltip>
              </button>
            </div>
          </Column>
        ))}
      </Row>
    </BoxContainer>
  );
};

export default SelectInsuranceType;

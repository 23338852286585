/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import SelectInput, {
  SelectInputOptionInterface,
} from "../../Input/SelectInputs/SelectInput";
import { useFlags } from "../../../hooks/useFlags";
import { useNavigate } from "react-router-dom";
import Button from "../../Button";

interface BreadcrumbSelectOption extends SelectInputOptionInterface {
  value: string;
  label: string;
  flag?: string;
}

export interface BreadcrumbItem {
  label: string;
  value: string;
  selectOptions?: BreadcrumbSelectOption[];
  active?: boolean;
}

const Breadcrumb: FC<BreadcrumbItem> = ({
  label,
  value,
  selectOptions,
  active,
}) => {
  const { isFlagEnabled, flagsLoaded } = useFlags();
  const navigate = useNavigate();

  const selectOptionsFiltered = useMemo(() => {
    return (
      selectOptions?.filter((option) => {
        if (option.flag) {
          return isFlagEnabled(option.flag);
        }
        return true;
      }) || []
    );
  }, [selectOptions, isFlagEnabled, flagsLoaded]);

  return (
    <div>
      {selectOptions ? (
        <SelectInput
          inputName={label}
          placeholder={label}
          options={selectOptionsFiltered}
          onSelect={(e) => {
            const selectedValue = e.value;
            const selectedOption = selectOptions?.find(
              (option) => option.value === selectedValue
            );
            if (selectedOption) {
              navigate(selectedOption.value);
            }
          }}
          value={value}
          optionDisplay={active ? "primary" : "default"}
          weight={active ? "bold" : "regular"}
          textVariant="textLarge"
        />
      ) : (
        <Button
          text={label}
          onClick={() => navigate(value)}
          width="auto"
          selected={active}
        />
      )}
    </div>
  );
};

export default Breadcrumb;

import React, { ReactNode } from "react";
import { useDropzone, DropzoneOptions, FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

interface MediaInputProps {
  onFilesAccepted: (files: File[]) => void;
  children: (props: { open: () => void }) => ReactNode;
}

const MediaInput: React.FC<MediaInputProps> = ({
  onFilesAccepted,
  children,
}) => {
  const oneMb = 1048576;
  const maxSizeImage = oneMb * 5; // 5MB for images in bytes
  const maxSizeOther = oneMb * 16; // 16MB for other files in bytes

  const accept = {
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/webp": [".webp"],
    "audio/mpeg": [".mp3"],
    "audio/ogg": [".ogg"],
    "audio/amr": [".amr"],
    "audio/3gpp": [".3gp"],
    "audio/aac": [".aac"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "video/mp4": [".mp4"],
    "video/h264": [],
    "image/heic": [".heic"],
    "text/vcard": [".vcf"],
  };

  const acceptedFileTypes = Object.keys(accept);

  const validator = (file: File) => {
    if (!acceptedFileTypes.includes(file.type)) {
      return {
        code: "file-invalid-type",
        message: `Arquivo ${file.name} é do tipo inválido. Por favor, envie um arquivo válido.`,
      };
    }

    const isImage = file.type.startsWith("image/");
    const maxSize = isImage ? maxSizeImage : maxSizeOther;

    if (file.size > maxSize) {
      return {
        code: "file-too-large",
        message: `Arquivo ${file.name} é maior que o limite de ${
          maxSize / (1024 * 1024)
        } MB`,
      };
    }

    return null;
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach(({ file, errors }) => {
      const error = errors[0];
      if (error) {
        if (error.code === "file-invalid-type") {
          toast.error(
            `Arquivo ${file.name} é do tipo inválido. Por favor, envie um arquivo válido.`
          );
        } else if (error.code === "file-too-large") {
          toast.error(`Arquivo ${file.name} é maior que o limite permitido.`);
        } else {
          toast.error(error.message);
        }
      }
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFilesAccepted(acceptedFiles);
    },
    onDropRejected,
    noClick: true, // Prevent clicking the dropzone area from opening the file dialog
    noKeyboard: true, // Prevent pressing Enter/Space to open the file dialog
    validator,
    accept,
  } as DropzoneOptions);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children({ open })}
    </div>
  );
};

export default MediaInput;

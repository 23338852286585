/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import QuotationCard from "../../../components/QuotationCard/index";
import Row from "../../../../../../components/Grid/Row";
import Column from "../../../../../../components/Grid/Column";
import { useFlags } from "../../../../../../hooks/useFlags";
import Typography from "../../../../../../components/Typography";
import useDocumentColor from "../../../../../../hooks/useDocumentColor";

const ValidQuotations: FC = () => {
  const {
    documentData: { selectedSimulationResults, validSimulationResults, stage },
  } = useDocumentDetails();

  const styles = {
    row: {
      marginBottom: 0,
    },
    col: {
      marginBottom: 20,
    },
  };

  const documentColor = useDocumentColor(stage!);

  const { isFlagEnabled } = useFlags();

  const resultsToShow = useMemo(() => {
    return isFlagEnabled("admin-only")
      ? validSimulationResults
      : selectedSimulationResults;
  }, [validSimulationResults, selectedSimulationResults, isFlagEnabled]);

  const referenceLocation = useMemo(() => {
    return resultsToShow?.find((result) => !!result?.referenceLocation)
      ?.referenceLocation;
  }, [resultsToShow]);

  if (!resultsToShow?.length) {
    if (!isFlagEnabled("admin-only")) {
      return (
        <Typography variant="textMedium" color={documentColor} uppercase>
          Suas informações foram registradas! Entraremos em contato em breve
        </Typography>
      );
    }
    return (
      <Typography variant="textMedium" color={documentColor} uppercase>
        Não há resultados automáticos para a cotação
      </Typography>
    );
  }

  return (
    <>
      <Row css={styles.row}>
        {resultsToShow.map((result) => (
          <Column
            xs={12}
            xl={6}
            xxl={4}
            key={`quotationCard-${result?._id}`}
            css={styles.col}
          >
            <QuotationCard result={result!} />
          </Column>
        ))}
      </Row>
      <Row>
        <Column>
          <Typography variant="textMedium" component="p" align="left">
            *Sujeito à disponibilidade
          </Typography>
          {referenceLocation && (
            <Typography variant="textMedium" component="p" align="left">
              **Valores calculados usando como referência o município de{" "}
              {referenceLocation}.
            </Typography>
          )}
        </Column>
      </Row>
    </>
  );
};

export default ValidQuotations;

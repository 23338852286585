import { FC } from "react";
import BooleanInput from "../../../../components/Input/BooleanInput";
import { useForm } from "../../hooks/useForm";
import { FormField } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";

const FormInputBoolean: FC<FormField & { groupPath: string }> = ({
  name,
  label,
  groupPath,
  disabled,
}) => {
  const [value] = useFieldValue(name, groupPath);
  const { handleChange } = useForm();
  const handleBooleanChange = (e: any) => {
    handleChange(e, groupPath);
  };
  return (
    <BooleanInput
      value={!!value}
      name={name}
      label={label}
      handleChange={handleBooleanChange}
      disabled={disabled}
    />
  );
};

export default FormInputBoolean;

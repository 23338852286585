export interface LocalStorageOptions {
  documentsTableSelectedColumns?: string[];
  contactsTableSelectedColumns?: string[];
}

const getAllOptions = () => {
  try {
    const opts = JSON.parse(
      window.localStorage.getItem("localStorageOptions") || ""
    );
    return opts || {};
  } catch (e) {
    return {};
  }
};

export const getLocalStorageOptions = (id: string): LocalStorageOptions => {
  const opts = getAllOptions();
  return opts[id] || {};
};

export const setLocalStorageOptions = (
  id: string,
  options: LocalStorageOptions
) => {
  const opts = getAllOptions();
  window.localStorage.setItem(
    "localStorageOptions",
    JSON.stringify({
      ...opts,
      [id]: {
        ...(opts[id] || {}),
        ...options,
      },
    })
  );
};

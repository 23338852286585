import Table from "../../../../components/Table";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToContactDetailsLocation } from "../../../../Locations";
import { formatPhone } from "../../../../utils/formatNumber";
import { useMemo } from "react";
import { contactTagLabels } from "../../../../shared/contacts/contactTags";
import ScrollContainer from "../../../../components/ScrollContainer";
import { orgDepartmentRoleLabels } from "../../../../shared/org/departmentRoles";

type OrgMembersTableData = {
  _id: string;
  name: string;
  departmentRole: string;
  tags: string;
  email: string;
  mobilePhone: string;
};

const OrgMembersData = () => {
  const { contactData } = useContactDetails();

  const tableContactData: OrgMembersTableData[] = useMemo(() => {
    const members = [...(contactData.members || [])];
    return members.map((members) => ({
      _id: members!._id!,
      name: members!.name! || "Sem nome",
      email: members!.email! || "Sem email",
      mobilePhone: members!.mobilePhone
        ? formatPhone(members.mobilePhone)
        : "Sem documento",
      tags: contactTagLabels[members.tags![0]],
      departmentRole: orgDepartmentRoleLabels[members.departmentRole!],
    }));
  }, [contactData.members]);

  return (
    <ScrollContainer>
      <Table<OrgMembersTableData>
        data={tableContactData}
        withBorder
        color={ColorNames.GREEN}
        rowLink={(org: OrgMembersTableData) =>
          ToContactDetailsLocation(org._id!)
        }
        columns={[
          {
            label: "Nome",
            key: "name",
          },
          {
            label: "Telefone",
            key: "mobilePhone",
          },
          {
            label: "E-mail",
            key: "email",
          },
          {
            label: "Permissão",
            key: "tags",
          },
          {
            label: "Cargo",
            key: "departmentRole",
          },
        ]}
      />
    </ScrollContainer>
  );
};

export default OrgMembersData;

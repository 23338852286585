import { FC } from "react";

const ChevronRight: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_816)">
        <path
          d="M22 16L12 26L10.6 24.6L19.2 16L10.6 7.4L12 6L22 16Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_816">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronRight;

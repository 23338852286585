import { FC, useMemo } from "react";
import Sidebar from "../../../components/Sidebar";
import Summary from "./components/Summary";
import AllianzSettings from "./settings/Allianz/Table";

const CultureFlow: FC = () => {
  const menuItems = useMemo(() => {
    return [
      {
        title: "Geral",
        Component: Summary,
      },
      {
        title: "Allianz",
        Component: AllianzSettings,
      },
    ];
  }, []);
  return <Sidebar menuItems={menuItems} />;
};

export default CultureFlow;

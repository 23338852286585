import { AnimalAges } from '../../graphql/generated/types';
import { TModelOptionLabels } from '../types';

export const animalAgeLabels: TModelOptionLabels<AnimalAges> = {
  [AnimalAges.UpTo6Months]: 'Até 6 meses',
  [AnimalAges.From6To12Months]: 'De 6 a 12 meses',
  [AnimalAges.From12To24Months]: 'De 12 a 24 meses',
  [AnimalAges.From24To36Months]: 'De 24 a 36 meses',
  [AnimalAges.From36To48Months]: 'De 36 a 48 meses',
  [AnimalAges.From48To60Months]: 'De 48 a 60 meses',
  [AnimalAges.From60To72Months]: 'De 60 a 72 meses',
  [AnimalAges.From72To84Months]: 'De 72 a 84 meses',
  [AnimalAges.From84To96Months]: 'De 84 a 96 meses',
  [AnimalAges.Over96Months]: 'Mais de 96 meses',
};

/** @jsxImportSource @emotion/react */

import { FC, MouseEventHandler, useMemo } from "react";
import { ColorNames, getThemeColorFromAlias, theme } from "../../theme";
import { IconSizes } from "./styles/iconSizes";
import { IconNames, IconByName } from "./styles/iconNames";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Tooltip from "../Tooltip";

interface IconProps {
  name: IconNames;
  size?: IconSizes;
  color?: ColorNames;
  hoverColor?: ColorNames;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  disabled?: boolean;
  tooltipText?: string;
  className?: string;
}

const getCSSRules: CSSRulesResolver<{
  onClick?: MouseEventHandler<HTMLSpanElement>;
  size: IconSizes;
  themeColor: string;
  hoverThemeColor: string;
}> = ({ size, themeColor, hoverThemeColor, onClick }) => ({
  cursor: onClick ? "pointer" : "auto",
  display: "inline-flex",
  "& > svg": {
    width: size,
    height: size,
  },
  "& > span > svg": {
    width: size,
    height: size,
    strokeWidth: 3,
  },
  "& path": {
    fill: themeColor,
  },
  "&:hover path": {
    fill: hoverThemeColor,
  },
});

const Icon: FC<IconProps> = ({
  name,
  size = IconSizes.Medium,
  color = ColorNames.BLACK,
  hoverColor,
  onClick,
  disabled = false,
  tooltipText,
  className,
}) => {
  const themeColor = disabled
    ? getThemeColorFromAlias(ColorNames.GREY, theme.colors)!
    : getThemeColorFromAlias(color, theme.colors)!;
  const hoverThemeColor = disabled
    ? getThemeColorFromAlias(ColorNames.GREY, theme.colors)!
    : getThemeColorFromAlias(hoverColor || color, theme.colors)!;

  const styles = useCSSRulesWithTheme(getCSSRules, {
    themeColor,
    hoverThemeColor,
    size,
    onClick,
  });

  const icon = useMemo(() => {
    return IconByName[name]({});
  }, [name]);

  const handleIconClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (!onClick) return;
    e.stopPropagation();
    onClick(e);
  };

  if (tooltipText)
    return (
      <Tooltip id={tooltipText} text={tooltipText}>
        <span
          css={styles}
          className={className}
          onClick={handleIconClick}
          data-icon={name}
        >
          {icon}
        </span>
      </Tooltip>
    );

  return (
    <span
      css={styles}
      className={className}
      onClick={handleIconClick}
      data-icon={name}
    >
      {icon}
    </span>
  );
};

export default Icon;

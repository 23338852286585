import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import VariableFieldColumn from "../utils/VariableFieldColumn";
import GeoLocationInput from "../../../../components/Input/GeoLocationInput";

interface GeoLocationFieldProps {
  field: FormFieldV2;
}

const GeoLocationField: FC<GeoLocationFieldProps> = ({ field }) => {
  const { label, value, variant } = field;

  const { handleChange } = useFormField(field);

  return (
    <VariableFieldColumn variant={variant}>
      <GeoLocationInput
        value={value}
        label={label}
        handleChange={handleChange}
      />
    </VariableFieldColumn>
  );
};

export default GeoLocationField;

import { FormState } from "../context/form";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  EditedFields,
  FormErrors,
  upsertForm,
  uploadFile,
  FormVisibility,
} from "../services/form";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import {
  composeFieldPath,
  deepCopy,
  filterRelevantPaths,
  findGroupObj,
} from "../components/inputs/utils";
import { useAuth } from "../../../hooks/useAuth";
import { FormField, FormObj, FormSection } from "../models/formInterfaces";
import { useApolloClient } from "@apollo/client";
import {
  FieldUpdateResponse,
  GetFormResponseDocument,
  useSubmitFormResponseMutation,
  useUpsertFormFieldMutation,
} from "../../../graphql/generated/types";

const useFormState = (formId: string, docId: string) => {
  const apolloClient = useApolloClient();
  const [upsertFormFieldMutation] = useUpsertFormFieldMutation();
  const [submitFormResponseMutation] = useSubmitFormResponseMutation();
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [formState, setFormState] = useState<FormState>({});
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingField, setEditingField] = useState({});
  const authState = useAuth();
  const editingFieldRef = useRef() as {
    current: { [groupName: string]: string };
  };
  editingFieldRef.current = editingField;
  const formStateRef = useRef() as {
    current: FormState;
  };
  formStateRef.current = formState;

  const [errors, setErrors] = useState<FormErrors>({});
  const [visibility, setVisibility] = useState<FormVisibility>({});
  const [updatedFields, setUpdatedFields] = useState<EditedFields>({});
  const [editedFields, setEditedFields] = useState<EditedFields>({});
  const [fieldsToValidate, setFieldsToValidate] = useState<EditedFields>({});
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const { errorHandler } = useErrorHandler();

  const [formData, setFormData] = useState<FormObj>();
  const [error, setError] = useState<unknown>(null);
  const [validSections, setValidSections] = useState<{
    [sectionId: FormSection["id"]]: boolean;
  }>({});

  useEffect(() => {
    const toValidate = Object.keys(editedFields)
      .filter(
        (fieldPath) =>
          !(fieldPath in editingFieldRef) && fieldPath in updatedFields
      )
      .reduce((acc, fieldPath) => ({ ...acc, [fieldPath]: true }), {});
    setFieldsToValidate(toValidate);
  }, [editingField, editedFields, updatedFields]);

  useEffect(() => {
    if (formData) {
      setValidSections(
        formData.sections.reduce((acc, section) => {
          return {
            ...acc,
            [section.id]: Object.values(
              section.id === "main"
                ? errors
                : filterRelevantPaths(errors || {}, section.id)
            ).every((error) => !error),
          };
        }, validSections)
      );
    }
  }, [errors]);

  const getFormResponse = async () => {
    try {
      const { data } = await apolloClient.query({
        query: GetFormResponseDocument,
        variables: { formResponseId: docId },
      });
      if (data && data.getFormResponse) {
        const dataCopy = deepCopy(data.getFormResponse);
        setFormData(dataCopy.formData);
        updateFormState(dataCopy.formState!);
        setErrors(dataCopy.formData.errors!);
        setVisibility(dataCopy.formData.visibility!);
      }
    } catch (e) {
      setError(e);
    }
  };
  useEffect(() => {
    if (authState.loaded && !isFormLoaded) {
      getFormResponse();
      setIsFormLoaded(true);
    }
  }, [authState.loaded, isFormLoaded]);

  const upsert = async (
    formId: string,
    docId: string,
    formState: FormState
  ) => {
    try {
      const res = await upsertForm(authState, formId, docId, formState);
      return res;
    } catch (e) {
      errorHandler(new Error("Ooops. Erro ao atualizar o formulário"), e);
    }
  };

  const handleSubmit = async (docId: string) => {
    try {
      const { data } = await submitFormResponseMutation({
        variables: {
          formResponseId: docId,
        },
      });
      return data?.submitFormResponse!;
    } catch (e) {
      errorHandler(new Error("Ooops. Erro ao enviar o formulário"), e);
    }
  };

  const getAllFields = (formData: FormObj | FormField): FormField[] => {
    const fields: FormField[] = [];
    if ("sections" in formData) {
      formData.sections.map((section) => {
        fields.push(...section.fields);
        section.fields.forEach((field) => {
          if ("fields" in field) {
            fields.push(...getAllFields(field));
          }
        });
      });
    }
    if ("fields" in formData) {
      fields.push(...formData.fields);
      formData.fields.forEach((field) => {
        if ("fields" in field) {
          fields.push(...getAllFields(field));
        }
      });
    }
    return fields;
  };

  const handleStateUpdateRes = (updateRes: FieldUpdateResponse[]) => {
    setFormState((prev) => {
      const newState = { ...prev };
      updateRes.forEach(({ fieldPath, ...fieldUpdateRes }) => {
        if ("value" in fieldUpdateRes) {
          const paths = fieldPath!.split(".");
          const fieldName = paths.pop()!;
          const group = findGroupObj(newState, paths.join("."));
          group[fieldName] = fieldUpdateRes.value;
        }
      });
      return newState;
    });
  };

  const handleErrorsUpdateRes = (updateRes: FieldUpdateResponse[]) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      updateRes.forEach(({ fieldPath, error }) => {
        if (error) {
          newErrors[fieldPath!] = error;
        } else {
          delete newErrors[fieldPath!];
        }
      });
      return newErrors;
    });
  };

  const handleVisibilityUpdateRes = (updateRes: FieldUpdateResponse[]) => {
    setVisibility((prevVisibility) => {
      const newVisibility = { ...prevVisibility };
      updateRes.forEach(({ fieldPath, ...res }) => {
        if ("invisible" in res) {
          newVisibility[fieldPath!] = !res.invisible;
        }
      });
      return newVisibility;
    });
  };

  const handleFormDataUpdateRes = (updateRes: FieldUpdateResponse[]) => {
    setFormData((prev) => {
      const newData = prev;
      const allFields = getAllFields(prev!);
      updateRes.forEach(({ fieldPath, ...fieldUpdateRes }) => {
        const fieldsToUpdate = allFields.filter(
          ({ name, group }) => composeFieldPath(group, name) === fieldPath
        );
        fieldsToUpdate.forEach((field) => {
          Object.assign(field, fieldUpdateRes);
        });
      });
      return newData;
    });
  };

  const syncUpdatedFieldsList = (updateRes: FieldUpdateResponse[]) => {
    const newUpdatedFields: EditedFields = {};
    updateRes.forEach(({ fieldPath }) => {
      newUpdatedFields[fieldPath!] = true;
    });
    setUpdatedFields((prev) => ({ ...prev, ...newUpdatedFields }));
  };

  const handleUpdateRes = (
    updateRes: FieldUpdateResponse[],
    groupPath: string,
    fieldName: string
  ) => {
    handleStateUpdateRes(updateRes);
    handleErrorsUpdateRes(updateRes);
    handleVisibilityUpdateRes(updateRes);
    handleFormDataUpdateRes(updateRes);
    syncUpdatedFieldsList(updateRes);
    if (editedFields[composeFieldPath(groupPath, fieldName)]) {
      setFieldsToValidate({
        ...fieldsToValidate,
        [composeFieldPath(groupPath, fieldName)]: true,
      });
    }
  };

  const updateField = async (
    groupPath: string,
    fieldName: string,
    value: any
  ) => {
    try {
      const { data } = await upsertFormFieldMutation({
        variables: {
          formResponseId: docId,
          fieldPath: composeFieldPath(groupPath, fieldName),
          value,
        },
      });
      handleUpdateRes(deepCopy(data?.upsertFormField!), groupPath, fieldName);
    } catch (e) {
      errorHandler(new Error("Ooops. Erro ao atualizar o formulário"), e);
      return;
    }
  };

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    groupPath: string
  ) {
    let nextState = { ...formState };
    const groupObj = findGroupObj(nextState, groupPath);
    groupObj[e.target.name] = e.target.value;
    setFormState({ ...nextState });
    updateField(groupPath, e.target.name, e.target.value);
  }

  const updateFormState = (initialState: FormState, errors?: FormErrors) => {
    setIsFormUpdated(true);
    const nextState = { ...initialState };
    Object.entries(editingFieldRef.current).forEach(
      ([groupPath, fieldName]) => {
        const fieldGroup = findGroupObj(nextState, groupPath);
        const refGroup = findGroupObj(formStateRef.current, groupPath);
        fieldGroup[fieldName] = refGroup[fieldName];
      }
    );
    setFormState(nextState);
    if (errors) {
      setErrors(errors);
    }
    setFieldsToValidate({ ...editedFields });
  };

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    groupPath: string
  ) => {
    const doUpdate = async () => {
      const res = await uploadFile(
        authState,
        formId,
        docId,
        composeFieldPath(groupPath, e.target.name),
        e.target.files?.[0]
      );
      handleUpdateRes(res.data, groupPath, e.target.name);
    };
    doUpdate();
  };

  const handleFileDelete = (fieldName: string, groupPath: string) => {
    const doUpdate = async () => {
      const res = await uploadFile(
        authState,
        formId,
        docId,
        groupPath ? composeFieldPath(groupPath, fieldName) : fieldName,
        undefined
      );
      handleUpdateRes(res.data, groupPath, fieldName);
    };
    doUpdate();
  };

  const startEditing = (groupPath: string, fieldName: string) => {
    setIsEditing(true);
    setEditingField({ [groupPath]: fieldName });
  };

  const finishEditing = (groupPath: string, fieldName: string) => {
    setIsEditing(false);
    setEditedFields({
      ...editedFields,
      [composeFieldPath(groupPath, fieldName)]: true,
    });
  };

  const clearErrors = useCallback(
    (groupPath: string) => {
      const newErrors = deepCopy(errors);
      Object.keys(filterRelevantPaths(newErrors, groupPath)).forEach(
        (fieldPath) => {
          delete newErrors[fieldPath];
        }
      );
      setErrors(newErrors);
    },
    [errors]
  );

  return {
    upsert,
    formState,
    isEditing,
    startEditing,
    handleChange,
    handleSubmit,
    finishEditing,
    isFormUpdated,
    updateFormState,
    errors,
    fieldsToValidate,
    clickedSubmit,
    setClickedSubmit,
    handleFileUpload,
    handleFileDelete,
    formData,
    error,
    validSections,
    setError,
    clearErrors,
    visibility,
  };
};

export default useFormState;

import { useMemo } from "react";
import { useAuth } from "./useAuth";
import { useUIStore } from "./useUIStore";
import { useErrorHandler } from "./useErrorHandler";

export function useRootStore() {
  const authStore = useAuth();
  const uiStore = useUIStore();
  const errorHandler = useErrorHandler();

  if (
    authStore.bearerToken &&
    authStore.tokenPayload.user &&
    !authStore.user._id
  ) {
    authStore.loadCurrentUser();
  }

  const rootStore = useMemo(
    () => ({
      authStore,
      uiStore,
      errorHandler,
    }),
    [authStore, uiStore, errorHandler]
  );

  return rootStore;
}

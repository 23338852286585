import { AuthContextInterface } from "./../contexts/auth";
//TODO: get rid of querystring dependency
import qs, { ParsedUrlQueryInput } from "querystring";

export const API_SERVICE_BASE_PATH =
  process.env.REACT_APP_API_SERVICE_BASE_PATH || "http://localhost:3000/";

type ServiceFetchParams = Parameters<typeof serviceFetchParameters>;

interface ServiceError extends Error {
  code?: number;
  payload?: unknown;
}

type ServiceFetchMethod = "get" | "post" | "put" | "delete";

function parseServiceFetchError(
  res: Response,
  params: ServiceFetchParams
): Promise<never> {
  const [auth, path] = params;

  if (auth && res.status === 401) {
    auth.invalidate();
  }

  if (!res.ok) {
    return res.json().then((err) => {
      return Promise.reject(err);
      // throw new Error(`Invalid response status ${res.status} at: "${path}"`);
    });
  }

  return res
    .json()
    .catch((): never => {
      throw new Error(`Invalid response status ${res.status} at: "${path}"`);
    })
    .then((body: any): never => {
      const err: ServiceError = new Error(
        body.error
          ? body.error.message
            ? String(body.error.message)
            : String(body.error)
          : String(body.message) || JSON.stringify(body)
      );

      err.code = body.error.code;
      err.payload = body.payload || "";
      throw err;
    });
}

function serviceFetchParameters(
  auth: AuthContextInterface | null,
  path: string,
  query: ParsedUrlQueryInput | null = null,
  method: ServiceFetchMethod = "get",
  body?: unknown,
  file?: File,
  headers?: object
): [string, RequestInit] {
  const fetchOpts: RequestInit & { headers: Record<string, string> } = {
    method,
    headers: {},
  };

  if (auth) {
    fetchOpts.headers["Authorization"] = `Bearer ${auth.bearerToken}`;
  }

  // Extend our headers if needed
  if (headers) {
    fetchOpts.headers = { ...fetchOpts.headers, ...headers };
  }

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    if (body) {
      formData.append("payload", JSON.stringify(body));
    }
    fetchOpts.body = formData;
  } else if (body) {
    fetchOpts.headers["Content-Type"] = "application/json";
    fetchOpts.body = JSON.stringify(body);
  }

  const url = query ? `${path}?${qs.stringify(query)}` : path;

  return [API_SERVICE_BASE_PATH + url, fetchOpts];
}

export default function serviceFetch<T>(
  ...params: ServiceFetchParams
): Promise<T> {
  return fetch(...serviceFetchParameters(...params)).then(
    (res: Response): Promise<T> => {
      if (res.ok) {
        return res.json();
      }

      return parseServiceFetchError(res, params);
    }
  );
}

const getFilenameFromResponse = (response: any) => {
  const contentDisposition = response.headers.get("Content-Disposition");
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition!);
  let filename = "";
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, "");
  }
  return filename;
};

export type FileData = [Blob, string];

export function serviceFetchFile(
  ...params: ServiceFetchParams
): Promise<FileData> {
  return fetch(...serviceFetchParameters(...params))
    .then(async (res: Response): Promise<FileData> => {
      if (res.ok) {
        const blob = await res.blob();
        return [blob, getFilenameFromResponse(res)];
      }

      return parseServiceFetchError(res, params);
    })
    .then((blob: FileData) => {
      return blob;
    });
}

import { FC } from "react";

const Tokyo: FC = () => {
  return (
    <svg
      width="256"
      height="128"
      viewBox="0 0 256 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.9961 40.0256C99.9961 40.0256 102.537 37.2812 109.59 44.2715C116.643 51.2619 122.451 58.3557 122.451 58.3557C122.451 58.3557 113.894 25.6307 119.028 27.909C122.901 29.6278 126.214 32.9083 127.607 34.4161C127.592 34.6961 127.585 34.9781 127.585 35.2618C127.585 43.9609 134.638 51.003 143.35 51.003C152.062 51.003 159.115 43.9609 159.115 35.2618C159.115 26.5628 152.062 19.5207 143.35 19.5207C138.7 19.5207 134.523 21.5267 131.638 24.7201C129.675 22.4755 122.486 14.8322 117.732 17.4494C117.732 17.4494 108.138 24.2326 109.642 31.7925C109.642 31.7925 102.9 25.2164 100.826 31.2747C98.907 36.8152 99.9961 40.0256 99.9961 40.0256Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M154.448 50.9516C151.752 48.5179 144.906 39.1457 142.884 33.657C142.813 33.4656 142.741 33.2719 142.669 33.0763C141.255 29.2522 139.572 24.7032 139.72 21.7476C139.772 21.1262 139.876 20.2977 140.291 20.0388C140.93 19.6951 141.056 19.6774 141.377 19.6323L141.432 19.6246C141.466 19.6246 141.495 19.6189 141.524 19.6131C141.581 19.6016 141.639 19.5901 141.743 19.6246C138.216 17.8123 133.964 16.3107 129.815 16C129.037 17.1392 128.467 19.4175 129.66 23.767C131.941 32.1554 136.972 48.1037 148.225 59.2882C148.225 59.2882 150.611 62.1879 153.826 61.929C155.693 58.7186 156.989 55.1976 157.612 51.4176C156.574 51.7801 155.434 51.8319 154.448 50.9516Z"
        fill="black"
      />
      <path
        d="M127.482 48.8803C131.06 56.2331 136.816 63.3788 139.928 66.8998C141.069 68.1943 142.624 69.4371 144.18 70.1102C144.751 70.3691 145.321 70.5762 145.891 70.7833C142.935 73.0098 139.565 74.7186 135.935 75.7542C135.312 75.4953 134.638 75.1846 133.912 74.7186C131.009 72.8023 127.685 68.5079 124.472 64.3576L124.463 64.3452C124.349 64.1983 124.236 64.0516 124.122 63.9052C124.066 63.8332 124.011 63.7613 123.955 63.6895C115.606 52.8674 109.694 34.6408 108.449 28.9967C107.775 25.9417 108.086 23.7669 108.501 22.4206C111.509 20.0388 114.983 18.2265 118.769 17.0873C115.568 24.7492 123.72 41.2618 127.269 48.4506L127.482 48.8803Z"
        fill="black"
      />
      <path
        d="M102.122 38.4207C103.834 45.2039 107.931 53.903 112.339 61.7218C117.628 71.094 122.296 75.1846 124.888 76.7898C123.696 76.6863 122.503 76.5309 121.31 76.272L116.85 75.0293C114.672 74.2526 112.65 73.217 110.731 71.9743C102.537 63.9484 98.5959 53.3852 96.8327 46.7055V46.3431C96.8327 40.5955 98.4403 35.2621 101.189 30.6537C101.189 32.5178 101.344 35.3139 102.122 38.4207Z"
        fill="black"
      />
      <path
        d="M82.6233 86.6795H82.6751V86.7313H82.8307L82.3121 88.6472H82.1566V88.5954C81.9491 88.1293 81.4305 88.0776 80.9638 88.0776H79.7192V94.7572C79.7192 95.5339 79.7192 96.0517 80.4452 96.4659H80.4971V96.6213H77.4374V96.4142H77.4892C78.0078 96.2071 78.0597 95.5339 78.0597 94.9125V88.0776H76.8669C76.1927 88.0776 75.5704 88.2847 75.2074 88.6472H75L75.5704 86.7313H75.6742C75.7208 86.7313 75.7674 86.7417 75.8187 86.7532C75.8816 86.7674 75.9515 86.783 76.0372 86.783H82.001C82.2084 86.783 82.4677 86.783 82.6233 86.6795Z"
        fill="black"
      />
      <path
        d="M81.2289 107.319C79.8926 106.703 78.4227 106.026 78.4227 104.337C78.4227 102.421 80.4452 101.696 82.3121 101.799C83.0381 101.799 83.816 101.903 84.4902 102.11H84.5421V104.026L84.3865 103.871C83.8679 103.353 82.9863 102.99 82.1566 102.99C81.3268 102.99 80.3415 103.301 80.3415 104.233C80.3415 105.109 81.3677 105.574 82.5014 106.089L82.5196 106.097C83.8679 106.667 85.3718 107.392 85.3718 108.893C85.3718 110.602 83.5567 112 81.3787 112C80.4452 112 79.5117 111.845 78.7339 111.534H78.682L78.1634 109.411L78.3708 109.566C79.2006 110.291 80.3933 110.757 81.4824 110.757C82.3121 110.757 83.453 110.239 83.453 109.307C83.453 108.371 82.4655 107.903 81.4308 107.414C81.3789 107.389 81.3269 107.365 81.2749 107.34L81.2289 107.319Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.551 106.822C147.551 105.476 148.069 104.233 149.055 103.249C150.092 102.214 151.544 101.644 153.1 101.696C154.604 101.696 155.952 102.265 156.937 103.249C157.871 104.181 158.389 105.424 158.389 106.77C158.389 109.722 156.004 112 152.944 112C149.885 112 147.551 109.773 147.551 106.822ZM152.996 102.887C150.87 102.887 149.366 104.44 149.366 106.667C149.366 108.634 150.766 110.757 153.048 110.705C155.122 110.705 156.574 109.152 156.574 106.977C156.574 105.01 155.33 102.887 152.996 102.887Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.972 101.955H140.55C143.973 101.955 146.047 103.819 146.047 106.77C146.047 109.67 143.765 111.741 140.498 111.741H136.92V111.586L136.972 111.534C137.594 111.068 137.594 110.498 137.594 109.773V103.819C137.594 103.771 137.594 103.723 137.594 103.674C137.598 103.032 137.602 102.306 137.024 102.162H136.972V101.955ZM144.284 106.926C144.284 104.44 142.987 103.146 140.498 103.146C140.083 103.146 139.72 103.197 139.253 103.301V110.395C139.565 110.447 139.876 110.498 140.239 110.498C142.209 110.498 144.284 109.566 144.284 106.926Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.562 101.955H173.792L176.851 109.515L176.955 109.722C176.979 109.778 177.003 109.834 177.026 109.889C177.309 110.556 177.581 111.199 178.252 111.534H178.303V111.741H175.14V111.534H175.192C175.503 111.43 175.659 111.327 175.659 111.12C175.659 111.005 175.602 110.863 175.511 110.631C175.478 110.547 175.44 110.452 175.399 110.343L175.347 110.188C175.347 110.188 174.621 108.272 174.569 108.168H171.043C170.939 108.479 170.317 110.188 170.317 110.188C170.289 110.285 170.261 110.375 170.236 110.458C170.165 110.685 170.11 110.865 170.11 111.016C170.11 111.223 170.265 111.43 170.576 111.534H170.628V111.741H167.05C166.22 111.741 165.753 111.534 165.287 110.964L163.524 108.738L162.123 106.563H162.279C163.16 106.408 164.716 105.942 164.716 104.544C164.716 104.078 164.561 103.715 164.25 103.456C163.938 103.146 163.42 102.99 162.901 102.99C162.655 102.99 162.441 103.023 162.183 103.062C162.115 103.072 162.044 103.083 161.968 103.094V109.981C161.968 110.602 162.02 111.275 162.59 111.482H162.642V111.689H159.686V111.482H159.738C160.308 111.275 160.308 110.395 160.308 109.773V103.871C160.308 103.823 160.308 103.775 160.309 103.726C160.312 103.083 160.316 102.354 159.738 102.162H159.686V101.955H163.264C165.131 101.955 166.376 102.887 166.376 104.388C166.376 105.735 165.287 106.718 164.146 107.029C164.509 107.495 166.168 109.566 166.168 109.566C166.687 110.188 167.413 110.861 168.191 111.379C168.702 111.007 168.922 110.47 169.148 109.915C169.175 109.851 169.201 109.786 169.228 109.722L169.332 109.515L171.769 103.405L171.821 103.353C171.835 103.312 171.849 103.27 171.864 103.228C171.961 102.949 172.067 102.646 171.977 102.421C171.925 102.317 171.769 102.214 171.614 102.162H171.562V101.955ZM173.559 105.064C173.291 104.356 173.033 103.675 172.962 103.508C172.858 103.871 171.769 106.667 171.665 106.874H174.258C174.202 106.762 173.874 105.895 173.559 105.064Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.245 101.955H131.423L134.482 109.515L134.586 109.722C134.61 109.778 134.634 109.834 134.657 109.889C134.94 110.556 135.213 111.199 135.883 111.534H135.934V111.741H132.771V111.534H132.823C133.134 111.43 133.29 111.327 133.29 111.12C133.29 111.005 133.233 110.863 133.142 110.631C133.109 110.547 133.071 110.452 133.03 110.343L132.978 110.188C132.978 110.188 132.252 108.272 132.201 108.168H128.674C128.57 108.479 127.948 110.188 127.948 110.188C127.92 110.285 127.893 110.375 127.867 110.458C127.796 110.685 127.741 110.865 127.741 111.016C127.741 111.223 127.896 111.43 128.207 111.534H128.259V111.741H124.681C123.851 111.741 123.385 111.534 122.918 110.964L121.155 108.738L119.754 106.563H119.91C120.792 106.408 122.347 105.942 122.347 104.544C122.347 104.078 122.192 103.715 121.881 103.456C121.569 103.146 121.051 102.99 120.532 102.99C120.334 102.99 120.135 103.021 119.89 103.058C119.815 103.07 119.736 103.082 119.651 103.094V109.981C119.651 110.602 119.703 111.275 120.273 111.482H120.325V111.689H117.369V111.482H117.421C117.991 111.275 117.991 110.395 117.991 109.773V103.871C117.991 103.823 117.991 103.775 117.992 103.726C117.995 103.083 117.999 102.354 117.421 102.162H117.369V101.955H120.947C122.866 101.955 124.059 102.887 124.059 104.388C124.059 105.735 122.97 106.718 121.829 107.029C122.192 107.495 123.851 109.566 123.851 109.566C124.37 110.188 125.096 110.861 125.874 111.379C126.385 111.007 126.605 110.47 126.831 109.915C126.858 109.851 126.884 109.786 126.911 109.722L127.015 109.515L129.452 103.405L129.504 103.353C129.518 103.312 129.532 103.27 129.547 103.228C129.644 102.948 129.75 102.646 129.659 102.421C129.608 102.317 129.452 102.214 129.296 102.162H129.245V101.955ZM131.242 105.064C130.974 104.356 130.716 103.675 130.645 103.508C130.489 103.871 129.4 106.667 129.348 106.874H131.941C131.885 106.762 131.557 105.895 131.242 105.064Z"
        fill="black"
      />
      <path
        d="M112.39 101.955H115.243L115.295 102.162L115.191 102.214C114.568 102.472 114.568 103.353 114.568 103.974V109.877C114.568 110.498 114.568 111.379 115.191 111.586H115.243V111.793H112.909V110.964C112.235 111.689 111.042 112 110.16 112C109.071 112 108.086 111.638 107.464 110.964C106.686 110.084 106.686 109.152 106.686 108.116V103.871C106.686 103.827 106.686 103.782 106.686 103.737C106.69 103.129 106.694 102.354 106.115 102.162H106.064V101.955H109.02V102.162H108.968C108.397 102.369 108.397 103.249 108.397 103.87V107.961C108.397 109.825 109.123 110.705 110.627 110.705C111.509 110.705 112.287 110.343 112.702 109.722C113.013 109.359 113.013 108.997 113.013 108.531V103.871C113.013 103.827 113.013 103.782 113.013 103.737C113.017 103.129 113.021 102.354 112.442 102.162H112.39V101.955Z"
        fill="black"
      />
      <path
        d="M100.826 111.948C97.1957 111.948 94.8102 109.981 94.8102 106.874C94.8102 103.767 97.2475 101.696 100.981 101.644C101.707 101.644 102.485 101.748 103.263 101.903H103.315V103.767H103.159V103.715C102.485 102.99 101.137 102.887 100.618 102.887C98.2847 102.887 96.6771 104.44 96.6771 106.667C96.6771 108.945 98.4922 110.705 100.774 110.705C101.448 110.705 102.07 110.602 102.693 110.447V108.427C102.693 107.702 102.641 107.081 102.07 106.874H102.019V106.667H104.404V106.822C104.352 106.977 104.352 107.184 104.352 107.392V110.861C104.352 111.016 104.352 111.223 104.404 111.379L104.456 111.482L104.249 111.534C103.108 111.741 101.967 111.948 100.826 111.948Z"
        fill="black"
      />
      <path
        d="M92.3728 101.851H92.4246V101.903H92.5284V103.767H92.3728L92.3209 103.715C91.8542 103.197 91.2838 103.146 90.6615 103.146C90.1947 103.146 89.728 103.197 89.2613 103.249V105.786H90.9208C91.18 105.786 91.3875 105.786 91.543 105.683H91.6986V107.392H91.4912V107.34C91.2838 106.977 90.7133 106.977 90.2984 106.977H89.2613V110.291C89.4168 110.291 91.0244 110.447 91.0244 110.447C92.0098 110.55 92.9951 110.239 93.6693 109.618H93.8767L93.1507 111.689H86.9276V111.482H86.9794C87.6018 111.275 87.6018 110.395 87.6018 109.773V103.871C87.6018 103.197 87.6018 102.317 86.9794 102.11H86.9276V101.903H91.8542C92.0615 101.903 92.2173 101.903 92.3728 101.851Z"
        fill="black"
      />
      <path
        d="M179.496 86.6795H179.548V86.783H179.6V88.6472H179.444L179.392 88.5954C178.926 88.0776 178.355 88.0258 177.733 88.0258C177.266 88.0258 176.799 88.0776 176.333 88.1294V90.7184H177.992C178.252 90.7184 178.459 90.7184 178.615 90.6148H178.77V92.3235H178.615V92.2718C178.407 91.9093 177.837 91.9093 177.422 91.9093H176.385V95.2232C176.488 95.2232 178.148 95.3786 178.148 95.3786C179.133 95.4821 180.118 95.1714 180.793 94.5501H181L180.274 96.6213H174.051V96.4142H174.103C174.725 96.1553 174.725 95.275 174.725 94.6536V88.6989C174.725 88.0258 174.725 87.1455 174.103 86.9384H174.051V86.7313H178.977C179.185 86.7313 179.34 86.7313 179.496 86.6795Z"
        fill="black"
      />
      <path
        d="M169.487 86.783H172.132V86.7313H172.288V86.8866H172.236C171.51 87.3008 171.51 87.8186 171.51 88.647V96.9837L171.406 96.932C170.317 96.5695 169.85 96.0517 169.228 95.2232C169.228 95.2232 164.613 89.4238 164.353 89.0614V94.5501C164.353 95.3268 164.353 95.8446 165.079 96.2588H165.131V96.4142H162.383V96.2588H162.434C163.16 95.7928 163.16 95.2751 163.16 94.4467V88.5954C163.16 87.767 163.16 87.2491 162.434 86.8348H162.383V86.6795H164.664V86.7313C164.716 86.9384 164.768 86.9902 164.872 87.0937L165.079 87.4044C165.079 87.4044 170.006 93.618 170.265 93.9805V88.5954C170.265 87.8704 170.265 87.3526 169.539 86.9384H169.487V86.783Z"
        fill="black"
      />
      <path
        d="M157.197 86.783H160.049V86.7313H160.153V86.9384H160.101C159.53 87.1973 159.53 88.0776 159.53 88.6989V94.6536C159.478 95.275 159.479 96.1553 160.101 96.3624H160.153V96.5695H157.197V96.3624H157.249C157.819 96.1553 157.819 95.2751 157.819 94.6537V88.6989C157.871 88.0258 157.871 87.1973 157.249 86.9902H157.197V86.783Z"
        fill="black"
      />
      <path
        d="M147.603 86.783H147.706V86.8348H151.181C153.1 86.8348 154.293 87.7669 154.293 89.2685C154.293 90.6148 153.152 91.5986 152.011 91.9093C152.374 92.3753 154.033 94.4465 154.033 94.4465C154.604 95.1196 155.485 95.8964 156.367 96.4659L156.626 96.6213H155.019C154.189 96.6213 153.722 96.4142 153.255 95.8446L151.492 93.618L150.092 91.4433H150.196C151.077 91.2879 152.633 90.8737 152.633 89.4239C152.633 89.0096 152.478 88.5954 152.166 88.3365C151.855 88.0258 151.337 87.8704 150.818 87.8704C150.572 87.8704 150.358 87.9029 150.099 87.9421C150.031 87.9524 149.96 87.9632 149.885 87.974V94.8608C149.885 95.4304 149.936 96.1553 150.507 96.3624H150.559V96.5695H147.603V96.3624H147.655C148.225 96.1553 148.225 95.275 148.225 94.6536V88.6989C148.277 88.0258 148.277 87.1973 147.655 86.9902H147.603V86.783Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.979 86.783H142.365V86.7313L145.425 94.2912L145.528 94.4983C145.788 95.2232 146.099 95.9482 146.825 96.3106H146.877V96.4659H143.713V96.3106H143.765C144.076 96.1553 144.232 96.0517 144.232 95.8446C144.232 95.6892 144.128 95.4303 143.973 95.0679L143.921 94.9125C143.921 94.9125 143.195 92.9967 143.143 92.8931H139.565C139.461 93.2038 138.839 94.9125 138.839 94.9125C138.821 94.9747 138.803 95.0354 138.786 95.0943C138.702 95.3786 138.631 95.6212 138.631 95.7928C138.631 95.8446 138.631 95.9481 138.683 95.9999C138.735 96.1035 138.89 96.2071 139.098 96.3106H139.15V96.5177H136.297V96.3624H136.349C137.075 95.9999 137.387 95.275 137.646 94.5501L137.749 94.343L140.187 88.2329L140.239 88.1811C140.252 88.1402 140.267 88.0984 140.282 88.056C140.379 87.7768 140.484 87.4739 140.394 87.2491C140.342 87.1455 140.187 87.042 140.031 86.9902H139.979V86.783ZM142.676 91.7022C142.624 91.495 141.535 88.6989 141.38 88.3365C141.294 88.5075 140.925 89.4474 140.594 90.2923C140.324 90.9823 140.078 91.6091 140.031 91.7022H142.676Z"
        fill="black"
      />
      <path d="M134.482 86.783H134.534V86.6277H134.482V86.783Z" fill="black" />
      <path
        d="M131.941 86.783H134.482V86.8348H134.431C134.068 86.9384 134.016 87.4562 134.016 87.6115L135.001 94.809L135.003 94.8246C135.106 95.4877 135.161 95.8478 135.727 96.2588L135.779 96.3106V96.4659H132.823V96.2588H132.875C133.29 96.1553 133.393 95.7928 133.393 95.275C133.393 95.275 132.667 89.1649 132.615 88.5436C132.408 89.1132 129.452 96.7248 129.452 96.7248L129.348 96.6731C128.622 96.1035 128.415 95.6893 128.052 94.8608C128.052 94.8608 125.615 89.0614 125.407 88.5436C125.355 89.1132 124.629 94.6019 124.629 94.6019C124.629 94.7643 124.615 94.9126 124.601 95.0541C124.589 95.1832 124.577 95.3068 124.577 95.4303C124.577 95.8446 124.681 96.1553 125.096 96.3106H125.148V96.4659H122.607V96.3106H122.659C123.229 95.7928 123.333 95.1714 123.436 94.3947V94.343L124.266 88.2329V88.1294C124.269 88.1026 124.273 88.0757 124.277 88.0487C124.329 87.6557 124.385 87.229 123.851 86.9384H123.799V86.783H126.548V86.8866C126.548 86.9992 126.602 87.1391 126.652 87.2667C126.671 87.3151 126.689 87.3617 126.704 87.4044L126.755 87.508C126.755 87.508 129.245 93.4109 129.4 93.7734C129.504 93.4109 131.734 87.7669 131.734 87.7669C131.747 87.7274 131.76 87.6887 131.773 87.6507C131.862 87.3896 131.941 87.1578 131.941 86.8866V86.783Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.02 91.6504C109.02 90.3041 109.538 89.0614 110.523 88.0776C111.561 87.0419 113.013 86.4724 114.568 86.5241C116.072 86.5241 117.421 87.0937 118.406 88.0776C119.34 89.0096 119.858 90.2523 119.858 91.5986C119.858 94.5501 117.473 96.8284 114.413 96.8284C111.353 96.8284 109.02 94.6019 109.02 91.6504ZM114.465 87.7151C112.339 87.7151 110.835 89.2685 110.835 91.4951C110.835 93.4627 112.235 95.5857 114.517 95.5857C116.591 95.5857 118.043 94.0323 118.043 91.8575C118.043 89.8381 116.798 87.7151 114.465 87.7151Z"
        fill="black"
      />
      <path
        d="M104.612 86.783H107.464V86.7313H107.568V86.9384H107.516C106.945 87.1973 106.945 88.0776 106.945 88.6989V94.6536C106.945 94.6971 106.945 94.7418 106.945 94.7874C106.941 95.3948 106.937 96.1697 107.516 96.3624H107.568V96.5695H104.612V96.3624H104.663C105.234 96.1553 105.234 95.275 105.234 94.6537V88.6989C105.286 88.0258 105.286 87.1973 104.663 86.9902H104.612V86.783Z"
        fill="black"
      />
      <path
        d="M95.2769 86.783H98.2329V86.9902H98.181C97.5587 87.2491 97.5587 88.1293 97.5587 88.7507V91.4433V94.7054C97.5587 95.3786 97.5587 96.2588 98.181 96.4659H98.2329V96.6731H95.2769V96.4659H95.3288C95.9511 96.2071 95.9511 95.3268 95.9511 94.7055V88.7507C95.9511 88.0776 95.9511 87.1973 95.3288 86.9902H95.2769V86.783Z"
        fill="black"
      />
      <path
        d="M99.8924 86.783H102.745C102.745 86.783 99.3738 91.0808 99.3219 91.1844C99.6849 91.6504 102.226 95.0161 102.226 95.0161C102.641 95.5857 103.159 95.9999 103.937 96.3624H103.989V96.5177H102.537C101.811 96.5177 101.085 96.2588 100.826 95.8446L97.5587 91.4433L99.5294 88.8543L99.7368 88.5436C99.7468 88.5303 99.7568 88.5169 99.7669 88.5036C100.071 88.0995 100.408 87.6517 100.307 87.3008C100.307 87.1455 100.152 87.0419 99.9442 86.9384H99.8924V86.783Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.9863 91.6504C82.9863 90.3041 83.5049 89.0614 84.4902 88.0776C85.5793 87.0419 86.9794 86.4724 88.5352 86.5241C90.0391 86.5241 91.3875 87.0937 92.3728 88.0776C93.3063 89.0096 93.8249 90.2523 93.8249 91.5986C93.8249 94.5501 91.4393 96.8284 88.3796 96.8284C85.32 96.8284 82.9863 94.6019 82.9863 91.6504ZM88.4315 87.7151C86.3053 87.7151 84.8014 89.2685 84.8014 91.4951C84.8014 93.4627 86.2016 95.5857 88.4834 95.5857C90.5577 95.5857 92.0098 94.0323 92.0098 91.8575C92.0098 89.8381 90.7652 87.7151 88.4315 87.7151Z"
        fill="black"
      />
    </svg>
  );
};

export default Tokyo;

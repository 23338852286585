import { FC } from "react";
import useColor from "../../hooks/useColor";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { ColorNames, CustomTheme } from "../../theme";

const getSyles = ({
  width,
  color,
  colors,
  borderWidth,
  dashedLine,
}: CustomTheme & HorizontaLineProps) => ({
  borderBottom: 0,
  // borderTop: `thin solid ${color ? colors[color][40] : colors.Green[40]}`,
  color: color ? colors[color][100] : colors.Green[100],
  width,
  borderWidth: borderWidth && borderWidth,
  height: dashedLine ? "1.5px" : 0,
  backgroundImage: dashedLine
    ? `linear-gradient(90deg, ${
        color ? colors[color][100] : colors.Green[100]
      }, ${
        color ? colors[color][100] : colors.Green[100]
      } 50%, transparent 50%, transparent 50%)`
    : "none",
  backgroundSize: dashedLine ? "15px 1px" : 0,
});

interface HorizontaLineProps {
  color?: ColorNames;
  spacingSize?: "large" | "medium" | "small" | "none" | "marginTopXLarge";
  width?: number | string;
  borderWidth?: number;
  dashedLine?: boolean;
}

const HR: FC<HorizontaLineProps> = ({
  color = ColorNames.GREEN,
  spacingSize = "medium",
  width = "100% !important",
  borderWidth,
  dashedLine,
}) => {
  const lineColor = useColor(color);
  const styles = useCSSRulesWithTheme(getSyles, {
    color,
    width,
    borderWidth,
    dashedLine,
  });
  let margin;
  switch (spacingSize) {
    case "none":
      margin = "0px";
      break;
    case "small":
      margin = "8px 0";
      break;
    case "medium":
      margin = "22px 0";
      break;
    case "large":
      margin = "34px 0";
      break;
    case "marginTopXLarge":
      margin = "68px 0 15px 0";
      break;
  }

  if (dashedLine) {
    return <div style={{ ...styles, margin }}></div>;
  }

  return <hr color={lineColor} style={{ ...styles, margin }} />;
};

export default HR;

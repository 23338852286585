import React from "react";
import IconLabel from "../../../../components/IconLabel";
import { DocumentStatus } from "../../../../graphql/generated/types";
import { documentStatusLabels } from "../../../../shared/documents/documentStatus";
import TableCell from "../../../../components/ResizableTable/components/cells/TableCell";
import { documentStatusIcons } from "../../../../utils/icons";

interface StatusCellProps {
  status?: DocumentStatus;
}

const StatusCell: React.FC<StatusCellProps> = ({ status }) => {
  const label = status ? documentStatusLabels[status] : undefined;
  return (
    <TableCell tooltip={label}>
      {status ? (
        <IconLabel label={label!} icon={documentStatusIcons[status]} />
      ) : null}
    </TableCell>
  );
};

export default StatusCell;

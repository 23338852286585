import { FC } from "react";

const Simulation: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1623_2854)">
        <path d="M11 23H9V25H11V23Z" fill="black" />
        <path d="M21 23H23V25H21V23Z" fill="black" />
        <path d="M11 18H9V20H11V18Z" fill="black" />
        <path d="M21 18H23V20H21V18Z" fill="black" />
        <path d="M11 13H9V15H11V13Z" fill="black" />
        <path d="M15 23H17V25H15V23Z" fill="black" />
        <path d="M17 18H15V20H17V18Z" fill="black" />
        <path d="M15 13H17V15H15V13Z" fill="black" />
        <path d="M23 13H21V15H23V13Z" fill="black" />
        <path d="M9 7H23V10H9V7Z" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V28C4 28.5304 4.21071 29.0391 4.58579 29.4142C4.96086 29.7893 5.46957 30 6 30H26C26.5304 30 27.0391 29.7893 27.4142 29.4142C27.7893 29.0391 28 28.5304 28 28V4C28 3.46957 27.7893 2.96086 27.4142 2.58579C27.0391 2.21071 26.5304 2 26 2ZM26 4V28H6V4H26Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1623_2854">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Simulation;

import { IconNames } from "../components/Icons/styles/iconNames";
import { UserStatus } from "../graphql/generated/types";

const statusIconMap: { [key in UserStatus]: IconNames } = {
  [UserStatus.Registered]: IconNames.Pending,
  [UserStatus.Approved]: IconNames.Done,
  [UserStatus.Active]: IconNames.Valid,
  [UserStatus.Rejected]: IconNames.Rejected,
};

export const getStatusIcon = (status: UserStatus): IconNames | undefined => {
  return statusIconMap[status];
};

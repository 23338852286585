import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import {
  CreateNoteDocument,
  CreateNoteMutationVariables,
  Note,
  Services,
} from "../graphql/generated/types";
import { useAuth } from "../hooks/useAuth";
import { useApolloClient } from "@apollo/client";
import { useErrorHandler } from "../hooks/useErrorHandler";

interface NotesContextValue {
  refetch: () => Promise<void>;
  sendNote: (text: string) => Promise<void>;
  documentNotes: Note[];
  isLoading: boolean;
}

const NotesContext = React.createContext<NotesContextValue>({
  documentNotes: [],
  refetch: async () => {},
  sendNote: async () => {},
  isLoading: false,
});

interface NotesProviderProps {
  children: ReactNode;
  documentId: string;
  notes: Note[];
  loading: boolean;
  serviceName: Services;
  refetch: () => Promise<any>;
}

export const NotesProvider: FC<NotesProviderProps> = ({
  children,
  notes,
  loading,
  documentId,
  serviceName,
  refetch,
}) => {
  const { user } = useAuth();
  const apolloClient = useApolloClient();
  const { errorHandler } = useErrorHandler();

  const sendNote = useCallback(
    async (text: string) => {
      try {
        apolloClient
          .mutate<any, CreateNoteMutationVariables>({
            mutation: CreateNoteDocument,
            variables: {
              authorId: user._id,
              docId: documentId,
              text,
              serviceName,
            },
          })
          .then(refetch);
      } catch (e) {
        errorHandler(new Error("não foi possível criar a nota"), e);
      }
    },
    [documentId]
  );

  const [documentNotes, setDocumentNotes] = useState<Note[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDocumentNotes(notes);
  }, [notes]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <NotesContext.Provider
      value={{
        refetch,
        sendNote,
        isLoading,
        documentNotes,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export default NotesContext;

/** @jsxImportSource @emotion/react */

import DetailsSection from "../../../../components/Details/components/Main/Section/DetailsSection";
import { FC } from "react";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import ContactData from "../../../../components/Details/ContactData";

const BeneficiaryData: FC = () => {
  const {
    documentData: { beneficiary },
  } = useDocumentDetails();

  return (
    <DetailsSection title="Beneficiário">
      <ContactData contact={beneficiary!} />
    </DetailsSection>
  );
};

export default BeneficiaryData;

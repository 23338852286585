/** @jsxImportSource @emotion/react */

import { FC, useEffect, useState } from "react";
import Typography from "../Typography";
import { ColorNames, getThemeColorFromAlias, theme } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import useOutsideClick from "../../hooks/useOutsideClick";
import { IconSizes } from "../Icons/styles/iconSizes";
import { css } from "@emotion/react";
import useDetailsColor from "../../hooks/useDetailsColor";

export type TOverflowMenuOptions = {
  text: string;
  iconName?: IconNames;
  onClick?: () => void;
  disabled?: boolean;
};

interface OverflowMenuProps {
  hoverColor?: ColorNames;
  options: TOverflowMenuOptions[];
  vertical?: boolean;
}

const getCSSStyles = (
  { borderColor }: { borderColor: string },
  { backgroundColor }: { backgroundColor: string }
) => ({
  container: {
    position: "relative" as "relative",
  },
  optionsList: {
    display: "block",
    position: "absolute" as "absolute",
    height: "fit-content",
    right: "50%",
    backgroundColor: theme.colors.White[100],
    borderRadius: 5,
    boxShadow: theme.boxShadow.default,
    padding: 10,
    minWidth: 180,
    border: `1px solid ${borderColor}`,
    zIndex: 100,
  },
  option: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    "&:hover": {
      backgroundColor: backgroundColor,
    },
    "& > span": {
      marginRight: 6,
    },
  },
});

const OverflowMenu: FC<OverflowMenuProps> = ({
  options,
  hoverColor,
  vertical,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [menuYPosition, setMenuYPosition] = useState<{
    top: string | number;
    bottom: string | number;
  }>({ top: "100%", bottom: "auto" });
  const [menuXPosition, setMenuXPosition] = useState<{
    left: string | number;
    right: string | number;
  }>({ left: "auto", right: "50%" });
  const { color } = useDetailsColor();
  const borderColor = getThemeColorFromAlias(color, theme.colors)!;
  const backgroundColor = getThemeColorFromAlias(color, theme.colors, 20)!;
  const styles = getCSSStyles({ borderColor }, { backgroundColor });

  const handleOptionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    option: TOverflowMenuOptions
  ) => {
    if (!option.onClick || option.disabled) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    option.onClick();
    setShowOptions(false);
  };

  const ref = useOutsideClick(() => {
    setShowOptions(false);
  });

  const adjustMenuPosition = () => {
    if (!ref.current) return;
    const { left, bottom } = ref.current.getBoundingClientRect();
    const newYPosition =
      bottom > window.innerHeight - 200
        ? { top: "auto", bottom: "100%" }
        : { top: "100%", bottom: "auto" };
    const newXPosition =
      left < 200
        ? { left: "50%", right: "auto" }
        : { left: "auto", right: "50%" };

    setMenuYPosition(newYPosition);
    setMenuXPosition(newXPosition);
  };

  useEffect(() => {
    adjustMenuPosition();
  }, [ref.current, showOptions]);

  return (
    <div ref={ref} css={styles.container}>
      <Icon
        name={
          vertical ? IconNames.OverflowMenuVertical : IconNames.OverflowMenu
        }
        color={ColorNames.GREY}
        hoverColor={hoverColor ? hoverColor : ColorNames.BLACK}
        onClick={() => setShowOptions(!showOptions)}
      />
      {showOptions && (
        <div css={css(styles.optionsList, menuYPosition, menuXPosition)}>
          {options.map((option, i) => (
            <div
              css={css(styles.option, {
                cursor: option.disabled ? "not-allowed" : "pointer",
              })}
              key={`overflow-item-${i}`}
              onClick={(e) => handleOptionClick(e, option)}
            >
              {option.iconName && (
                <Icon
                  name={option.iconName}
                  size={IconSizes.Small}
                  color={option.disabled ? ColorNames.GREY : color}
                />
              )}
              <Typography
                variant="textMedium"
                component="div"
                color={option.disabled ? ColorNames.GREY : ColorNames.BLACK}
              >
                {option.text}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OverflowMenu;

import React from "react";
import { DocumentStages } from "../../../../graphql/generated/types";
import Chip from "../../../../components/Chip";
import useDocumentColor from "../../../../hooks/useDocumentColor";
import { documentStageLabels } from "../../../../shared/documents/documentStage";
import TableCell from "../../../../components/ResizableTable/components/cells/TableCell";

interface StageChipProps {
  stage?: DocumentStages;
}

const StageCell: React.FC<StageChipProps> = ({ stage }) => {
  const label = stage ? documentStageLabels[stage].toUpperCase() : "-";
  const color = useDocumentColor(stage);
  return (
    <TableCell tooltip={label}>
      {stage ? <Chip color={color} label={label} /> : null}
    </TableCell>
  );
};

export default StageCell;

/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useCreateFormResponseV2Mutation } from "../../graphql/generated/types";
import { useAuth } from "../../hooks/useAuth";
import FormV2 from "../../modules/Forms/form.v2";
import { FormSuccess } from "./components/FormSuccess";
import Layout from "../../components/Layout";
import Row from "../../components/Grid/Row";
import Column from "../../components/Grid/Column";
import { CSSObject } from "@emotion/react";
import { theme } from "../../theme";
import { Visible } from "react-grid-system";
import { farmFieldsUrl } from "../../s3/bgImages";

const styles: CSSObject = {
  bgImage: {
    border: `2px solid ${theme.colors.Green[100]}`,
    borderRadius: "10px",
    backgroundImage: `url(${farmFieldsUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  },
  imgWrapper: {
    padding: "15px",
    height: "100%",
    boxSizing: "border-box",
  },
};

const formId = "orgRegister";

export const OrgRegister = () => {
  const { errorHandler } = useErrorHandler();

  const [resId, setResId] = useState<string>("");
  const [showMessage, setShowMessage] = useState(false);
  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();
  const { loaded: authIsLoaded } = useAuth();

  useEffect(() => {
    if (authIsLoaded && !resId) {
      createFormResponseMutation({
        variables: {
          formId,
          metadata: {},
        },
      })
        .then(({ data }) => setResId(data!.createFormResponseV2!))
        .catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [authIsLoaded]);

  const handleSubmit = (id: string) => {
    if (id) {
      setShowMessage(true);
    }
  };

  return (
    <Layout padding={20}>
      <Row noMargins noGutters noWrap css={{ height: "100%" }}>
        <Column xs={12} lg={7}>
          {!showMessage && resId && (
            <FormV2
              formId={formId}
              onSubmit={handleSubmit}
              formResponseId={resId}
            />
          )}
          {showMessage && (
            <FormSuccess successMessage="Formulário enviado com sucesso! Entraremos em contato em breve" />
          )}
        </Column>
        <Visible lg md xl xxl xxxl>
          <Column lg={5} xs={0}>
            <div css={styles.imgWrapper}>
              <div css={styles.bgImage} />
            </div>
          </Column>
        </Visible>
      </Row>
    </Layout>
  );
};

import { dashboardDocumentFilters } from "../filters/documents";
import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";

const useDashboardDocumentsFilter = () => {
  const filters = useModelFilter(dashboardDocumentFilters, []);

  useUrlFilter(filters);

  return filters;
};

export default useDashboardDocumentsFilter;

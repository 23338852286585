/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { CSSObject } from "@emotion/react";
import Typography from "../../Typography";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import { ColorNames, theme } from "../../../theme";
import { IconSizes } from "../../Icons/styles/iconSizes";

const styles: CSSObject = {
  fileTagsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "20px",
    width: "100%",
  },
  fileTag: {
    backgroundColor: theme.colors.Grey[20],
    borderRadius: "5px",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
  closeButton: {
    marginLeft: "10px",
    cursor: "pointer",
  },
};

interface FileListProps {
  files: File[];
  handleRemoveFile: (index: number) => void;
}

const FileList: FC<FileListProps> = ({ files, handleRemoveFile }) => {
  return (
    <div css={styles.fileTagsContainer}>
      {files.map((file, index) => (
        <div css={styles.fileTag} key={index}>
          <Typography variant="textMedium">{file.name}</Typography>
          <span css={styles.closeButton}>
            <Icon
              onClick={() => handleRemoveFile(index)}
              name={IconNames.Close}
              color={ColorNames.RED}
              size={IconSizes.Small}
            />
          </span>
        </div>
      ))}
    </div>
  );
};

export default FileList;

/** @jsxImportSource @emotion/react */

import Layout from "../../components/Layout/index";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Column from "../../components/Grid/Column";
import Container from "../../components/Grid/Container";
import Row from "../../components/Grid/Row";
import Typography from "../../components/Typography";
import { useAuth } from "../../hooks/useAuth";
import HR from "../../components/HorizontalLine";
import { ColorNames } from "../../theme";

const getCSSRules: CSSRulesResolver = ({ ...props }) => ({
  main: {
    padding: "0px 20px",
    height: "100%",
    border: `2px solid ${props.colors.Green[100]}`,
    borderRadius: 10,
    backgroundColor: `${props.colors.White[100]}`,
    overflow: "auto",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  },
});

const PendingUser = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { user } = useAuth();

  return (
    <Layout>
      <div css={styles.main}>
        <Container>
          <Row justify="center">
            <Column>
              <Typography
                uppercase
                variant="textMedium"
                color="Grey"
                align="center"
                component="p"
              >
                Obrigado,{" "}
                <Typography
                  uppercase
                  variant="textMedium"
                  color="Green"
                  align="center"
                  component="span"
                >
                  {user.name}
                </Typography>
                ! Estamos analisando seu cadastro.
                <HR spacingSize="small" color={ColorNames.GREEN} />
              </Typography>
            </Column>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default PendingUser;

import { useParams } from "react-router-dom";
import { useUIStore } from "../../../hooks/useUIStore";
import FormV2 from "../../../modules/Forms/form.v2";
import Form from "../../../modules/Forms";

const DirectQuotationForm = () => {
  const { formId, docId } = useParams();
  const { openSubmittedModal } = useUIStore();

  const onSubmit = async () => {
    openSubmittedModal(
      "Obrigado pelas informações! Entraremos em contato em breve."
    );
  };

  if (formId === "equipamentos") {
    return <FormV2 formResponseId={docId!} onSubmit={onSubmit} />;
  }

  if (docId && formId && docId !== "new") {
    if (formId === "cotacao") {
      return <Form formId={formId} docId={docId} onSubmit={onSubmit} />;
    }
    return <FormV2 formResponseId={docId} onSubmit={onSubmit} />;
  }
  return null;
};

export default DirectQuotationForm;

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { useCSSRulesWithTheme } from "../../../../hooks/useCSSRulesWithTheme";
import { IconSizes } from "../../../../components/Icons/styles/iconSizes";
import { css } from "@emotion/react";
import { ColorNames, theme } from "../../../../theme";
import Tooltip from "../../../../components/Tooltip";
import Avatar from "../../../../components/Avatar";

interface ResponsiblesProps {
  responsibles: string[];
  color: ColorNames;
  docId?: string;
}

const getCSSRules = () => ({
  container: {
    display: "flex",
    justifyContent: "end",
    alignItems: "start",
  },
  responsible: {
    marginRight: -8,
  },
  initialsContainer: {
    padding: 2,
  },
  initials: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 25,
    height: 25,
    borderRadius: "50%",
    border: "2px solid",
    backgroundColor: theme.colors.White[100],
  },
});

const Responsibles: FC<ResponsiblesProps> = ({
  responsibles,
  color,
  docId,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const getFirstName = (name: string) => {
    if (!name) return "Não adicionado";
    const names = name.split(" ");
    return names[0];
  };

  const tooltipLabels = [
    "Parceiro",
    "Responsável Comercial",
    "Responsável Operacional",
  ];

  return (
    <div css={styles.container}>
      {responsibles.map(
        (responsible, i) =>
          (i !== 0 || responsible) && (
            <div
              key={`responsible-${i}`}
              css={css(i < responsibles.length - 1 && styles.responsible, {
                zIndex: responsibles.length - i,
              })}
            >
              <Tooltip
                text={`${tooltipLabels[i]}: ${getFirstName(responsible)}`}
                id={`responsible-${docId}-${i}`}
              >
                <Avatar
                  name={responsible}
                  color={color}
                  size={IconSizes.Large}
                  filled={i === 0}
                />
              </Tooltip>
            </div>
          )
      )}
    </div>
  );
};

export default Responsibles;

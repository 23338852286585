/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import Typography from "../../Typography";
import { ColorNames } from "../../../theme";

const markerStyle: { [key: string]: CSSObject } = {
  container: {
    position: "relative",
  },
  text: {
    position: "absolute",
    top: -12,
    left: 12,
    fontWeight: "bold",
  },
};
const MarkerLabel: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div css={markerStyle}>
      <Typography
        css={markerStyle.text}
        variant="textLarge"
        color={ColorNames.WHITE}
        component="div"
      >
        {text}
      </Typography>
      //{" "}
    </div>
  );
};

export default MarkerLabel;

import { FC } from "react";

const Email: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_306_213)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 6H28C28.5304 6 29.0391 6.21071 29.4142 6.58579C29.7893 6.96086 30 7.46957 30 8V24C30 24.5304 29.7893 25.0391 29.4142 25.4142C29.0391 25.7893 28.5304 26 28 26H4C3.46957 26 2.96086 25.7893 2.58579 25.4142C2.21071 25.0391 2 24.5304 2 24V8C2 7.46957 2.21071 6.96086 2.58579 6.58579C2.96086 6.21071 3.46957 6 4 6ZM16 14.78L25.8 8H28V8.91L16.57 16.82C16.4026 16.9361 16.2037 16.9984 16 16.9984C15.7963 16.9984 15.5974 16.9361 15.43 16.82L4 8.91V8H6.2L16 14.78Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_306_213">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Email;

import { FC } from "react";

const Logout: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_290_112)">
        <path
          d="M18 30H6C5.46975 29.9994 4.9614 29.7885 4.58646 29.4135C4.21152 29.0386 4.00061 28.5302 4 28V4C4.00061 3.46975 4.21152 2.9614 4.58646 2.58646C4.9614 2.21152 5.46975 2.00061 6 2H18C18.5302 2.00061 19.0386 2.21152 19.4135 2.58646C19.7885 2.9614 19.9994 3.46975 20 4V7H18V4H6V28H18V25H20V28C19.9994 28.5302 19.7885 29.0386 19.4135 29.4135C19.0386 29.7885 18.5302 29.9994 18 30Z"
          fill="black"
        />
        <path
          d="M24.172 17L20.586 20.586L22 22L28 16L22 10L20.586 11.414L24.172 15H10V17H24.172Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_290_112">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logout;

/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { getThemeColorFromAlias, theme } from "../../theme";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import useDetailsColor from "../../hooks/useDetailsColor";
import { ChangeEvent, useEffect, useMemo, useState } from "react";

interface UploadInputProps {
  label: string;
  onClick?: () => void | any;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileType: string;
  file: File | null;
  fileName: string;
  onDelete: () => void;
  name?: string;
  id?: string;
}

const getStyles: CSSRulesResolver<{ tabColor: string }> = (props) => ({
  inputLabel: {
    position: "relative" as "relative",
    display: "inline-block",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    color: props.tabColor,
    borderBottom: `2px solid ${props.tabColor}`,
    width: "100%",
    fontSize: 20,
    textAlign: "left" as "left",
    marginTop: 40,
    padding: "5px 0px",
    marginBottom: 20,
    textTransform: "uppercase" as "uppercase",
  },
  uploadInput: {
    display: "none",
  },
  uploadIcon: {
    position: "absolute" as "absolute",
    right: 0,
    bottom: 5,
    margin: "auto",
  },
  downloadIcon: {
    position: "absolute" as "absolute",
    right: 0,
    bottom: 5,
    margin: "auto",
  },
});

const UploadInput: React.FC<UploadInputProps> = ({
  label,
  onClick,
  fileType,
  fileName,
  onDelete,
  handleChange,
  name,
  id,
}) => {
  const { color } = useDetailsColor();
  const styles = useCSSRulesWithTheme(getStyles, {
    tabColor: getThemeColorFromAlias(color, theme.colors)!,
  });
  let accept = "";
  switch (fileType) {
    case "pdf":
      accept = "application/pdf";
      break;
    case "kml":
      accept = ".kml";
      break;
    case "image":
      accept = "image/*";
      break;
    case "csv":
      accept = ".csv";
      break;
    default:
      accept = "application/pdf";
      break;
  }

  let inputId = useMemo(() => id || `upload-input-${name}`, [id, name]);

  const [loading, setLoading] = useState(false);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    handleChange && handleChange(e);
    onClick && onClick();
  };

  useEffect(() => {
    if (loading && fileName) {
      setLoading(false);
    }
  }, [loading, fileName]);

  return fileName ? (
    <label htmlFor={inputId} css={styles.inputLabel} onClick={onDelete}>
      {fileName}
      <span css={styles.downloadIcon}>
        <Icon name={IconNames.Delete} color={color} />
      </span>
    </label>
  ) : (
    <label htmlFor={inputId} css={styles.inputLabel}>
      {loading ? "Carregando..." : label}
      <input
        id={inputId}
        type="file"
        css={styles.uploadInput}
        onChange={handleUpload}
        accept={accept}
        name={name}
      />
      <div css={styles.uploadIcon}>
        <Icon name={IconNames.Upload} color={color} />
      </div>
    </label>
  );
};

export default UploadInput;

const Company = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1542_9929)">
      <path
        d="M7.71429 6.28571H6.28571V9.14286H7.71429V6.28571Z"
        fill="black"
      />
      <path
        d="M7.71429 10.5714H6.28571V13.4286H7.71429V10.5714Z"
        fill="black"
      />
      <path d="M10.5714 6.28571H12V9.14286H10.5714V6.28571Z" fill="black" />
      <path d="M12 10.5714H10.5714V13.4286H12V10.5714Z" fill="black" />
      <path
        d="M6.28571 14.8571H7.71429V17.7143H6.28571V14.8571Z"
        fill="black"
      />
      <path d="M12 14.8571H10.5714V17.7143H12V14.8571Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5816 9.56128C21.8495 9.82919 22 10.1925 22 10.5714V22H2V3.42857C2 3.04969 2.15051 2.68633 2.41842 2.41842C2.68633 2.15051 3.04969 2 3.42857 2H14.8571C15.236 2 15.5994 2.15051 15.8673 2.41842C16.1352 2.68633 16.2857 3.04969 16.2857 3.42857V9.14286H20.5714C20.9503 9.14286 21.3137 9.29337 21.5816 9.56128ZM14.8571 3.42857H3.42857V20.5714H14.8571V3.42857ZM16.2857 10.5714V20.5714H20.5714V10.5714H16.2857Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1542_9929">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Company;

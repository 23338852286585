const Agriculture = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1542_9876)">
      <path
        d="M18.3939 2C16.7149 2.00257 15.0759 2.47353 13.6911 3.35129C12.3063 4.22905 11.2403 5.47274 10.6325 6.9197C10.1456 6.2667 9.49443 5.73277 8.73502 5.36381C7.9756 4.99484 7.13063 4.80188 6.27273 4.80149H4V6.90261C4.00156 8.20242 4.56078 9.44858 5.55495 10.3677C6.54912 11.2868 7.89706 11.8038 9.30303 11.8052H10.0606V18.1431C8.3205 18.3004 6.69412 19.0135 5.46136 20.1598L6.53258 21.1502C7.1663 20.5644 7.93266 20.1158 8.77646 19.8368C9.62025 19.5577 10.5205 19.4552 11.4123 19.5364C12.3041 19.6177 13.1654 19.8808 13.934 20.3068C14.7026 20.7328 15.3595 21.3111 15.8573 22L17.1161 21.2206C16.495 20.3626 15.6761 19.6425 14.7182 19.1119C13.7602 18.5813 12.687 18.2535 11.5758 18.152V11.8052H12.3333C14.5427 11.8029 16.6609 10.9905 18.2231 9.54619C19.7854 8.10189 20.6642 6.14366 20.6667 4.10112V2H18.3939ZM9.30303 10.4045C8.29877 10.4034 7.33598 10.0341 6.62586 9.37764C5.91574 8.72114 5.51629 7.83104 5.51515 6.90261V6.20224H6.27273C7.277 6.20324 8.23984 6.57251 8.94997 7.22902C9.6601 7.88553 10.0595 8.77566 10.0606 9.70411V10.4045H9.30303ZM19.1515 4.10112C19.1495 5.77231 18.4305 7.37451 17.1523 8.55622C15.8741 9.73793 14.141 10.4026 12.3333 10.4045H11.5758V9.70411C11.5778 8.03292 12.2967 6.43071 13.575 5.24901C14.8532 4.0673 16.5863 3.4026 18.3939 3.40075H19.1515V4.10112Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1542_9876">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Agriculture;

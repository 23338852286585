/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import Typography from "../../../../../../components/Typography";
import {
  DocumentClaimStatus,
  DocumentRejectReason,
  DocumentStages,
  DocumentStatus,
  Services,
  useModelTasksQuery,
} from "../../../../../../graphql/generated/types";
import DetailsSectionColumn from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import Icon from "../../../../../../components/Icons";
import { ColorNames } from "../../../../../../theme";
import { getStatusIcon } from "../../../../../../filters/documents";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import {
  formatDateWithTime,
  formatISODate,
} from "../../../../../../utils/dateUtils";
import { documentStageLabels } from "../../../../../../shared/documents/documentStage";
import { documentStatusLabels } from "../../../../../../shared/documents/documentStatus";
import { insuranceTypeGroupLabels } from "../../../../../../shared/insurance/insuranceTypeGroup";
import { concatArrayOfStrings } from "../../../../../../utils/stringUtils";
import { useFlags } from "../../../../../../hooks/useFlags";

const claimStatusLabels = {
  [DocumentClaimStatus.Closed]: "Fechado",
  [DocumentClaimStatus.Open]: "Aberto",
};

const InsuranceInfo = () => {
  const {
    documentData: {
      stage,
      status,
      createdAt,
      archived,
      rejectReason,
      provider,
      claimStatus,
      updatedAt,
      policy,
      insuranceTypeGroup,
      _id,
    },
  } = useDocumentDetails();

  const DocumentRejectReasonLabels = {
    [DocumentRejectReason.NotAvailable]: "Produto indisponível",
    [DocumentRejectReason.OutOfCoveragePeriod]: "Fora da época de contratação",
    [DocumentRejectReason.PriceTooHigh]: "Valor muito alto",
    [DocumentRejectReason.ContactLost]: "Contato perdido",
    [DocumentRejectReason.Other]: "Outra",
  };

  const { data: tasksData } = useModelTasksQuery({
    variables: {
      serviceName: Services.Documents,
      objectId: _id,
    },
  });

  const insuranceInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Etapa // Status",
        value: (
          <Typography
            variant="textMedium"
            component="div"
            css={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon
              name={
                archived
                  ? IconNames.Archive
                  : getStatusIcon(status as DocumentStatus)!
              }
              color={archived ? ColorNames.ERROR_RED : ColorNames.GREEN}
            />
            {archived
              ? "Arquivado"
              : `${documentStageLabels[stage as DocumentStages]} ${
                  documentStatusLabels[status as DocumentStatus]
                }`}
          </Typography>
        ),
      },
      status === DocumentStatus.Rejected && {
        label: "Motivo da Recusa",
        value:
          rejectReason?.rejectReason === DocumentRejectReason.Other
            ? `${DocumentRejectReasonLabels[DocumentRejectReason.Other]}: ${
                rejectReason.other
              }`
            : rejectReason &&
              `${DocumentRejectReasonLabels[rejectReason.rejectReason]}`,
      },
      {
        label: "Tipo de Seguro",
        value: insuranceTypeGroupLabels[insuranceTypeGroup!],
      },
      {
        label: "Criado em",
        value: formatDateWithTime(createdAt),
      },
      {
        label: "Atualizado em",
        value: formatDateWithTime(updatedAt),
      },
      policy && {
        label: "Vigência da apólice",
        value: `${formatISODate(policy.startDate)} - ${formatISODate(
          policy.endDate
        )}`,
      },
      claimStatus && {
        label: "Sinistro",
        value: claimStatusLabels[claimStatus],
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [
    archived,
    stage,
    status,
    policy,
    claimStatus,
    rejectReason,
    createdAt,
    updatedAt,
    insuranceTypeGroup,
  ]);

  const approvedInsuranceInfo = useMemo(() => {
    if (provider) {
      return [
        {
          label: "Seguradora",
          value: provider,
        },
      ];
    }
    return [];
  }, [provider]);

  const { isFlagEnabled } = useFlags();

  const taskInfo = useMemo(() => {
    if (!isFlagEnabled("admin-only")) return [];
    return [
      {
        label: "Próxima(s) Tarefa(s)",
        value:
          tasksData?.modelTasks &&
          concatArrayOfStrings(
            tasksData?.modelTasks?.map((task) => task.message)
          ),
      },
    ];
  }, [tasksData, isFlagEnabled]);

  return (
    <DetailsSectionColumn
      xs={12}
      sm={6}
      rows={[...insuranceInfo, ...taskInfo, ...approvedInsuranceInfo]}
    />
  );
};

export default InsuranceInfo;

import Table from "../../../../components/Table";
import { useListContactsQuery } from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToContactDetailsLocation } from "../../../../Locations";
import { formatCpfCnpj, formatPhone } from "../../../../utils/formatNumber";
import { useMemo } from "react";
import { contactTagLabels } from "../../../../shared/contacts/contactTags";
import ScrollContainer from "../../../../components/ScrollContainer";

type TableContactData = {
  _id: string;
  name: string;
  cpfCnpj: string;
  tags: string;
  email: string;
  mobilePhone: string;
};

const Subcontacts = () => {
  const { contactData } = useContactDetails();

  const { data } = useListContactsQuery({
    variables: { filter: { parentId: contactData._id! } },
  });

  const tableContactData: TableContactData[] = useMemo(() => {
    const contacts = [
      ...(data?.contacts || []),
      ...(contactData.parent ? [contactData.parent] : []),
    ];
    return contacts.map((contact) => ({
      _id: contact!._id!,
      name: contact!.name! || "Sem nome",
      cpfCnpj: contact!.cpfCnpj
        ? formatCpfCnpj(contact.cpfCnpj)
        : "Sem documento",
      tags: (contact.tags || []).map((t) => contactTagLabels[t]).join(", "),
      email: contact!.email! || "Sem email",
      mobilePhone: contact!.mobilePhone
        ? formatPhone(contact.mobilePhone)
        : "Sem documento",
    }));
  }, [data?.contacts, contactData.parent]);

  return (
    <ScrollContainer>
      <Table<TableContactData>
        data={tableContactData}
        withBorder
        color={ColorNames.PINK}
        rowLink={(doc: TableContactData) => ToContactDetailsLocation(doc._id!)}
        columns={[
          {
            label: "Nome",
            key: "name",
          },
          {
            label: "CPF/CNPJ",
            key: "cpfCnpj",
          },
          {
            label: "Telefone",
            key: "mobilePhone",
          },
          {
            label: "E-mail",
            key: "email",
          },
          {
            label: "Tags",
            key: "tags",
          },
        ]}
      />
    </ScrollContainer>
  );
};

export default Subcontacts;

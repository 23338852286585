/** @jsxImportSource @emotion/react */

import { FC } from "react";
import ExclamationIcon from "../../Icons/ExclamationIcon";
import { ColorNames } from "../../../theme";
import HR from "../../HorizontalLine";
import Typography from "../../Typography";
import { useCSSRulesWithTheme } from "../../../hooks/useCSSRulesWithTheme";

const getCSSRules = () => ({
  container: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

const NoDocumentsMessage: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.container}>
      <div>
        <Typography uppercase variant="textMedium" color="Grey">
          Não foram encontrados documentos para os filtros selecionados
        </Typography>
        <HR color={ColorNames.ERROR_RED} spacingSize="small" />
        <ExclamationIcon color={ColorNames.ERROR_RED} />
      </div>
    </div>
  );
};

export default NoDocumentsMessage;

/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from "react";
import { FC } from "react";
import Column from "../../../components/Grid/Column";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import { FormObj } from "../models/formInterfaces";
import FormFooter from "./FormFooter";
import FormSection from "./FormSection";
import FormSectionSummary from "./FormSectionSummary";
import { theme } from "../../../theme";
import Stepper from "./stepper/Stepper";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { useForm } from "../hooks/useForm";
import TableRowForm from "./TableRowForm";

interface FormContentProps {
  formData: FormObj;
  handleFormSubmit: () => void;
  large?: boolean;
  handleCancel?: () => void;
  displayAsTableRow?: boolean;
}

export enum StepperAction {
  NEXT,
  BACK,
}

const formStepperHeight = 50;
const formMobileStepperHeight = 80;

const getCSSRules: CSSRulesResolver<FormContentProps> = ({
  colors,
  breakpoints,
}) => ({
  container: {
    paddingLeft: 24,
    height: "100%",
    color: colors.Grey[100],
    overflowX: "hidden",
  },
  summarySectionSpacing: {
    marginBottom: "2em",
  },
  scroll: {
    overflow: "scroll",
    marginBottom: 0,
    "&:hover": {
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
      },
      "::-moz-scrollbar-thumb:": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
      },
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: `calc(100% - ${formStepperHeight}px - 24px)`,
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: `calc(100% - ${formMobileStepperHeight}px - 24px)`,
    },
  },
  formStepper: {
    marginBottom: 0,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: "12px 0px",
      height: formMobileStepperHeight,
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: formStepperHeight,
      alignContent: "center",
    },
  },
});

const FormContent: FC<FormContentProps> = ({
  formData,
  handleFormSubmit,
  handleCancel,
  large,
  displayAsTableRow,
}) => {
  const { setClickedSubmit, validSections } = useForm();
  const { sections } = formData;

  const singleSection = sections.length === 1;

  const lastSectionValid = useMemo(() => {
    const lastIndex = sections.findIndex((section) => !section.isValid);
    return lastIndex === -1 ? (singleSection ? 0 : sections.length) : lastIndex;
  }, [sections]);

  const [activeSectionIndex, setActiveSectionIndex] =
    useState(lastSectionValid);
  const [maxStep, setMaxStep] = useState(lastSectionValid);

  useEffect(() => {
    activeSectionIndex > maxStep && setMaxStep(activeSectionIndex);
  }, [activeSectionIndex]);

  const activeSection = useMemo(
    () => sections[activeSectionIndex],
    [sections, activeSectionIndex]
  );

  const sectionIsValid = useMemo<boolean>(() => {
    return !activeSection || validSections[activeSection.id];
  }, [activeSection, validSections]);

  const handleStepChange = (action: StepperAction) => {
    setClickedSubmit(true);
    const isHandleBack = action === StepperAction.BACK;
    if ((!activeSectionIndex && isHandleBack) || !sectionIsValid) {
      return;
    }
    const increment = isHandleBack ? -1 : 1;
    setActiveSectionIndex(activeSectionIndex + increment);
    setClickedSubmit(false);
  };

  const showFormSummary = useMemo(
    () => activeSectionIndex === sections?.length,
    [activeSectionIndex, sections?.length]
  );

  const steps = useMemo(
    () => sections?.map((section) => section.label).concat("Finalizar cotação"),
    [sections]
  );

  const styles = useCSSRulesWithTheme(getCSSRules, {
    formData,
    handleFormSubmit,
  });

  const handleStepClick = (step: number) => {
    if (
      // Usuário estiver tentando ir para um passo que já esteve
      step <= maxStep &&
      // Ele está tentando voltar no form
      (step < activeSectionIndex ||
        // Ele está no passo de revisão
        activeSectionIndex === sections.length ||
        // Todas as sessões até o passo desejado estão devidamente preenchidas
        sections
          .slice(activeSectionIndex, step)
          .every((section) => section.isValid))
    ) {
      setActiveSectionIndex(step);
    }
  };

  if (displayAsTableRow) {
    return (
      <TableRowForm
        sectionData={activeSection}
        handleFormSubmit={handleFormSubmit}
        handleCancel={handleCancel}
      />
    );
  }

  return (
    <Container css={styles.container}>
      {!singleSection && (
        <Row noGutters css={styles.formStepper}>
          <Column xs={11.5}>
            <Stepper
              steps={steps}
              maxStep={maxStep}
              activeStepIndex={activeSectionIndex}
              handleStepClick={handleStepClick}
            />
          </Column>
        </Row>
      )}
      <Row
        noGutters
        css={
          (styles.scroll,
          {
            height: singleSection
              ? "100%"
              : `calc(100% - ${formStepperHeight}px - 24px)`,
          })
        }
      >
        {!showFormSummary && (
          <Column xs={11.5} xl={large ? 12 : 8} style={{ paddingTop: 12 }}>
            <FormSection sectionData={activeSection} large={large} />
          </Column>
        )}
        {showFormSummary &&
          sections?.map((section, i) => (
            <Column
              xs={12}
              xl={large ? 12 : 8}
              css={i !== sections.length - 1 && styles.summarySectionSpacing}
            >
              <FormSectionSummary
                sectionData={section}
                handleEditSelection={() => {
                  setActiveSectionIndex(i);
                }}
              />
            </Column>
          ))}
        <Column xs={11.5} xl={large ? 12 : 8}>
          <FormFooter
            handleFormSubmit={
              !sectionIsValid ? () => setClickedSubmit(true) : handleFormSubmit
            }
            shouldSubmit={showFormSummary || !!singleSection}
            handleStepChange={handleStepChange}
            sectionIsValid={showFormSummary || sectionIsValid}
            handleCancel={handleCancel}
          />
        </Column>
      </Row>
    </Container>
  );
};

export default FormContent;

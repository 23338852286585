/** @jsxImportSource @emotion/react */

import { FC } from "react";
import SkeletonTotalPrizeCard from "./components/SkeletonCard/SkeletonFunnelCard";
import SkeletonAnimationContainer from "../../../../components/Skeletons/components/SkeletonAnimationContainer";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";

const SkeletonFunnelCards: FC = () => {
  const skeletonData = Array(4).fill(<SkeletonTotalPrizeCard />);
  return (
    <div css={{ position: "relative" }}>
      <SkeletonAnimationContainer />
      <Row noMargins>
        {skeletonData.map((item, index) => (
          <Column xs={12} lg={6} key={`prize-card-${index}`} useYPaddings>
            <SkeletonTotalPrizeCard />
          </Column>
        ))}
      </Row>
    </div>
  );
};

export default SkeletonFunnelCards;

import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { API_SERVICE_BASE_PATH } from "../../services/fetch";
import { TOKEN_COOKIE_NAME } from "../../contexts/auth";
import Cookies from "js-cookie";

// Function to get the WebSocket URL based on the API service base path
const getWebSocketUrl = () => {
  const httpUrl = new URL(API_SERVICE_BASE_PATH);
  const wsProtocol = httpUrl.protocol === "https:" ? "wss:" : "ws:";
  return `${wsProtocol}//${httpUrl.host}${httpUrl.pathname}graphql`;
};

const httpLink = new HttpLink({
  uri: `${API_SERVICE_BASE_PATH}graphql`, // Ensure the URI is correct
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get(TOKEN_COOKIE_NAME);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Setup the WebSocket link for subscriptions using graphql-ws
const wsClient = createClient({
  url: getWebSocketUrl(), // Use the dynamically constructed URL
  connectionParams: () => {
    const token = Cookies.get(TOKEN_COOKIE_NAME);
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  },
  on: {
    error: (error) => console.log("WebSocket error", error),
  },
});

const wsLink = new GraphQLWsLink(wsClient);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink, // Use WebSocket link for subscriptions
  authLink.concat(httpLink) // Use HTTP link for queries and mutations
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default {
  client,
};

import { FC, ReactNode, createContext, useState } from "react";
import { DashboardFunnelDateRange } from "../graphql/generated/types";

interface DashboardContextValue {
  funnelDateRange: DashboardFunnelDateRange;
  handleChangeFunnelDateRange: (dateRange: DashboardFunnelDateRange) => void;
}

export const DashboardContext = createContext<DashboardContextValue>({
  funnelDateRange: DashboardFunnelDateRange.Month,
  handleChangeFunnelDateRange: () => {},
});

interface DashboardProviderProps {
  children: ReactNode;
}

export const DashboardContextProvider: FC<DashboardProviderProps> = ({
  children,
}) => {
  const [activeDateRange, setActiveDateRange] = useState(
    DashboardFunnelDateRange.Month
  );

  const handleChangeFunnelDateRange = async (
    dateRange: DashboardFunnelDateRange
  ) => {
    setActiveDateRange(dateRange);
  };

  return (
    <DashboardContext.Provider
      value={{
        funnelDateRange: activeDateRange,
        handleChangeFunnelDateRange,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

import { FC, useMemo } from "react";
import {
  GetEditMeetingOnObjectFormResponseDocument,
  MeetingStatus,
  MeetingTopics,
  Meeting,
  Services,
} from "../../graphql/generated/types";
import { formatISODate } from "../../utils/dateUtils";
import EditableSection from "../EditableSection";
import DetailsSectionColumns from "../Details/components/Main/Grid/DetailsSectionColumns";
import { DetailsRowValueVariants } from "../Details/components/Main/Grid/DetailsRowValue";

interface MeetingProps {
  data: Meeting;
}

export const MeetingTopicLabels: { [key in MeetingTopics]: string } = {
  [MeetingTopics.Intro]: "Introdução",
  [MeetingTopics.Followup]: "Follow-up",
  [MeetingTopics.Quotation]: "Cotação",
};

export const MeetingStatusLabels: { [key in MeetingStatus]: string } = {
  [MeetingStatus.Done]: "Realizada",
  [MeetingStatus.Scheduled]: "Agendada",
};

const MeetingComponent: FC<MeetingProps> = ({ data: meeting }) => {
  const meetingInfo = useMemo(() => {
    return [
      [
        {
          label: "data",
          value: formatISODate(meeting.date),
        },
        {
          label: "assunto",
          value: MeetingTopicLabels[meeting.topic],
        },
        {
          label: "status",
          value: MeetingStatusLabels[meeting.status],
        },
      ],
      [
        {
          label: "responsável",
          value: meeting.responsible?.name,
        },
        meeting.attachment && {
          label: "anexo",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: meeting.attachment.fileName,
            },
            objectId: meeting._id,
            fieldPath: "attachment",
            serviceName: Services.Meetings,
          },
        },
        {
          label: "observações",
          value: meeting.comment,
        },
      ],
    ];
  }, [meeting]);

  return (
    <EditableSection
      title={meeting.topic}
      editQuery={GetEditMeetingOnObjectFormResponseDocument}
      objectId={meeting._id}
      main
    >
      <DetailsSectionColumns columns={meetingInfo} />
    </EditableSection>
  );
};

export default MeetingComponent;

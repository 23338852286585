import { FC } from "react";

const Close: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_206)">
        <path
          d="M17.4141 16L26 7.4141L24.5859 6L16 14.5859L7.4143 6L6 7.4141L14.5859 16L6 24.5859L7.4143 26L16 17.4141L24.5859 26L26 24.5859L17.4141 16Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_206">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Close;

import { TwilioWaMsgStatus } from "../../../../graphql/generated/types";
import { ColorNames } from "../../../../theme";
import { IconNames } from "../../../Icons/styles/iconNames";

export const getStatusRepresentation = (status: TwilioWaMsgStatus) =>
  ({
    [TwilioWaMsgStatus.Pending]: {
      icons: [
        {
          name: IconNames.Pending,
          color: ColorNames.GREY,
        },
      ],
      tooltipText: "Sua mensagem será enviada assim que o cliente autorizar",
    },
    [TwilioWaMsgStatus.Enqueued]: {
      icons: [
        {
          name: IconNames.Expired,
          color: ColorNames.GREY,
        },
      ],
      tooltipText: "Sua mensagem está sendo enviada",
    },
    [TwilioWaMsgStatus.Sent]: {
      icons: [
        {
          name: IconNames.Done,
          color: ColorNames.GREEN,
        },
      ],
      tooltipText: "Sua mensagem foi enviada",
    },
    [TwilioWaMsgStatus.Delivered]: {
      icons: [
        {
          name: IconNames.Done,
          color: ColorNames.GREEN,
        },
        {
          name: IconNames.Done,
          color: ColorNames.GREEN,
        },
      ],
      tooltipText: "Sua mensagem foi recebida",
    },
    [TwilioWaMsgStatus.Failed]: {
      icons: [
        {
          name: IconNames.Close,
          color: ColorNames.RED,
        },
      ],
      tooltipText: "Ocorreu um erro ao enviar a sua mensagem",
    },
    [TwilioWaMsgStatus.Read]: {
      icons: [
        {
          name: IconNames.Done,
          color: ColorNames.BLUE,
        },
        {
          name: IconNames.Done,
          color: ColorNames.BLUE,
        },
      ],
      tooltipText: "Sua mensagem foi visualizada",
    },
  }[status]);

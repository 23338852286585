import { FC } from "react";
import SelectInput, {
  SelectInputOptionInterface,
} from "../../../../components/Input/SelectInputs/SelectInput";
import { useForm } from "../../hooks/useForm";
import { FormField } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import { selectionToEvent } from "./utils";
import FormLabel from "../FormLabel";

const FormInputDropdown: FC<FormField & { groupPath: string }> = ({
  options,
  name,
  label,
  type,
  groupPath,
  disabled,
}) => {
  const [value, error] = useFieldValue(name, groupPath);
  const compareValue = type === "labelAndCode" ? value?.cod_mapfre : value;
  const { handleChange, startEditing, finishEditing } = useForm();
  const handleSelect = (option: SelectInputOptionInterface) => {
    handleChange(selectionToEvent(name, type, option), groupPath);
  };

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <SelectInput
        options={options!}
        value={compareValue}
        inputName={name}
        placeholder={label}
        onSelect={handleSelect}
        onFocus={() => startEditing(groupPath, name)}
        onBlur={() => finishEditing(groupPath, name)}
        errorMessage={error}
        disabled={disabled}
        optionDisplay="old"
      />
    </>
  );
};

export default FormInputDropdown;

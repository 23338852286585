/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useState } from "react";
import ReactPaginate from "react-paginate";
import { theme } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import Typography from "../Typography";
import SelectInput from "../Input/SelectInputs/SelectInput";
import { useTableContext } from "../../contexts/table";

export interface PaginatorProps {
  total: number;
}

const styles = {
  paginator: css`
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: ${theme.colors.Black[60]};
    gap: 4px;

    li {
      border: 1px solid transparent;

      &.next,
      &.previous {
        font-weight: bold;
        color: ${theme.colors.Green[100]};
      }

      &:hover:not(.break):not(.selected) {
        background-color: ${theme.colors.Green[20]};
        border-radius: 50%;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 36px;
        height: 36px;
      }

      &.selected a {
        color: ${theme.colors.Green[100]};
        border: 1px solid ${theme.colors.Green[100]};
        border-radius: 50%;
        font-weight: bold;
      }
    }
  `,
};

const LIMIT_OPTIONS = [9, 27, 90];

const Paginator: FC<PaginatorProps> = ({ total }) => {
  const { paginator } = useTableContext();
  const { currentPage, limit, onPaginatorChange } = paginator;
  const [values, setValues] = useState({
    currentPage,
    limit,
  });

  const handleChange = (values: { currentPage: number; limit: number }) => {
    setValues(values);
    onPaginatorChange(values);
  };

  const pageCount = Math.max(Math.ceil(total / values.limit), 1);
  const firstIndex = currentPage * limit + 1;
  const lastIndex = Math.min(total, firstIndex + limit - 1);
  return (
    <Row
      style={{
        marginBottom: 0,
        marginTop: 10,
      }}
    >
      <Column xs={4}></Column>
      <Column xs={4}>
        <ReactPaginate
          css={styles.paginator}
          pageCount={pageCount}
          previousLabel={<Icon name={IconNames.ChevronLeft} />}
          nextLabel={<Icon name={IconNames.ChevronRight} />}
          onPageChange={({ selected }) =>
            handleChange({ ...values, currentPage: selected })
          }
          forcePage={values.currentPage}
        />
      </Column>
      <Column
        xs={4}
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          paddingRight: 20,
          gap: 20,
        }}
      >
        <Typography variant="textMedium">Resultados por página:</Typography>
        <SelectInput
          inputName="paginatorLimit"
          placeholder="Por página"
          options={LIMIT_OPTIONS.map((limit) => ({
            label: String(limit),
            value: String(limit),
          }))}
          value={String(values.limit)}
          onSelect={({ value }) =>
            handleChange({ currentPage: 0, limit: Number(value) })
          }
          menuPlacement="top"
          disableSearch
        />
        <Typography variant="textMedium">
          Mostrando {firstIndex} - {lastIndex} de {total}
        </Typography>
      </Column>
    </Row>
  );
};

export default Paginator;

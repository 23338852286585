import { IconNames } from "../components/Icons/styles/iconNames";
import { ContactTags, UserStatus } from "../graphql/generated/types";
import { ContactsLocation } from "../Locations";
import { contactTagLabels } from "../shared/contacts/contactTags";
import { responsibleFilter } from "./common";
import { TFilter } from "./types";

const stateOptions = [
  { value: "AC", label: "Acre", filter: { state: "AC" } },
  { value: "AL", label: "Alagoas", filter: { state: "AL" } },
  { value: "AM", label: "Amazonas", filter: { state: "AM" } },
  { value: "AP", label: "Amapá", filter: { state: "AP" } },
  { value: "BA", label: "Bahia", filter: { state: "BA" } },
  { value: "CE", label: "Ceará", filter: { state: "CE" } },
  { value: "DF", label: "Distrito Federal", filter: { state: "DF" } },
  { value: "ES", label: "Espírito Santo", filter: { state: "ES" } },
  { value: "GO", label: "Goiás", filter: { state: "GO" } },
  { value: "MA", label: "Maranhão", filter: { state: "MA" } },
  { value: "MG", label: "Minas Gerais", filter: { state: "MG" } },
  { value: "MS", label: "Mato Grosso do Sul", filter: { state: "MS" } },
  { value: "MT", label: "Mato Grosso", filter: { state: "MT" } },
  { value: "PA", label: "Pará", filter: { state: "PA" } },
  { value: "PB", label: "Paraíba", filter: { state: "PB" } },
  { value: "PE", label: "Pernambuco", filter: { state: "PE" } },
  { value: "PI", label: "Piauí", filter: { state: "PI" } },
  { value: "PR", label: "Paraná", filter: { state: "PR" } },
  { value: "RJ", label: "Rio de Janeiro", filter: { state: "RJ" } },
  { value: "RN", label: "Rio Grande do Norte", filter: { state: "RN" } },
  { value: "RO", label: "Rondônia", filter: { state: "RO" } },
  { value: "RR", label: "Roraima", filter: { state: "RR" } },
  { value: "RS", label: "Rio Grande do Sul", filter: { state: "RS" } },
  { value: "SC", label: "Santa Catarina", filter: { state: "SC" } },
  { value: "SP", label: "São Paulo", filter: { state: "SP" } },
  { value: "SE", label: "Sergipe", filter: { state: "SE" } },
  { value: "TO", label: "Tocantins", filter: { state: "TO" } },
];

const contactTagFilter: TFilter = {
  label: "Tipo",
  value: [],
  urlParam: "tipo",
  name: "tipo",
  selectMultiValueDisplay: "all",
  options: Object.values(ContactTags).map((tag) => {
    const label = contactTagLabels[tag];
    return {
      value: label.toLowerCase(),
      name: tag,
      label,
      filter: {
        tags: tag,
      },
    };
  }),
};

export const contactStatusFilter: TFilter = {
  label: "Status de usuario",
  value: [],
  urlParam: "status",
  name: "status",
  selectOptionDisplay: "icon",
  selectMenuListDisplay: "buttons",
  selectMultiValueDisplay: "all",
  options: [
    {
      value: "cadastrado",
      label: "Cadastrados",
      filter: {
        status: UserStatus.Registered,
      },
      iconName: IconNames.Pending,
    },
    {
      value: "aprovado",
      label: "Aprovados",
      filter: {
        status: UserStatus.Approved,
      },
      iconName: IconNames.Done,
    },
    {
      value: "ativo",
      label: "Ativos",
      filter: {
        status: UserStatus.Active,
      },
      iconName: IconNames.Valid,
    },
    {
      value: "recusado",
      label: "Recusados",
      filter: {
        status: UserStatus.Rejected,
      },
      iconName: IconNames.Rejected,
    },
  ],
};

export const getFilterLink = (
  contactTag: ContactTags,
  contactStatus?: UserStatus
) => {
  const params = new URLSearchParams();
  const contactTagFilterValue = contactTagFilter.options.find(
    (opt) => opt.name === contactTag
  )!.value;
  params.append(contactTagFilter.urlParam, contactTagFilterValue);
  if (contactStatus) {
    const contactStagFilterValue = contactStatusFilter.options.find(
      (opt) => opt.filter.status === contactStatus
    )!.value;
    params.append(contactStatusFilter.urlParam, contactStagFilterValue);
  }
  const tagFilterUrl = `${ContactsLocation}?${params.toString()}`;
  return tagFilterUrl;
};

export const contactFilters = (): TFilter[] => [
  contactTagFilter,
  {
    label: "Estado",
    value: [],
    urlParam: "estado",
    name: "state",
    selectMultiValueDisplay: "all",
    options: stateOptions,
  },
  contactStatusFilter,
  responsibleFilter,
];

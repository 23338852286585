import { dashboardDocumentTypeFilters } from "../filters/documents";
import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";

const useDashboardDocumentsTypeFilter = () => {
  const filters = useModelFilter(dashboardDocumentTypeFilters, []);

  useUrlFilter(filters);

  return filters;
};

export default useDashboardDocumentsTypeFilter;

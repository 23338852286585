import { FC } from "react";

const Kovr: FC = () => {
  return (
    <svg
      width="256"
      height="128"
      viewBox="0 0 256 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.0355 64.3242C55.0355 62.0815 55.0378 59.8388 55.04 57.596C55.0466 50.8689 55.0533 44.1417 55 37.4146C55 36.1028 55.3909 35.5001 56.6704 35.2519C57.9498 35.0037 59.1937 34.6492 60.4376 34.2237C61.9302 33.6919 62.4278 34.0819 62.4278 35.6773C62.3745 39.7988 62.3811 43.9203 62.3878 48.0418C62.39 49.4157 62.3923 50.7896 62.3923 52.1635V69.536C62.3923 69.6411 62.3849 69.7512 62.3775 69.8624C62.3449 70.3515 62.3108 70.8649 62.8898 71.096C63.4635 71.2963 63.7826 70.896 64.0899 70.5105C64.1633 70.4184 64.236 70.3271 64.3114 70.2451C65.4677 68.9666 66.6256 67.6881 67.7841 66.409L67.796 66.3958L67.7974 66.3943C72.2243 61.5063 76.6598 56.6089 81.0506 51.6671C81.8324 50.7808 82.6499 50.4262 83.7871 50.4971C84.701 50.5377 85.6148 50.5319 86.5287 50.5261C87.2141 50.5217 87.8996 50.5174 88.585 50.5326C88.7489 50.5417 88.9316 50.5205 89.1179 50.4989C89.6587 50.4361 90.2307 50.3698 90.4685 51.029C90.6902 51.6648 90.2421 52.0635 89.8149 52.4436C89.6942 52.551 89.5752 52.6569 89.4735 52.7662C88.4945 53.8234 87.5183 54.8834 86.5418 55.9439L86.5414 55.9444C84.0794 58.618 81.615 61.2943 79.0959 63.9343C78.1008 64.9624 77.9586 65.707 78.6339 66.9833C82.8631 74.9959 87.0212 83.0795 91.1793 91.163C92.1744 93.113 92.0323 93.3612 89.8644 93.3966C89.4075 93.3966 88.9505 93.3908 88.4936 93.385C87.3513 93.3706 86.2089 93.3561 85.0666 93.4321C83.9648 93.503 83.3962 93.0421 82.8986 92.0848C80.3216 87.0562 77.722 82.0726 75.1179 77.0802C74.4539 75.8072 73.7895 74.5337 73.1252 73.2587C72.059 71.2023 72.0235 71.2023 70.3531 72.8687C68.8605 74.3578 67.3323 75.8823 65.8751 77.4068C63.5651 79.8177 62.3923 82.6895 62.4278 86.0576C62.4278 86.7076 62.4199 87.3576 62.412 88.0076C62.3962 89.3076 62.3804 90.6076 62.4278 91.9075C62.4633 93.0775 62.0013 93.4321 60.864 93.4321L60.7594 93.4352C55.0355 93.6095 55.0355 93.6096 55.0355 87.7949V64.3242Z"
        fill="black"
      />
      <path
        d="M140.439 50.3147C141.353 50.2953 141.813 50.2855 142.108 50.5006C142.406 50.7183 142.536 51.166 142.797 52.0669C142.933 52.534 143.104 53.1234 143.352 53.8653C146.479 63.2607 149.535 72.656 152.592 82.0868L152.592 82.0872C152.734 82.477 152.876 82.8669 152.983 83.2567C153.089 83.6467 153.338 83.8949 153.694 83.9304C154.227 84.0013 154.404 83.6113 154.546 83.2213C155.08 81.7322 155.613 80.2077 156.11 78.7186L156.112 78.7148C159.132 69.8525 162.152 60.9903 165.102 52.0926C165.528 50.7808 166.097 50.1072 167.59 50.2845C168.536 50.3839 169.5 50.3613 170.457 50.339C170.866 50.3294 171.273 50.3199 171.677 50.3199C173.631 50.3199 173.773 50.4972 173.063 52.3763C171.357 57.0562 169.642 61.7274 167.927 66.3985L167.927 66.3996C166.212 71.0703 164.497 75.7409 162.792 80.4204C162.223 81.9619 161.655 83.5122 161.087 85.0626C160.518 86.6145 159.949 88.1664 159.38 89.7094C158.314 92.6521 156.359 94.0348 153.374 93.9993C150.424 93.9639 148.611 92.6166 147.616 89.7094L134.715 52.4826C134.04 50.4972 134.111 50.3554 136.172 50.3199H138.483C139.302 50.3388 139.939 50.3253 140.439 50.3147Z"
        fill="black"
      />
      <path
        d="M177.825 58.4034V74.7123C177.825 75.8092 177.823 76.9082 177.821 78.0095C177.814 81.3222 177.807 84.6549 177.861 88.0076C177.861 88.5026 177.797 89.0366 177.734 89.572C177.58 90.8775 177.424 92.1918 178.18 92.9712C178.934 93.7233 180.114 93.5963 181.323 93.4662C181.995 93.3939 182.676 93.3206 183.298 93.3966C185.04 93.6093 185.359 92.8293 185.359 91.2694C185.324 86.1299 185.324 80.9993 185.324 75.8687V75.864C185.324 70.7322 185.324 65.6004 185.288 60.4598C185.253 58.8643 185.786 58.2971 187.279 58.0134C191.081 57.3043 194.92 56.9498 198.758 56.9498C199.789 56.9498 200.251 56.6307 200.215 55.5316C200.145 54.2316 200.179 52.9663 200.214 51.6669V51.6665L200.215 51.6317C200.251 50.6035 199.895 50.1426 198.793 50.1426C193.249 50.1781 187.812 50.5681 182.481 52.1989C179.104 53.2271 177.825 54.9289 177.825 58.4034Z"
        fill="black"
      />
      <path
        d="M124.906 71.8051C124.978 68.756 124.587 65.8133 123.343 63.0124C122.099 60.2116 120.18 58.0489 117.301 56.8789C116.128 56.418 115.773 55.6734 115.737 54.468C115.631 49.6817 116.093 49.5044 120.5 51.1353C127.75 53.8298 131.197 59.5379 132.334 66.806C133.258 72.975 132.796 79.0377 129.598 84.6394C127.11 89.0358 123.272 91.6948 118.438 93.0066C116.448 93.5384 115.808 93.0421 115.773 91.0566V88.5749C115.773 87.8658 116.057 87.2985 116.732 87.0503C122.845 84.7813 124.338 79.6759 124.906 73.9678C124.93 73.47 124.922 72.9882 124.914 72.5115C124.91 72.2752 124.906 72.0401 124.906 71.8051Z"
        fill="black"
      />
      <path
        d="M109.056 57.0916C110.868 56.3117 111.828 55.3899 111.366 53.6881C111.366 50.0717 110.975 49.7881 107.741 50.7808C102.161 52.5181 98.2872 56.0989 96.2259 61.488C93.6315 68.3306 93.7026 75.2796 96.2259 82.0868C98.1451 87.2276 101.841 90.773 107.066 92.5457C110.513 93.7157 111.579 94.0348 111.366 89.603C111.366 89.5189 111.362 89.4389 111.358 89.3602C111.35 89.2066 111.342 89.058 111.366 88.8939C111.472 87.7949 111.046 87.1212 109.98 86.7312C106.461 85.4194 104.329 82.7604 103.263 79.2504C101.699 74.145 101.735 69.0042 103.334 63.9343C104.258 60.8852 106.035 58.3325 109.056 57.0916Z"
        fill="black"
      />
    </svg>
  );
};

export default Kovr;

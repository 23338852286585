import { FC, useEffect, useState } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import VariableFieldColumn from "../utils/VariableFieldColumn";
import PolygonInput from "../../../../components/Input/PolygonInput";
import useForm from "../../hooks/useForm.v2";
import { LatLng } from "../../../../types";

interface PolygonFieldProps {
  field: FormFieldV2;
}

const PolygonField: FC<PolygonFieldProps> = ({ field }) => {
  const { label, value, error, name, variant } = field;

  const { handleChange, startEditing, finishEditing } = useFormField(field);
  const { form } = useForm();
  const propertyInfoSection = form?.sections.find(
    (section) => section.id === "propertyInfo"
  );
  const entranceLocation = propertyInfoSection?.fields[0]?.fields?.find(
    (field) => field.name === "entranceLocation"
  );
  const [polygonPoints, setPolygonPoints] = useState<LatLng[]>(value || []);
  const handleMapClick = (latLng: LatLng) => {
    setPolygonPoints([...polygonPoints, latLng]);
  };
  const [filledOnce, setFilledOnce] = useState(false);
  useEffect(() => {
    startEditing();
    handleChange(polygonPoints);
    finishEditing();
    if (polygonPoints.length > 2) {
      setFilledOnce(true);
    }
  }, [polygonPoints]);

  return (
    <VariableFieldColumn variant={variant}>
      <PolygonInput
        defaultCenter={entranceLocation?.value || {}}
        error={filledOnce && error ? error : ""}
        value={value}
        handleChange={handleMapClick}
        handleDelete={(index) =>
          setPolygonPoints(polygonPoints.filter((_, i) => i !== index))
        }
        label={label}
        name={name}
      />
    </VariableFieldColumn>
  );
};

export default PolygonField;

/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { FC, useMemo } from "react";
import Typography from "../../../../../components/Typography";
import Chart, { chartTitleHeight, TStatusChart } from "./Chart";
import { ColorNames, theme } from "../../../../../theme";
import CustomLink from "../../../../../components/CustomLink";

export interface StageChartProps {
  label: string;
  count: number;
  color: ColorNames;
  redirectLocation: string;
  dashboardFilters?: string;
  urlParam: [string, string];
  statuses: TStatusChart[];
}

const styles: CSSObject = {
  cardContainer: {
    boxSizing: "border-box" as const,
    border: "2px solid",
    borderColor: theme.colors.Black[100],
    backgroundColor: theme.colors.White[100],
    width: "100%",
    aspectRatio: 1,
    borderRadius: 15,
    padding: 20,
    boxShadow: theme.boxShadow.default,
  },
  text: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    height: chartTitleHeight,
  },
};

const StageChart: FC<StageChartProps> = ({
  label,
  count,
  color,
  redirectLocation,
  urlParam,
  statuses,
  dashboardFilters,
}) => {
  const queryFilter = useMemo(() => {
    const queryFilter = `${urlParam[0]}=${urlParam[1]}`;
    return dashboardFilters
      ? `${dashboardFilters}&${queryFilter}`
      : queryFilter;
  }, [urlParam, dashboardFilters]);

  return (
    <div css={styles.cardContainer}>
      <CustomLink to={count ? `${redirectLocation}?&${queryFilter}` : ""}>
        <Typography
          variant="textExtraLarge"
          align="center"
          weight="bold"
          component="div"
          css={styles.text}
        >
          {count}{" "}
          <Typography
            variant="textLarge"
            align="center"
            uppercase
            weight="bold"
          >
            {label}
          </Typography>
        </Typography>
      </CustomLink>
      <Chart
        statuses={statuses}
        color={color}
        urlParam={urlParam}
        redirectLocation={redirectLocation}
      />
    </div>
  );
};

export default StageChart;

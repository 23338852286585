/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames } from "../../../../theme";
import Avatar from "../../../Avatar";
import Typography from "../../../Typography";
import { SelectOptionDisplay } from "../SelectInput";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";
import { IconSizes } from "../../../Icons/styles/iconSizes";

const styles = {
  container: {
    display: "flex" as "flex",
    alignItems: "center" as "center",
    width: "100%",
  },
  optionLabel: {
    marginLeft: 6,
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  },
};

interface AvatarTemplateProps {
  label: string;
  color?: ColorNames;
  iconName?: IconNames;
  optionDisplay?: SelectOptionDisplay;
}

const OptionTemplate: FC<AvatarTemplateProps> = ({
  label,
  color = ColorNames.GREEN,
  iconName,
  optionDisplay,
}) => {
  return (
    <div css={styles.container}>
      {optionDisplay === "avatar" && (
        <Avatar name={label} filled color={color} />
      )}
      {optionDisplay === "icon" && iconName && (
        <Icon name={iconName} color={color} size={IconSizes.Small} />
      )}
      <Typography
        variant="textMedium"
        align="left"
        uppercase
        css={styles.optionLabel}
      >
        {label || "Não adicionado"}
      </Typography>
    </div>
  );
};

export default OptionTemplate;

/** @jsxImportSource @emotion/react */

import { FC } from "react";

import Typography, { TypographyVariants } from "../../Typography";
import { theme } from "../../../theme";
import { useCSSRulesWithTheme } from "../../../hooks/useCSSRulesWithTheme";

const getCSSRules = () => ({
  typography: {
    color: "transparent",
    backgroundColor: `${theme.colors.Grey[20]}`,
  },
});

interface SkeletonTypographyProps {
  variant: TypographyVariants;
}

const SkeletonTypography: FC<SkeletonTypographyProps> = ({ variant }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <Typography variant={variant} component="div">
      <div css={styles.typography}>fake text</div>
    </Typography>
  );
};

export default SkeletonTypography;

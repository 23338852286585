import { porExtenso } from "numero-por-extenso";
import { Address } from "../../../graphql/generated/types";

export const getAddressString = (address: Address) => {
  return `${address.street}, ${address.number}${
    address.complement ? ", " + address.complement : ""
  }, ${address.neighborhood}`;
};

export const getCommissionValueString = (commission: number) => {
  const commissionInPercentage = commission! * 100;
  const writtenOutCommission = porExtenso(
    commissionInPercentage,
    "porcentagem"
  );
  const commissionValueString = `${commissionInPercentage}% (${writtenOutCommission})`;
  return commissionValueString;
};

/** @jsxImportSource @emotion/react */
import { FC, CSSProperties } from "react";
import { FormSection } from "../models/formInterfaces";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";
import Container from "../../../components/Grid/Container";
import SectionTitle from "./SectionTitle";
import FormSectionRows from "./FormSectionRows";

interface FormSectionProps {
  sectionData: FormSection;
  large?: boolean;
}

const styles = {
  container: {
    justifyContent: "space-evenly" as CSSProperties["justifyContent"],
  },
};

const GenericFormSection: FC<FormSectionProps> = ({ sectionData, large }) => {
  return (
    <Container css={styles.container}>
      <Row>
        <Column xs={12}>
          <SectionTitle sectionData={sectionData} />
        </Column>
      </Row>
      <FormSectionRows
        fields={sectionData.fields}
        groupPath={""}
        large={large}
      />
    </Container>
  );
};

export default GenericFormSection;

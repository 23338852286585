/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames, theme } from "../../../../../theme";
import Typography from "../../../../../components/Typography";
import { ResultTags } from "../../../../../graphql/generated/types";
import useIsMobile from "../../../../../hooks/useIsMobile";

interface CardTagsProps {
  tags: ResultTags[];
}

const CardTagLabels = {
  [ResultTags.HighestProductivity]: "Maior produtividade",
  [ResultTags.HighestSackValue]: "Melhor valor unitário",
  [ResultTags.LowestCost]: "Menor custo",
};

const styles = {
  tag: {
    backgroundColor: theme.colors.Green[100],
    padding: "2.5px 10px",
    borderRadius: 20,
    height: "100%",
  },
};

const CardTags: FC<CardTagsProps> = ({ tags }) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return null;
  }
  return (
    <>
      {tags.map((tag, index) => (
        <Typography
          key={`tag-${index}`}
          variant="textSmall"
          color={ColorNames.WHITE}
          lowercase
          css={styles.tag}
        >
          {CardTagLabels[tag]}
        </Typography>
      ))}
    </>
  );
};

export default CardTags;

/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { FC, useEffect, useState } from "react";
import { ColorNames } from "../../theme";
import { LatLng } from "../../types";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import Map from "../Map";
import Typography from "../Typography";
import TextInput from "./TextInput";
import useDebounce from "../../hooks/useDebounce";
import {
  GMSToDecimalCoordValue,
  decimalCoordToGMSValue,
} from "../../utils/formatNumber";

interface GeoLocationInputProps {
  value: LatLng;
  label: string;
  handleChange: (latLng: LatLng) => void;
}

const styles: CSSObject = {
  mapContainer: {
    height: 270,
  },
};

const GeoLocationInput: FC<GeoLocationInputProps> = ({
  value,
  label,
  handleChange,
}) => {
  const [coords, setCoords] = useState({
    lat: decimalCoordToGMSValue(value?.lat || 0),
    lng: decimalCoordToGMSValue(value?.lng || 0),
  });
  const { debouncedValue: debouncedCoords } = useDebounce(coords, 500);

  useEffect(() => {
    handleChange({
      lat: GMSToDecimalCoordValue(debouncedCoords.lat),
      lng: GMSToDecimalCoordValue(debouncedCoords.lng),
    });
  }, [debouncedCoords]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const { latLng } = event;
    const newLat = latLng!.lat();
    const newLng = latLng!.lng();
    setCoords({
      lat: decimalCoordToGMSValue(newLat),
      lng: decimalCoordToGMSValue(newLng),
    });
    handleChange({
      lat: newLat,
      lng: newLng,
    });
  };

  const handleCoordChange = (
    type: "lat" | "lng",
    unit: "coordG" | "coordM" | "coordS",
    value: string
  ) => {
    const strippedValue = value.replace(/[^\d,-]/g, "");
    setCoords((prevCoords) => ({
      ...prevCoords,
      [type]: {
        ...prevCoords[type],
        [unit]: strippedValue,
      },
    }));
  };

  return (
    <>
      <Row>
        <Column xs={12} sm={8}>
          <Typography
            variant="textMedium"
            component="div"
            color={ColorNames.GREEN}
            align="left"
          >
            {`SUL latitude`}
          </Typography>
          <Row>
            <Column xs={4}>
              <TextInput
                placeholder={"graus"}
                value={coords.lat.coordG + " °"}
                onChange={(e) =>
                  handleCoordChange("lat", "coordG", e.target.value)
                }
                type="text"
              />
            </Column>
            <Column xs={3}>
              <TextInput
                placeholder={"minutos"}
                value={coords.lat.coordM + " '"}
                onChange={(e) =>
                  handleCoordChange("lat", "coordM", e.target.value)
                }
                type="text"
              />
            </Column>
            <Column xs={5}>
              <TextInput
                placeholder={"segundos"}
                value={coords.lat.coordS + " ''"}
                onChange={(e) =>
                  handleCoordChange("lat", "coordS", e.target.value)
                }
                type="text"
              />
            </Column>
          </Row>
        </Column>
        <Column xs={12} sm={8}>
          <Typography
            variant="textMedium"
            component="div"
            color={ColorNames.GREEN}
            align="left"
          >
            {`OESTE longitude`}
          </Typography>
          <Row>
            <Column xs={4}>
              <TextInput
                placeholder={"graus"}
                value={coords.lng.coordG + " °"}
                onChange={(e) =>
                  handleCoordChange("lng", "coordG", e.target.value)
                }
                type="text"
              />
            </Column>
            <Column xs={3}>
              <TextInput
                placeholder={"minutos"}
                value={coords.lng.coordM + " '"}
                onChange={(e) =>
                  handleCoordChange("lng", "coordM", e.target.value)
                }
                type="text"
              />
            </Column>
            <Column xs={5}>
              <TextInput
                placeholder={"segundos"}
                value={coords.lng.coordS + " ''"}
                onChange={(e) =>
                  handleCoordChange("lng", "coordS", e.target.value)
                }
                type="text"
              />
            </Column>
          </Row>
        </Column>
      </Row>
      <Row>
        <Column>
          <Typography
            variant="textMedium"
            component="div"
            color={ColorNames.GREEN}
            align="left"
          >
            {`Clique na localização da ${label}`}
          </Typography>
        </Column>
        <Column xs={12} css={styles.mapContainer}>
          <Map
            center={value && value.lat ? value : { lat: -23, lng: -45 }}
            onClick={handleMapClick}
            markers={value ? [value] : []}
          />
        </Column>
      </Row>
    </>
  );
};

export default GeoLocationInput;

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import { ColorNames, theme } from "../../../../../theme";
import Typography from "../../../../../components/Typography";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import { IconSizes } from "../../../../../components/Icons/styles/iconSizes";

interface PrismaRecommendationProps {
  active?: boolean;
  selectable?: boolean;
}

export const recommendationHeight = 45;

const getCSSRules: CSSRulesResolver<PrismaRecommendationProps> = ({
  active,
  selectable,
}) => ({
  recommendation: {
    backgroundColor:
      selectable && active ? theme.colors.Blue[100] : theme.colors.Black[100],
    borderColor:
      selectable && active ? theme.colors.Blue[100] : theme.colors.Black[100],
    borderRadius: "15px 15px 0 0",
    height: recommendationHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
});

const PrismaRecommendation: FC<PrismaRecommendationProps> = ({
  active,
  selectable,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { active, selectable });
  return (
    <Typography
      variant="textLarge"
      uppercase
      weight="bold"
      component="div"
      color={ColorNames.WHITE}
      css={styles.recommendation}
    >
      <Icon
        name={IconNames.StarFilled}
        size={IconSizes.Small}
        color={ColorNames.WHITE}
      />
      Indicação Prisma
    </Typography>
  );
};

export default PrismaRecommendation;

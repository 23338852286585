/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/react";

const rotate = keyframes`
from {
  rotate: 0deg;
}
to {
  rotate: 180deg;
}
`;
const rotateBar = {
  animation: `${rotate} 1s infinite`,
};

const LoadingSpinner = () => {
  return <div css={rotateBar}>/</div>;
};

export default LoadingSpinner;

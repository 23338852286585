import { FC } from "react";

const Allianz: FC = () => {
  return (
    <svg
      width="256"
      height="128"
      viewBox="0 0 256 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M205.549 77.4271V50.4398C205.549 48.2634 204.633 47.5532 202.571 47.5532H196.385V50.5315H197.072C198.218 50.5315 198.676 50.9667 198.676 52.2954V77.4271H205.549Z"
        fill="black"
      />
      <path
        d="M209.214 77.4271H215.858V57.0606C215.858 54.9988 214.942 54.174 212.88 54.174H209.214V77.4271Z"
        fill="black"
      />
      <path
        d="M195.01 54.174V77.4271H188.367V57.0606C188.367 54.9988 189.283 54.174 191.345 54.174H195.01Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228 63.8648C228 49.0882 217.003 38 202.112 38C187.45 38 176.225 49.0882 176.225 63.8648C176.225 78.6871 187.45 89.7753 202.112 89.7753C217.003 89.7753 228 78.6871 228 63.8648ZM223.647 63.8648C223.647 76.4878 214.483 85.6973 202.112 85.6973C189.741 85.6973 180.577 76.4878 180.577 63.8648C180.577 51.2417 189.741 42.0321 202.112 42.0321C214.483 42.0321 223.647 51.2417 223.647 63.8648Z"
        fill="black"
      />
      <path
        d="M88.7099 46.3635C91.23 46.3635 92.8336 47.6006 92.8336 49.7082C92.8336 51.8617 91.23 53.1217 88.7099 53.1217C85.9608 53.1217 84.5862 51.8617 84.5862 49.7082C84.5862 47.6006 85.9608 46.3635 88.7099 46.3635Z"
        fill="black"
      />
      <path
        d="M92.6045 78.7344V57.2454C92.6045 55.7563 91.6881 54.8857 90.3136 54.8857H83.2117V57.4745H83.6699C85.0444 57.4745 85.5026 58.0472 85.5026 59.3531V78.7344H92.6045Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6219 71.014L35.331 78.7345H28L36.2474 53.6945C36.9347 52.1138 36.9347 51.6785 36.9347 51.0141C36.9347 49.9603 36.2474 49.525 34.8728 49.525H33.9564V46.8904H45.4111C47.473 46.8904 48.3893 47.509 48.8475 49.4334L57.0949 78.7345H49.3057L47.0148 71.014H37.6219ZM38.7674 65.9281H46.0984L42.662 53.2592L38.7674 65.9281Z"
        fill="black"
      />
      <path
        d="M66.9459 78.7345V49.2272C66.9459 47.6923 66.0295 46.8904 64.4259 46.8904H57.324V49.525H57.7822C59.1567 49.525 59.844 50.0977 59.844 51.3349V78.7345H66.9459Z"
        fill="black"
      />
      <path
        d="M79.7753 78.7345V49.2272C79.7753 47.6923 78.8589 46.8904 77.2552 46.8904H70.1533V49.525H70.6115C71.9861 49.525 72.6734 50.0977 72.6734 51.3349V78.7345H79.7753Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.165 61.1858L99.4772 61.4607L97.8736 57.0621C99.9354 55.9854 100.852 55.6646 102.685 55.2294C104.517 54.7941 106.35 54.565 108.412 54.565C114.368 54.565 116.43 56.6268 116.43 62.7207V74.4274C116.43 75.6645 117.117 76.2831 118.492 76.2831H118.721V78.7344H111.848C110.474 78.7344 109.786 78.0242 109.786 76.5351V75.802C108.412 77.062 107.954 77.3369 106.808 77.8638C105.663 78.5969 104.059 78.9635 102.455 78.9635C98.3318 78.9635 96.499 76.81 96.499 72.2968C96.499 68.7001 97.8736 66.4091 100.852 65.2179C101.768 64.8284 102.685 64.5306 103.601 64.3015C105.434 63.912 106.35 63.7287 109.557 63.2018V62.6062C109.557 60.4985 108.87 59.7425 106.579 59.7425C106.121 59.7425 105.205 59.8342 104.517 59.9258C103.143 60.1778 101.997 60.4527 100.165 61.1858ZM109.557 71.9303V67.1652C107.496 67.5088 106.35 67.8066 105.205 68.2877C103.601 68.9063 103.143 69.6623 103.143 71.6325C103.143 73.4194 103.83 74.0838 105.205 74.0838C106.579 74.0838 108.183 73.3049 109.557 71.9303Z"
        fill="black"
      />
      <path
        d="M129.489 62.5604V78.7345H122.387V59.3531C122.387 58.0473 121.699 57.4746 120.325 57.4746H120.096V54.8858H126.969C128.343 54.8858 129.03 55.6189 129.03 57.108V58.3909C132.238 55.6189 134.3 54.6109 137.049 54.6109C139.11 54.6109 140.485 55.1836 141.631 56.4207C142.547 57.6807 143.005 59.124 143.005 62.4688V78.7345H135.903V63.6143C135.903 61.1859 135.445 60.4986 133.841 60.4986C132.238 60.4986 131.092 61.0713 129.489 62.5604Z"
        fill="black"
      />
      <path
        d="M154.461 74.174H164.999V78.7329H146.672V73.7844L156.981 59.3974H151.483C150.108 59.3974 149.421 59.9243 149.421 61.2302V61.7113H146.901V57.3814C146.901 55.6174 147.588 54.8842 149.421 54.8842H164.541V59.7869L154.461 74.174Z"
        fill="black"
      />
    </svg>
  );
};

export default Allianz;

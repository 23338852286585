import { FC } from "react";

const Incomplete: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_807)">
        <path
          d="M23.7642 6.85929L25.0493 5.32779C23.8079 4.27706 22.3926 3.45106 20.8672 2.88699L20.1836 4.76459C21.4899 5.24917 22.7016 5.95804 23.7642 6.85929Z"
          fill="black"
        />
        <path
          d="M27.81 14L29.7777 13.5872C29.5011 11.9894 28.9468 10.4523 28.14 9.0457L26.4087 10C27.0997 11.244 27.5736 12.5967 27.81 14Z"
          fill="black"
        />
        <path
          d="M20.1836 27.2354L20.8672 29.113C22.3926 28.5489 23.8079 27.7229 25.0493 26.6722L23.7642 25.1407C22.7016 26.042 21.4899 26.7508 20.1836 27.2354Z"
          fill="black"
        />
        <path
          d="M26.4087 22L28.14 23C28.9473 21.5772 29.5016 20.0251 29.7782 18.4128L27.81 18.0659C27.5734 19.4483 27.0993 20.7793 26.4087 22Z"
          fill="black"
        />
        <path
          d="M16 30V2C12.287 2 8.72601 3.475 6.1005 6.1005C3.475 8.72601 2 12.287 2 16C2 19.713 3.475 23.274 6.1005 25.8995C8.72601 28.525 12.287 30 16 30Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_807">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Incomplete;

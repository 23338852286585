import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Table from "../../../../../components/Table";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import ForestItemData from "./ForestItemData";

const ForestItemsTable: FC = () => {
  const {
    documentData: { forestItems },
  } = useDocumentDetails();

  const forestItemsList = useMemo(() => {
    if (!forestItems) return [];
    return forestItems.map((item) => ({
      ...item,
      area: `${item.area} ha`,
      harvestCycleDuration: `${item.harvestCycleDuration} anos`,
    }));
  }, [forestItems]);

  return (
    <DetailsSection title="Florestas">
      <Table
        data={forestItemsList}
        columns={[
          {
            label: "Espécie",
            key: "species",
            special: "many2one",
          },
          {
            label: "Tipo de manejo",
            key: "management",
            special: "many2one",
          },
          {
            label: "Ciclo florestal",
            key: "harvestCycleDuration",
          },
          {
            label: "Ano de plantio",
            key: "plantYear",
          },
          {
            label: "Area",
            key: "area",
          },
          {
            label: "Propriedade",
            key: "property",
            special: "many2one",
          },
        ]}
        DetailsComponent={ForestItemData}
      />
    </DetailsSection>
  );
};

export default ForestItemsTable;

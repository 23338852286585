/** @jsxImportSource @emotion/react */

import { FC, useCallback, useMemo } from "react";
import Row from "../../../../../../components/Grid/Row";
import Column from "../../../../../../components/Grid/Column";
import {
  ColorLevels,
  ColorNames,
  getThemeColorFromAlias,
  theme,
} from "../../../../../../theme";
import ChartBar from "./components/ChartBar";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import { useLocation } from "react-router-dom";

export type TStatusChart = {
  label: string;
  count: number;
  iconName: IconNames;
  urlParam: [string, string];
  mergeFilters?: boolean;
};

interface ChartProps {
  statuses: TStatusChart[];
  color: ColorNames;
  urlParam: [string, string];
  redirectLocation: string;
}

const getColorLevel = (index: number) => {
  return 100 - (index % 5) * 20;
};

export const chartTitleHeight = 40;

const styles = {
  chart: {
    height: `calc(100% - ${chartTitleHeight}px)`,
  },
  chartBar: {
    height: "100%",
  },
};

const Chart: FC<ChartProps> = ({
  statuses,
  color,
  urlParam,
  redirectLocation,
}) => {
  const getColor = (index: number) =>
    getThemeColorFromAlias(
      color,
      theme.colors,
      getColorLevel(index) as ColorLevels
    );
  const maxCount = useMemo(() => {
    return statuses
      .map((status) => status.count)
      .reduce((a, b) => Math.max(a, b), 0);
  }, [statuses]);

  const location = useLocation();

  const composeQueryFilter = useCallback(
    ({ mergeFilters = true, ...status }: TStatusChart) => {
      const dashboardFilters = location.search.split("?")[1];
      const queryFilter = mergeFilters
        ? `${urlParam[0]}=${urlParam[1]}&${status.urlParam[0]}=${status.urlParam[1]}`
        : `${status.urlParam[0]}=${status.urlParam[1]}`;
      return dashboardFilters
        ? `${dashboardFilters}&${queryFilter}`
        : queryFilter;
    },
    [urlParam, location]
  );
  return (
    <Row
      noGutters
      noMargins
      noWrap
      align="end"
      justify="center"
      css={styles.chart}
    >
      {statuses?.map((status, index) => (
        <Column xs={2} key={`chart-bar-${index}`} css={styles.chartBar}>
          <ChartBar
            label={status.label}
            count={status.count}
            maxCount={maxCount}
            iconName={status.iconName}
            color={getColor(index)!}
            redirectTo={`${redirectLocation}?&${composeQueryFilter(status)}`}
          />
        </Column>
      ))}
    </Row>
  );
};

export default Chart;

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Row } from "react-grid-system";
import Column from "../../../../../../components/Grid/Column";
import SkeletonTypography from "../../../../../../components/Skeletons/components/SkeletonTypography";
import { theme } from "../../../../../../theme";

const SkeletonFunnelCard: FC = () => {
  return (
    <>
      <Row
        css={{
          backgroundColor: theme.colors.White[100],
          borderRadius: "15px",
          padding: "12px 24px 12px 24px",
          border: "1px solid",
          borderColor: theme.colors.Grey[20],
        }}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Column
          xs={2}
          md={1}
          css={{ alignContent: "center" }}
          style={{ padding: 0 }}
        >
          <SkeletonTypography variant="textMedium" />
        </Column>

        <Column xs={10} md={11}>
          <Row>
            <Column>
              <SkeletonTypography variant="textMedium" />
            </Column>
          </Row>
          <Row>
            <Column>
              <SkeletonTypography variant="textLarge" />
            </Column>
          </Row>
        </Column>
      </Row>
    </>
  );
};

export default SkeletonFunnelCard;

/** @jsxImportSource @emotion/react */

import { FC, ReactNode, useEffect, useState } from "react";
import { useMatchedRoute } from "../../../../hooks/useMatchRoute";
import { RouteObject } from "../../../../types";

const styles = {
  wrapper: {
    height: "100%",
    boxSizing: "border-box" as "border-box",
    position: "relative" as const,
  },
};

interface DetailsWrapperProps {
  children: ReactNode;
  detailsTabRoutes?: RouteObject[] | any;
  detailsTabContent?: ReactNode;
  detailsFooter?: ReactNode;
}

const DetailsWrapper: FC<DetailsWrapperProps> = ({
  children,
  detailsTabRoutes,
  detailsTabContent,
}) => {
  const detailsTab = detailsTabRoutes && useMatchedRoute(detailsTabRoutes);
  const [showDetailsTab, setShowDetailsTab] = useState(false);

  useEffect(() => {
    setShowDetailsTab(detailsTab);
  }, [detailsTab]);

  return (
    <div css={styles.wrapper}>
      {showDetailsTab ? detailsTabContent : children}
    </div>
  );
};

export default DetailsWrapper;

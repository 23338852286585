import { FC } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import Table from "../../../../../components/Table";
import Typography from "../../../../../components/Typography";
import useDetailsColor from "../../../../../hooks/useDetailsColor";

const ArchivedProposals: FC = () => {
  const {
    documentData: { archivedProposals },
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  return (
    <DetailsSection title={"Propostas arquivadas"} noTitle>
      {archivedProposals?.length ? (
        <Table
          data={archivedProposals}
          color={color}
          withBorder
          columns={[
            {
              key: "externalId",
              label: "ID",
              textAlign: "left",
            },
            {
              key: "provider",
              label: "Seguradora",
              textAlign: "left",
            },
            {
              key: "basicCoverageType",
              label: "Cobertura",
              textAlign: "left",
            },
          ]}
        />
      ) : (
        <Typography variant="textMedium" uppercase color={color}>
          Não há propostas arquivadas
        </Typography>
      )}
    </DetailsSection>
  );
};

export default ArchivedProposals;

import { FC, useMemo } from "react";
import Layout from "../../components/Layout";
import Typography from "../../components/Typography";
import EditableTable from "../../components/EditableTable";
import {
  GetCreateIntegrationSettingsFormResponseDocument,
  GetEditIntegrationSettingsFormResponseDocument,
  GetSwissIntegrationSettingsQuery,
  PropostaProviders,
  useGetSwissIntegrationSettingsQuery,
} from "../../graphql/generated/types";
import { useFlags } from "../../hooks/useFlags";
import SwissSettingsDetails from "./swissSettings";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";

const SwissSettingsTable: FC = () => {
  const { data, refetch } = useGetSwissIntegrationSettingsQuery({
    variables: { provider: PropostaProviders.Swiss },
  });
  const integrationSettings = useMemo<
    GetSwissIntegrationSettingsQuery["integrationSettings"]
  >(() => {
    return data?.integrationSettings || [];
  }, [data]);
  const settings = useMemo(() => {
    return integrationSettings.map((item) => ({ ...item, ...item.swiss }));
  }, [integrationSettings]);

  const { isFlagEnabled } = useFlags();

  if (!isFlagEnabled("admin-only")) {
    return null;
  }

  return (
    <ModalStoreProvider>
      <Layout>
        <Typography variant="h1" align="left">
          Configurações - SwissRe
        </Typography>
        <EditableTable
          withBorder
          data={settings}
          createQuery={GetCreateIntegrationSettingsFormResponseDocument}
          createPayload={{ formId: "swissSettings" }}
          editQuery={GetEditIntegrationSettingsFormResponseDocument}
          editPayload={{ formId: "swissSettings" }}
          onSubmit={refetch}
          DetailsComponent={SwissSettingsDetails}
          columns={[
            {
              label: "Cultura",
              key: "culture",
              special: "many2one",
            },
            {
              label: "Cód. do Produto",
              key: "productCode",
            },
            {
              label: "Cód. do Sistema",
              key: "systemCode",
            },
            {
              label: "Cód. do Tipo de Solo",
              key: "soilTypeCode",
            },
            {
              label: "Valor da Saca",
              key: "valSaca",
              special: "monetary",
            },
            {
              label: "Risco/ha (R.N.)",
              key: "valRiscoNomeado",
              special: "monetary",
            },
            {
              label: "Data de Plantio",
              key: "plantDate",
              special: "date",
            },
          ]}
        />
      </Layout>
    </ModalStoreProvider>
  );
};

export default SwissSettingsTable;

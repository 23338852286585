/** @jsxImportSource @emotion/react */

import SkeletonAnimationContainer from "../../Skeletons/components/SkeletonAnimationContainer";
import SkeletonCardContainer from "../../Skeletons/components/SkeletonContainer";
import SkeletonTypography from "../../Skeletons/components/SkeletonTypography";

const SkeletonDetails = () => {
  return (
    <>
      <SkeletonAnimationContainer />
      <SkeletonCardContainer>
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
        <br />
        <SkeletonTypography variant="textLarge" />
        <SkeletonTypography variant="textLarge" />
      </SkeletonCardContainer>
    </>
  );
};

export default SkeletonDetails;

import { FC, useEffect, useState } from "react";
import PolygonInput from "../../../../components/Input/PolygonInput";
import { LatLng } from "../../../../types";
import { useForm } from "../../hooks/useForm";
import { PolygonFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import { polygonToEvent } from "./utils";

const FormInputPolygon: FC<PolygonFieldType & { groupPath: string }> = ({
  name,
  groupPath,
  label,
  defaultCenter,
}) => {
  const [value, error] = useFieldValue(name, groupPath);
  const [polygons, setPolygons] = useState<LatLng[]>(value || []);
  const { handleChange, startEditing, finishEditing } = useForm();
  const handleMapClick = (latLng: LatLng) => {
    setPolygons([...polygons, latLng]);
  };
  const centerPath = defaultCenter.split(".");
  const [center] = useFieldValue(centerPath.pop()!, centerPath.join("."));
  const [filledOnce, setFilledOnce] = useState(false);
  useEffect(() => {
    startEditing(groupPath, name);
    handleChange(polygonToEvent(polygons, name), groupPath);
    finishEditing(groupPath, name);
    if (polygons.length > 2) {
      setFilledOnce(true);
    }
  }, [polygons]);
  return (
    <PolygonInput
      defaultCenter={center}
      error={filledOnce && error}
      value={value}
      handleChange={handleMapClick}
      handleDelete={(index) =>
        setPolygons(polygons.filter((_, i) => i !== index))
      }
      label={label}
      name={`${groupPath}.${name}`}
    />
  );
};

export default FormInputPolygon;

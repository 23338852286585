import { FC, useMemo } from "react";
import Sidebar, { MenuItem } from "../../components/Sidebar";
import DetailsMenu from "../../components/Details/components/Header/DetailsTabs";
import { ColorNames } from "../../theme";
import ContactPersonalData from "./ContactDetails/components/ContactPersonalData";
import ContactDocuments from "./ContactDetails/components/ContactDocuments";
import Subcontacts from "./ContactDetails/components/Subcontacts";
import ContactPayments from "./ContactDetails/components/ContactPayments";
import ContactClaims from "./ContactDetails/components/ContactClaims";
import { DetailsRowProps } from "../../components/Details/components/Main/Grid/DetailsRow";
import useContactDetails from "../../hooks/useContactDetails";
import { DetailsRowValueVariants } from "../../components/Details/components/Main/Grid/DetailsRowValue";
import { ContactTags } from "../../graphql/generated/types";
import ContactSummary from "./ContactDetails/components/ContactSummary";
import { isDocumentRelatedContact } from "./utils/tags";
import { contactTagLabels } from "../../shared/contacts/contactTags";
import ContactMeetings from "./ContactDetails/components/ContactMeetings";

const ContactFlow: FC = () => {
  const { contactData } = useContactDetails();

  const headerInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Contato",
        value: { text: contactData.name!, Component: ContactPersonalData },
        variant: DetailsRowValueVariants.Modal,
      },
      {
        label: "Tipo(s)",
        value: (contactData.tags || [])
          .map((t: ContactTags) => contactTagLabels[t])
          .join(", "),
      },
    ];
  }, [contactData]);

  const menuItems: MenuItem[] = useMemo(() => {
    const shouldShowDocumentRelatedTabs = isDocumentRelatedContact(
      contactData.tags || []
    );

    const documentRelatedTabs = [
      {
        title: "Documentos",
        Component: ContactDocuments,
        color: ColorNames.BLUE,
      },
      {
        title: "Contatos",
        Component: Subcontacts,
        color: ColorNames.PINK,
      },
      {
        title: "Parcelas",
        Component: ContactPayments,
        color: ColorNames.BLUE,
      },
      {
        title: "Sinistros",
        Component: ContactClaims,
        color: ColorNames.ORANGE,
      },
    ];

    return [
      {
        title: "Geral",
        Component: ContactSummary,
        color: ColorNames.GREEN,
      },
      {
        title: "Conversas",
        color: ColorNames.GREEN,
        Component: ContactMeetings,
      },
      ...(shouldShowDocumentRelatedTabs ? documentRelatedTabs : []),
    ].filter(Boolean) as MenuItem[];
  }, [contactData]);

  return (
    <Sidebar
      TopComponent={() => <DetailsMenu />}
      header={headerInfo}
      menuItems={menuItems}
    />
  );
};

export default ContactFlow;

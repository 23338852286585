import { FC, useMemo } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";
import useFormField from "../../hooks/useFormField";
import { SelectInputOptionInterface } from "../../../../components/Input/SelectInputs/SelectInput";
import FormLabel from "../FormLabel";
import RadioOptionInput from "../../../../components/Input/RadioOptionInput";

interface RadioFieldProps {
  field: FormFieldV2;
}

const RadioField: FC<RadioFieldProps> = ({ field }) => {
  const { options, value, name, label, saveAs } = field;
  const { handleChange, finishEditing, error } = useFormField(field);

  const handleSelect = ({ value, label }: SelectInputOptionInterface) => {
    handleChange(saveAs === "option" ? { value, label } : value);
    finishEditing();
  };

  const inputValue = useMemo(
    () => (saveAs === "option" ? value?.value : value),
    [value, saveAs]
  );

  return (
    <Column useYPaddings xs={12}>
      <FormLabel>{label}</FormLabel>
      <RadioOptionInput
        options={options!}
        value={inputValue}
        name={name}
        onSelect={handleSelect}
        error={error}
      />
    </Column>
  );
};

export default RadioField;

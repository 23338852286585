import { components, DropdownIndicatorProps } from "react-select";
import { IconNames } from "../../../Icons/styles/iconNames";
import Icon from "../../../Icons";
import { ColorNames } from "../../../../theme";
import { SelectOptionDisplay } from "../SelectInput";

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const display = (props.selectProps as any)
    .optionDisplay as SelectOptionDisplay;
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <Icon
          name={IconNames.ChevronUp}
          color={display === "primary" ? ColorNames.BLACK : ColorNames.GREY}
        />
      ) : (
        <Icon
          name={IconNames.ChevronDown}
          color={display === "primary" ? ColorNames.BLACK : ColorNames.GREY}
        />
      )}
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;

import { AuthContextInterface } from "./../contexts/auth";
import { AuthCredentials, AuthObject, BearerToken } from "../models/Auth";
import serviceFetch from "./fetch";

export const authenticateUser = async (
  auth: AuthContextInterface,
  credentials: AuthCredentials
): Promise<AuthObject> =>
  serviceFetch(auth, "login", null, "post", credentials);

export const renewToken = async (
  auth: AuthContextInterface
): Promise<BearerToken> => serviceFetch(auth, "renew-token", null, "post");

export const getSessionToken = async (
  auth: AuthContextInterface
): Promise<any> => serviceFetch(auth, "session-token", null, "get");

export const validateQueryToken = async (data: {
  queryToken: string;
  currentToken?: string;
}): Promise<any> => serviceFetch(null, "query-token", null, "post", data);

export const requestLoginCode = async (
  credentials: AuthCredentials
): Promise<void> =>
  serviceFetch(null, "request-login", null, "post", credentials);

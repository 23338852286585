import React, { FC, PropsWithChildren } from "react";
import { ColorNames } from "../theme";

interface DetailsColorContextValue {
  color: ColorNames;
}

const DetailsColorContext = React.createContext<DetailsColorContextValue>({
  color: ColorNames.GREEN,
});

interface DetailsColorProviderProps extends PropsWithChildren {
  color?: ColorNames;
}

export const DetailsColorProvider: FC<DetailsColorProviderProps> = ({
  color,
  children,
}) => {
  return (
    <DetailsColorContext.Provider
      value={{
        color: color || ColorNames.GREEN,
      }}
    >
      {children}
    </DetailsColorContext.Provider>
  );
};

export default DetailsColorContext;

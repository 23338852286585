import {
  CreateCultureLocation,
  CultureDetailLocation,
  CultureListLocation,
} from "../../Locations";
import { RouteObject } from "../../types";
import { CreateCulture } from "./Create";
import Culture from "./Details";
import CulturesList from "./List";

const drawerRoutes: RouteObject[] = [
  {
    path: CultureDetailLocation,
    element: <Culture />,
    withAuth: true,
    flag: "admin-only",
  },
];

export const cultureRoutes: RouteObject[] = [
  {
    path: CultureListLocation,
    element: <CulturesList />,
    withAuth: true,
    drawerRoutes,
    flag: "admin-only",
  },
  {
    path: CreateCultureLocation,
    element: <CreateCulture />,
    withAuth: true,
    flag: "admin-only",
  },
];

/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { useFlags } from "../../../../../hooks/useFlags";
import {
  DocumentStages,
  DocumentStatus,
  GetCreateResultOnDocumentFormResponseDocument,
  GetEditResultOnDocumentFormResponseDocument,
} from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import FailedQuotations from "./components/FailedQuotations";
import EditableSection from "../../../../../components/EditableSection";
import useQuotationFooter from "./hooks/useQuotationsFooter";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import ValidQuotations from "./components/ValidQuotations";
import QuotationComment from "./components/QuotationComment";

const Quotations: FC = () => {
  const { isFlagEnabled } = useFlags();
  const isAdmin = isFlagEnabled("admin-only");

  const {
    refetch,
    documentData: { status, _id, stage },
    editingQuotationResultId,
  } = useDocumentDetails();

  const footerButtons = useQuotationFooter();

  const currentStageStatus = useDocumentOrder(stage!, status!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);
  const simulationPending = useDocumentOrder(
    DocumentStages.Simulation,
    DocumentStatus.Pending
  );

  const createQuery = useMemo(
    () =>
      currentStageStatus === simulationPending
        ? GetCreateResultOnDocumentFormResponseDocument
        : undefined,
    [currentStageStatus]
  );

  const editQuery = useMemo(
    () =>
      currentStageStatus < policyStage
        ? GetEditResultOnDocumentFormResponseDocument
        : undefined,
    [currentStageStatus]
  );

  return (
    <EditableSection
      title="Mercado Segurador"
      noTitle
      createPayload={{ documentId: _id! }}
      createQuery={createQuery}
      editQuery={editQuery}
      editingObjectId={editingQuotationResultId}
      footerButtons={footerButtons}
      addText={"Nova Cotação"}
      addFlag={"admin-only"}
      onSubmit={refetch}
      main
      useV2
    >
      {isAdmin && <FailedQuotations />}
      <QuotationComment />
      <ValidQuotations />
    </EditableSection>
  );
};

export default Quotations;

/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import TextInput from "./TextInput";

const styles: CSSObject = {
  inputWrapper: {
    position: "relative",
    display: "inline-block",
  },
  percentageSymbol: {
    position: "absolute",
    right: 8, // Adjust this value as needed
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#999",
  },
};

interface PercentageInputProps {
  value: string;
  onChange: (value: string) => void;
  name?: string;
}

export const PercentageInput = ({
  value,
  onChange,
  name,
}: PercentageInputProps) => {
  const handleChange = (inputValue: string) => {
    if (inputValue === "") {
      onChange("");
      return;
    }
    const numericValue = parseFloat(inputValue);

    if (isNaN(numericValue)) {
      onChange("");
    } else if (numericValue > 100) {
      onChange("100");
    } else if (numericValue < 0) {
      onChange("0");
    } else {
      onChange(inputValue);
    }
  };

  return (
    <div css={styles.inputWrapper}>
      <TextInput
        name={name || "percentage"}
        value={value}
        handleChange={handleChange}
        type="number"
        css={{ borderRadius: "30px", borderColor: "black" }}
      />
      <span css={styles.percentageSymbol}>%</span>
    </div>
  );
};

/** @jsxImportSource @emotion/react */

import { FC, useEffect, useState } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { theme } from "../../theme";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import Button, { ButtonProps } from "./components/Button";

interface ButtonGroupProps {
  buttons: ButtonProps[];
  noBackground?: boolean;
  noBorder?: boolean;
  defaultActiveIndex: number | null;
}

const getStyles: CSSRulesResolver<{
  noBackground?: boolean;
  noBorder?: boolean;
}> = ({ noBackground, noBorder }) => ({
  buttons: {
    minHeight: 32,
    margin: 0,
    gap: 5,
    borderRadius: 10,
    padding: 5,
    border: noBorder ? "none" : "1px solid",
    borderColor: theme.colors.Black[100],
    backgroundColor: noBackground ? "transparent" : theme.colors.White[100],
  },
});

const ButtonGroup: FC<ButtonGroupProps> = ({
  buttons,
  noBackground,
  noBorder,
  defaultActiveIndex = null,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(
    defaultActiveIndex || null
  );

  const styles = useCSSRulesWithTheme(getStyles, { noBackground, noBorder });

  const handleClick = (index: number) => {
    setActiveIndex(index);
    buttons[index].onClick();
  };

  // Set default active index
  useEffect(() => {
    if (defaultActiveIndex !== null) {
      setActiveIndex(defaultActiveIndex);
    }
  }, []);

  return (
    <Row noGutters css={styles.buttons}>
      {buttons.map((button, index) => (
        <Column key={`group-button-${index}`}>
          <Button
            label={button.label}
            iconName={button.iconName}
            onClick={() => handleClick(index)}
            active={activeIndex === index}
          />
        </Column>
      ))}
    </Row>
  );
};

export default ButtonGroup;

/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { ForestItem } from "../../../../../graphql/generated/types";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";

interface Props {
  data: Omit<ForestItem, "area" | "harvestCycleDuration"> & {
    area: string;
    harvestCycleDuration: string;
  };
}

const ForestItemData: FC<Props> = ({ data }) => {
  const leftInfo = useMemo(
    () => [
      { label: "Espécie", value: data.species.name },
      { label: "Tipo de Manejo", value: data.management.name },
      { label: "Ciclo Florestal", value: data.harvestCycleDuration },
    ],
    [data]
  );
  const rightInfo = useMemo(
    () => [
      { label: "Ano de plantio", value: data.plantYear },
      { label: "Área", value: data.area },
      { label: "Propriedade", value: data.property.displayName },
    ],
    [data]
  );

  return (
    <DetailsSection title="Dados da Floresta" footerButtons={[]} main>
      <DetailsSectionColumns columns={[leftInfo, rightInfo]} />
    </DetailsSection>
  );
};

export default ForestItemData;

import { FC } from "react";

const Unarchive: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_324_24)">
        <path
          d="M16 8H28C28.5304 8 29.0391 8.21071 29.4142 8.58579C29.7893 8.96086 30 9.46957 30 10V26C30 26.5304 29.7893 27.0391 29.4142 27.4142C29.0391 27.7893 28.5304 28 28 28H22V26H28V10H15.1716L11.1716 6H4V26H16V16.83L13.41 19.41L12 18L17 13L22 18L20.59 19.41L18 16.83V26C18 26.5304 17.7893 27.0391 17.4142 27.4142C17.0391 27.7893 16.5304 28 16 28H4C3.46957 28 2.96086 27.7893 2.58579 27.4142C2.21071 27.0391 2 26.5304 2 26V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H11.1716C11.702 4.00001 12.2106 4.21069 12.5857 4.5857L16 8Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_324_24">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Unarchive;

import { FC } from "react";

const Notes: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_262_97)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.707 2.29303L28.707 5.29303C28.7999 5.38585 28.8736 5.49606 28.9239 5.61737C28.9742 5.73868 29 5.86872 29 6.00004C29 6.13135 28.9742 6.26139 28.9239 6.3827C28.8736 6.50401 28.7999 6.61422 28.707 6.70704L19.4141 16H15V11.5859L24.293 2.29303C24.3858 2.20014 24.496 2.12644 24.6173 2.07616C24.7386 2.02588 24.8687 2 25 2C25.1313 2 25.2614 2.02588 25.3827 2.07616C25.504 2.12644 25.6142 2.20014 25.707 2.29303ZM17 14H18.5859L23.5859 9.00004L22 7.41414L17 12.4141V14ZM23.4141 6.00004L25 7.58593L26.5859 6.00004L25 4.41414L23.4141 6.00004Z"
          fill="black"
        />
        <path
          d="M2 13C2 11.8954 2.89543 11 4 11H13V13H5C4.44772 13 4 13.4477 4 14V27C4 27.5523 4.44772 28 5 28H27C27.5523 28 28 27.5523 28 27V14C28 13.4477 27.5523 13 27 13H26V11H28C29.1046 11 30 11.8954 30 13V28C30 29.1046 29.1046 30 28 30H4C2.89543 30 2 29.1046 2 28V13Z"
          fill="black"
        />
        <path d="M6 21V19H26V21H6Z" fill="black" />
        <path d="M6 23H16V25H6V23Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_262_97">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Notes;

import { FC } from "react";
import "./App.css";
import { AuthProvider } from "./contexts/auth";
import { AppRoutes } from "./Routes";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
import { UIContextProvider } from "./contexts/ui";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorContextProvider } from "./contexts/errorHandler";
import ErrorBoundary from "./components/ErrorBoundary";
import useQuerySave from "./hooks/useQuerySave";
import { FeatureFlagsContextProvider } from "./contexts/featureFlags";
import useHotjar from "./hooks/useHotjar";
import { LoadScript } from "@react-google-maps/api";
import { ApolloProvider } from "@apollo/client";
import apollo from "./graphql/apollo";
import { HelmetProvider } from "react-helmet-async";

const libraries = ["places" as const];

const App: FC = () => {
  useHotjar();
  useQuerySave();
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <ErrorContextProvider>
            <ApolloProvider client={apollo.client}>
              <FeatureFlagsContextProvider>
                <AuthProvider>
                  <UIContextProvider>
                    <HelmetProvider>
                      <LoadScript
                        googleMapsApiKey={`${process.env
                          .REACT_APP_API_GOOGLE_MAPS_KEY!}&loading=async`}
                        libraries={libraries}
                        language="pt-BR"
                      >
                        <AppRoutes />
                      </LoadScript>
                    </HelmetProvider>
                  </UIContextProvider>
                </AuthProvider>
              </FeatureFlagsContextProvider>
              <ToastContainer />
            </ApolloProvider>
          </ErrorContextProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
};

export default App;

const Filter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0266 6.6C9.6522 7.64868 8.64194 8.4 7.45455 8.4C6.26716 8.4 5.25689 7.64868 4.88247 6.6H2.90909C2.40701 6.6 2 6.19706 2 5.7C2 5.20294 2.40701 4.8 2.90909 4.8H4.88247C5.25689 3.75132 6.26716 3 7.45455 3C8.64194 3 9.6522 3.75132 10.0266 4.8H21.0909C21.593 4.8 22 5.20294 22 5.7C22 6.19706 21.593 6.6 21.0909 6.6H10.0266ZM5.82087 4.90901C6.11664 4.31143 6.73709 3.9 7.45455 3.9C8.20761 3.9 8.8538 4.35329 9.12984 4.99934C9.22185 5.21469 9.27273 5.45146 9.27273 5.7C9.27273 5.97557 9.21018 6.23667 9.09837 6.47015C8.80681 7.07898 8.18031 7.5 7.45455 7.5C6.73286 7.5 6.10932 7.0837 5.81567 6.48039C5.70076 6.24432 5.63636 5.97961 5.63636 5.7C5.63636 5.4162 5.70271 5.14775 5.82087 4.90901Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1175 12.9H21.0909C21.593 12.9 22 12.4971 22 12C22 11.5029 21.593 11.1 21.0909 11.1H19.1175C18.7431 10.0513 17.7328 9.3 16.5455 9.3C15.3581 9.3 14.3478 10.0513 13.9734 11.1H2.90909C2.40701 11.1 2 11.5029 2 12C2 12.4971 2.40701 12.9 2.90909 12.9H13.9734C14.3478 13.9487 15.3581 14.7 16.5455 14.7C17.7328 14.7 18.7431 13.9487 19.1175 12.9ZM14.9467 12.858C14.8068 12.603 14.7273 12.3107 14.7273 12C14.7273 11.6721 14.8158 11.3648 14.9705 11.1C15.2849 10.562 15.8725 10.2 16.5455 10.2C17.2358 10.2 17.8363 10.5809 18.1442 11.142C18.2841 11.397 18.3636 11.6893 18.3636 12C18.3636 12.3279 18.2751 12.6353 18.1204 12.9C17.806 13.438 17.2184 13.8 16.5455 13.8C15.8551 13.8 15.2546 13.4191 14.9467 12.858Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0909 21C12.2783 21 13.2886 20.2487 13.663 19.2H21.0909C21.593 19.2 22 18.7971 22 18.3C22 17.8029 21.593 17.4 21.0909 17.4H13.663C13.2886 16.3513 12.2783 15.6 11.0909 15.6C9.90352 15.6 8.89325 16.3513 8.51883 17.4H2.90909C2.40701 17.4 2 17.8029 2 18.3C2 18.7971 2.40701 19.2 2.90909 19.2H8.51883C8.89325 20.2487 9.90352 21 11.0909 21ZM11.0909 16.5C10.418 16.5 9.83035 16.862 9.51597 17.4C9.36127 17.6648 9.27273 17.9721 9.27273 18.3C9.27273 18.6107 9.35223 18.903 9.49219 19.158C9.80009 19.7191 10.4006 20.1 11.0909 20.1C11.7639 20.1 12.3515 19.738 12.6659 19.2C12.8205 18.9352 12.9091 18.6279 12.9091 18.3C12.9091 17.9893 12.8296 17.697 12.6896 17.442C12.3817 16.8809 11.7812 16.5 11.0909 16.5Z"
        fill="black"
      />
    </svg>
  );
};

export default Filter;

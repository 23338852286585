import { FC, useMemo } from "react";
import DefaultFieldSummary from "./DefaultFieldSummary";
import { FormFieldV2 } from "../../../../graphql/generated/types";

type SelectionFieldSummaryProps = {
  field: FormFieldV2;
};

const SelectionFieldSummary: FC<SelectionFieldSummaryProps> = ({
  field: { value, options, variant, label },
}) => {
  const valueStr = useMemo(() => {
    const valueWithArray = value instanceof Array ? value : [value];
    return options
      ?.filter((option) => valueWithArray.includes(option.value))
      .map((option) => option.label)
      .join(", ");
  }, [options, value]);

  return (
    <DefaultFieldSummary
      label={label}
      value={valueStr || ""}
      variant={variant}
    />
  );
};

export default SelectionFieldSummary;

/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { useParams } from "react-router-dom";

import WAChat from "../../../components/WAChat";
import Layout from "../../../components/Layout";
import { useFlags } from "../../../hooks/useFlags";

const ContactConversation: FC = () => {
  const { contactId } = useParams();
  const { isFlagEnabled } = useFlags();
  return (
    <Layout padding={0}>
      {isFlagEnabled("admin-only") && (
        <WAChat contactId={contactId!} showChatList />
      )}
    </Layout>
  );
};

export default ContactConversation;

/** @jsxImportSource @emotion/react */

import { InputHTMLAttributes } from "react";
import { FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Typography, { BaseFontSize } from "../Typography";
import { ColorNames, theme } from "../../theme";
import { css } from "@emotion/react";

const getCSSRules: CSSRulesResolver<TextAreaInputProps> = ({
  errorMessage,
  color,
  ...props
}) => {
  const input = {
    fontSize: BaseFontSize,
    fontFamily: "inherit",
    width: "100%",
    // height: 135,
    maxHeight: 135,
    borderRadius: 15,
    height: "auto",
    borderColor: errorMessage
      ? theme.colors.ErrorRed[100]
      : color || theme.colors.Black[100],
    "&:focus-visible": {
      outline: "none",
    },
    resize: "none" as const,
    padding: 20,
    boxSizing: "border-box" as const,
  };
  const placeholder = css`
    ::placeholder {
      color: ${props.colors.Grey[100]};
    }
  `.styles;
  return { input, placeholder };
};

interface TextAreaInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  handleBlur?: () => void;
  handleChange?: (value: string) => any;
  value: string;
  errorMessage?: string;
  disabled?: boolean;
  color?: ColorNames;
  maxLength?: number;
}

const TextAreaInput: FC<TextAreaInputProps> = ({
  handleChange,
  handleBlur,
  errorMessage,
  value,
  color,
  maxLength,
  ...props
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, {
    handleChange,
    handleBlur,
    errorMessage,
    value,
    color,
    ...props,
  });

  const { onChange } = props;

  const callbackFunction: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    handleChange
      ? handleChange(e.target.value)
      : onChange
      ? onChange(e)
      : undefined;
  };

  return (
    <>
      <textarea
        css={css(styles.input, styles.placeholder)}
        onChange={callbackFunction}
        // onBlur={handleBlur}
        value={value}
        maxLength={maxLength}
        {...props}
      />
      {errorMessage && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="right"
          component="div"
          css={{ width: "100%" }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default TextAreaInput;

import { FC } from "react";

const Rejected: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_805)">
        <path
          d="M2 16C2 18.7689 2.82109 21.4757 4.35943 23.778C5.89777 26.0803 8.08427 27.8747 10.6424 28.9343C13.2006 29.9939 16.0155 30.2712 18.7313 29.731C21.447 29.1908 23.9416 27.8574 25.8995 25.8995C27.8574 23.9416 29.1908 21.447 29.731 18.7313C30.2712 16.0155 29.9939 13.2006 28.9343 10.6424C27.8747 8.08427 26.0803 5.89777 23.778 4.35943C21.4757 2.82109 18.7689 2 16 2C12.287 2 8.72601 3.475 6.1005 6.1005C3.475 8.72601 2 12.287 2 16ZM25.15 23.75L8.25 6.85C10.5506 4.935 13.4839 3.94898 16.4742 4.08546C19.4644 4.22195 22.2957 5.47108 24.4123 7.5877C26.5289 9.70432 27.7781 12.5356 27.9145 15.5258C28.051 18.5161 27.065 21.4494 25.15 23.75ZM8.24 25.16C5.81832 23.1035 4.311 20.1706 4.04856 17.0044C3.78612 13.8382 4.78997 10.6972 6.84 8.27L23.73 25.16C21.5642 26.99 18.8204 27.994 15.985 27.994C13.1496 27.994 10.4058 26.99 8.24 25.16Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_805">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Rejected;

import { FC } from "react";
import { useForm } from "../../hooks/useForm";
import { FormField } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import MultiSelectInput from "../../../../components/Input/SelectInputs/MultiSelectInput";

const FormInputMultiSelect: FC<FormField & { groupPath: string }> = ({
  options,
  name,
  label,
  groupPath,
  disabled,
}) => {
  const [value, error] = useFieldValue(name, groupPath);
  const { handleChange, startEditing, finishEditing } = useForm();
  const handleSelect = (values: string[]) => {
    handleChange({ target: { value: values, name } } as any, groupPath);
  };

  return (
    <MultiSelectInput
      options={options!}
      value={value}
      inputName={name}
      placeholder={label}
      onSelect={handleSelect}
      onFocus={() => startEditing(groupPath, name)}
      onBlur={() => finishEditing(groupPath, name)}
      errorMessage={error}
      disabled={disabled}
      multiValueDisplay="all"
      hideDropdown
      optionDisplay="old"
    />
  );
};

export default FormInputMultiSelect;

/** @jsxImportSource @emotion/react */

import SkeletonTypography from "./SkeletonTypography";

const SkeletonMessage = () => {
  return (
    <>
      <SkeletonTypography variant="textMedium" />
      <SkeletonTypography variant="textMedium" />
      <br />
    </>
  );
};

export default SkeletonMessage;

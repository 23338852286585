import { IconNames } from "../../../components/Icons/styles/iconNames";
import { ColorNames } from "../../../theme";

export type TFunnelCard = {
  color: ColorNames;
  category: string;
  label: string;
  iconName: IconNames;
};

export const documentsFunnel: TFunnelCard[] = [
  {
    color: ColorNames.GREEN,
    category: "quotation",
    label: "Cotações",
    iconName: IconNames.Simulation,
  },
  {
    color: ColorNames.PINK,
    category: "proposal",
    label: "Propostas",
    iconName: IconNames.Proposal,
  },
  {
    color: ColorNames.BLUE,
    category: "policy",
    label: "Apólices",
    iconName: IconNames.Policy,
  },
  {
    color: ColorNames.ORANGE,
    category: "claim",
    label: "Sinistros",
    iconName: IconNames.Claim,
  },
];

import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import Table from "../../../../../components/Table";
import { LivestockAnimal } from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import { animalAgeLabels } from "../../../../../shared/livestock/animalAges";
import { farmingSystemsLabels } from "../../../../../shared/livestock/farmingSystems";
import { animalGendersLabels } from "../../../../../shared/livestock/genders";
import { formatPrice } from "../../../../../utils/formatNumber";

const getAnimalsTableData = (animals: LivestockAnimal[]) =>
  animals.map((animal) => ({
    ...animal,
    age: animalAgeLabels[animal.age],
    gender: animalGendersLabels[animal.gender],
    unitValue: formatPrice(animal.unitValue),
    totalValue: formatPrice(animal.unitValue * animal.totalAnimalCount),
  }));

export type AnimalsData = ReturnType<typeof getAnimalsTableData>;

const LivestockFarmingData = () => {
  const { documentData } = useDocumentDetails();
  const livestock = documentData.livestock!;
  const animals = getAnimalsTableData(livestock.animals);

  const columns = [
    [
      { label: "Espécie", value: livestock.species.name },
      {
        label: "Relacionado a crédito pecuário",
        value: livestock.relatedToLivestockCredit ? "Sim" : "Não",
        col: 12,
      },
      // ],
      // [
      {
        label: "Sistema de criação",
        value: farmingSystemsLabels[livestock.farmingSystem],
      },
      {
        label: "Finalidade de produção",
        value: livestock.productionPropose.name,
      },
    ],
  ];

  return (
    <DetailsSection title="Dados do rebanho" main>
      <DetailsSectionColumns noMargins columns={columns} />
      <Table
        data={animals}
        columns={[
          {
            label: "Raça",
            key: "breed",
            special: "many2one",
          },
          { label: "Idade", key: "age" },
          { label: "Gênero", key: "gender" },
          { label: "Número de cabeças", key: "totalAnimalCount" },
          { label: "Valor unitário", key: "unitValue" },
          { label: "Valor total", key: "totalValue" },
        ]}
      />
    </DetailsSection>
  );
};

export default LivestockFarmingData;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import { theme } from "../../../../theme";

interface TableCellProps extends PropsWithChildren {
  tooltip?: string;
}

interface StylesParams {
  tooltip: string;
}

const getStyles = ({ tooltip }: StylesParams) => ({
  td: css`
    position: relative;
    overflow: visible;
    text-align: left;
    cursor: pointer;

    span.ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%;
      width: 100%;
    }

    &::after {
      content: "${tooltip}";
      position: absolute;
      background-color: white;
      border: 1px solid ${theme.colors.LightGrey[100]};
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      width: fit-content;
      min-width: 10px;
      text-align: center;
      color: black;
      padding: 5px 10px;
      border-radius: 8px;
      top: 100%;
      left: 0;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      z-index: 1000;
      transition: none;
      transition: opacity 0.2s ease;
      transition-delay: 0s;
    }

    &:hover::after {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.2s ease, visibility 0s linear 0.5s;
      transition-delay: 0.5s;
    }
  `,
});

const TableCell: React.FC<TableCellProps> = ({ children, tooltip = "-" }) => {
  const styles = getStyles({
    tooltip: typeof children === "string" ? children : tooltip,
  });
  return (
    <td css={styles.td}>
      <span className="ellipsis">{children || "-"}</span>
    </td>
  );
};

export default TableCell;

import { useEffect } from "react";
import { TModelFilter } from "./useModelFilter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const useUrlFilter = ({
  filterValues,
  filters,
  setFilterValue,
}: TModelFilter) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  useEffect(() => {
    const existingParams = new URLSearchParams(location.search);
    const paramsKeys = Array.from(existingParams.keys());
    const otherFiltersParams = paramsKeys.filter(
      (key) => !filters.some(({ urlParam }) => urlParam === key)
    );

    const searchParams = new URLSearchParams();

    otherFiltersParams.forEach((key) => {
      const queryValues = existingParams.getAll(key);
      queryValues.forEach((value) => searchParams.append(key, value));
    });

    filters.forEach((filter) => {
      if (!filter.value.length) return;
      filter.value.map((value) => searchParams.append(filter.urlParam, value));
    });
    location.search = searchParams.toString();
    navigate(location);
  }, [...filterValues]);

  useEffect(() => {
    filters.forEach((filter) => {
      const queryValue = queryParams.getAll(filter.urlParam);
      setFilterValue(filter.name, queryValue);
    });
  }, []);
};

export default useUrlFilter;

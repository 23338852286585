export const getDaysInterval = (date: string, messageType: string) => {
  const today = new Date();
  const formattedDate = new Date(date);
  if (messageType === "notification") {
    const interval = today.getTime() - formattedDate.getTime();
    return Math.floor(interval / (1000 * 3600 * 24));
  }
  if (messageType === "task") {
    const interval = formattedDate.getTime() - today.getTime();
    return Math.ceil(interval / (1000 * 3600 * 24));
  } else return 0;
};

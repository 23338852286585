import { FC } from "react";
import useForm from "../../hooks/useForm.v2";
import FormSectionSummary from "./FormSectionSummary";

type FormSummaryProps = {
  handleEditSection: (sectionIndex: number) => void;
};

export const FormSummary: FC<FormSummaryProps> = ({ handleEditSection }) => {
  const { form } = useForm();
  return (
    <>
      {form!.sections.map((section, i) => (
        <FormSectionSummary
          key={section.id}
          section={section}
          handleEditSection={() => handleEditSection(i)}
        />
      ))}
    </>
  );
};

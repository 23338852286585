import { FC } from "react";

const Swiss: FC = () => {
  return (
    <svg
      width="256"
      height="128"
      viewBox="0 0 256 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8495 38C29.579 38 18 49.6578 18 63.9744C18 78.3422 29.579 90 43.8495 90C58.1201 90 69.699 78.3422 69.699 63.9744C69.699 49.6578 58.1201 38 43.8495 38ZM56.7997 56.2537H30.9502V50.7827H56.7997V56.2537ZM56.5458 77.2173H50.807V60.3442H56.5458V77.2173ZM46.7443 77.2173H41.0055V60.3442H46.7443V77.2173ZM36.9427 77.2173H31.2041V60.3442H36.9427V77.2173Z"
        fill="black"
      />
      <path
        d="M106.264 58.1962H100.881C100.83 55.6396 98.9509 54.1569 96.4625 54.1569C94.3296 54.1569 91.7903 54.975 91.7903 57.5315C91.7903 60.1121 94.7247 60.8116 98.0179 61.5967C102.229 62.6006 107.026 63.7443 107.026 69.1382C107.026 75.0693 101.947 77.7281 96.6149 77.7281C90.5206 77.7281 85.4423 75.5806 85.493 68.8314H91.2317C91.2317 71.7458 92.806 73.7399 95.9039 73.7399C98.1892 73.7399 101.186 72.9219 101.186 70.1608C101.186 67.4557 98.2736 66.7482 95.0172 65.9571C90.8875 64.9538 86.204 63.816 86.204 58.2985C86.204 52.4184 91.5872 50.1686 96.6149 50.1686C101.896 50.1686 105.908 52.7763 106.264 58.1962Z"
        fill="black"
      />
      <path
        d="M125.867 68.4737L128.508 77.2682H134.907L141.204 58.8099H135.313L133.028 67.042C132.52 68.7805 132.063 70.5189 131.809 72.2573L131.656 73.3311C131.352 71.2859 130.946 69.2917 130.387 67.2977L128 58.8099H121.703L119.417 66.9398C118.859 69.0361 118.351 71.1836 118.046 73.3822C117.995 72.9988 117.932 72.6154 117.868 72.2319L117.833 72.0177C117.812 71.8907 117.792 71.7637 117.772 71.6367C117.743 71.4516 117.715 71.2665 117.691 71.0814L117.386 69.8031L117.031 68.4737L114.491 58.8099H108.346L114.339 77.2682H120.788L123.226 68.3203L123.633 66.8375C123.903 65.9512 124.084 65.0195 124.265 64.0877C124.355 63.6219 124.445 63.156 124.547 62.6959C124.641 63.1346 124.732 63.5762 124.824 64.0199C125.129 65.4891 125.438 66.9814 125.867 68.4737Z"
        fill="black"
      />
      <path
        d="M143.947 77.2682V58.8099H149.431V77.2682H143.947Z"
        fill="black"
      />
      <path d="M143.743 50.783V55.3336H149.584V50.783H143.743Z" fill="black" />
      <path
        d="M167.917 63.6675H163.042C162.94 62.3382 161.721 61.6223 160.401 61.6223C159.233 61.6223 157.557 61.9802 157.608 63.5142C157.658 64.6902 158.928 65.0481 159.893 65.2526L161.975 65.7639C165.175 66.4797 168.831 67.8091 168.831 71.6951C168.831 76.3991 164.057 77.7797 160.249 77.7797C156.49 77.7797 152.732 76.0923 152.478 72.0019H157.557C157.709 73.6381 159.03 74.4562 160.604 74.4562C162.026 74.4562 163.803 73.8937 163.803 72.1553C163.803 70.697 161.938 70.2695 159.749 69.7679C156.643 69.0561 152.885 68.1949 152.885 64.0255C152.885 59.6794 157.201 58.2988 160.807 58.2988C164.108 58.2988 167.46 59.6282 167.968 63.2585L167.917 63.6675Z"
        fill="black"
      />
      <path
        d="M181.02 63.6675H185.895L185.946 63.2585C185.438 59.6282 182.086 58.2988 178.785 58.2988C175.179 58.2988 170.863 59.6794 170.863 64.0255C170.863 68.1949 174.62 69.0561 177.727 69.7679C179.916 70.2695 181.781 70.697 181.781 72.1553C181.781 73.8937 180.004 74.4562 178.582 74.4562C177.008 74.4562 175.687 73.6381 175.535 72.0019H170.456C170.71 76.0923 174.468 77.7797 178.226 77.7797C182.035 77.7797 186.809 76.3991 186.809 71.6951C186.809 67.8091 183.152 66.4797 179.953 65.7639L177.871 65.2526C176.906 65.0481 175.636 64.6902 175.586 63.5142C175.535 61.9802 177.211 61.6223 178.379 61.6223C179.699 61.6223 180.918 62.3382 181.02 63.6675Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.592 64.1281C214.842 63.2078 217.28 61.2137 217.28 57.5834C217.28 52.4703 212.557 50.783 208.19 50.783H197.322V77.2687H202.959V66.5824H207.377C211.184 66.5824 211.452 70.547 211.69 74.0616C211.77 75.2357 211.846 76.3596 212.049 77.2687H218.194C217.178 75.4459 216.982 73.3998 216.796 71.4652C216.472 68.0816 216.18 65.0389 211.592 64.1281ZM207.021 62.1851H202.959V54.9246H207.072C209.408 54.9246 211.338 55.8961 211.338 58.5037C211.338 61.1625 209.408 62.1851 207.021 62.1851Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.913 69.4965C225.913 71.9507 226.472 74.4562 229.367 74.4562C231.195 74.4562 232.16 73.5358 232.464 71.7974H237.34C237.086 76.1435 233.125 77.7797 229.265 77.7797C223.628 77.7797 220.276 74.2005 220.276 68.525C220.276 63.1051 223.222 58.2988 229.113 58.2988C235.918 58.2988 238 63.5653 238 69.4965H225.913ZM232.363 66.1218C232.261 63.9232 231.754 61.6223 229.011 61.6223C226.573 61.6223 226.015 64.1277 225.913 66.1218H232.363Z"
        fill="black"
      />
    </svg>
  );
};

export default Swiss;

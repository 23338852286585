const Benefectory = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1542_9895)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11108 6.42079C8.5222 6.68602 9.00555 6.82759 9.5 6.82759C10.1628 6.82682 10.7982 6.57227 11.2669 6.11976C11.7356 5.66725 11.9992 5.05374 12 4.41379C12 3.93639 11.8534 3.46971 11.5787 3.07276C11.304 2.67582 10.9135 2.36643 10.4567 2.18374C9.99989 2.00105 9.49723 1.95324 9.01228 2.04638C8.52732 2.13952 8.08187 2.36941 7.73223 2.70698C7.3826 3.04456 7.1445 3.47466 7.04804 3.94289C6.95158 4.41112 7.00108 4.89645 7.1903 5.33751C7.37952 5.77858 7.69995 6.15556 8.11108 6.42079ZM8.90475 3.55365C9.08094 3.43998 9.28809 3.37931 9.5 3.37931C9.78406 3.37962 10.0564 3.48871 10.2573 3.68265C10.4581 3.87658 10.5711 4.13953 10.5714 4.41379C10.5714 4.6184 10.5086 4.8184 10.3909 4.98852C10.2731 5.15864 10.1058 5.29123 9.91002 5.36953C9.71424 5.44783 9.49881 5.46832 9.29098 5.4284C9.08314 5.38848 8.89223 5.28996 8.74239 5.14528C8.59255 5.00061 8.4905 4.81628 8.44916 4.61561C8.40782 4.41494 8.42904 4.20694 8.51013 4.01791C8.59122 3.82889 8.72855 3.66732 8.90475 3.55365Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0989 12.3691C21.2406 12.3321 21.3906 12.3381 21.5286 12.3862C21.6666 12.4344 21.7859 12.5225 21.8703 12.6385C21.9546 12.7545 21.9999 12.8927 22 13.0345V21.3103C22 21.4933 21.9247 21.6687 21.7908 21.798C21.6568 21.9273 21.4752 22 21.2857 22H14.1429C14.0071 22 13.8742 21.9626 13.7597 21.8923C13.6452 21.8219 13.5538 21.7215 13.4962 21.6029C13.4386 21.4842 13.4173 21.3521 13.4346 21.2221C13.452 21.0922 13.5073 20.9697 13.5942 20.869L16.9929 16.9317L11.2206 15.9185L12 17.7241V22H10.5714V18L9.54657 15.6244L7 15.1775V22H5.57143V14.9267L2 14.2997V9.88524L5.57857 7.81221C5.91168 7.61925 6.29283 7.5173 6.68129 7.51724H9.58129C10.0171 7.51862 10.4422 7.64767 10.8004 7.88734C11.1587 8.12701 11.4331 8.46601 11.5875 8.85952L14.1604 15.0328L18.0429 15.7143L20.7369 12.5931C20.8309 12.4843 20.9573 12.4061 21.0989 12.3691ZM3.42857 10.6665V13.1486L5.57143 13.525V9.4251L3.42857 10.6665ZM7 8.89655V13.7757L12.4994 14.7411L10.2565 9.35924C10.2064 9.22474 10.1148 9.10829 9.99411 9.02557C9.87339 8.94284 9.72932 8.89782 9.58129 8.89655H7ZM15.6681 20.6207H20.5714V14.9391L15.6681 20.6207Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1542_9895">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Benefectory;

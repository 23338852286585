import Register from ".";
import {
  OrgCompleteRegisterLocation,
  OrgRegisterLocation,
  RegisterLocation,
} from "../../Locations";
import { RouteObject } from "../../types";
import { OrgCompleteRegister } from "./OrgCompleteRegister";
import { OrgRegister } from "./OrgRegister";

export const registerRoutes: RouteObject[] = [
  {
    path: RegisterLocation,
    element: <Register />,
    withAuth: false,
  },
  {
    path: OrgRegisterLocation,
    element: <OrgRegister />,
    withAuth: false,
  },
  {
    path: OrgCompleteRegisterLocation,
    element: <OrgCompleteRegister />,
    withAuth: true,
  },
];

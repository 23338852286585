/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames, theme } from "../../../../../../theme";
import Typography from "../../../../../../components/Typography";
import Column from "../../../../../../components/Grid/Column";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import Icon from "../../../../../../components/Icons";
import Row from "../../../../../../components/Grid/Row";
import {
  // formatNumber,
  formatPrice,
} from "../../../../../../utils/formatNumber";

interface FunnelCardProps {
  label: string;
  value: number;
  iconName: IconNames;
  color: ColorNames;
}

const styles = {
  container: {
    backgroundColor: theme.colors.White[100],
    border: "1px solid",
    boxShadow: theme.boxShadow.default,
    borderColor: theme.colors.Black[100],
    borderRadius: 15,
    padding: "12px 24px",
  },
};

const FunnelCard: FC<FunnelCardProps> = ({ label, value, iconName, color }) => {
  return (
    <div css={styles.container}>
      <Row align="center" noGutters noMargins>
        <Column xs={2}>
          <Icon name={iconName} />
        </Column>
        <Column xs={8}>
          <Typography
            variant="textMedium"
            uppercase
            component="div"
            align="left"
          >
            {label}
          </Typography>
          <Typography
            variant="textLarge"
            weight="bold"
            component="div"
            align="left"
            color={color}
          >
            {formatPrice(value)}
          </Typography>
        </Column>
      </Row>
    </div>
  );
};

export default FunnelCard;

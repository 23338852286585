import { FC } from "react";
import Row from "../../components/Grid/Row";
import Column from "../../components/Grid/Column";
import Button from "../../components/Button";
import { IconNames } from "../../components/Icons/styles/iconNames";
import useForm from "./hooks/useForm.v2";

type FormFooterProps = {
  handleFormSubmit: () => void;
  disabled: boolean;
  submitText: string;
};

const FormFooter: FC<FormFooterProps> = ({
  handleFormSubmit,
  disabled,
  submitText,
}) => {
  const { onCancel } = useForm();
  return (
    <Row noMargins justify="center">
      {onCancel && (
        <Column xs={12} sm={6} useYPaddings>
          <Button
            onClick={onCancel}
            text="CANCELAR"
            iconName={IconNames.Close}
          />
        </Column>
      )}
      <Column xs={12} sm={onCancel ? 6 : 12} useYPaddings>
        <Button
          onClick={handleFormSubmit}
          asDisabled={disabled}
          text={submitText}
        />
      </Column>
    </Row>
  );
};

export default FormFooter;

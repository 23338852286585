/** @jsxImportSource @emotion/react */
import React from "react";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { css } from "@emotion/react";

interface IconLabelProps {
  label: string;
  icon: IconNames;
}

const getStyes = () => ({
  container: css`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
});

const IconLabel: React.FC<IconLabelProps> = ({ label, icon }) => {
  const styles = getStyes();
  return (
    <div css={styles.container}>
      <Icon name={icon} />
      {label}
    </div>
  );
};

export default IconLabel;

import { FC } from "react";

const SkipForward: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_296_149)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM24 10H22V22H24V10ZM8.13186 21.4963C8.21828 21.6475 8.34266 21.7735 8.4927 21.8618C8.64283 21.9501 8.81342 21.9977 8.98759 21.9999C9.16176 22.0021 9.33349 21.9588 9.4858 21.8743L18.4858 16.8743C18.6418 16.7877 18.7717 16.661 18.8622 16.5072C18.9527 16.3535 19.0004 16.1784 19.0004 16C19.0004 15.8216 18.9527 15.6465 18.8622 15.4927C18.7717 15.339 18.6418 15.2123 18.4858 15.1257L9.4858 10.1257C9.33356 10.0411 9.16186 9.99769 8.9877 9.99983C8.81354 10.002 8.64296 10.0496 8.49285 10.1379C8.34274 10.2262 8.2183 10.3522 8.13186 10.5034C8.04541 10.6546 7.99996 10.8258 8 11V21C7.99999 21.1741 8.04545 21.3452 8.13186 21.4963Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_296_149">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SkipForward;

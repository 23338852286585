/** @jsxImportSource @emotion/react */
import Row from "../Grid/Row";
import { CSSObject } from "@emotion/react";
import { ColorNames } from "../../theme";
import TableCell from "./TableCell";
import { TableColumn } from ".";
import Column from "../Grid/Column";
import useColor from "../../hooks/useColor";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";

interface TableHeaderProps<T> {
  columns: TableColumn<T>[];
  hasActions?: boolean;
  color?: ColorNames;
  withBorder?: boolean;
}

const getStyles = ({ headerColor }: { headerColor: string }): CSSObject => ({
  header: {
    borderBottom: `1px solid ${headerColor}`,
    margin: 0,
    color: headerColor,
  },
});

const TableHeader = <T,>({
  columns,
  hasActions,
  color = ColorNames.BLACK,
  withBorder,
}: TableHeaderProps<T>) => {
  const headerColor = useColor(color)!;
  const styles = useCSSRulesWithTheme(getStyles, { headerColor });
  return (
    <Row css={styles.header}>
      {columns.map((column, index) => (
        <TableCell
          key={index}
          value={column.label}
          fontWeight="bold"
          textAlign={column.textAlign}
          borderColor={withBorder ? color : undefined}
        />
      ))}
      {hasActions && <Column xs={1} />}
    </Row>
  );
};

export default TableHeader;

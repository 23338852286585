import { FC, useMemo } from "react";
import DetailsSectionColumns from "../../../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import {
  InspectionType,
  Inspection,
  Services,
  GetEditReportFileOnInspectionDocument,
  GetEditInspectionOnFormResponseDocument,
  GetEditInspectionOnFormResponseQueryVariables,
  InspectionStatus,
} from "../../../../../../../graphql/generated/types";
import { formatPhone } from "../../../../../../../utils/formatNumber";
import EditableSection from "../../../../../../../components/EditableSection";
import useDocumentDetails from "../../../../../../../hooks/useDocumentDetails";
import { useModalStore } from "../../../../../../../components/Modal/context/ModalStore";
import { DetailsRowValueVariants } from "../../../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { IconNames } from "../../../../../../../components/Icons/styles/iconNames";
import { formatISODate } from "../../../../../../../utils/dateUtils";

export const InspectionTypeLabels = {
  [InspectionType.Preliminary]: "Preliminar",
  [InspectionType.Final]: "Final",
};

export const InspectionStatusLabels = {
  [InspectionStatus.Done]: "Finalizada",
  [InspectionStatus.Pending]: "Pendente",
};

export type MappedInspection = Inspection & {
  claimsLabel: string;
};

interface InspectionProps {
  data: MappedInspection;
}

const InspectionFC: FC<InspectionProps> = ({ data: inspection }) => {
  const { refetch } = useDocumentDetails();

  const inspectionInfo = useMemo(() => {
    return [
      {
        label: "Tipo de Vistoria",
        value: InspectionTypeLabels[inspection.type] || "",
      },
      {
        label: "Data",
        value: formatISODate(inspection.date),
      },
      {
        label: "Agência Reguladora",
        value: inspection.regulatoryAgency,
      },
      {
        label: "Sinistros Associados",
        value: inspection.claimsLabel,
      },
      {
        label: "status",
        value: InspectionStatusLabels[inspection.status],
      },
      {
        label: "Observação",
        value: inspection.observation,
      },
    ];
  }, [inspection]);

  const inspectorInfo = useMemo(() => {
    return [
      {
        label: "Perito",
        value: inspection.inspector?.name,
      },
      {
        label: "Telefone",
        value: formatPhone(inspection.inspector?.mobilePhone || ""),
      },
      {
        label: "Laudo",
        variant: DetailsRowValueVariants.File,
        value: inspection.reportFile?.fileKey && {
          file: inspection.reportFile,
          objectId: inspection?._id,
          fieldPath: "reportFile",
          serviceName: Services.Inspections,
        },
      },
    ] as DetailsRowProps[];
  }, [inspection]);

  const { closeModal } = useModalStore();

  const footerButtons = useMemo(() => {
    return [
      inspection &&
        !inspection.reportFile?.fileKey && {
          text: "Adicionar Laudo",
          formQuery: {
            query: GetEditReportFileOnInspectionDocument,
            variables: {
              objectId: inspection?._id,
            },
          },
          iconName: IconNames.Add,
        },
    ].filter(Boolean) as any;
  }, []);

  return (
    <EditableSection<{}, GetEditInspectionOnFormResponseQueryVariables>
      title={`${formatISODate(inspection.date)} - ${
        InspectionTypeLabels[inspection.type]
      }`}
      main
      editQuery={GetEditInspectionOnFormResponseDocument}
      editPayload={{
        objectId: inspection._id!,
        documentId: inspection.documentId!,
      }}
      objectId={inspection._id}
      onSubmit={() => {
        refetch(), closeModal();
      }}
      footerButtons={footerButtons}
      editFlag="admin-only"
    >
      <DetailsSectionColumns columns={[inspectionInfo, inspectorInfo]} />
    </EditableSection>
  );
};

export default InspectionFC;

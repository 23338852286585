import ChatList from "./ChatList";
import { useGetTwilioConversationsQuery } from "../../../graphql/generated/types";
import { useState } from "react";
import { useFlags } from "../../../hooks/useFlags";

const ChatListQueryWrapper = () => {
  const [onlySubscribeds, setOnlySubscribeds] = useState(false);
  const { isFlagEnabled } = useFlags();
  const query = useGetTwilioConversationsQuery({
    variables: { filter: { onlySubscribeds } },
    skip: !isFlagEnabled("admin-only"),
  });
  return (
    <ChatList
      query={query}
      setOnlySubscribeds={async (value: boolean) => setOnlySubscribeds(value)}
    />
  );
};

export default ChatListQueryWrapper;

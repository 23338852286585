import { FC } from "react";

const Unassigned: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_811)">
        <path
          d="M26.7489 24.93C28.1846 23.2021 29.1831 21.1539 29.6599 18.9585C30.1367 16.7632 30.0778 14.4853 29.4881 12.3176C28.8984 10.1498 27.7953 8.15596 26.2721 6.50465C24.749 4.85334 22.8505 3.59315 20.7373 2.83067C18.6242 2.06818 16.3585 1.82584 14.1318 2.12413C11.9052 2.42242 9.78314 3.25257 7.94517 4.54437C6.1072 5.83616 4.60737 7.55161 3.57254 9.5456C2.53772 11.5396 1.99833 13.7535 2 16C2.00079 19.2662 3.15181 22.4278 5.2511 24.93L5.2311 24.9466C5.3011 25.0311 5.3811 25.1033 5.4533 25.1858C5.5433 25.2894 5.6397 25.3858 5.7333 25.4866C6.0133 25.7899 6.3007 26.0818 6.6033 26.3566C6.6948 26.4397 6.7897 26.5178 6.8833 26.5983C7.2033 26.8742 7.5317 27.1355 7.8733 27.3796C7.9174 27.4108 7.9565 27.4489 8.0009 27.4802V27.4675C10.343 29.1157 13.137 30.0002 16.0009 30.0002C18.8648 30.0002 21.6588 29.1157 24.0009 27.4675V27.48C24.0453 27.4487 24.0844 27.4106 24.1285 27.3794C24.4697 27.1353 24.7985 26.874 25.1185 26.5981C25.2121 26.5181 25.307 26.4395 25.3985 26.3564C25.701 26.0815 25.9885 25.7896 26.2685 25.4864C26.3618 25.3858 26.4579 25.2892 26.5485 25.1856C26.6204 25.1031 26.7007 25.0309 26.7707 24.9464L26.7489 24.93ZM16 8C16.89 8 17.76 8.26392 18.5001 8.75839C19.2401 9.25285 19.8169 9.95566 20.1575 10.7779C20.4981 11.6002 20.5872 12.505 20.4135 13.3779C20.2399 14.2508 19.8113 15.0526 19.182 15.682C18.5526 16.3113 17.7508 16.7399 16.8779 16.9135C16.005 17.0872 15.1002 16.9981 14.2779 16.6575C13.4557 16.3169 12.7529 15.7401 12.2584 15.0001C11.7639 14.26 11.5 13.39 11.5 12.5C11.5 11.3065 11.9741 10.1619 12.818 9.31802C13.6619 8.47411 14.8065 8 16 8ZM8.0071 24.93C8.02437 23.617 8.55794 22.3635 9.49234 21.4409C10.4267 20.5183 11.6869 20.0006 13 20H19C20.3131 20.0006 21.5732 20.5183 22.5076 21.4409C23.442 22.3635 23.9756 23.617 23.9929 24.93C21.7998 26.9063 18.9522 28 16 28C13.0478 28 10.2003 26.9063 8.0071 24.93Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_811">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Unassigned;

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { IconNames } from "../../Icons/styles/iconNames";
import Typography from "../../Typography";
import Icon from "../../Icons";
import { IconSizes } from "../../Icons/styles/iconSizes";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { theme } from "../../../theme";

export interface ButtonProps {
  label?: string;
  iconName?: IconNames;
  onClick: () => void;
  active?: boolean;
}

const getStyles: CSSRulesResolver<{ active?: boolean }> = ({ active }) => ({
  button: {
    minWidth: 32,
    height: 32,
    width: "100%",
    display: "flex",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "inherit",
    padding: 5,
    borderRadius: 5,
    cursor: "pointer",
    whiteSpace: "nowrap",
    backgroundColor: active ? theme.colors.Grey[40] : "transparent",
    "&:hover": {
      backgroundColor: theme.colors.Grey[40],
    },
    "& span": {
      cursor: "pointer",
    },
  },
  text: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});

const Button: FC<ButtonProps> = ({ label, iconName, onClick, active }) => {
  const styles = useCSSRulesWithTheme(getStyles, { active });
  return (
    <button css={styles.button} onClick={onClick}>
      {label && (
        <Typography
          variant="textMedium"
          weight={active ? "bold" : "regular"}
          css={styles.text}
          uppercase
        >
          {label}
        </Typography>
      )}
      {iconName && <Icon name={iconName} size={IconSizes.Small} />}
    </button>
  );
};

export default Button;

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import ContactData from "../../../../components/Details/ContactData";
import EditableSection from "../../../../components/EditableSection";
import { GetEditFinancialResponsibleOnDocumentFormResponseDocument } from "../../../../graphql/generated/types";

const FinancialResponsibleData: FC = () => {
  const {
    documentData: { paymentData, _id: docId },
    refetch,
  } = useDocumentDetails();

  return (
    <EditableSection
      title="Responsável Financeiro"
      main
      editQuery={GetEditFinancialResponsibleOnDocumentFormResponseDocument}
      editPayload={{
        documentId: docId,
        objectId: paymentData?.responsibleId,
      }}
      editFlag="admin-only"
      objectId={paymentData?.responsibleId}
      onSubmit={() => {
        refetch();
      }}
    >
      <ContactData contact={paymentData?.responsible!} />
    </EditableSection>
  );
};

export default FinancialResponsibleData;

/** @jsxImportSource @emotion/react */
import { InputHTMLAttributes } from "react";

import { CSSProperties, FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Typography, { BaseFontSize } from "../Typography";
import { ColorNames, theme } from "../../theme";
import { css } from "@emotion/react";

const getCSSRules: CSSRulesResolver<TextInputProps> = ({
  errorMessage,
  ...props
}) => {
  const input = {
    fontSize: BaseFontSize,
    fontFamily: "inherit",
    width: "100%",
    height: 45,
    borderRadius: 5,
    border: errorMessage
      ? `0.2px solid ${props.colors.ErrorRed[100]}`
      : "0.2px solid rgba(0, 0, 0, 0.2)",
    padding: "0.55em 0.85em",
    //@ts-ignore
    boxSizing: "border-box" as CSSProperties["box-sizing"],
    boxShadow: theme.boxShadow.default,
    // Hide the arrows for number input
    "&::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    '&[type="number"]': {
      "-moz-appearance": "textfield",
    },
  };
  const placeholder = css`
    ::placeholder {
      color: ${props.colors.Grey[100]};
    }
  `.styles;
  return { input, placeholder };
};

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  handleBlur?: () => void;
  handleChange?: (value: string) => any;
  value: string;
  errorMessage?: string;
  disabled?: boolean;
  color?: ColorNames;
  maxLength?: number;
}

const TextInput: FC<TextInputProps> = ({
  handleChange,
  handleBlur,
  errorMessage,
  value,
  color,
  maxLength,
  ...props
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, {
    handleChange,
    handleBlur,
    errorMessage,
    value,
    color,
    ...props,
  });

  const { onChange } = props;

  const callbackFunction = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange
      ? handleChange(e.target.value)
      : onChange
      ? onChange(e)
      : undefined;
  return (
    <>
      <input
        css={css(styles.input, styles.placeholder)}
        onChange={callbackFunction}
        onBlur={handleBlur}
        value={value}
        maxLength={maxLength}
        {...props}
      />
      {errorMessage && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="right"
          component="div"
          css={{ width: "100%" }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default TextInput;

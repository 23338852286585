/** @jsxImportSource @emotion/react */

import { ReactNode, FC, useEffect, useMemo } from "react";
import Column from "../Grid/Column";
import { useUIStore } from "../../hooks/useUIStore";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Sidebar, { MenuItem } from "../Sidebar";
import Row from "../Grid/Row";
import Breadcrumbs, { totalBreadcrumbsHeight } from "../Breadcrumbs";
import useIsMobile from "../../hooks/useIsMobile";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { useParams } from "react-router-dom";
import Layout from "../Layout";
import { mainFrameBreadCrumbs } from "../MainFrame/assets/breadcrumbs";

interface AppViewProps {
  sidebarMenu: MenuItem[];
  TopComponent?: FC;
  children: ReactNode;
  drawerContent?: ReactNode;
}

const getCSSRules = ({ subtractHeight }: { subtractHeight: number }) => ({
  wrapper: {
    height: "100%",
  },
  mainContent: {
    marginBottom: 0,
    height: `calc(100% - ${subtractHeight}px)`,
  },
  sidebarContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
  },
  drawerContainer: {
    height: "100%",
    boxSizing: "border-box" as "border-box",
    overflow: "hidden",
  },
});

const AppView: FC<AppViewProps> = ({
  children,
  drawerContent,
  sidebarMenu,
  TopComponent,
}) => {
  const { isDrawerOpen, openDrawer, closeDrawer } = useUIStore();
  const {
    localErrors: { quotationsList: quotationsError },
    dismissError,
  } = useErrorHandler();
  const isMobile = useIsMobile();

  const subtractHeight = useMemo(() => {
    return isMobile ? 0 : totalBreadcrumbsHeight;
  }, [isMobile]);

  const styles = useCSSRulesWithTheme(getCSSRules, { subtractHeight });

  const params = useParams();

  useEffect(() => {
    if (params.docId && (!isDrawerOpen || isMobile)) {
      openDrawer();
    }
    if (!params.docId && isDrawerOpen) {
      closeDrawer();
    }
  }, [params, isMobile, isDrawerOpen]);

  return (
    <Layout>
      <div css={styles.wrapper}>
        <Row css={{ margin: 0 }}>
          <Column xs={12} lg={8}>
            <div css={{ display: "flex", justifyContent: "space-between" }}>
              {!isMobile && <Breadcrumbs items={mainFrameBreadCrumbs} />}
            </div>
          </Column>
        </Row>
        {quotationsError && (
          <ErrorMessage
            message={quotationsError}
            handleClose={() => dismissError("quotationsList")}
          />
        )}
        <Row css={styles.mainContent}>
          {!isDrawerOpen && (
            <Column xs={12} css={styles.sidebarContainer}>
              <Sidebar menuItems={sidebarMenu} TopComponent={TopComponent}>
                {children}
              </Sidebar>
            </Column>
          )}
          {isDrawerOpen && drawerContent && (
            <Column xs={12} css={styles.drawerContainer}>
              {drawerContent}
            </Column>
          )}
        </Row>
      </div>
    </Layout>
  );
};

export default AppView;

/** @jsxImportSource @emotion/react */

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  useGetTwilioConversationsQuery,
  useGetTwilioMediaUrlLazyQuery,
  useGetTwilioWaMessagesQuery,
  useOnConversationUpdatedSubscription,
  useSendTwilioWaMessageMutation,
  useToggleUserConversationSubscriptionMutation,
} from "../../graphql/generated/types";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ChatInput from "./components/ChatInput";
import Message from "./components/Message";
import ScrollContainer from "../ScrollContainer";
import { formatPhone } from "../../utils/formatNumber";
import { theme } from "../../theme";
import serviceFetch from "../../services/fetch";
import { ObjectId } from "bson";
import PageTitle from "../PageTitle";
import { CSSObject } from "@emotion/react";
import ChatList from "./components/ChatList";
import LoadingSpinner from "../Loading/LoadingSpinner";
import LoadingOverlay from "../Loading/LoadingOverlay";
import { useParams } from "react-router-dom";
import Carousel from "../Carousel";
import { Visible } from "react-grid-system";
import { ContactHeader } from "./components/ContactHeader";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { useFlags } from "../../hooks/useFlags";
import useDebounce from "../../hooks/useDebounce";

const styles: CSSObject = {
  chatWrapper: {
    height: "100%",
  },
  conversationWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "6px",
    width: "100%",
    position: "relative",
  },
  chatListWrapper: {
    borderRight: `1px solid ${theme.colors.Grey[60]}`,
    padding: "16px",
    boxSizing: "border-box",
    height: "100%",
  },
};
interface WAChatProps {
  contactId?: string;
  showChatList?: boolean;
}

const DRAFT_MESSAGES_KEY = "DRAFT_MESSAGES";

const getLocalStorageJsonData = () => {
  const draftMessages: { [key: string]: string } = JSON.parse(
    localStorage.getItem(DRAFT_MESSAGES_KEY) || "{}"
  );
  return draftMessages;
};

const WAChat: FC<WAChatProps> = ({ contactId: _contactId, showChatList }) => {
  const auth = useAuth();
  const params = useParams();
  const contactId = _contactId || params.contactId;
  const [message, setMessage] = useState(() => {
    const draftMessages = getLocalStorageJsonData();
    return draftMessages[contactId!] || "";
  });
  const [sendingMessage, setSendingMessage] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [onlySubscribeds, setStateOnlySubscribeds] = useState(false);
  const { isFlagEnabled } = useFlags();
  const isAdmin = isFlagEnabled("admin-only");
  const { debouncedValue: debouncedMessage } = useDebounce(message, 500);

  useEffect(() => {
    const draftMessages = getLocalStorageJsonData();
    draftMessages[contactId!] = debouncedMessage;
    localStorage.setItem(DRAFT_MESSAGES_KEY, JSON.stringify(draftMessages));
  }, [debouncedMessage]);

  const { data, refetch } = useGetTwilioWaMessagesQuery({
    variables: {
      contactId: contactId!,
    },
    skip: !isAdmin,
  });

  const getConversationsQuery = useGetTwilioConversationsQuery({
    variables: { filter: { onlySubscribeds } },
    skip: !isAdmin,
  });

  const setOnlySubscribeds = useCallback(
    async (value: boolean) => {
      setStateOnlySubscribeds(value);
      await getConversationsQuery.refetch({
        filter: { onlySubscribeds: value },
      });
    },
    [setStateOnlySubscribeds, getConversationsQuery]
  );

  const [getTwilioMediaUrlQuery] = useGetTwilioMediaUrlLazyQuery();

  const [sendMessageMutation] = useSendTwilioWaMessageMutation();
  const [toggleSubscriptionMutation] =
    useToggleUserConversationSubscriptionMutation();
  const { errorHandler } = useErrorHandler();

  if (!isAdmin) return null;

  const fullyRefresh = async () => {
    await refetch();
    await getConversationsQuery.refetch();
  };
  const toggleSubscription = useCallback(async () => {
    await toggleSubscriptionMutation({
      variables: {
        contactId: contactId!,
        userId: auth.user._id,
        shouldAdd: !data?.getTwilioWAMessages.isSubscribed,
      },
    });
    await fullyRefresh();
  }, [contactId, auth.user._id, data?.getTwilioWAMessages.isSubscribed]);

  const sendMessage = async () => {
    setSendingMessage(true);
    try {
      const filePayload = await Promise.all(
        files.map(async (file) => {
          const fileKey = `twilio/conversation/${
            contact!.twilioConversationId
          }/${new ObjectId().toString()}`;
          await uploadFile(file, fileKey);
          return { fileKey, fileName: file.name };
        })
      );
      await sendMessageMutation({
        variables: {
          contactId: contactId!,
          content: message,
          files: filePayload,
        },
      });
      await fullyRefresh();
      const draftMessages = getLocalStorageJsonData();
      draftMessages[contactId!] = "";
      localStorage.setItem(DRAFT_MESSAGES_KEY, JSON.stringify(draftMessages));
      setMessage("");
      setFiles([]);
    } catch (e) {
      errorHandler(new Error("Não foi possível enviar sua mensagem"), e);
    }
    setSendingMessage(false);
  };

  const getMediaUrl = useCallback(
    async (fileKey: string) => {
      try {
        const { data } = await getTwilioMediaUrlQuery({
          variables: {
            fileKey,
          },
        });
        window.open(data!.getTwilioMediaUrl as unknown as string, "_blank");
        return data;
      } catch (e) {
        errorHandler(
          new Error("Não foi possível fazer o download desse arquivo"),
          e
        );
      }
    },
    [getTwilioMediaUrlQuery, errorHandler]
  );

  const chatBoxRef = useRef<HTMLDivElement>(null); // Reference for the chatBox container

  useEffect(() => {
    if (chatBoxRef.current) {
      const element = chatBoxRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [data?.getTwilioWAMessages.messages]); // Dependency on messages array

  useEffect(() => {
    fullyRefresh();
  }, [contactId]);

  useOnConversationUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (
        subData?.conversationUpdated?.conversationId ===
        contact?.twilioConversationId
      ) {
        fullyRefresh();
      }
    },
  });

  const {
    getTwilioWAMessages: { contact, messages, isSubscribed },
  } = data || { getTwilioWAMessages: {} };

  async function uploadFile(file: File, fileKey: string): Promise<void> {
    await serviceFetch(
      auth,
      `upload-file`,
      null,
      "post",
      {
        fileKey,
      },
      file
    );
  }

  const onFilesAccepted = (filesAdded: File[]) => {
    setFiles([...files, ...filesAdded]);
  };

  const contactLabel = contact
    ? formatPhone(contact.mobilePhone!) +
      (contact.name ? ` - ${contact.name}` : "")
    : "";

  const chatColumn = useMemo(
    () => (
      <div css={styles.conversationWrapper}>
        {!data?.getTwilioWAMessages && (
          <LoadingOverlay>
            <LoadingSpinner />
          </LoadingOverlay>
        )}
        {contact && (
          <ContactHeader
            contact={contact}
            toggleSubscription={toggleSubscription}
            isSubscribed={isSubscribed!}
          />
        )}
        <div style={{ padding: "10px 0" }}></div>
        <ScrollContainer useAnchor>
          {messages?.map((msg) => (
            <Message message={msg} key={msg._id} getMediaUrl={getMediaUrl} />
          ))}
        </ScrollContainer>
        <ChatInput
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          loading={sendingMessage}
          onFilesAccepted={onFilesAccepted}
          files={files}
          setFiles={setFiles}
        />
      </div>
    ),
    [
      contact,
      data?.getTwilioWAMessages,
      files,
      message,
      messages,
      sendingMessage,
    ]
  );

  const chatListColumn = useMemo(() => {
    if (!showChatList) return null;
    return (
      <div css={styles.chatListWrapper}>
        <ScrollContainer>
          <ChatList
            selectedContactId={contactId}
            query={getConversationsQuery}
            setOnlySubscribeds={setOnlySubscribeds}
          />
        </ScrollContainer>
      </div>
    );
  }, [getConversationsQuery, showChatList, contactId]);

  return (
    <div css={styles.chatWrapper}>
      <PageTitle title={`Conversa  - ${contactLabel}`} />
      <Visible xs sm>
        <Carousel
          columns={[chatColumn, chatListColumn]}
          rowProps={{ noGutters: true, noMargins: true }}
        />
      </Visible>
      <Visible md lg xl xxl>
        <Row noMargins noGutters noWrap css={{ height: "100%" }}>
          {showChatList && <Column md={3}>{chatListColumn}</Column>}
          <Column md={showChatList ? 9 : 12}>{chatColumn}</Column>
        </Row>
      </Visible>
    </div>
  );
};

export default WAChat;

import { FC } from "react";
import { FormContextProvider } from "./context/form";
import Form from "./form";

export type TDefaultValues = {
  [key: string]: any;
};

interface FormProps {
  formId: string;
  docId: string;
  onSubmit: (id: any) => void;
  defaultValues?: TDefaultValues;
  singleSection?: boolean;
  large?: boolean;
  loadingMessage?: string;
  displayAsTableRow?: boolean;
  onCancel?: () => void;
}

const FormApp: FC<FormProps> = ({
  formId,
  docId,
  onSubmit,
  defaultValues,
  large = false,
  loadingMessage = "Estamos processando suas informações. Aguarde um momento.",
  onCancel,
  displayAsTableRow = false,
}) => (
  <FormContextProvider formId={formId} docId={docId}>
    <Form
      formId={formId}
      docId={docId}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      large={large}
      loadingMessage={loadingMessage}
      onCancel={onCancel}
      displayAsTableRow={displayAsTableRow}
    />
  </FormContextProvider>
);

export default FormApp;

/** @jsxImportSource @emotion/react */

import { ColorNames, theme } from "../../../../theme";
import CustomLink from "../../../CustomLink";
import HR from "../../../HorizontalLine";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";
import Typography from "../../../Typography";

const styles = {
  footer: {
    display: "flex" as "flex",
    justifyContent: "space-around" as "space-around",
    alignItems: "center" as "center",
    padding: "30px 50px",
    flexWrap: "wrap" as "wrap",
  },
  footerElement: {
    display: "flex" as "flex",
    alignItems: "center" as "center",
    textDecoration: "none" as "none",
    "&:hover": {
      opacity: 0.7,
    },
    "& > span": {
      padding: 5,
      cursor: "pointer",
    },
  },
  copyRight: {
    textAlign: "center" as "center",
    padding: "10px 50px",
    backgroundColor: theme.colors.LightGrey[100],
  },
};

const Footer = () => {
  const footerElements = [
    {
      icon: IconNames.Whatsapp,
      text: "14 99725-3006",
      link: "https://api.whatsapp.com/send/?phone=5514997253006&text&type=phone_number&app_absent=0",
    },
    {
      icon: IconNames.Phone,
      text: "11 3034-3456",
    },
    {
      icon: IconNames.Email,
      text: "info@prismaagro.com.br",
      link: "mailto:info@prismaagro.com.br",
    },
    {
      icon: IconNames.Location,
      text: "Al. Ministro Rocha Azevedo, 912",
      subtext: "São Paulo / SP / 01410-002",
      link: "https://goo.gl/maps/MRJERBHxhDEYkPtE6",
    },
    {
      icon: IconNames.Facebook,
      text: "Facebook",
      link: "https://www.facebook.com/prismasegurosagro/",
    },
    {
      icon: IconNames.Instagram,
      text: "Instagram",
      link: "https://www.instagram.com/prismasegurosagro/",
    },
    {
      icon: IconNames.Linkedin,
      text: "Linkedin",
      link: "https://www.linkedin.com/company/prisma-corretora-de-seguros-agro/mycompany/",
    },
  ];

  return (
    <footer>
      <HR color={ColorNames.GREEN} spacingSize="none" borderWidth={2} />
      <div css={styles.footer}>
        {footerElements.map((element, i) => (
          <CustomLink
            to={element.link!}
            target="_blank"
            rel="noreferrer"
            css={styles.footerElement}
            key={`footerElement-${i}}`}
          >
            <Icon name={element.icon} color={ColorNames.GREEN} />
            <div>
              <Typography
                variant="textMedium"
                component="div"
                color={ColorNames.GREEN}
                weight="bold"
              >
                {element.text}
              </Typography>
              <Typography
                variant="textSmall"
                component="div"
                color={ColorNames.GREEN}
                weight="bold"
              >
                {element.subtext}
              </Typography>
            </div>
          </CustomLink>
        ))}
      </div>
      <div css={styles.copyRight}>
        Copyright 2023 Prisma Agro Corretora de Seguros | Todos os direitos
        reservados
      </div>
    </footer>
  );
};

export default Footer;

import { useMemo } from "react";
import { DocumentStages, DocumentStatus } from "../graphql/generated/types";

const useDocumentOrder = (stage: DocumentStages, status?: DocumentStatus) => {
  const order = useMemo(() => {
    const stageOrder = {
      [DocumentStages.Simulation]: 1,
      [DocumentStages.Quotation]: 2,
      [DocumentStages.Proposal]: 3,
      [DocumentStages.Policy]: 4,
    };

    const statusOrder = {
      [DocumentStatus.Incomplete]: 1,
      [DocumentStatus.Pending]: 2,
      [DocumentStatus.Done]: 3,
      [DocumentStatus.Sent]: 4,
      [DocumentStatus.Effective]: 5,
      [DocumentStatus.NonEffective]: 6,
      [DocumentStatus.UnderAnalysis]: 7,
      [DocumentStatus.Rejected]: 8,
      [DocumentStatus.Cancelled]: 9,
    };

    return stageOrder[stage] * 10 + (status ? statusOrder[status] : 0);
  }, [stage, status]);
  return order;
};

export default useDocumentOrder;

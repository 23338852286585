import { FC } from "react";

const Organization: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_192_18)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.2222 2.84265C22.0444 2.29324 23.0111 2 24 2C25.3261 2 26.5979 2.52678 27.5355 3.46447C28.4732 4.40215 29 5.67392 29 7C29 7.98891 28.7068 8.95561 28.1574 9.77785C27.6079 10.6001 26.8271 11.241 25.9134 11.6194C24.9998 11.9978 23.9945 12.0969 23.0246 11.9039C22.0546 11.711 21.1637 11.2348 20.4645 10.5355C19.7652 9.83627 19.289 8.94536 19.0961 7.97545C18.9032 7.00555 19.0022 6.00021 19.3806 5.08658C19.759 4.17295 20.3999 3.39206 21.2222 2.84265ZM25.6667 4.50559C25.1734 4.17595 24.5933 4 24 4C23.2044 4 22.4413 4.31607 21.8787 4.87868C21.3161 5.44129 21 6.20435 21 7C21 7.59334 21.1759 8.17336 21.5056 8.66671C21.8352 9.16006 22.3038 9.54458 22.852 9.77164C23.4001 9.9987 24.0033 10.0581 24.5853 9.94236C25.1672 9.8266 25.7018 9.54088 26.1213 9.12132C26.5409 8.70176 26.8266 8.16721 26.9424 7.58527C27.0581 7.00333 26.9987 6.40013 26.7716 5.85195C26.5446 5.30377 26.1601 4.83524 25.6667 4.50559Z"
          fill="black"
        />
        <path
          d="M24 14H26C27.3256 14.0015 28.5965 14.5288 29.5338 15.4662C30.4712 16.4035 30.9985 17.6744 31 19V23H29V19C28.9991 18.2046 28.6828 17.4421 28.1204 16.8796C27.5579 16.3172 26.7954 16.0009 26 16H24V14Z"
          fill="black"
        />
        <path
          d="M23 30H21V28C20.9991 27.2046 20.6828 26.4421 20.1204 25.8796C19.5579 25.3172 18.7954 25.0009 18 25H14C13.2046 25.0009 12.4421 25.3172 11.8796 25.8796C11.3172 26.4421 11.0009 27.2046 11 28V30H9V28C9.00156 26.6744 9.52885 25.4035 10.4662 24.4662C11.4035 23.5288 12.6744 23.0016 14 23H18C19.3256 23.0016 20.5965 23.5288 21.5338 24.4662C22.4712 25.4035 22.9984 26.6744 23 28V30Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2222 11.8427C14.0444 11.2932 15.0111 11 16 11C17.3261 11 18.5979 11.5268 19.5355 12.4645C20.4732 13.4021 21 14.6739 21 16C21 16.9889 20.7068 17.9556 20.1574 18.7779C19.6079 19.6001 18.8271 20.241 17.9134 20.6194C16.9998 20.9978 15.9945 21.0969 15.0246 20.9039C14.0546 20.711 13.1637 20.2348 12.4645 19.5355C11.7652 18.8363 11.289 17.9454 11.0961 16.9755C10.9031 16.0055 11.0022 15.0002 11.3806 14.0866C11.759 13.173 12.3999 12.3921 13.2222 11.8427ZM17.6667 13.5056C17.1734 13.1759 16.5933 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16C13 16.5933 13.176 17.1734 13.5056 17.6667C13.8352 18.1601 14.3038 18.5446 14.852 18.7716C15.4001 18.9987 16.0033 19.0581 16.5853 18.9424C17.1672 18.8266 17.7018 18.5409 18.1213 18.1213C18.5409 17.7018 18.8266 17.1672 18.9424 16.5853C19.0581 16.0033 18.9987 15.4001 18.7716 14.8519C18.5446 14.3038 18.1601 13.8352 17.6667 13.5056Z"
          fill="black"
        />
        <path
          d="M8 14H6C4.6744 14.0016 3.40353 14.5288 2.46619 15.4662C1.52885 16.4035 1.00156 17.6744 1 19V23H3V19C3.00087 18.2046 3.31722 17.4421 3.87964 16.8796C4.44206 16.3172 5.20462 16.0009 6 16H8V14Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.22215 2.84265C6.0444 2.29324 7.0111 2 8 2C9.32609 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7C13 7.98891 12.7068 8.95561 12.1573 9.77785C11.6079 10.6001 10.8271 11.241 9.91342 11.6194C8.99979 11.9978 7.99446 12.0969 7.02455 11.9039C6.05465 11.711 5.16373 11.2348 4.46447 10.5355C3.76521 9.83627 3.289 8.94536 3.09608 7.97545C2.90315 7.00555 3.00217 6.00021 3.3806 5.08658C3.75904 4.17295 4.39991 3.39206 5.22215 2.84265ZM9.66671 4.50559C9.17337 4.17595 8.59335 4 8 4C7.20435 4 6.44129 4.31607 5.87868 4.87868C5.31607 5.44129 5 6.20435 5 7C5 7.59334 5.17595 8.17336 5.50559 8.66671C5.83524 9.16006 6.30377 9.54458 6.85195 9.77164C7.40013 9.9987 8.00333 10.0581 8.58527 9.94236C9.16722 9.8266 9.70177 9.54088 10.1213 9.12132C10.5409 8.70176 10.8266 8.16721 10.9424 7.58527C11.0581 7.00333 10.9987 6.40013 10.7716 5.85195C10.5446 5.30377 10.1601 4.83524 9.66671 4.50559Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_192_18">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Organization;

/** @jsxImportSource @emotion/react */
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import FormApp from "../../../modules/Forms";
import { useAuth } from "../../../hooks/useAuth";
import Loading from "../../../components/Loading";
import FormTitle from "../../../modules/Forms/components/FormTitle";
import { headerHeight } from "../../../components/Layout/components/Header";
import ConfirmedActionComponent from "../../../components/Modal/ConfirmedAction";

const NfeUploadForm: FC = () => {
  const { formResponseId } = useParams();
  const { loaded } = useAuth();

  const [submitFinished, setSubmitFinished] = useState(false);

  const onSubmit = async () => {
    setSubmitFinished(true);
  };

  if (!loaded) {
    return <Loading size="extraLarge" />;
  }

  if (submitFinished) {
    return (
      <Layout useFullHeight>
        <div style={{ height: `calc(100vh - ${headerHeight}px)` }}>
          <ConfirmedActionComponent message="Informações enviadas com sucesso!" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout useFullHeight>
      <FormTitle title="Notas fiscais" />
      <FormApp formId="nfeUpload" docId={formResponseId!} onSubmit={onSubmit} />
    </Layout>
  );
};

export default NfeUploadForm;

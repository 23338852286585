import { FC, useMemo } from "react";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import useCultureDetails from "../../../hooks/useCulture";
import EditableTable from "../../../../../components/EditableTable";
import {
  GetCreateIntegrationSettingsFormResponseDocument,
  GetEditIntegrationSettingsFormResponseDocument,
} from "../../../../../graphql/generated/types";
import { AllianzSettingsModelLabels } from "../../../../../shared/settings/allianz";
import AllianzSettingsDetails, { AllianzSettingsData } from "./Details";

const AllianzSettings: FC = () => {
  const {
    culture: { allianzSettings, _id: cultureId },
    refetch,
  } = useCultureDetails();

  const data = useMemo(
    () =>
      (allianzSettings?.map(({ allianz, _id }) => ({ _id, ...allianz })) ||
        []) as AllianzSettingsData[],
    [allianzSettings]
  );

  return (
    <DetailsSection main title="Configurações - Allianz">
      <EditableTable
        createQuery={GetCreateIntegrationSettingsFormResponseDocument}
        editQuery={GetEditIntegrationSettingsFormResponseDocument}
        formDisplay="modal"
        createPayload={{
          formId: "allianzCultureSettings",
          cultureId,
        }}
        editPayload={{
          formId: "allianzCultureSettings",
        }}
        data={data}
        DetailsComponent={AllianzSettingsDetails}
        useV2
        onSubmit={refetch}
        columns={[
          {
            label: AllianzSettingsModelLabels["custoEstimado"]!,
            key: "custoEstimado",
            special: "monetary",
          },
          {
            label: AllianzSettingsModelLabels["valorSaca"]!,
            key: "valorSaca",
            special: "selectionOption",
          },
        ]}
      />
    </DetailsSection>
  );
};

export default AllianzSettings;

/** @jsxImportSource @emotion/react */

import { FC, ReactNode } from "react";
import { ColorNames } from "../../../theme";
import CustomContainer from "../../Grid/CustomContainer";

interface SkeletonContainerProps {
  children: ReactNode;
  horizontal?: boolean;
}

const SkeletonCardContainer: FC<SkeletonContainerProps> = ({
  children,
  horizontal,
}) => {
  return (
    <CustomContainer borderColor={ColorNames.LIGHTGREY} horizontal={horizontal}>
      {children}
    </CustomContainer>
  );
};

export default SkeletonCardContainer;

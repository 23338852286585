/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import Typography from "../../../../components/Typography";
import QuotationCard from "../../DocumentDetails/components/QuotationCard/index";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import Button from "../../../../components/Button";
import { theme } from "../../../../theme";
import useIsMobile from "../../../../hooks/useIsMobile";
import { breakTextLines } from "../../../../utils/stringUtils";
import { InsuranceTypeGroupEnum } from "../../../../graphql/generated/types";

const styles = {
  row: {
    marginBottom: 0,
  },
  col: {
    marginBottom: 20,
  },
};

const Quotations: FC = () => {
  const {
    handleDownloadAllComparisonPdf,
    documentData: {
      selectedSimulationResults,
      insuranceTypeGroup,
      description,
      equipments,
      farmer,
      crop,
      properties,
      justificationMessage,
      forestItemIds,
      propertyImprovementItems,
    },
  } = useDocumentDetails();
  const isMobile = useIsMobile();

  const formattedMessage = useMemo(() => {
    if (!justificationMessage) return null;
    return breakTextLines(justificationMessage).map((line, index) => (
      <Typography
        key={`textLine-${index}`}
        variant="textLarge"
        component="p"
        align="left"
      >
        {line}
      </Typography>
    ));
  }, [justificationMessage]);

  const referenceLocation = useMemo(() => {
    return selectedSimulationResults?.find(
      (result) => !!result?.referenceLocation
    )?.referenceLocation;
  }, [selectedSimulationResults]);

  const insuredItemsText = useMemo(() => {
    if (insuranceTypeGroup === InsuranceTypeGroupEnum.Agricultural) {
      return (
        <>
          sua lavoura de <strong>{description}!</strong>
        </>
      );
    }
    if (insuranceTypeGroup === InsuranceTypeGroupEnum.Machinery) {
      if (equipments?.length === 1) {
        return (
          <>
            o seu equipamento <strong>{description}!</strong>
          </>
        );
      }
      return (
        <>
          seus <strong>{description}!</strong>
        </>
      );
    }
    if (insuranceTypeGroup === InsuranceTypeGroupEnum.Forest) {
      if (forestItemIds?.length === 1) {
        return (
          <>
            a sua floresta <strong>{description}!</strong>
          </>
        );
      }
      return (
        <>
          suas <strong>{description}!</strong>
        </>
      );
    }
    if (insuranceTypeGroup === InsuranceTypeGroupEnum.PropertyImprovement) {
      if (propertyImprovementItems?.length === 1) {
        return (
          <>
            a seu(sua) <strong>{description}!</strong>
          </>
        );
      }

      return (
        <>
          suas <strong>{description}!</strong>
        </>
      );
    }
    return "";
  }, [description, insuranceTypeGroup, equipments, forestItemIds]);

  const propertiesLocations = useMemo(() => {
    const uniqueCities = new Set(
      properties!.map(({ address }) => `${address?.city}, ${address?.state}`)
    );
    return Array.from(uniqueCities).join(" // ");
  }, [properties]);

  if (!selectedSimulationResults?.length) return null;
  return (
    <>
      <Row
        justify="between"
        css={{ backgroundColor: theme.colors.LightGrey[100], padding: 6 }}
      >
        <Column xs={12} md={6}>
          <Typography
            variant="textLarge"
            weight="bold"
            align="left"
            component="p"
          >
            {description} // {propertiesLocations}
          </Typography>
        </Column>
        {insuranceTypeGroup === InsuranceTypeGroupEnum.Agricultural && (
          <Column xs={12} md={6}>
            <Typography
              variant="textLarge"
              weight="bold"
              align={isMobile ? "left" : "right"}
              component="p"
            >
              Área total segurada: {crop?.area} ha
            </Typography>
          </Column>
        )}
      </Row>
      <Row justify="end">
        <Column>
          <Typography
            variant="textExtraLarge"
            component="p"
            align="left"
            margin="sm"
          >
            Olá <strong>{farmer?.name}</strong>,
          </Typography>
          <Typography variant="textLarge" component="p" align="left">
            Obrigado pelo seu interesse na contração do seguro rural!
          </Typography>
          <Typography variant="textLarge" component="p" align="left">
            Analisamos suas informações e fizemos uma cotação indicativa para{" "}
            {insuredItemsText}
          </Typography>
          <Typography variant="textLarge" component="p" align="left">
            Aqui estão as <strong>melhores opções do mercado</strong>.
          </Typography>
        </Column>
      </Row>
      <Row css={styles.row}>
        {selectedSimulationResults.map((result) => (
          <Column
            xs={12}
            lg={6}
            xl={4}
            key={`quotationCard-${result?._id}`}
            style={styles.col}
          >
            <QuotationCard result={result!} directDocument />
          </Column>
        ))}
      </Row>
      <Row>
        <Column>
          <Typography variant="textMedium" component="p" align="left">
            *Sujeito à disponibilidade
          </Typography>
          {referenceLocation && (
            <Typography variant="textMedium" component="p" align="left">
              **Valores calculados usando como referência o município de{" "}
              {referenceLocation}
            </Typography>
          )}
        </Column>
      </Row>
      {formattedMessage && (
        <Row>
          <Column xs={12} xl={8}>
            <Typography variant="textLarge" component="p" align="left">
              Nossa equipe deixou um <strong>comentário sobre a cotação</strong>{" "}
              para você:
            </Typography>
            <Typography
              variant="textLarge"
              component="p"
              align="left"
              margin="sm"
            >
              {formattedMessage}
            </Typography>
          </Column>
        </Row>
      )}
      <Row>
        <Column>
          <Typography variant="textLarge" component="p" align="left">
            Também é possível fazer o{" "}
            <strong>download de todas as opções:</strong>
          </Typography>
          <br />
          <Button
            text="Baixar cotação completa"
            iconName={IconNames.Download}
            width="auto"
            onClick={handleDownloadAllComparisonPdf}
          />
        </Column>
      </Row>
    </>
  );
};

export default Quotations;

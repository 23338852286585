import { FC, Fragment } from "react";
import Row from "../../../../components/Grid/Row";
import Typography from "../../../../components/Typography";
import { ColorNames } from "../../../../theme";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";
import ObjectListItemFieldSummary from "./ObjectListItemFieldSummary";

type ObjectListFieldSummaryProps = {
  field: FormFieldV2;
};

const ObjectListFieldSummary: FC<ObjectListFieldSummaryProps> = ({
  field: { fields, label },
}) => {
  return (
    <Column xs={12}>
      {fields!.map((itemField, index) => (
        <Fragment key={itemField.fieldPath}>
          <Row noMargins>
            <Typography
              variant="textLarge"
              component="div"
              align="left"
              color={ColorNames.GREEN}
            >
              {`${label} ${index + 1}${
                itemField.label && ` - ${itemField.label}`
              }`}
            </Typography>
          </Row>
          <Row noMargins>
            <ObjectListItemFieldSummary field={itemField} />
          </Row>
        </Fragment>
      ))}
    </Column>
  );
};

export default ObjectListFieldSummary;

import { FC, useContext } from "react";
import Typography from "../../../../../Typography";
import { ModalStoreContext } from "../../../../../Modal/context/ModalStore";

export interface ModalVariantProps {
  Component: FC;
  text: string;
}

const ModalVariant: FC<ModalVariantProps> = ({ text, Component }) => {
  const { openModal } = useContext(ModalStoreContext);
  if (!text) {
    return (
      <Typography variant="textMedium" uppercase component="div" align="left">
        -
      </Typography>
    );
  }
  return (
    <Typography
      variant="link"
      uppercase
      component="div"
      align="left"
      noWrap
      onClick={() => openModal(<Component />)}
    >
      {text}
    </Typography>
  );
};

export default ModalVariant;

import { useParams } from "react-router-dom";
import { useGetDocumentDetailsQuery } from "../../../graphql/generated/types";
import WAChat from "..";
import { useFlags } from "../../../hooks/useFlags";

const WAChatByDocId = () => {
  const { docId: paramsId } = useParams();
  const { isFlagEnabled } = useFlags();
  const { data } = useGetDocumentDetailsQuery({
    variables: {
      docId: paramsId!,
    },
    skip: !isFlagEnabled("admin-only"),
  });

  if (!data) return null;

  return <WAChat contactId={data?.document!.farmer!._id!} />;
};
export default WAChatByDocId;

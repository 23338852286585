import React, { CSSProperties } from "react";
import { Link, LinkProps } from "react-router-dom";

interface CustomLinkProps extends LinkProps {
  styles?: CSSProperties;
}

const defaultStyles: CSSProperties = {
  textDecoration: "none",
  color: "inherit",
};

const CustomLink: React.FC<CustomLinkProps> = ({
  styles: customStyles,
  children,
  ...rest
}) => {
  const mergedStyles: CSSProperties = { ...defaultStyles, ...customStyles };

  return (
    <Link {...rest} style={mergedStyles}>
      {children}
    </Link>
  );
};

export default CustomLink;

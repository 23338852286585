const Forest = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1541_9857)">
      <path
        d="M14.5921 22H16.8142V20.5714H14.5921V14.1429H17.5549C18.1752 14.1429 18.7886 14.0177 19.3557 13.7754C19.9228 13.533 20.431 13.179 20.8477 12.7359C21.2643 12.2928 21.5802 11.7706 21.7751 11.2027C21.9699 10.6348 22.0393 10.0339 21.9788 9.43864C21.8422 8.36321 21.2992 7.37412 20.4536 6.66062C19.6081 5.94712 18.5194 5.55933 17.3959 5.57143H17.371C16.5863 3.41257 14.3542 2 11.6293 2C10.5903 2.00287 9.57019 2.26824 8.67068 2.76967C7.77116 3.2711 7.02361 3.99107 6.50249 4.85786C6.48316 4.85714 6.46382 4.85714 6.44427 4.85714C5.26558 4.85714 4.13516 5.30867 3.3017 6.1124C2.46823 6.91613 2 8.00622 2 9.14286C2 10.2795 2.46823 11.3696 3.3017 12.1733C4.13516 12.977 5.26558 13.4286 6.44427 13.4286V12C5.65847 12 4.90486 11.699 4.34922 11.1632C3.79358 10.6273 3.48142 9.90062 3.48142 9.14286C3.48142 8.3851 3.79358 7.65837 4.34922 7.12255C4.90486 6.58673 5.65847 6.28571 6.44427 6.28571C6.54038 6.28847 6.63616 6.29773 6.73093 6.31343L7.37016 6.39507L7.59867 5.9185C7.95554 5.17691 8.52425 4.5488 9.23813 4.10779C9.952 3.66679 10.7815 3.43117 11.6293 3.42857C13.8742 3.42857 15.6676 4.64007 16.092 6.44357L16.2228 7H17.3959C18.1515 6.98549 18.8862 7.24 19.4595 7.71489C20.0329 8.18979 20.4046 8.85176 20.5037 9.57429C20.5446 9.97141 20.4988 10.3724 20.3692 10.7514C20.2396 11.1304 20.0291 11.479 19.7514 11.7748C19.4736 12.0706 19.1346 12.307 18.7563 12.4689C18.378 12.6307 17.9688 12.7143 17.5549 12.7143H13.1107V20.5714C13.1111 20.9502 13.2674 21.3133 13.5451 21.5811C13.8228 21.8489 14.1993 21.9995 14.5921 22Z"
        fill="black"
      />
      <path
        d="M7.18495 22H9.40708C9.79984 21.9995 10.1764 21.8489 10.4541 21.5811C10.7318 21.3133 10.8881 20.9502 10.8885 20.5714V11.6903C10.8877 11.4437 10.8212 11.2015 10.6953 10.9869C10.5693 10.7723 10.3883 10.5926 10.1695 10.4651L7.56611 8.95905L6.80377 10.1839L9.40708 11.6903V20.5714H7.18495V22Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1541_9857">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Forest;

import { hotjar } from "react-hotjar";
const REACT_APP_HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID || "";
const REACT_APP_HOTJAR_SV = process.env.REACT_APP_HOTJAR_SV || "";

const useHotjar = (): void => {
  if (!hotjar.initialized()) {
    hotjar.initialize(
      REACT_APP_HOTJAR_ID as unknown as number,
      REACT_APP_HOTJAR_SV as unknown as number
    );
  }
};

export default useHotjar;

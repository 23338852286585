import { FC } from "react";

const Facebook: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_306_215)">
        <path
          d="M26.67 4H5.33C4.97807 4.00262 4.6413 4.14358 4.39244 4.39244C4.14358 4.6413 4.00262 4.97807 4 5.33V26.67C4.00262 27.0219 4.14358 27.3587 4.39244 27.6076C4.6413 27.8564 4.97807 27.9974 5.33 28H16.82V18.72H13.7V15.09H16.82V12.42C16.82 9.32 18.71 7.63 21.49 7.63C22.42 7.63 23.35 7.63 24.28 7.77V11H22.37C20.86 11 20.57 11.72 20.57 12.77V15.08H24.17L23.7 18.71H20.57V28H26.67C27.0219 27.9974 27.3587 27.8564 27.6076 27.6076C27.8564 27.3587 27.9974 27.0219 28 26.67V5.33C27.9974 4.97807 27.8564 4.6413 27.6076 4.39244C27.3587 4.14358 27.0219 4.00262 26.67 4Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_306_215">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;

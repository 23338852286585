/** @jsxImportSource @emotion/react */
import { FC, CSSProperties } from "react";
import { FormSection } from "../models/formInterfaces";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";
import Container from "../../../components/Grid/Container";
import SectionTitle from "./SectionTitle";
import FormSumarrySectionRows from "./FormSummarySectionRows";

interface FormSectionProps {
  sectionData: FormSection;
  handleEditSelection: () => void;
}

const styles = {
  container: {
    justifyContent: "space-evenly" as CSSProperties["justifyContent"],
  },
};

const FormSectionSummary: FC<FormSectionProps> = ({
  sectionData,
  handleEditSelection,
}) => {
  return (
    <Container css={styles.container}>
      <Row>
        <Column>
          <SectionTitle
            sectionData={sectionData}
            handleEdit={handleEditSelection}
          />
        </Column>
      </Row>
      <FormSumarrySectionRows
        fields={sectionData.fields}
        groupPath={sectionData.id}
      />
    </Container>
  );
};

export default FormSectionSummary;

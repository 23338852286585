import { FC } from "react";

const ChevronUp: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_817)">
        <path
          d="M16 10L26 20L24.6 21.4L16 12.8L7.4 21.4L6 20L16 10Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_817">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronUp;

/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { NotificationResponse } from "../../../../../../../graphql/generated/types";
import moment from "moment";
import HR from "../../../../../../../components/HorizontalLine";
import Typography from "../../../../../../../components/Typography";
import { ColorNames } from "../../../../../../../theme";
import Notification from "./Notification";

interface NotificationGroupProps {
  notifications: NotificationResponse;
  redirectLocation: (docId: string) => string;
}

const NotificationGroup: FC<NotificationGroupProps> = ({
  notifications,
  redirectLocation,
}) => {
  const isToday = useMemo(() => {
    return moment()
      .startOf("day")
      .isSame(moment(notifications.notificationDate).startOf("day"));
  }, [notifications.notificationDate]);

  const color = useMemo(
    () => (isToday ? ColorNames.PINK : ColorNames.BLACK),
    [isToday]
  );

  const date = useMemo(() => {
    if (isToday) return "Hoje";
    return moment(notifications.notificationDate).format("D [de] MMMM");
  }, [isToday, notifications.notificationDate]);

  return (
    <>
      <Typography
        variant="textMedium"
        align="left"
        component="div"
        weight="bold"
        color={color}
        uppercase
      >
        {date} [{notifications.dateNotifications.length}]
      </Typography>
      <HR dashedLine color={color} spacingSize="small" />
      {notifications.dateNotifications.map((notification) => (
        <Notification
          notification={notification}
          key={`${date}${notification.notificationGroup}`}
          redirectLocation={redirectLocation}
        />
      ))}
    </>
  );
};

export default NotificationGroup;

import { FC } from "react";

const Send: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_214_86)">
        <path
          d="M27.45 15.11L5.45001 4.11001C5.27758 4.02377 5.0839 3.98922 4.89228 4.01051C4.70067 4.0318 4.5193 4.10803 4.37001 4.23001C4.22743 4.3495 4.12101 4.50639 4.06272 4.68305C4.00442 4.85971 3.99655 5.04912 4.04001 5.23001L6.69001 15H18V17H6.69001L4.00001 26.74C3.95923 26.8911 3.95447 27.0496 3.98611 27.2028C4.01775 27.356 4.0849 27.4997 4.18217 27.6223C4.27943 27.7448 4.4041 27.8428 4.54614 27.9084C4.68818 27.9741 4.84364 28.0054 5.00001 28C5.15655 27.9991 5.31068 27.9614 5.45001 27.89L27.45 16.89C27.6138 16.8061 27.7513 16.6786 27.8473 16.5216C27.9433 16.3645 27.994 16.1841 27.994 16C27.994 15.816 27.9433 15.6355 27.8473 15.4785C27.7513 15.3214 27.6138 15.1939 27.45 15.11Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_214_86">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Send;

import { FC } from "react";

const Scrutiny: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_409_18)">
        <path d="M18 2H2V4H18V2Z" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3115 23.8975L30 28.5859L28.5859 30L23.8975 25.3115C22.2846 26.5646 20.2548 27.1559 18.2213 26.9649C16.1878 26.7739 14.3036 25.815 12.9523 24.2835C11.6009 22.7519 10.8842 20.7629 10.9479 18.7215C11.0117 16.68 11.8512 14.7396 13.2954 13.2954C14.7396 11.8512 16.68 11.0117 18.7215 10.948C20.7629 10.8842 22.7519 11.601 24.2834 12.9523C25.815 14.3036 26.7739 16.1878 26.9649 18.2213C27.1559 20.2548 26.5646 22.2846 25.3115 23.8975ZM15.6666 23.9888C16.6533 24.6481 17.8133 25 19 25C20.5908 24.9983 22.1159 24.3656 23.2407 23.2407C24.3655 22.1159 24.9982 20.5908 25 19C25 17.8133 24.6481 16.6533 23.9888 15.6666C23.3295 14.6799 22.3924 13.9109 21.2961 13.4567C20.1997 13.0026 18.9933 12.8838 17.8294 13.1153C16.6656 13.3468 15.5965 13.9182 14.7573 14.7574C13.9182 15.5965 13.3468 16.6656 13.1153 17.8295C12.8838 18.9933 13.0026 20.1997 13.4567 21.2961C13.9108 22.3925 14.6799 23.3295 15.6666 23.9888Z"
          fill="black"
        />
        <path d="M2 12H10V14H2V12Z" fill="black" />
        <path d="M2 7H18V9H2V7Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_409_18">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Scrutiny;

/** @jsxImportSource @emotion/react */

import { CSSProperties, FC, ReactNode, useEffect, useRef } from "react";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { theme } from "../../theme";
import useIsMobile from "../../hooks/useIsMobile";

const getCSSStyles = ({
  height,
  padding,
  isMobile,
}: {
  height: string;
  padding: number;
  isMobile: boolean;
}) => ({
  scroll: {
    overflow: "scroll" as const,
    overflowX: isMobile ? ("scroll" as const) : ("hidden" as const),
    height: height,
    padding: `0px ${padding}px`,
    boxSizing: "border-box" as "border-box",
    "&:hover": {
      "::-webkit-scrollbar-thumb": {
        backgroundColor: theme.colors.Grey[100],
      },

      "::-moz-scrollbar-thumb:": {
        backgroundColor: theme.colors.Grey[100],
      },
    },
  },
});

interface ScrollContainerProps {
  height?: string;
  padding?: number;
  children: ReactNode;
  useAnchor?: boolean;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  style?: CSSProperties;
}

const ScrollContainer: FC<ScrollContainerProps> = ({
  height = "100%",
  padding = 12,
  children,
  useAnchor,
  onScroll,
  style,
}) => {
  const isMobile = useIsMobile();
  const styles = useCSSRulesWithTheme(getCSSStyles, {
    height,
    padding,
    isMobile,
  });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (useAnchor) {
      ref.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }
  }, [children]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    onScroll && onScroll(e);
  };

  return (
    <div
      css={styles.scroll}
      style={style}
      onScroll={(e) => handleScroll(e)}
      id={"#scroll-container"}
    >
      {children}
      {useAnchor && <div ref={ref}></div>}
    </div>
  );
};

export default ScrollContainer;

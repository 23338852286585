/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useUIStore } from "../../hooks/useUIStore";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import { useLocation } from "react-router-dom";
import { CardFC } from "./components/Card";
import NoDocumentsMessage from "./components/NoDocumentsMessage";
import { useParams } from "react-router-dom";
import SkeletonCardList from "./components/SkeletonCardList";
import CustomLink from "../CustomLink";
import ScrollContainer from "../ScrollContainer";
import { Maybe } from "../../graphql/generated/types";

interface CardListProps<T> {
  data: T[];
  redirectLocation: (id: string) => string;
  showNewQuotationsCard?: boolean;
  CardComponent: CardFC<any>;
  loading?: boolean;
}
const CardList = <T extends { _id?: Maybe<string> }>({
  redirectLocation,
  data,
  showNewQuotationsCard,
  CardComponent,
  loading,
}: CardListProps<T>) => {
  const { isDrawerOpen } = useUIStore();
  const { docId } = useParams();
  const location = useLocation();
  const [activeCard, setActiveCard] = useState("");

  // activate card when change location
  useEffect(() => {
    if (showNewQuotationsCard && location.pathname.includes("form")) {
      setActiveCard("new");
    }
    if (docId) {
      setActiveCard(docId);
    } else {
      setActiveCard("");
    }
  }, [location, docId]);

  return (
    <ScrollContainer>
      {loading || !data ? (
        <SkeletonCardList />
      ) : (
        <Row>
          {!data.length && <NoDocumentsMessage />}
          {data.map((cardData, i) => (
            <Column
              xs={12}
              md={isDrawerOpen ? 12 : 6}
              lg={isDrawerOpen ? 12 : 4}
              key={`card-${i}`}
              css={{ marginBottom: 24 }}
            >
              <CustomLink to={redirectLocation(cardData._id?.toString() || "")}>
                <CardComponent
                  active={activeCard === cardData._id?.toString()}
                  data={cardData}
                />
              </CustomLink>
            </Column>
          ))}
        </Row>
      )}
    </ScrollContainer>
  );
};

export default CardList;

/** @jsxImportSource @emotion/react */

import { ReactNode, FC } from "react";
import { css } from "@emotion/react";
import { ColorNames, theme } from "../../theme";
import HR from "../HorizontalLine";

const styles = {
  container: {
    boxSizing: "border-box" as "border-box",
    padding: 20,
    height: "100%",
    width: "100%",
    borderRadius: 10,
  },
};

const borderWidth = 2;

interface CustomContainerProps {
  children: ReactNode;
  borderColor?: ColorNames;
  horizontal?: boolean;
  style?: any;
}

const CustomContainer: FC<CustomContainerProps> = ({
  children,
  borderColor = ColorNames.GREEN,
  horizontal = false,
  style,
}) => {
  const customStyles = {
    border: horizontal ? "none" : `2px solid ${theme.colors[borderColor][100]}`,
    height: horizontal ? `calc(100% - ${2 * borderWidth}px)` : `100%`,
    ...style,
  };
  return (
    <>
      {horizontal && (
        <HR
          color={borderColor}
          spacingSize="none"
          borderWidth={borderWidth}
          width="100%"
        />
      )}
      <div css={css(styles.container, customStyles)}>{children}</div>
      {horizontal && (
        <HR
          color={borderColor}
          spacingSize="none"
          borderWidth={borderWidth}
          width="100%"
        />
      )}
    </>
  );
};

export default CustomContainer;

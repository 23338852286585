import { FileData } from "../services/fetch";

export const openFile = (fileData: FileData) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(fileData[0]);
  downloadLink.download = fileData[1];
  downloadLink.click();
  // Clean up the temporary download link
  URL.revokeObjectURL(downloadLink.href);
};

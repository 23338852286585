/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import {
  ContactTags,
  Services,
} from "../../../../../../graphql/generated/types";
import UserSelect from "../../../../../../components/Details/components/Main/UserSelect";
import DetailsSectionColumn from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import ContactSelect from "../../../../../../components/Details/components/Main/ContactSelect";
import { useAuth } from "../../../../../../hooks/useAuth";
import { UserRoles } from "../../../../../../graphql/generated/types";
import Many2OneInput from "../../../../../../components/Input/SelectInputs/Many2OneInput";
import { useFlags } from "../../../../../../hooks/useFlags";
import { Maybe } from "graphql/jsutils/Maybe";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";

interface SelectInput {
  inputName: string;
  onSelect: (value: string) => void;
  value?: Maybe<string> | undefined;
  domain?: Record<string, any>;
  disabled?: boolean;
  flag?: string;
  isClearable?: boolean;
  type: "user" | "contact";
}

const Selects = () => {
  const {
    documentData: {
      commercialResponsibleId,
      operationalResponsibleId,
      claimResponsibleId,
      originatorId,
      contactId,
      specialOperationId,
      crop,
    },
    handleSetCommercialResponsible,
    handleSetOperationalResponsible,
    handleSetClaimResponsible,
    handleSetContact,
    handleSetSpecialOperation,
  } = useDocumentDetails();

  const auth = useAuth();
  const { isFlagEnabled } = useFlags();

  const selectInputs: SelectInput[] = useMemo(() => {
    return [
      {
        inputName: "Responsável Comercial",
        value: commercialResponsibleId,
        onSelect: handleSetCommercialResponsible,
        domain: { role: UserRoles.Admin },
        flag: "admin-only",
        type: "user",
      },
      {
        inputName: "Responsável Operacional",
        value: operationalResponsibleId,
        onSelect: handleSetOperationalResponsible,
        domain: { role: UserRoles.Admin },
        flag: "admin-only",
        type: "user",
      },
      {
        inputName: "Responsável pelo Sinistro",
        value: claimResponsibleId,
        onSelect: handleSetClaimResponsible,
        domain: { role: UserRoles.Admin },
        flag: "admin-only",
        type: "user",
      },
      {
        inputName: "Parceiro",
        value: originatorId,
        onSelect: () => {},
        disabled: true,
        domain: {
          tags: { $in: [ContactTags.Consultant, ContactTags.Organization] },
        },
        flag: "admin-only",
        type: "contact",
      },
      {
        inputName: "Contato",
        value: contactId,
        onSelect: handleSetContact,
        disabled: false,
        isClearable: false,
        type: "contact",
      },
    ].filter(Boolean) as SelectInput[];
  }, [
    commercialResponsibleId,
    operationalResponsibleId,
    claimResponsibleId,
    originatorId,
    contactId,
  ]);

  const selectsInfo: DetailsRowProps[] = useMemo(() => {
    return selectInputs
      .map((select) => {
        if (select.flag && !isFlagEnabled(select.flag)) return;
        return {
          label: select.inputName,
          value:
            select.type === "user" ? (
              <UserSelect
                value={select.value!}
                onSelect={(option) => select.onSelect(option?.value!)}
                inputName={select.inputName}
                placeholder={select.inputName}
                domain={select.domain}
                isClearable={select.isClearable}
              />
            ) : (
              <ContactSelect
                value={select.value}
                onSelect={(option) => select.onSelect(option?.value!)}
                inputName={select.inputName}
                placeholder={select.inputName}
                disabled={select.disabled}
                domain={select.domain}
                isClearable={select.isClearable}
              />
            ),
        };
      })
      .filter(Boolean) as DetailsRowProps[];
  }, [selectInputs, isFlagEnabled]);

  const specialOperationInfo = useMemo(
    () =>
      crop?.cultureId && isFlagEnabled("admin-only")
        ? [
            {
              label: "Operação Especial",
              value: (
                <Many2OneInput
                  serviceName={Services.SpecialOperations}
                  optionDisplay="avatar"
                  domain={{ cultureId: crop?.cultureId }}
                  value={specialOperationId}
                  placeholder="Operação Especial"
                  onSelect={(option) =>
                    handleSetSpecialOperation(option?.value)
                  }
                  inputName="operationalResponsbibleId"
                  isClearable
                  disabled={auth.user.role !== UserRoles.Admin}
                />
              ),
            },
          ]
        : [],
    [specialOperationId, crop]
  );

  return (
    <DetailsSectionColumn
      xs={12}
      sm={6}
      rows={[...selectsInfo, ...specialOperationInfo]}
    />
  );
};

export default Selects;

import React from "react";
import Avatar from "../../../Avatar";
import TableCell from "./TableCell";
import { IconSizes } from "../../../Icons/styles/iconSizes";

interface AvatarCellProps {
  name?: string;
}

const AvatarCell: React.FC<AvatarCellProps> = ({ name }) => (
  <TableCell>
    <Avatar name={name || ""} size={IconSizes.Large} />
  </TableCell>
);

export default AvatarCell;

import { AllianzSettings } from '../../graphql/generated/types';
import { TModelFieldLabels } from '../types';

export const AllianzSettingsModelLabels: TModelFieldLabels<AllianzSettings> = {
  cultura: 'Cultura',
  percentualComissao: 'Comissao (%)',
  custoEstimado: 'Custo Estimado',
  tipoProduto: 'Tipo de Produto',
  culturaAnterior: 'Cultura Anterior',
  tecnicaPlantio: 'Técnica De Plantio',
  custeioProvenienteCreditoRurual: 'Custeio Proveniente de Crédito Rural?',
  realizadoControleFitosantitario: 'É realizado controle fitosanitário recomendado pelas Instituições de Pesquisa?',
  regulacaoSinistro: 'Forma de regulação no sinistro',
  texturaSolo: 'Textura do Solo',
  grupoCultivar: 'Grupo Cultivar',
  codigoCultivar: 'Cultivar',
  corte: 'Corte (cana)',
  franquia: 'Franquia',
  percentualDesconto: 'Desconto (%)',
  coberturas: 'Coberturas',
  codigoNivelCobertura: 'Nível de Cobertura',
  percentualProdutividadeEstimada: 'Percentual Produtividade Estimada',
  valorSaca: 'Valor Unitário',
  formaPagamento: 'Método de Pagamento',
  quantidadeParcelas: 'Número de Parcelas',
};

/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { CustomTheme, theme } from "../../theme";
import Typography from "../Typography";
import { SelectInputOptionInterface } from "./SelectInputs/SelectInput";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { css } from "@emotion/react";

const getStyles = ({ colors }: CustomTheme) => ({
  col: {
    paddingBottom: 16,
  },
  option: {
    backgroundColor: "white",
    textOverflow: "ellipsis",
    height: 45,
    borderRadius: 5,
    //@ts-ignore
    boxSizing: "border-box" as CSSProperties["box-sizing"],
    border: "0.2px solid rgba(0, 0, 0, 0.2)",
    boxShadow: theme.boxShadow.default,
    cursor: "pointer",
    width: "100%",
    overflow: "hidden",
    //@ts-ignore
    whiteSpace: "nowrap" as CSSProperties["white-space"],
    "&:hover": {
      border: `1px solid ${colors.Green[100]}`,
    },
  },
  content: {
    lineHeight: "45px",
  },
  active: {
    border: `1px solid ${colors.Green[100]}`,
    color: colors.Green[100],
  },
});

export interface ButtonSelectInputProps {
  error: string;
  options: SelectInputOptionInterface[];
  name: string;
  onSelect: (option: SelectInputOptionInterface, inputName: string) => void;
  value: string;
}

const ButtonSelectInput: FC<ButtonSelectInputProps> = ({
  name,
  options,
  onSelect,
  value,
  error,
}) => {
  const styles = useCSSRulesWithTheme(getStyles, {});

  const selectedOptionIndex = options?.findIndex(
    (option) => option.value === value
  );

  return (
    <Container>
      <Row>
        {options?.map((option, i) => (
          <Column xs={12} sm={6} md={4} key={option.value} css={styles.col}>
            <div
              css={css(
                styles.option,
                selectedOptionIndex === i && styles.active
              )}
              onClick={() => onSelect(option, name)}
            >
              <Typography
                variant="textMedium"
                component={"div"}
                css={styles.content}
                lowercase
                align="center"
              >
                {option.label}
              </Typography>
            </div>
          </Column>
        ))}
        {error && (
          <Typography
            variant="textSmall"
            color="ErrorRed"
            align="left"
            component="div"
            css={{ width: "100%" }}
          >
            {error}
          </Typography>
        )}
      </Row>
    </Container>
  );
};

export default ButtonSelectInput;

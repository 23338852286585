/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorAlias, getThemeColorFromAlias, theme } from "../../theme";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { css } from "@emotion/react";

interface ExclamationIconProps {
  color?: ColorAlias;
  onClick?: () => void;
  style?: any;
}

const getStyles = ({ cursor }: { cursor: string }) => ({
  cursor: cursor,
});

const ExclamationIcon: FC<ExclamationIconProps> = ({
  color,
  onClick,
  style,
}) => {
  const iconColor = color
    ? getThemeColorFromAlias(color, theme.colors)
    : getThemeColorFromAlias("Green", theme.colors);
  const cursor = onClick ? "pointer" : "arrow";
  const styles = useCSSRulesWithTheme(getStyles, { cursor });

  return (
    <span css={css(styles, style)} onClick={onClick}>
      <svg
        width="30"
        height="15"
        viewBox="0 0 5 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.734626 0L1.05438 12.8386H3.58332L3.99027 0H0.734626ZM2.36245 15.1457C1.19972 15.1457 0.269531 16.0143 0.269531 17.0729C0.269531 18.1586 1.22879 19 2.33338 19C3.49611 19 4.45537 18.1586 4.45537 17.0729C4.45537 16.0143 3.49611 15.1457 2.36245 15.1457Z"
          fill={iconColor}
        />
      </svg>
    </span>
  );
};

export default ExclamationIcon;

import React, { useEffect } from "react";
import ListView from "../../../components/ListView/ListView";
import { DocumentsTableData, mapTableData } from "./tableColumns";
import {
  ListDocumentsQuery,
  useBulkUpdateClaimResponsibleMutation,
  useBulkUpdateCommercialResponsibleMutation,
  useBulkUpdateOperationalResponsibleMutation,
  useCountDocumentsQuery,
  useListDocumentsQuery,
} from "../../../graphql/generated/types";
import { useTableContext } from "../../../contexts/table";
import DocumentCard from "../DocumentCards/DocumentCard";
import { ToDocumentDetailsLocation } from "../../../Locations";
import UploadDocumentModal from "./UploadDocumentModal";
import { useModalStore } from "../../../components/Modal/context/ModalStore";
import UpdateResponsiblesModal from "./UpdateResponsiblesModal/UpdateResponsiblesModal";
import { MutationFunctionOptions } from "@apollo/client";

interface DocumentsTableProps<TFilter> {
  filter: TFilter;
}

const DocumentsTable = <TFilter extends { composeQuery: () => any[][] }>({
  filter,
}: DocumentsTableProps<TFilter>) => {
  const { paginator, actions, setData } = useTableContext();
  const { openModal, closeModal } = useModalStore();

  const documentsQuery = useListDocumentsQuery({
    variables: {
      filter: filter.composeQuery(),
      sort: { [actions.sort.sortKey]: actions.sort.direction },
      limit: paginator.limit,
      skip: paginator.skip,
    },
  });

  const countDocumentsQuery = useCountDocumentsQuery({
    variables: {
      filter: filter.composeQuery(),
    },
  });

  useEffect(() => {
    if (documentsQuery.data?.documents) {
      setData(documentsQuery.data?.documents);
    }
  }, [documentsQuery]);

  const refetchData = () => {
    documentsQuery.refetch();
  };

  const [bulkUpdateOperationalResponsible] =
    useBulkUpdateOperationalResponsibleMutation();

  const [bulkUpdateCommercialResponsible] =
    useBulkUpdateCommercialResponsibleMutation();

  const [bulkUpdateClaimResponsible] = useBulkUpdateClaimResponsibleMutation();

  const openResponsibleModal = <TData, TResponse>(
    label: string,
    mutation: (
      options: MutationFunctionOptions<
        TData,
        {
          responsibleId: string;
          documentIds: string | string[];
        }
      >
    ) => Promise<TResponse>
  ) => {
    openModal(
      <UpdateResponsiblesModal
        label={label}
        onClick={async (id: string) => {
          mutation({
            variables: {
              documentIds: Object.keys(actions.selectedRows).filter(
                (key) => !!actions.selectedRows[key] && key !== "all"
              ),
              responsibleId: id,
            },
          });
          await documentsQuery.refetch();
          closeModal();
        }}
      />,
      undefined,
      {
        styles: {
          container: {
            height: 250,
            width: 500,
            top: 0,
            left: 0,
            right: 0,
            margin: "auto",
          },
          content: {
            overflow: "visibile",
          },
        },
      }
    );
  };

  const massActions = [
    {
      label: "ALTERAR RESP. OPERACIONAL",
      onClick: () => {
        openResponsibleModal("OPERACIONAL", bulkUpdateOperationalResponsible);
      },
    },
    {
      label: "ALTERAR RESP. COMERCIAL",
      onClick: () => {
        openResponsibleModal("COMERCIAL", bulkUpdateCommercialResponsible);
      },
    },
    {
      label: "ALTERAR RESP. SINISTRO",
      onClick: () => {
        openResponsibleModal("SINISTRO", bulkUpdateClaimResponsible);
      },
    },
  ];

  return (
    <ListView<DocumentsTableData, ListDocumentsQuery["documents"][number]>
      CardComponent={DocumentCard}
      loading={documentsQuery.loading}
      redirectLocation={ToDocumentDetailsLocation}
      mapTableData={mapTableData}
      total={countDocumentsQuery.data?.countDocuments || 0}
      uploadModal={<UploadDocumentModal refetch={refetchData} />}
      bulkActions={massActions}
    />
  );
};

export default DocumentsTable;

import { FC } from "react";

const ChevronDown: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_815)">
        <path
          d="M16 22L6 12L7.4 10.6L16 19.2L24.6 10.6L26 12L16 22Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_815">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronDown;

/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useMemo, useState } from "react";
import Container from "../../../../components/Grid/Container";
import Row from "../../../../components/Grid/Row";
import Typography from "../../../../components/Typography";
import { ColorNames, theme } from "../../../../theme";
import { FormField, ObjectListFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import { CSSObject } from "@emotion/react";
import CloseIcon from "../../../../components/Icons/closeIcon";
import FormSectionRows from "../FormSectionRows";
import { useForm } from "../../hooks/useForm";
import {
  composeFieldPath,
  deepCopy,
  filterRelevantPaths,
  findGroupObj,
} from "./utils";
import ArrowUpIcon from "../../../../components/Icons/arrowUpIcon";
import ArrowDownIcon from "../../../../components/Icons/arrowDownIcon";
import Column from "../../../../components/Grid/Column";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";

const styles: CSSObject = {
  clickable: {
    cursor: "pointer",
  },
  plusContainer: {
    border: "1px solid",
    borderRadius: 5,
    overflow: "auto",
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "45px",
    width: "45px",
    marginRight: "1.5em",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      paddingLeft: 10,
    },
  },
  buttons: {},
};

const createInitialState = (fields: FormField[]) => {
  return fields.reduce(
    (initialState, field: FormField) => ({
      ...initialState,
      [field.name]: field.value || null,
    }),
    {}
  );
};

const FormInputObjectList: FC<ObjectListFieldType & { groupPath: string }> = ({
  fields,
  name,
  label,
  groupPath,
  allowCopy,
}) => {
  const [value] = useFieldValue(name, groupPath);
  const {
    formState,
    handleChange,
    errors,
    setClickedSubmit,
    clearErrors,
    clickedSubmit,
  } = useForm();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggleShowObject = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const hasNestedErrors = useMemo(() => {
    return !!Object.keys(
      filterRelevantPaths(errors, `${composeFieldPath(groupPath, name)}.`)
    ).length;
  }, [errors]);

  useEffect(() => {
    if (clickedSubmit) {
      setOpenIndexes(
        value
          .map((_: any, i: any) => i)
          .filter(
            (i: any) =>
              !!Object.keys(
                filterRelevantPaths(
                  errors,
                  `${composeFieldPath(groupPath, name)}.${i}`
                )
              ).filter((key) => !!errors[key]).length
          )
      );
    }
  }, [clickedSubmit]);

  const handleAddNew = () => {
    setClickedSubmit(false);
    if (!hasNestedErrors) {
      const newState = deepCopy(formState);
      const groupObj = findGroupObj(newState, groupPath);
      const value = [...groupObj[name], createInitialState(fields)];
      handleChange({ target: { value, name } } as any, groupPath);
      setActiveIndex(value.length - 1);
      setOpenIndexes([value.length - 1]);
    }
  };

  const handleDuplicate = () => {
    setClickedSubmit(false);
    if (!hasNestedErrors) {
      const newValue = [...value, value[value.length - 1]];
      handleChange({ target: { value: newValue, name } } as any, groupPath);
      setActiveIndex(newValue.length - 1);
      setOpenIndexes([newValue.length - 1]);
    }
  };
  useEffect(() => {
    document.querySelector("input")?.focus();
  }, [activeIndex]);

  const deleteItem = (index: number) => {
    clearErrors(`${groupPath}.${name}`);
    const groupObj = findGroupObj(formState, groupPath);
    const value = [
      ...groupObj[name].slice(0, index),
      ...groupObj[name].slice(index + 1),
    ];
    handleChange({ target: { value, name } } as any, groupPath);
    const filteredIndexes = openIndexes.filter((i) => i !== index);
    const newOpenIndexes = filteredIndexes.map((i) => {
      if (i > index) {
        return i - 1;
      }
      return i;
    });
    setOpenIndexes(newOpenIndexes);
  };

  const titles = useMemo(() => {
    return value.map(
      (item: any, i: number) =>
        `${label} ${i + 1}${item.name ? ` - ${item.name}` : ""}`
    );
  }, [activeIndex, groupPath, value]);
  return (
    <Container>
      {value &&
        value.map((item: any, i: number) => {
          const newGroupPath = composeFieldPath(groupPath, `${name}.${i}`);
          const itemErrors = Object.values(
            filterRelevantPaths(errors, newGroupPath)
          );
          return (
            <React.Fragment key={newGroupPath}>
              <Row
                align="center"
                justify="between"
                css={styles.clickable}
                noGutters
                onClick={() => toggleShowObject(i)}
              >
                <Row noGutters css={{ margin: 0 }}>
                  <Typography
                    variant="textLarge"
                    color={ColorNames.GREEN}
                    uppercase
                    component="p"
                  >
                    {titles[i]}
                  </Typography>
                </Row>
                {hasNestedErrors && (
                  <Typography
                    variant="textSmall"
                    color={ColorNames.RED}
                    align="left"
                    component="p"
                  >
                    talhão é obrigatório.
                  </Typography>
                )}
                <div css={styles.icons}>
                  {openIndexes.includes(i) ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  )}
                  <CloseIcon onClick={() => deleteItem(i)} />
                </div>
              </Row>
              {openIndexes.includes(i) && (
                <FormSectionRows fields={fields} groupPath={newGroupPath} />
              )}
              {itemErrors.length > 0 && i !== activeIndex && clickedSubmit && (
                <div css={{ marginTop: "-1.5em" }}>
                  {itemErrors.map((error) => (
                    <Typography
                      variant="textSmall"
                      color="ErrorRed"
                      align="left"
                      component="div"
                      css={{ width: "100%" }}
                    >
                      {error}
                    </Typography>
                  ))}
                </div>
              )}
            </React.Fragment>
          );
        })}
      <Row css={styles.buttons} noGutters>
        <Column xs={12} lg={6}>
          <Row
            align="center"
            justify="start"
            css={styles.clickable}
            onClick={handleAddNew}
            noGutters
          >
            <div css={styles.plusContainer}>
              <Icon name={IconNames.Add} color={ColorNames.GREEN} />
            </div>
            <Typography variant="textLarge" color={ColorNames.GREEN} uppercase>
              {`Adicionar ${label}`}
            </Typography>
          </Row>
        </Column>
        {allowCopy && !!value?.length && (
          <Column xs={12} lg={6}>
            <Row
              align="center"
              justify="start"
              css={styles.clickable}
              onClick={handleDuplicate}
              noGutters
            >
              <div css={styles.plusContainer}>
                <Icon name={IconNames.Add} color={ColorNames.GREEN} />
              </div>
              <Typography
                variant="textLarge"
                color={ColorNames.GREEN}
                uppercase
              >
                Duplicar Anterior
              </Typography>
            </Row>
          </Column>
        )}
      </Row>
    </Container>
  );
};

export default FormInputObjectList;

export const rejectReasons = [
  {
    value: 'informacoes-invalidas',
    label: 'Cadastro com informações inválidas',
  },
  {
    value: 'cadastro-duplicado',
    label: 'Já existe um cadastro para este parceiro',
  },
  {
    value: 'suspeita-spam-bot',
    label: 'Suspeita de spam ou bot',
  },
  {
    value: 'outros',
    label: 'Outros',
  },
];

import { FC } from "react";
import { useForm } from "../../hooks/useForm";
import { Many2ManyFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import Many2ManyInput from "../../../../components/Input/SelectInputs/Many2ManyInput";

const FormInputMany2Many: FC<Many2ManyFieldType & { groupPath: string }> = ({
  serviceName,
  domain,
  name,
  label,
  groupPath,
  disabled,
  multiValueDisplay,
}) => {
  const [value, error] = useFieldValue(name, groupPath);
  const { handleChange, startEditing, finishEditing } = useForm();
  const handleSelect = (values: string[]) => {
    handleChange({ target: { value: values, name } } as any, groupPath);
  };

  return (
    <Many2ManyInput
      serviceName={serviceName}
      domain={domain}
      value={value}
      inputName={name}
      placeholder={label}
      onSelect={handleSelect}
      optionDisplay="old"
      onFocus={() => startEditing(groupPath, name)}
      onBlur={() => finishEditing(groupPath, name)}
      errorMessage={error}
      disabled={disabled}
      multiValueDisplay={multiValueDisplay}
      hideDropdown
    />
  );
};

export default FormInputMany2Many;

/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Column from "../../../components/Grid/Column";
import Row from "../../../components/Grid/Row";
import Typography from "../../../components/Typography";

interface TermLineProps {
  section?: string;
  subSection?: string;
  children: ReturnType<typeof Typography>;
}

const TermLine: FC<TermLineProps> = ({ children, section, subSection }) => {
  return (
    <Row noGutters>
      <Column xs={1} width={30} css={{ textAlign: "right" }}>
        {section && (
          <Typography
            variant="textMedium"
            color="Green"
            align="right"
            margin="sm"
            component="p"
          >
            {section}.
          </Typography>
        )}
      </Column>
      <Column xs={1} width={30} css={{ textAlign: "left" }}>
        {subSection && (
          <Typography
            variant="textMedium"
            color="Green"
            align="left"
            margin="sm"
            component="p"
          >
            {subSection}.
          </Typography>
        )}
      </Column>
      <Column xs={10}>{children}</Column>
    </Row>
  );
};

export default TermLine;

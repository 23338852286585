/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorAlias, getThemeColorFromAlias, theme } from "../../theme";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { css } from "@emotion/react";

interface ArrowDownIconProps {
  onClick?: () => void;
  color?: ColorAlias;
  style?: any;
}

const getStyles = ({ cursor }: { cursor: string }) => ({
  cursor: cursor,
});

const ArrowDownIcon: FC<ArrowDownIconProps> = ({ onClick, color, style }) => {
  const iconColor = color
    ? getThemeColorFromAlias(color, theme.colors)
    : getThemeColorFromAlias("Green", theme.colors);
  const cursor = onClick ? "pointer" : "arrow";
  const styles = useCSSRulesWithTheme(getStyles, { cursor });
  return (
    <span css={css(styles, style)} onClick={onClick}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 27 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L13.367 13.5L26 1"
          stroke={iconColor}
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};

export default ArrowDownIcon;

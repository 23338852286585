import { FC } from "react";
import { FormSection } from "../models/formInterfaces";
import Column from "../../../components/Grid/Column";
import FormInput from "./FormInput";
import Icon from "../../../components/Icons";
import { IconNames } from "../../../components/Icons/styles/iconNames";

interface FormRowProps {
  sectionData: FormSection;
  handleFormSubmit: () => void;
  handleCancel?: () => void;
}

const TableRowForm: FC<FormRowProps> = ({
  sectionData,
  handleFormSubmit,
  handleCancel,
}) => {
  return (
    <>
      {sectionData.fields.map((field) => (
        <Column key={field.name}>
          <FormInput fieldProps={field} groupPath={""} />
        </Column>
      ))}
      <Column xs={1}>
        <Icon name={IconNames.Valid} onClick={handleFormSubmit} />
        <Icon name={IconNames.Close} onClick={handleCancel} />
      </Column>
    </>
  );
};

export default TableRowForm;

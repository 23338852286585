/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import TableHeader from "./TableHeader";
import TableRow, { TableAction, TableRowColumn } from "./TableRow";
import Container from "../Grid/Container";
import { ColorNames } from "../../theme";
import { FC } from "react";
import CustomLink from "../CustomLink";
import Typography from "../Typography";
import { useModalStore } from "../Modal/context/ModalStore";

export const styles: CSSObject = {
  table: {
    textTransform: "uppercase",
    width: "100%",
    overflow: "display",
  },
  emptyListTextWrapper: {
    textTransform: "none",
    paddingTop: "10px",
  },
};

export interface TableColumn<T> extends TableRowColumn<T> {
  label: string;
}

export interface TableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  actions?: TableAction<T>[];
  color?: ColorNames;
  withBorder?: boolean;
  DetailsComponent?: FC<{ data: T }>;
  rowLink?: (row: T) => string;
}

const Table = <T,>({
  columns,
  data,
  actions,
  color,
  withBorder,
  DetailsComponent,
  rowLink,
}: TableProps<T>) => {
  const { closeModal } = useModalStore();

  return (
    <>
      <Container css={styles.table}>
        <TableHeader
          columns={columns}
          hasActions={!!actions?.length}
          color={color}
          withBorder={withBorder}
        />
        {!data.length && (
          <div css={styles.emptyListTextWrapper}>
            <Typography variant="textMedium">Lista vazia</Typography>
          </div>
        )}
        {data.map((row, index) => {
          const tableRow = (
            <TableRow
              key={`row-${index}`}
              actions={actions}
              columns={columns}
              data={row}
              borderColor={withBorder ? color || ColorNames.BLACK : undefined}
              DetailsComponent={DetailsComponent}
            />
          );

          return rowLink ? (
            <CustomLink
              to={rowLink(row)}
              onClick={closeModal}
              key={`row-link-${index}`}
            >
              {tableRow}
            </CustomLink>
          ) : (
            tableRow
          );
        })}
      </Container>
    </>
  );
};

export default Table;

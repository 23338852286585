import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TDefaultValues } from "../../../modules/Forms";
import {
  ToDocumentDetailsLocation,
  ToSimulationLocation,
} from "../../../Locations";
import { useAuth } from "../../../hooks/useAuth";
import { useCreateFormResponseV2Mutation } from "../../../graphql/generated/types";
import FormV2 from "../../../modules/Forms/form.v2";

const NewSimulation = () => {
  const { formId, docId } = useParams();
  const authState = useAuth();
  const navigate = useNavigate();

  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();
  const { user } = useAuth();

  useEffect(() => {
    if (formId && docId === "new" && authState.loaded) {
      asyncCreateFormResponse(formId);
    }
  }, [formId, docId, authState.loaded]);

  const asyncCreateFormResponse = async (formId: string) => {
    try {
      const { data } = await createFormResponseMutation({
        variables: {
          formId: formId!,
          metadata: {},
        },
      });
      navigate(ToSimulationLocation(data!.createFormResponseV2, formId), {
        replace: true,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const onSubmit = async ({ documentId }: any) => {
    navigate(ToDocumentDetailsLocation(documentId));
  };

  const defaultValues: TDefaultValues = {};
  if (user && user._id) {
    defaultValues.userInfo = { originatorId: user._id };
  }

  return (
    <>
      {docId && formId && docId !== "new" && (
        <FormV2
          formId={formId}
          formResponseId={docId}
          onSubmit={onSubmit}
          // defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default NewSimulation;

import { useEffect } from "react";
import { useTableContext } from "../../../../contexts/table";
import {
  ListContactsQuery,
  useCountContactsQuery,
  useListContactsQuery,
} from "../../../../graphql/generated/types";
import ListView from "../../../../components/ListView/ListView";
import { ContactsTableData, mapTableData } from "./tableColumns";
import ContactCard from "../ContactCard";
import { ToContactDetailsLocation } from "../../../../Locations";

interface ContactsTableProps<TFilter> {
  filter: TFilter;
}

const ContactsTable = <TFilter extends { composeQuery: () => any[][] }>({
  filter,
}: ContactsTableProps<TFilter>) => {
  const { paginator, actions, setData } = useTableContext();

  const contactsQuery = useListContactsQuery({
    variables: {
      filter: filter.composeQuery(),
      sort: { [actions.sort.sortKey]: actions.sort.direction },
      limit: paginator.limit,
      skip: paginator.skip,
    },
  });

  const countContactsQuery = useCountContactsQuery({
    variables: {
      filter: filter.composeQuery(),
    },
  });

  useEffect(() => {
    if (contactsQuery.data?.contacts) {
      setData(contactsQuery.data?.contacts);
    }
  }, [contactsQuery]);

  return (
    <ListView<ContactsTableData, ListContactsQuery["contacts"][number]>
      CardComponent={ContactCard}
      loading={contactsQuery.loading}
      redirectLocation={ToContactDetailsLocation}
      mapTableData={mapTableData}
      total={countContactsQuery.data?.countContacts || 0}
    />
  );
};

export default ContactsTable;

import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Table from "../../../../../components/Table";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import PropertyData from "./PropertyData";
import { Address, Property } from "../../../../../graphql/generated/types";

const PropertiesTable: FC = () => {
  const {
    documentData: { properties },
  } = useDocumentDetails();

  const propertiesList = useMemo(() => {
    if (!properties) return [];
    return properties.map(
      (property) =>
        ({
          ...property,
          ...property.address,
        } as Property & Address)
    );
  }, [properties]);

  return (
    <DetailsSection title="Propriedades">
      <Table
        data={propertiesList}
        columns={[
          {
            label: "Nome",
            key: "name",
          },
          {
            label: "CEP",
            key: "cep",
          },
          {
            label: "Cidade",
            key: "city",
          },
          {
            label: "Estado",
            key: "state",
          },
        ]}
        DetailsComponent={PropertyData}
      />
    </DetailsSection>
  );
};

export default PropertiesTable;

/** @jsxImportSource @emotion/react */

import { Visible } from "react-grid-system";
import {
  FarmersLocation,
  DocumentsDashboardLocation,
  PartnersDashboardLocation,
  CultureListLocation,
} from "../../Locations";
import AppView from "../../components/AppView";
import Column from "../../components/Grid/Column";
import Row from "../../components/Grid/Row";
import { IconNames } from "../../components/Icons/styles/iconNames";
import { MenuItem } from "../../components/Sidebar";
import SidebarTopButtons from "./components/SidebarTopButtons";
import SwitchContainer, { SwitchOption } from "./components/SwitchContainer";
import Carousel from "../../components/Carousel";
import SelectInsuranceType from "./components/SelectInsuranceType";
import { useFlags } from "../../hooks/useFlags";
import { FC, useMemo } from "react";
import Notifications from "./components/SwitchContainer/components/Notifications";
import { Services } from "../../graphql/generated/types";
import Tasks from "./components/SwitchContainer/components/Tasks";
import ChatListQueryWrapper from "../../components/WAChat/components/ChatListQueryWrapper";
import ScrollContainer from "../../components/ScrollContainer";
import StageCharts, { StageChartsProps } from "./components/StageCharts";
import { TModelFilter } from "../../hooks/useModelFilter";
import FunnelCards, { FunnelCardsProps } from "./components/FunnelCards";
import Filters from "../../components/SearchBar/Filters";

const baseInsuranceMenu: MenuItem[] = [
  {
    title: "Seguros",
    location: DocumentsDashboardLocation,
  },
  {
    title: "Parceiros",
    location: PartnersDashboardLocation,
    flags: ["admin-only"],
  },
];

const styles = {
  row: {
    height: "100%",
    width: "100%",
  },
  col: {
    height: "100%",
    position: "relative" as const,
  },
  firstCol: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between" as const,
    gap: 24,
  },
};

interface DashboardProps {
  serviceName: Services;
  docIdRedirectLocation: (id: string) => string;
  typeFilter: TModelFilter;
  chartData: StageChartsProps;
  funnelData: FunnelCardsProps;
  filterList: TModelFilter;
}

const Dashboard: FC<DashboardProps> = ({
  serviceName,
  docIdRedirectLocation,
  typeFilter,
  chartData,
  funnelData,
  filterList,
}) => {
  const { isFlagEnabled, flagsLoaded } = useFlags();

  const selectedTypeLabel = useMemo(() => {
    const selectedTypeFilter = typeFilter.filters[0].value[0];
    const selectedTypeLabel = typeFilter.filters[0].options.find(
      (option) => option.value === selectedTypeFilter
    )?.label;
    return selectedTypeLabel;
  }, [typeFilter]);

  const firstColumn = (
    <ScrollContainer>
      <div css={styles.firstCol}>
        <SelectInsuranceType filter={typeFilter} />
        <StageCharts
          selectedTypeLabel={selectedTypeLabel}
          data={chartData.data}
          loading={chartData.loading}
        />
        <FunnelCards data={funnelData.data} loading={funnelData.loading} />
      </div>
    </ScrollContainer>
  );

  const switchOptions = useMemo(() => {
    return [
      isFlagEnabled("admin-only") && {
        iconName: IconNames.Chat,
        element: <ChatListQueryWrapper />,
      },
      {
        iconName: IconNames.Task,
        element: (
          <Tasks
            serviceName={serviceName}
            redirectLocation={docIdRedirectLocation}
          />
        ),
      },
      {
        iconName: IconNames.Notification,
        element: (
          <Notifications
            serviceName={serviceName}
            redirectLocation={docIdRedirectLocation}
          />
        ),
      },
      {
        iconName: IconNames.Filter,
        element: <Filters filter={filterList} />,
      },
    ].filter(Boolean) as SwitchOption[];
  }, [isFlagEnabled]);

  const insuranceMenu = useMemo(() => {
    return [
      ...baseInsuranceMenu,
      {
        title: "Produtores",
        location: FarmersLocation,
        flags: ["admin-only"],
      },
      {
        title: "Culturas",
        location: CultureListLocation,
        flags: ["admin-only"],
      },
    ];
  }, [isFlagEnabled]);

  const secondColumn = useMemo(
    () => flagsLoaded && <SwitchContainer options={switchOptions} />,
    [switchOptions, flagsLoaded]
  );
  return (
    <AppView sidebarMenu={insuranceMenu} TopComponent={SidebarTopButtons}>
      <Visible xs sm md lg>
        <Carousel columns={[firstColumn, secondColumn]} />
      </Visible>
      <Visible xl xxl xxxl>
        <Row noMargins noGutters css={styles.row}>
          <Column xs={12} xl={8} css={styles.col}>
            {firstColumn}
          </Column>
          <Column xs={12} xl={4} css={styles.col}>
            {secondColumn}
          </Column>
        </Row>
      </Visible>
    </AppView>
  );
};

export default Dashboard;

import { FC } from "react";

const SkipBack: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_296_158)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM23 22C22.83 21.9999 22.6629 21.9566 22.5142 21.8743L13.5142 16.8743C13.3582 16.7877 13.2283 16.661 13.1378 16.5072C13.0473 16.3535 12.9996 16.1784 12.9996 16C12.9996 15.8216 13.0473 15.6465 13.1378 15.4927C13.2283 15.339 13.3582 15.2123 13.5142 15.1257L22.5142 10.1257C22.6664 10.0411 22.8381 9.99769 23.0123 9.99983C23.1864 10.002 23.357 10.0496 23.5071 10.1379C23.6572 10.2262 23.7817 10.3522 23.8681 10.5034C23.9546 10.6546 24 10.8258 24 11V21C24 21.2652 23.8946 21.5196 23.7071 21.7071C23.5196 21.8946 23.2652 22 23 22ZM10 22H8V10H10V22Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_296_158">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SkipBack;

import { FC } from "react";

const Download: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_821)">
        <path
          d="M24.59 12.59L26 14L16 24L6 14L7.41 12.59L15 20.17V2H17V20.17L24.59 12.59Z"
          fill="black"
        />
        <path
          d="M26 28V24H28V28C28 28.5304 27.7893 29.0391 27.4142 29.4142C27.0391 29.7893 26.5304 30 26 30H6C5.46957 30 4.96086 29.7893 4.58579 29.4142C4.21071 29.0391 4 28.5304 4 28V24H6V28H26Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_821">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Download;

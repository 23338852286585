import React, { useState, useMemo } from "react";
import {
  GoogleMap,
  MarkerF,
  OverlayViewF,
  OverlayView,
  PolygonF,
} from "@react-google-maps/api";
import MarkerLabel from "./components/MarkerLabel";
import { LatLng } from "../../types";

const styles = {
  mapContainer: {
    height: "100%",
    width: "100%",
  },
};

interface MapProps {
  center: LatLng;
  onClick?: (event: google.maps.MapMouseEvent) => void;
  markers?: LatLng[];
  polygons?: LatLng[][];
}

const Map: React.FC<MapProps> = ({
  center,
  onClick,
  markers = [],
  polygons = [],
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const viewport = useMemo(() => {
    if (!polygons.length) return { center, zoom: 10 };

    let minLat = Number.MAX_VALUE;
    let maxLat = -Number.MAX_VALUE;
    let minLng = Number.MAX_VALUE;
    let maxLng = -Number.MAX_VALUE;

    let hasPoint = false;
    polygons.forEach((polygon) => {
      polygon.forEach(({ lat, lng }) => {
        hasPoint = true;
        minLat = Math.min(minLat, lat);
        maxLat = Math.max(maxLat, lat);
        minLng = Math.min(minLng, lng);
        maxLng = Math.max(maxLng, lng);
      });
    });

    const bounds = {
      north: maxLat,
      south: minLat,
      east: maxLng,
      west: minLng,
    };

    return {
      zoom: 14,
      bounds,
      center: hasPoint
        ? { lat: (maxLat + minLat) / 2, lng: (maxLng + minLng) / 2 }
        : center,
    };
  }, [isLoaded]);

  const mappedMarkers = useMemo(() => {
    if (!isLoaded || polygons.length) return null;
    return markers.map((marker, index) => (
      <React.Fragment key={`${index}-{marker.lat}-${marker.lng}`}>
        <MarkerF position={marker} />
      </React.Fragment>
    ));
  }, [viewport, markers]);

  const labels = useMemo(() => {
    if (!isLoaded || !polygons.length) return null;
    return markers.map((marker, index) => (
      <React.Fragment key={`${index}-{marker.lat}-${marker.lng}`}>
        <OverlayViewF
          position={marker}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <MarkerLabel text={`${index + 1}`} />
        </OverlayViewF>
      </React.Fragment>
    ));
  }, [viewport, polygons, isLoaded]);

  const mappedPolygons = useMemo(() => {
    if (!isLoaded) return null;
    return polygons.map((polygon) => (
      <PolygonF
        key={`polygon-${polygon[0]?.lng}`}
        paths={polygon}
        options={{
          fillColor: "red",
          fillOpacity: 0.3,
          strokeColor: "red",
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
    ));
  }, [viewport, polygons, isLoaded]);

  return (
    <GoogleMap
      zoom={viewport.zoom}
      center={viewport.center}
      onClick={onClick}
      mapContainerStyle={styles.mapContainer}
      onLoad={() => setIsLoaded(true)}
      mapTypeId={polygons.length ? "satellite" : undefined}
    >
      {mappedMarkers}
      {labels}
      {mappedPolygons}
    </GoogleMap>
  );
};

export default Map;

import { FC } from "react";

const Phone: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_306_218)">
        <path
          d="M20.33 21.48L22.57 19.24C22.8717 18.9421 23.2534 18.7381 23.6688 18.6529C24.0842 18.5677 24.5154 18.6049 24.91 18.76L27.64 19.85C28.0388 20.0119 28.3808 20.2882 28.6228 20.6441C28.8648 21 28.9961 21.4196 29 21.85V26.85C28.9977 27.1428 28.9361 27.4321 28.8191 27.7004C28.702 27.9688 28.5318 28.2107 28.3188 28.4116C28.1058 28.6124 27.8544 28.7681 27.5796 28.8693C27.3048 28.9704 27.0124 29.0149 26.72 29C7.59001 27.81 3.73001 11.61 3.00001 5.41001C2.96612 5.10555 2.99709 4.79736 3.09086 4.50572C3.18463 4.21408 3.33909 3.9456 3.54408 3.71795C3.74906 3.49029 3.99993 3.30862 4.28017 3.18487C4.56041 3.06113 4.86367 2.99813 5.17001 3.00001H10C10.431 3.00129 10.8518 3.13148 11.2082 3.37384C11.5646 3.6162 11.8404 3.95965 12 4.36001L13.09 7.09001C13.2503 7.48308 13.2912 7.91465 13.2076 8.33082C13.124 8.74699 12.9196 9.12931 12.62 9.43001L10.38 11.67C10.38 11.67 11.67 20.4 20.33 21.48Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_306_218">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Phone;

import { FC } from "react";

const Edit: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_139_855)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8 2.6L25.4 6.2C26.2 7 26.2 8.2 25.4 9L10.4 24H4V17.6L19 2.6C19.8 1.8 21 1.8 21.8 2.6ZM24 7.6L20.4 4L17.4 7L21 10.6L24 7.6ZM6 18.4V22H9.6L19.6 12L16 8.4L6 18.4Z"
          fill="black"
        />
        <path d="M2 28V26H30V28H2Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_139_855">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Edit;

import { createElement, FC, memo, useMemo } from "react";
import { FormFieldV2, FormInputTypes } from "../../graphql/generated/types";
import TextField from "./components/inputV2/TextField";
import ObjectField from "./components/inputV2/ObjectField";
import ObjectListField from "./components/inputV2/ObjectListField";
import DropdownField from "./components/inputV2/DropdownField";
import Many2manyField from "./components/inputV2/Many2manyField";
import MonetaryField from "./components/inputV2/MonetaryField";
import Many2OneField from "./components/inputV2/Many2OneField";
import NumberField from "./components/inputV2/NumberField";
import DateField from "./components/inputV2/DateField";
import FileField from "./components/inputV2/FileField";
import CityList from "./components/inputV2/CityList";
import MultiSelectField from "./components/inputV2/MultiSelect";
import SelectField from "./components/inputV2/SelectField";
import RadioField from "./components/inputV2/RadioField";
import BooleanField from "./components/inputV2/BooleanFields";
import DetailsSectionTitle from "../../components/Details/components/Main/Section/DetailsSectionTitle";
import Column from "../../components/Grid/Column";
import GeoLocationField from "./components/inputV2/GeoLocationField";
import PolygonField from "./components/inputV2/PolygonField";
import TextAreaField from "./components/inputV2/TextAreaField";

interface FormFieldProps {
  field: FormFieldV2;
}

const FormField: FC<FormFieldProps> = memo(({ field }) => {
  const { inputType, invisible, divider } = field;
  const inputComponent = useMemo(() => {
    if (invisible) {
      return null;
    }
    switch (inputType) {
      case FormInputTypes.TextField:
        return TextField;
      case FormInputTypes.Object:
        return ObjectField;
      case FormInputTypes.ObjectList:
        return ObjectListField;
      case FormInputTypes.Dropdown:
        return DropdownField;
      case FormInputTypes.Select:
        return SelectField;
      case FormInputTypes.Radio:
        return RadioField;
      case FormInputTypes.Boolean:
        return BooleanField;
      case FormInputTypes.MultiSelect:
        return MultiSelectField;
      case FormInputTypes.Many2one:
        return Many2OneField;
      case FormInputTypes.Many2many:
        return Many2manyField;
      case FormInputTypes.Monetary:
        return MonetaryField;
      case FormInputTypes.Float:
      case FormInputTypes.Integer:
        return NumberField;
      case FormInputTypes.Date:
        return DateField;
      case FormInputTypes.File:
        return FileField;
      case FormInputTypes.CityList:
        return CityList;
      case FormInputTypes.GeoLocation:
        return GeoLocationField;
      case FormInputTypes.Polygon:
        return PolygonField;
      case FormInputTypes.TextArea:
        return TextAreaField;
      default:
        return TextField;
    }
  }, [inputType, invisible]);

  if (!inputComponent) return <></>;

  const element = createElement<{ field: FormFieldV2 }>(inputComponent as FC, {
    field,
  });
  return (
    <>
      {divider && (
        <Column xs={12} useYPaddings>
          <DetailsSectionTitle title={divider} subtitle />
        </Column>
      )}

      {element}
    </>
  );
});

export default FormField;

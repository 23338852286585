import React, { FC, useEffect } from "react";
import DateInput from "../../../../components/Input/DateInput";
import { useForm } from "../../hooks/useForm";
import { TextFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import useDebounce from "../../../../hooks/useDebounce";
import FormLabel from "../FormLabel";

const FormInputDate: FC<TextFieldType & { groupPath: string }> = ({
  name,
  label,
  groupPath,
}) => {
  const [value, inputError] = useFieldValue(name, groupPath);
  const { handleChange, startEditing, finishEditing } = useForm();

  const [localValue, setLocalValue] = React.useState<string>(value);
  const { debouncedValue } = useDebounce(localValue, 1000);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };
  useEffect(() => {
    if (debouncedValue !== value) {
      handleChange(
        {
          target: { name, value: debouncedValue },
        } as React.ChangeEvent<HTMLInputElement>,
        groupPath
      );
    }
  }, [debouncedValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <>
      <FormLabel>{label.toLocaleUpperCase()}</FormLabel>
      <DateInput
        value={localValue}
        name={name}
        placeholder={label}
        errorMessage={inputError}
        onFocus={() => startEditing(groupPath, name)}
        onBlur={() => finishEditing(groupPath, name)}
        onChange={handleInputChange}
      />
    </>
  );
};

export default FormInputDate;

import { FC } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Table from "../../../../../components/Table";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import PropertyImprovementData from "./PropertyImprovementData";
import { PropertyImprovementItemLabels } from "../../../../../shared/propertyImprovements/propertyImprovementLabels";

const PropertyImprovementsTable: FC = () => {
  const {
    documentData: { propertyImprovementItems },
  } = useDocumentDetails();

  return (
    <DetailsSection title="Benfeitorias">
      <Table
        data={propertyImprovementItems || []}
        columns={[
          {
            label: PropertyImprovementItemLabels.improvement,
            key: "improvement",
            special: "many2one",
          },
          {
            label: PropertyImprovementItemLabels.property,
            key: "property",
            special: "many2one",
          },
          {
            label: PropertyImprovementItemLabels.riskValue,
            key: "riskValue",
            special: "monetary",
          },
          {
            label: PropertyImprovementItemLabels.isRenewal,
            key: "isRenewal",
            special: "boolean",
          },
          {
            label: PropertyImprovementItemLabels.hasGoodsInsurance,
            key: "hasGoodsInsurance",
            special: "boolean",
          },
        ]}
        DetailsComponent={PropertyImprovementData}
      />
    </DetailsSection>
  );
};

export default PropertyImprovementsTable;

/** @jsxImportSource @emotion/react */

import { ValueContainerProps, components } from "react-select";
import Typography from "../../../Typography";

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  const selectProps = props.selectProps as any;
  const styles = {
    display: "grid",
    padding: "0px 0px 0px 12px",
  };

  return (
    <components.ValueContainer {...props}>
      <Typography
        variant={
          selectProps.textVariant ? selectProps.textVariant : "textMedium"
        }
        component="div"
        weight={selectProps.weight ? selectProps.weight : "regular"}
        css={styles}
      >
        {children}
      </Typography>
    </components.ValueContainer>
  );
};

export default ValueContainer;
